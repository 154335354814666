import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class LogService {
	public header: Object;
	private baseApiUrl: string;
	

	constructor(private http: HttpClient) {
		this.baseApiUrl = environment.baseApiUrl ;
  }
  
  public logMessage(message : string){

    return this.http.post(this.baseApiUrl + '/medical-market-logs/v1/ecomlogs', message);
  }

}
