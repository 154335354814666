import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { NgSelectData } from 'src/app/csp/model/singleselect-dropdown.model';
import { OrderSearchService } from 'src/app/csp/services/order-search.service';

@Component({
  selector: 'invoice-search-component',
  templateUrl: './invoice-search-component.component.html',
  styleUrls: ['./invoice-search-component.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class InvoiceSearchComponentComponent implements OnInit {
  @Input() pricingDiscrepancy : boolean=false;
  @Input() showError : boolean;
  @Output() setInvoiceNumber = new EventEmitter();
  @Output() onSearchIconClick = new EventEmitter();
  isVisibleInvoiceDetail: boolean = false;
  cspShowSpinner: boolean = false;
  InvoiceDropdownValue: NgSelectData[] = [
    { value: 1, label: 'Invoice Number'}
  ];
  selectedItem: NgSelectData;
  searchInputVal: string;
  invoiceData: any;
  isInvoiceSearchError: boolean;
  invoiceSearchErrorMsg: string;
  invoiceSearchErrorMsgLine2: string;
  invoiceSearchErrorMsgLine3: string;
  
  constructor(private orderSearchService: OrderSearchService) { }

  ngOnInit(): void {
    this.invoiceData = null;
    this.isVisibleInvoiceDetail = false;
    this.isInvoiceSearchError = false;
    this.clearInputString();
    this.selectedItem = this.InvoiceDropdownValue[0];
  }

  searchInvoiceForInputValue(searchInputValueString: string){
    this.onSearchIconClick.emit();
    this.cspShowSpinner = true;
    this.isInvoiceSearchError = false;
    this.isVisibleInvoiceDetail = false;
    var currencyFormatter = new Intl.NumberFormat('en-US', {style: 'currency',currency: 'USD'});
    this.orderSearchService.searchInvoice(searchInputValueString).subscribe((data: any) => {
      this.cspShowSpinner = false;
      this.isVisibleInvoiceDetail = true;
      const invoiceDetails = data;
      if(invoiceDetails && invoiceDetails.invoiceHeader){
        const orderHeaderdetails = invoiceDetails.invoiceHeader;
          this.invoiceData = {
                                invoiceNumber: orderHeaderdetails.invoiceNumber,
                                type: orderHeaderdetails.type,
                                soldTo: orderHeaderdetails.soldTo.name + ' ' + orderHeaderdetails.soldTo.id,
                                shipTo: orderHeaderdetails.shipTo.name + ' ' +  orderHeaderdetails.shipTo.id,
                                totalDue: currencyFormatter.format(orderHeaderdetails.totalDue),
                                dueDate: orderHeaderdetails.dueDate,
                                purchaseOrderNumber: orderHeaderdetails.purchaseOrderNumber,
                                orderNumber: orderHeaderdetails.orderNumber
                            };
          if(this.pricingDiscrepancy){
            if(orderHeaderdetails.type == "Purchase"){
              this.setInvoiceNumber.emit(invoiceDetails);
            }else{
              this.isInvoiceSearchError = true;
              this.isVisibleInvoiceDetail = false;
              this.invoiceSearchErrorMsg = "We found a match for your Invoice#, but it is a Credit Invoice.";
              this.invoiceSearchErrorMsgLine2 = "Please choose a Purchase invoice to report a Pricing Discrepancy.";
              this.invoiceSearchErrorMsgLine3 = "Please confirm the Invoice number entered.";

            }
          }else{
            this.setInvoiceNumber.emit(invoiceDetails);                  
          }
      }else{
        this.cspShowSpinner = false;
        this.isInvoiceSearchError = true;
        this.isVisibleInvoiceDetail = false;
        this.invoiceSearchErrorMsg = "No results found.";
        this.invoiceSearchErrorMsgLine2 = "Please confirm the Invoice number entered.";
        this.invoiceSearchErrorMsgLine3 = "Please confirm that you have access to the Ship To account associated with the Invoice number entered.";
      }
    }, (error) => {
      this.cspShowSpinner = false;
      this.isInvoiceSearchError = true;
      this.isVisibleInvoiceDetail = false;
      this.invoiceSearchErrorMsg = "No results found.";
      this.invoiceSearchErrorMsgLine2 = "Please confirm the Invoice number entered.";
      this.invoiceSearchErrorMsgLine3 = "Please confirm that you have access to the Ship To account associated with the Invoice number entered.";
       });
  }
  public clearInputString(){
    this.searchInputVal = "";
  }
  
}
