export class RequestTypeStatusMapping  {
    public static readonly REQ_TYPE_STATUS_MAP = new Map<string, Array<string>>([
        [
            "RT_1", [
                        "Shipped",
                        "Dropship",
                        "Pending",
                        "Scheduled for shipping",
                        "Backordered",
                        "OutOfStock",
                        "Inandout"
                    ]       
        ],
        [
            "RT_2", [
                        "Dropship",
                        "Scheduled for shipping",
                        "Pending",
                        "Inandout",
                        "Backordered",
                        "OutOfStock",
                        "Rejected"
                        
                    ]       
        ],
        [
            "RT_3", [
                        "Pending",
                        "Shipped",
                        "Rejected",
                        "Scheduled for shipping",
                        "Dropship",
                        "OutOfStock",
                        "Backordered",
                        "Inandout"
                    ]       
        ],
        [
            "RT_4", [
                        "OutOfStock",
                        "Shipped",
                        "Inandout",
                        "Pending",
                        "Rejected",
                        "Scheduled for shipping",
                        "Dropship",
                        "Backordered",
                        
                    ]       
        ],
        [
            "RT_5", [
                        "Inandout",
                        "Shipped",
                        "Dropship",
                        "Pending",
                        "OutOfStock",
                        "Rejected",
                        "Scheduled for shipping",
                        "Backordered",
                        
                    ]       
        ],
        [
            "RT_6", [
                        "Rejected",        
                        "Backordered",
                        "Shipped",
                        "Dropship",
                        "Pending",
                        "Scheduled for shipping",
                        "OutOfStock",
                        "Inandout"
                    ]       
        ],
        [
            "RT_7", [
                        "Scheduled for shipping",
                        "OutOfStock",
                        "Shipped",
                        "Dropship",
                        "Pending",
                        "Backordered",
                        "Rejected",
                        "Inandout"
                    ]       
        ],
        [
            "RT_8", [
                        "Shipped",
                        "Dropship",

                    ]       
        ],
        [
            "RT_16", [
                        "Shipped",
                        "Dropship"
                    ]       
        ],
        [
            "RT_17",[
                        "Shipped",
                        "Dropship"
                    ]
        ],
        [
            "RT_18",[
                        "Shipped",
                        "Dropship"
                    ]
        ],
        [
            "RT_19",[
                        "Shipped",
                        "Dropship"
                    ]
        ],
        [
            "RT_22",[
                        "Shipped",
                        "Dropship"
                    ]
        ],
        [
            "RT_23",[
                        "Shipped",
                        "Dropship"
                    ]
        ],
        [
            "RT_27",[
                        "Shipped",
                        "Dropship"
                    ]
        ],
        [
            "RT_28",[
                        "Dropship",
                        "Shipped"
                    ]
        ],[
            "RT_29",[
                        "Dropship",
                        "Shipped"
                    ]
        ],
        [
            "RT_30",[
                        "Dropship",
                        "Shipped",
                        "Inandout"
                    ]
        ],
        [
            "RT_31",[
                        "Dropship",
                        "Shipped"
                    ]
        ],
        [
            "RT_32",[
                        "Dropship",
                        "Shipped"
                    ]
        ],
        [
            "RT_33",[
                        "Dropship",
                        "Shipped"
                    ]
        ],
        [
            "RT_34",[
                        "Dropship",
                        "Shipped"
                    ]
        ],
        [
            "RT_35",[
                        "Dropship",
                        "Shipped"
                    ]
        ],
        [
            "RT_36",[
                        "Dropship",
                        "Shipped"
                    ]
        ]

    ]);
}