<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<div class="seacrhCaseMain" id="search-case-div">
   <div class="spinner-main" [ngClass]="{'spinner-show': cspShowSpinner}">
      <div class="spinnerDiv">
      </div>
   </div>
   <div *ngIf="searchResults && searchResults.caseCount === 0" class="search-info-message">
      <div class="message-container">
         <span data-id="no-results-icon" class="icon-notification-medium alert-icon"></span>
         <span data-id="no-results-msg" class="message-text">
            There are no results associated with the applied filters. Begin a new search or broaden your filters to view
            results.
         </span>
      </div>
   </div>
   <div data-id="errorContainer" *ngIf="errorDetail" class="error-search-case">
      <p><span data-id="errorIcon" class="icon-error-medium"></span><span data-id="errorMsg" class="error-text">An error
            occurred while searching your request. Please contact Customer Service for more assistance.</span></p>
   </div>
   <div *ngIf="searchResults && exceedsMaxSearchResultCount" class="search-info-message">
      <div class="message-container">
         <span data-id="max-results-icon" class="icon-notification-medium alert-icon"></span>
         <span data-id="max-results-msg" class="message-text">
            The results exceed the maximum number of lines that can be displayed. Refine your filter criteria to narrow results,
             or export the full result set using the export link below.
         </span>
      </div>
   </div>

   <div *ngIf="searchResults && isWaitingForResponse" class="search-info-message">
      <div class="message-container">
         <span data-id="waiting-customer-response-icon" class="icon-notification-medium alert-icon"></span>
         <span data-id="waiting-customer-response-msg" class="message-text">
            Please take action on items below with status Waiting for Customer Response.
         </span>
      </div>
   </div>
   <div class="searchHeader">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 noPadding">
         <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 noPadding">
            <div class="pageHeading">
               <h1 class="pageTitle">
                  Cardinal Health Market<sup>SM</sup> Help Search
               </h1>
            </div>
         </div>
         <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 noPadding">
            <div class="pageHeading">
               <p class="paragraphTextBody">
                  Search for help cases and use the filter to narrow results. Select a help case number from the results
                  list to view case details.
               </p>
            </div>
         </div>
         <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 noPadding create_new_case">
            <div routerLink="/case/create" class="cta-btn primary red_button"><span>Create New Case</span></div>
         </div>
      </div>
   </div>
   <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 helpCaseSearchPart noPadding" #caseNoSection>
      <div class="col-md-2 col-lg-2 col-sm-5 col-xs-5 no-padding-L no-padding-R">
         <div id="case-search-box" class="casesearchSelect">
            <ng-select class="custom" [items]="SearchCaseDropdownValue" [placeholder]="'Case Number'"
               [clearable]="false" (change)="setSearchCaseDropdownValue($event)">
            </ng-select>
         </div>
      </div>
      <div class="col-md-6 col-lg-4 col-sm-7 col-xs-7 no-padding-R no-padding-L search-right-div"
         [ngClass]="{'fieldErrorBorder': highlightError}">

         <input *ngIf="searchByCaseId" id="search-case-box" autocomplete="off" #caseSearch class="search-box caseInput" maxlength="10"
            numericOnly type="text" [(ngModel)]="searchInputVal" [ngModelOptions]="{standalone: true}"
            (keyup.enter)="caseSearchSubmit('caseButton')" ngDefaultControl placeholder="Search by Case Number"
            (keyup)="validateCaseNumber(searchInputVal)" />
         <input *ngIf="searchByPONumber" id="search-po-box" autocomplete="off" #caseSearch class="search-box caseInput" maxlength="40"
             type="text" [(ngModel)]="searchInputVal" [ngModelOptions]="{standalone: true}"
            (keyup.enter)="caseSearchSubmit('caseButton')" ngDefaultControl placeholder="Search by PO Number"
            (keyup)="validatePONumber(searchInputVal)" />
         <span class="icon-close-red" (click)="clearInputValue()"></span>
         <span class="vertical-devider"></span>
         <button class="searchIconButton" (click)="caseSearchSubmit('caseButton')"><span
               class="search-icon"></span></button>
         <span class="inline-error">{{errmsg}}</span>

      </div>
   </div>
   <div class="helpCaseFilterPart">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 filterSection noPadding">
         <div class="col-xs-12 col-sm-4 col-md-3 col-lg-2 noPadding date_filter_field">
            <label class="formLabel">Date</label>
            <date-range [appliedDateRangeValue]="fromDatetoDate" (onFilterChange)="onDateRangeChange($event)">
            </date-range>
         </div>
         <div class="col-xs-12 col-sm-4 col-md-2 col-lg-2 noPadding shipto_filter_field">
            <label class="formLabel">Ship To</label>
            <div class="csp-custom-dropdown" data-id="cspSearchShiptoSelector">
               <custom-multiselect-dropdown #shipto id="shipToFilter" (onFilterChange)="onShipToSelect($event)"
                  [items]="shipTos" [selectAllText]="shipToLabel" [name]="numShipTos" [isMultiSelect]="false">
               </custom-multiselect-dropdown>
            </div>
         </div>
         <div class="col-xs-12 col-sm-4 col-md-2 col-lg-2 noPadding casestatus_filter_field">
            <label class="formLabel">Case Status</label>
            <div class="csp-custom-dropdown" data-id="cspSearchCaseStatusSelector">
               <custom-multiselect-dropdown #casestatus id="caseStatusFilter"
                  (onFilterChange)="onCaseStatusSelect($event)" [items]="caseStatus" [selectAllText]="caseStatuslabel"
                  [name]="numCaseStatus" [isMultiSelect]="true">
               </custom-multiselect-dropdown>
            </div>
         </div>
         <div class="col-xs-12 col-sm-4 col-md-2 col-lg-2 noPadding channel_filter_field">
            <label class="formLabel">Contact Method</label>
            <div class="csp-custom-dropdown" data-id="cspSearchChannelSelector">
               <custom-multiselect-dropdown #channel id="channelFilter" (onFilterChange)="onChannelChange($event)"
                  [items]="channels" [selectAllText]="channelLabel" [name]="numChannel" [isMultiSelect]="true">
               </custom-multiselect-dropdown>
            </div>
         </div>
         <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 noPadding filter_button_section noPadding">
            <div class="cta-btn primary red_button" (click)="caseSearchSubmit('filterButton')">Search</div>
            <div class="reset_button"><a (click)="resetFilter($event)">Reset</a></div>
         </div>
      </div>
   </div>
   <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 filterResutlsTable noPadding" *ngIf="searchResults" #caseResult>
      <div class="help_case_count_section">
         <p><span class="bold">{{searchResults.caseCount>0 ? searchResults.caseHeaders?.length : 0}}</span> Case(s)
            found for the applied filters  

            <span class="export_btn"><a (click)="downloadFile()">Export</a></span>
        
      </p>
      </div>
      
      <div class="filter_search_result visible-xs" *ngFor="let caseList of searchResults.caseHeaders;">
         <div class="list_row">
            <p>
               <span class="search_lft_label">Case #</span>
               <span class="search_rgt_label"><a [routerLink]="['/case/details', caseList.id]"
                     class="case_detail_link">{{caseList.id}}</a></span>
            </p>
            <p>
               <span class="search_lft_label">Contact Method</span>
               <span class="search_rgt_label">{{caseList.channel? getChannelType(caseList.channel) :""}}</span>
            </p>
            <p>
               <span class="search_lft_label">PO/Reference #</span>
               <span class="search_rgt_label">{{caseList.purchaseOrderNumber}}</span>
            </p>
            <p>
               <span class="search_lft_label">Description</span>
               <span class="search_rgt_label">{{caseList.description}}</span>
            </p>
            <p>
               <span class="search_lft_label">Status</span>
               <span class="search_rgt_label"><span *ngIf="caseList.status === 'Waiting for Customer Response'"
                     data-id="waiting-customer-response-status-icon"
                     class="icon-notification-small waiting-status"></span>{{caseList.status}}</span>
            </p>
            <p>
               <span class="search_lft_label">Ship To</span>
               <span class="search_rgt_label">{{caseList.shipToName}}</span>
            </p>
            <p>
               <span class="search_lft_label">Open Date</span>
               <span class="search_rgt_label">{{caseList.openDate}}</span>
            </p>
            <p>
               <span class="search_lft_label">Last Modified Date</span>
               <span class="search_rgt_label">{{caseList.dueDate}}</span>
            </p>
            <p>
               <span class="search_lft_label">Contact</span>
               <span class="search_rgt_label">{{caseList.contactName}}</span>
            </p>
         </div>
      </div>



      <div class="openUpdatedListTable hidden-xs visible-sm visible-md visible-lg" *ngIf="searchResults.caseCount > 0">

         <div class="search_list">
            <div class="case_column" [ngClass]="{'active-filter': activeSortingLabel == 0}"><span class="ascending"
                  (click)="sortCases($event.target, caseLabels[0], 'number')">Case #</span></div>
            <div class="channel_column" [ngClass]="{'active-filter': activeSortingLabel == 1}"><span class="ascending"
                  (click)="sortCases($event.target, caseLabels[1], 'string')">Contact Method</span></div>
            <div class="po_column" [ngClass]="{'active-filter': activeSortingLabel == 2}"><span class="ascending"
                  (click)="sortCases($event.target, caseLabels[2], 'string')">PO/Reference #</span></div>
            <div class="desc_column" [ngClass]="{'active-filter': activeSortingLabel == 3}"><span class="ascending"
                  (click)="sortCases($event.target, caseLabels[3], 'string')">Description</span></div>
            <div class="status_column" [ngClass]="{'active-filter': activeSortingLabel == 4}"><span class="ascending"
                  (click)="sortCases($event.target, caseLabels[4], 'string')">Status</span></div>
            <div class="shipto_column" [ngClass]="{'active-filter': activeSortingLabel == 5}"><span class="ascending"
                  (click)="sortCases($event.target, caseLabels[5], 'number')">Ship To</span></div>
            <div class="opendate_column" [ngClass]="{'active-filter': activeSortingLabel == 6}"><span class="ascending"
                  (click)="sortCases($event.target, caseLabels[6], 'date')">Open Date</span></div>
            <div class="modified_column" [ngClass]="{'active-filter': activeSortingLabel == 7}"><span class="ascending"
                  (click)="sortCases($event.target, caseLabels[7], 'date')" data-id="list-case-date-time">Last Modified
                  Date</span></div>
            <div class="conatct_column" [ngClass]="{'active-filter': activeSortingLabel == 8}"><span class="ascending"
                  (click)="sortCases($event.target, caseLabels[8], 'string')">Contact</span>
               <input class="contactsSearch" data-id="searchContact" #contactSearch type="text"
                  placeholder="search for contact" [(ngModel)]="contactSearchValue" ngDefaultControl
                  [ngModelOptions]="{standalone: true}" (keyup)="filterByContact()" />
            </div>
         </div>

         <div class="search_list_body" [ngClass]="{'table-error': searchResults?.caseHeaders?.length===0}">
            <div *ngFor="let caseList of searchResults.caseHeaders;" class="search_list_body_row">
               <div class="case_column"><a [routerLink]="['/case/details', caseList.id]"
                     class="case_detail_link"><span>{{caseList.id}}</span></a></div>
               <div class="channel_column"><span>{{caseList.channel? getChannelType(caseList.channel) :""}}</span></div>
               <div class="po_column"><span>{{caseList.purchaseOrderNumber}}</span></div>
               <div class="desc_column"><span>{{caseList.description}}</span></div>
               <div class="status_column"><span *ngIf="caseList.status == 'Waiting for Customer Response'"
                     data-id="waiting-customer-response-status-icon"
                     class="icon-notification-small waiting-status"></span><span
                     class="responseStatus">{{caseList.status}}</span> </div>
               <div class="shipto_column"><span *ngIf="caseList.shipToName !=''">{{caseList.shipToName}}</span><span
                     *ngIf="caseList.shipToId !=''">{{caseList.shipToId}}</span></div>
               <div class="opendate_column"><span>{{caseList.openDate}}</span></div>
               <div class="modified_column"><span>{{caseList.lastModifiedDate}}</span></div>
               <div class="conatct_column"><span>{{caseList.contactName}}</span></div>
            </div>
         </div>
         <div *ngIf="searchResults?.caseHeaders?.length==0" data-id="noData-class" [ngClass]="'filter-error-msg'">
            <p>No results found for search term entered</p>
         </div>
      </div>

   </div>
</div>