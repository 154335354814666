import { Component, OnInit, Input } from '@angular/core';
import { Attachment } from '../../model/attachment';
import { AttachmentService } from '../../services/attachment.service';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-comment-box',
  templateUrl: './comment-box.component.html',
  styleUrls: ['./comment-box.component.scss']
})
export class CommentBoxComponent implements OnInit {
  @Input() caseCreateForm : FormGroup;
  attachments: Array<Attachment> = [];
  constructor(private attachmentService:AttachmentService) { }

  ngOnInit() {
    // This is intentional
  }



    /**
   * attachUploadedFiles
   */
  // attachUploadedFiles(_attachments) {
  //   if (_attachments) {
  //     // Replace included attachments
  //     this.attachments = [].concat(_attachments);
  //     this.attachmentService.attachmentsSubject.next(this.attachments.filter(
  //       value => value.ecmDocId
  //     ) || []
  //     );
  //   } else {
  //     this.attachmentService.attachmentsSubject.next([]);
  //   }
  // }
}
