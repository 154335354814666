<div class="case-detail-section" id="caseDetailMain" *ngIf="caseDetail">
	<div  class="spinner-main" [ngClass]="{'spinner-show': cspShowSpinner}">
		<div class="spinnerDiv" > 
		</div>
	</div>
	<nav class="breadcrumb">
		<div class="nav-left hidden-xs">
			<a (click)="backClick($event)" data-id="previous-page-link" class="cta-link primary previous_link"><span
					class="previous-arrow"></span>Previous
				Page</a>
		</div>
		<span class="separator hidden-xs dib">|</span>
		<h2 data-id="caseDetailHeading" class="h2">Help Case Details</h2>
	</nav>

	<div id="error-detail" data-id="errorContainer" *ngIf="errorDetail && !updateCaseError" class="error-update-case">
		<p>
			<span data-id="errorIcon" class="icon-error-medium"></span><span data-id="errorMsg"
				class="error-text">{{errMessage}}</span>
		</p>
	</div>
	<div id="error-update-case-detail" data-id="errorContainer" *ngIf="updateCaseError" class="error-update-case">
		<p>
			<span data-id="errorIcon" class="icon-error-medium"></span><span data-id="errorMsg"
				class="error-text">{{errMessage}}
				<br>
						<span data-id="errorMsg" class="error-text">{{errMessageLine2}} </span>
				
			</span>	
		</p>
	</div>

	<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 created_on_content noPadding">
		<div class="innerContent">
			<p>
				CREATED ON :<span>{{caseDetail.caseHeader.openDate}}</span><span
					class="vertical-devider"></span><span>{{channel}}</span>
			</p>
		</div>
	</div>
	<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 caseSummaryDiv noPadding" *ngIf="caseDetail.caseHeader">
		<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 caseSummaryLft">
			<h4 class="case_summary_title">Case Summary</h4>
			<div class="caseInnerRow">
				<p>
					<span class="lft_paraTxt">Case #:</span> <span
						class="rgt_paraTxt">{{caseDetail.caseHeader.id}}</span>
				</p>
				<p>
					<span class="lft_paraTxt">Description:</span> <span
						class="rgt_paraTxt">{{caseDetail.caseHeader.description}}</span>
				</p>
				<p>
					<span class="lft_paraTxt">Ship To:</span> <span
						class="rgt_paraTxt">{{caseDetail.caseHeader.shipToId}}</span>
				</p>
				<p>
					<span class="lft_paraTxt">Status:</span> <span
						class="rgt_paraTxt">{{caseDetail.caseHeader.status}}</span>
				</p>
				<p>
					<span class="lft_paraTxt">Contact:</span> <span
						class="rgt_paraTxt">{{caseDetail.caseHeader.contactName}}</span>
				</p>
			</div>
		</div>
		<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 caseSummaryRft"
			*ngIf="((caseDetail.caseHeader.status | uppercase) !== 'WAITING FOR CUSTOMER RESPONSE' && caseDetail.isEditable!==false) && !allowNewResponse">
			<div class="cta-btn primary red_button" (click)="allowNewResponse = true">Create New Response</div>
		</div>
		<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 response_history_section">
			<div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 response_history">
				<div class="history_titel">
					<h2>Response History</h2>
				</div>
				<div class="historyDiv">
					<div class="historyRow" *ngFor="let notes of caseDetail.notes; let i = index">
						<h3 class="paraHeading">
							<span
								[ngClass]="{'cust_service': notes.organizationName.toUpperCase().split(' ').join('') ==='CUSTOMERSERVICE'}">{{notes.organizationName}}</span><span
								class="vertical-devider"></span><span>Date:</span><span>{{notes.messageCreatedDate}}</span>
						</h3>
						<p [innerHtml]="notes.message"></p>
						<div *ngIf="notes.attachments && notes.attachments.length>0" style="margin: 1rem;">
							<p style="padding: 0rem; margin-bottom: 0;">Attachments:</p>
							<div *ngFor="let attachment of notes.attachments;">
								<a href='javascript:void(0)'
									(click)="downloadAttachment(attachment.ecmDocId,attachment.fileName)">{{attachment.fileName}}</a>
							</div>
						</div>

					</div>

				</div>
			</div>
			<div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 create_new_response"
				*ngIf="((caseDetail.caseHeader.status | uppercase) === 'WAITING FOR CUSTOMER RESPONSE') || (allowNewResponse === true)">
				<div class="history_titel">
					<h2>Create New Response</h2>
				</div>
				<div class="response_comment">
					<p>Type a message below in response to the current help case.
						You may include up to 10 attachments as part of your response. A
						customer service representative will respond as soon as possible.
					</p>
					<div class="responseTextArea">
						<textarea id="caseDetailTextArea" placeholder="Begin typing your comments here..."
							class="form-control comments" [(ngModel)]="comments"
							(ngModelChange)="setFormChangeFlags()" [ngClass]="{'has-error':  submitted && !comments}"></textarea>
							
						<div class="cta-btn secondary black_button" (click)="showAttachmentModal()">Include
							Attachment(s)</div>
						<div class="cta-btn primary red_button" 
							(click)="submitResponse()">Submit Response</div>
						<p class="msg-wrapper" *ngIf="noCommentsAdded">
							<span data-id="enter-response-validation-icon" class="icon-notification-small"></span><span
								data-id="enter-response-validation-msg" class="information-message">Enter a response to
								continue.</span>
						</p>
						
					</div>
					<div class="attachments-list" *ngIf="attachments" id="casedetail-attachment">
						<div *ngFor="let attachment of attachments; let index = index;" class="clearfix">
							<span class="icon icon-file-small"
								[ngClass]="{'upload-error-icon': !attachment.ecmDocId}"></span>
							<span class="attachment-name"
								[ngClass]="{'upload-error-attachment': !attachment.ecmDocId}">{{attachment.name}}</span>
							<span class="icon icon-close-small test" *ngIf="attachment.ecmDocId"
								(click)="removeAttachments(index)"></span>
							<span *ngIf="!attachment.ecmDocId" class="upload-error-msg">File did not upload
								successfully.</span>
						</div>
					</div>
					<app-modal id="formCancelModal" [showModal]="isCaseDetailAttachModalVisible"
						[customBody]="addAttachments" (onCloseModal)="hideAttachmentModal()">
						<ng-template #addAttachments>
							<csp-attachment id="addAtttachmentSection" [fromPage]='"Create New Response."'
								(attachFiles)="attachUploadedFiles($event)" (onCloseModal)="hideAttachmentModal()">
							</csp-attachment>
						</ng-template>
					</app-modal>
				</div>
			</div>
		</div>
	</div>
	<app-modal id="submitCaseDiscard" [showModal]="isModalVisible" [customBody]="submitCaseDiscard"
		(onCloseModal)="hideModal()">
		<ng-template #submitCaseDiscard>
			<app-submit-case-discard id="submitCaseDiscardModal" (onCloseModal)="hideModal()"
				(discardChange)="navigateAway($event)" [name]="name" [header]="heading" [message]="message">
			</app-submit-case-discard>
		</ng-template>
	</app-modal>
</div>
<div *ngIf="caseDetailError" class="container-fluid">
	<div class="navigation-link-container">
        <ul>
            <li><a data-id="MarketHomeLink" [href]="[baseRoutingUrl]" class="cta-link primary">Market Home</a></li>
            <li><a data-id="CaseSearchLink" routerLink="/case/search" class="cta-link primary">Case Search</a></li>
            <li><a data-id="HelpLink" routerLink="/help" class="cta-link primary">Help</a></li>
        </ul>
    </div>
	<section class="not-found-title">
		<h4><span data-id="content-unavailable-icon" class="icon-notification-medium alert-icon"></span>
		Content Unavailable</h4>
	</section>
	<section class="not-found-content">
		<ul>Unfortunately, we were unable to display the page you requested.</ul><br>
		<ul>You may need additional permission to view this page, or it may not exist.</ul><br>
		<ul style="list-style-position: inside;">
			<li>Please confirm your case number.</li>
			<li>If the case number is correct and you believe you should be authorized to view the page, please
				contact your Administrator.</li>
		</ul>
	</section>

</div>