import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'itemsFilter'
})
export class ItemsFilterPipe implements PipeTransform {

  transform(items: any[], searchText: string): any[] {
    if (searchText.trim() === "" || searchText === null) {
      return items;
    }
    return items.filter(
      (item => (item.ordernumber && item.ordernumber.toLowerCase().indexOf(searchText.toLowerCase()) > -1) ||
                    (item.buyer && item.buyer.toLowerCase().indexOf(searchText.toLowerCase()) > -1) ||
                   (item.shipto && item.shipto.toLowerCase().indexOf(searchText.toLocaleLowerCase())> -1) ||
                   (item.materialNumber && item.materialNumber.toLowerCase().indexOf(searchText.toLocaleLowerCase())> -1) ||
                   (item.itemDescription && item.itemDescription.toLowerCase().indexOf(searchText.toLocaleLowerCase())> -1) ||
                   (item.shortDescription && item.shortDescription.toLowerCase().indexOf(searchText.toLocaleLowerCase())> -1) ||
                  (item.customerPOLineNumber && item.customerPOLineNumber.toLowerCase().indexOf(searchText.toLocaleLowerCase())> -1)
                    )
    );
  }

}
