import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Contact } from '../model/contact';
import { CasesService } from './cases.service';

@Injectable({
  providedIn: 'root'
})
export class AddressBookService {
  public header : Object;
  private baseApiUrl: string;

  constructor(private http: HttpClient,
              private caseService: CasesService) {
    
		this.baseApiUrl = environment.baseApiUrl  + "/medical-market-users-management/v1/usersmgmt/users/contacts";  //Dev
  

   }

  // Getting Address book contacts from Actual APi
  public fetchAddressBook(): Observable<Contact[]> {
    return this.http.get(this.baseApiUrl, this.header).pipe(map((addressBook: {contacts: Contact[]}) => addressBook.contacts))
   ;
  }

}
