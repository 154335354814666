import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'csp-case-acknowledgement',
  templateUrl: './case-acknowledgement.component.html',
  styleUrls: ['./case-acknowledgement.component.scss']
})
export class CaseAcknowledgementComponent implements OnInit {
  submittedCaseId:string="";
  ackUrl:string="";
  baseRoutingUrl: string = environment.baseRoutingUrl;
  hostname:string=""; 

  constructor(private router: Router,
    private titleService:Title,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.titleService.setTitle("Case Acknowledgment");
    this.submittedCaseId=this.activatedRoute.snapshot.paramMap.get('id');
  
  }

}
