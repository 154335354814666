<form [formGroup]="caseCreateForm">

  <div formArrayName="items">
    <div [formGroupName]="formIndex">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 short_dated_product_section">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 noPadding">
          <p>
            <span *ngIf="getFormArray().length > 1" class="icon icon-close-small" (click)="deleteForm()"></span>
            Product #{{ formIndex + 1 }} -
            <input type="hidden" formControlName="itemDescription">
            <span [innerHTML]="itemDescription"> {{ itemDescription }}</span>
          </p>
        </div>
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12  noPadding">
            <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2 health_Div">
                <div class="inputFields">
                  <div>
                    <label  class="formLabel">Cardinal
                      Health Material #</label>
                  </div>
                  <input type="text" readonly placeholder="Enter Material #..." maxlength="18" class="form-control material_input"
                  formControlName="itemId" />
                </div>
            </div>
            <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2 qty_Div noPadding">
                <div class="inputFields">
                  <div>
                    <label class="formLabel">QTY Ordered</label>
                  </div>
                  <input type="text" readonly placeholder="QTY" class="form-control qty_input"
                          formControlName="quantityOrdered" numericOnly [zeroNotAllowed]=true />
                </div>
            </div>
            <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2 uom_Div noPadding">
                <div class="inputFields">
                  <div>
                    <label class="formLabel">UOM Ordered</label>
                  </div>
                  <input type="text" readonly placeholder="UOM"  maxlength="3" class="form-control uom_input" formControlName="uom"
                    alphabetOnly />
                </div>
            </div>
            <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2 received_qty_Div noPadding">
              <div class="inputFields">
                <div>
                  <label class="formLabel"><span class="red-text">*</span>QTY Short-dated</label>
                </div>
                <input  type="text" placeholder="QTY" class="form-control received_qty_input"
                  formControlName="quantityReceived" numericOnly [zeroNotAllowed]="true" [(ngModel)]="quantityReceived"
                  [ngClass]="{'has-error':  caseCreateForm.value.submitted && !quantityReceived}"
                  />
                  <span *ngIf="caseCreateForm.value.submitted && !quantityReceived" style="color: red; font-size:15px;">QTY is required</span>
              </div>
            </div>
            <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2 uom_Div noPadding">
              <div class="inputFields">
                <div>
                  <label class="formLabel"><span class="red-text">*</span>UOM Short-dated</label>
                </div>
                <input type="text" placeholder="UOM"  maxlength="3" class="form-control uom_input" 
                  formControlName="uomReceived" alphabetOnly [(ngModel)]="uomReceived"
                  [ngClass]="{'has-error':  caseCreateForm.value.submitted && !uomReceived}"
                  />
                  <span *ngIf="caseCreateForm.value.submitted && !uomReceived" style="color: red; font-size:15px;">UOM is required</span>
              </div>
            </div>

            <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2 event_date noPadding">
              <div class="inputFields">
                <label class="formLabel"><span class="red-text">*</span>Expiration Date</label>
                <app-market-help-date-picker [caseCreateForm]="caseCreateForm" [formIndex]="formIndex" [minDate]="shippedDate" [maxDate]="date"
                 (invalidDate)= "setInvalidDateflag($event)"></app-market-help-date-picker>
                <span *ngIf="!incorrectDateFlag" class="red-text">A product is short-dated when received within 60 days of expiration.</span>
                </div>
            </div>
            
              <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2 radio_btns noPadding">
                <div class="inputFields">
                  <label class="formLabel radioLabel"><span class="red-text">*</span>Action</label>
                  <div>
                    <div [ngClass]="{'has-error': caseCreateForm.value.submitted && !actionRequest}">
                      <p>
                        <input type="radio" name="actionRequest" class="keep_radio_btn" value="{{ actionItems.option1.id }}"
                          formControlName="actionRequest" [(ngModel)]="actionRequest" />
                          <label>{{ actionItems.option1.value }}</label>
                      </p>
                      <p>
                        <input type="radio" name="actionRequest" class="return_radio_btn" value="{{ actionItems.option2.id }}"
                          formControlName="actionRequest" [(ngModel)]="actionRequest" />
                          <label>{{ actionItems.option2.value }}</label>
                      </p>
                  </div>
                  </div>
                  <span *ngIf="caseCreateForm.value.submitted && !actionRequest" style="color: red; font-size:15px;">Please select option</span>
                </div>
              </div>
          </div>
          
        </div>
        <div class="row">
          <input type="hidden" formControlName="statusMessageList">
          <ul>
            <li *ngFor="let statusMessage of statusMessageList">
              <span [innerHTML]="statusMessage">{{statusMessage}}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</form>
