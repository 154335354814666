export class User {

  public static getUser() {
    const user = JSON.parse(localStorage.getItem("ContextHubPersistence"));
    const { store: { market: { firstName, lastName, email, phoneNumber, loginId: userId,
      profile: { soldToAccountNumber, soldToName, shipToAccountNumber, shipToName  },
      rights: rights } } } = user;

    return {
       firstName, lastName, email, phoneNumber, soldToAccountNumber, soldToName, shipToAccountNumber, shipToName, userId, rights
      
          };
  }

}
