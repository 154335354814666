<form [formGroup]="caseCreateForm">

  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 product_questions_section noPadding">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 fieldSection noPadding"
      *ngIf="!productComplaints && isProdQuestWithoutOrdDetails">
      <div formArrayName="items">
        <div [formGroupName]="formIndex">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 noPadding">
            <p>
              <span *ngIf="getFormArray().length > 1" class="icon icon-close-small" (click)="deleteForm()"></span>
              Product #{{ formIndex + 1 }}
            </p>
          </div>
          <div class="row">
            <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3 health_Div">
              <div class="inputFields">
                <label class="formLabel"><span class="red-text">*</span>Cardinal Health Material #</label>
                <input type="text" maxlength="18" placeholder="Enter Material #..." class="form-control material_input"
                  formControlName="itemId" [(ngModel)]="itemId"
                  [ngClass]="{'has-error':  caseCreateForm.value.submitted && !itemId}"
                  />
                  <span *ngIf="caseCreateForm.value.submitted && !itemId" style="color: red; font-size:15px;">Please Enter Material</span>
              </div>
            </div>

            <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2 manufact_name noPadding">
              <div class="inputFields">
                <label class="formLabel">Manufacturer Name</label>
                <input type="text" placeholder="Name" maxlength="40" class="form-control manufacturer_input"
                  formControlName="manufacturerName" />
              </div>
            </div>
            <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3 vendor_mat_Div  noPadding">
              <div class="inputFields">
                <label class="formLabel">Product Information Vendor Material #</label>
                <input type="text" placeholder="Vendor Material #" maxlength="40" class="form-control vendor_mat_input"
                  formControlName="vendorMaterialNumber" />
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 fieldSection noPadding"
      *ngIf="!productComplaints && !isProdQuestWithoutOrdDetails">
      <div formArrayName="items">
        <div [formGroupName]="formIndex">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 noPadding">
            <p>
              <span *ngIf="getFormArray().length > 1" class="icon icon-close-small" (click)="deleteForm()"></span>
              Product #{{ formIndex + 1 }} -
              <input type="hidden" formControlName="itemDescription">
              <span [innerHTML]="itemDescription"> {{ itemDescription }}</span>
            </p>
          </div>
          <div class="row">
            <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3 health_Div">
              <div class="inputFields">
                <label class="formLabel">Cardinal Health Material #</label>
                <input type="text" readonly maxlength="18" placeholder="Enter Material #..."
                  class="form-control material_input" formControlName="itemId" />
              </div>
            </div>
            <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2 qty_Div noPadding">
              <div class="inputFields">
                <label class="formLabel">QTY Ordered</label>
                <input type="text" readonly placeholder="QTY" maxlength="15" class="form-control qty_input"
                  formControlName="quantityOrdered" numericOnly [zeroNotAllowed]="true" />
              </div>
            </div>
            <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2 uom_Div noPadding">
              <div class="inputFields">
                <label class="formLabel">UOM</label>
                <input type="text" readonly placeholder="UOM" maxlength="3" class="form-control uom_input"
                  formControlName="uom" alphabetOnly />
              </div>
            </div>
            <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2 manufact_name noPadding">
              <div class="inputFields">
                <label class="formLabel">Manufacturer Name</label>
                <input type="text" placeholder="Name" maxlength="40" class="form-control manufacturer_input"
                  formControlName="manufacturerName" />
              </div>
            </div>
            <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3 vendor_mat_Div  noPadding">
              <div class="inputFields">
                <label class="formLabel">Product Information Vendor Material #</label>
                <input type="text" placeholder="Vendor Material #" maxlength="40" class="form-control vendor_mat_input"
                  formControlName="vendorMaterialNumber" />
              </div>
            </div>
          </div>
          <div class="row">
            <input type="hidden" formControlName="statusMessageList">
            <ul>
              <li *ngFor="let statusMessage of statusMessageList">
                <span [innerHTML]="statusMessage">{{statusMessage}}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 fieldSection noPadding" *ngIf="productComplaints">
      <div formArrayName="items">
        <div [formGroupName]="formIndex">
          <div class="row ">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 noPadding">
              <p>
                <span *ngIf="getFormArray().length > 1" class="icon icon-close-small" (click)="deleteForm()"></span>
                Product #{{ formIndex + 1 }} -
                <input type="hidden" formControlName="itemDescription">
                <span [innerHTML]="itemDescription"> {{ itemDescription }}</span>
              </p>
            </div>
            <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3 health_Div">
              <div class="inputFields">
                <label class="formLabel">Cardinal Health Material #</label>
                <input type="text" readonly maxlength="18" placeholder="Enter Material #..."
                  class="form-control material_input" formControlName="itemId" />
              </div>
            </div>
            <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2 qty_Div noPadding">
              <div class="inputFields">
                <label class="formLabel">QTY Ordered</label>
                <input type="text" readonly placeholder="QTY" maxlength="15" class="form-control qty_input"
                  formControlName="quantityOrdered" numericOnly [zeroNotAllowed]="true" />
              </div>
            </div>
            <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2 uom_Div noPadding">
              <div class="inputFields">
                <label class="formLabel">UOM</label>
                <input type="text" readonly placeholder="UOM" maxlength="3" class="form-control uom_input"
                  formControlName="uom" alphabetOnly />
              </div>
            </div>
            <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2 lot_field noPadding">
              <div class="inputFields">
                <label class="formLabel"><span class="red-text">*</span>Lot #</label>
                <input type="text" placeholder="LOT #" class="form-control lot_input" 
                formControlName="lotNo" [(ngModel)]="lotNo"
                [ngClass]="{'has-error':  caseCreateForm.value.submitted && !lotNo}"
                />
                <!-- {{caseCreateForm.value.submitted}}
                {{caseCreateForm.controls.lotNo?.value}} -->
                <span *ngIf="caseCreateForm.value.submitted && !lotNo" style="color: red; font-size:15px;">Lot is required</span>
              </div>
            </div>
            <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3 event_date noPadding">
              <div class="inputFields">
                <label class="formLabel"><span class="red-text">*</span>Event Date</label>

                <app-market-help-date-picker [caseCreateForm]="caseCreateForm" [maxDate]="maxDate"
                  [formIndex]="formIndex" ></app-market-help-date-picker>     
                  
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3 radio_btns noPadding">
              <div class="inputFields">
                <div>
                  <label class="formLabel"><span class="red-text">*</span>Sample Available</label>
                </div>
                <div [ngClass]="{'has-error': caseCreateForm.value.submitted && !sampleAvailable}">
                <p>
                  <input type="radio" name="sampleAvailable" class="keep_radio_btn" value="Yes"
                    formControlName="sampleAvailable" [(ngModel)]="sampleAvailable" /><label>Yes</label>
                </p>
                <p>
                  <input type="radio" name="sampleAvailable" class="return_radio_btn" value="No"
                    formControlName="sampleAvailable" [(ngModel)]="sampleAvailable" /><label>No</label>
                </p>
                <p>
                  <input type="radio" name="sampleAvailable" class="return_radio_btn" value="Unknown"
                    formControlName="sampleAvailable" [(ngModel)]="sampleAvailable" /><label>Unknown</label>
                </p>
                </div>
              </div>
              <span *ngIf="caseCreateForm.value.submitted && !sampleAvailable" style="color: red; font-size:15px;">Please select option</span>
            </div>

            <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3 radio_btns noPadding">
              <div class="inputFields">
                <div>
                  <label class="formLabel">
                    <span class="red-text">*</span>
                    Was There an Injury?</label>
                </div>
                <div [ngClass]="{'has-error': caseCreateForm.value.submitted && !wasThereInjury}">
                <p>
                  <input type="radio" name="wasThereInjury" class="keep_radio_btn" value="No"
                    formControlName="wasThereInjury" [(ngModel)]="wasThereInjury" /><label>No</label>
                </p>
                <p>
                  <input type="radio" name="wasThereInjury" class="return_radio_btn" value="Yes"
                    formControlName="wasThereInjury" [(ngModel)]="wasThereInjury" /><label>Yes</label>
                </p>
                <p>
                  <input type="radio" name="wasThereInjury" class="return_radio_btn" value="Unknown"
                    formControlName="wasThereInjury" [(ngModel)]="wasThereInjury" /><label>Unknown</label>
                </p>
              </div>
              </div>
              <span *ngIf="caseCreateForm.value.submitted && !wasThereInjury" style="color: red; font-size:15px;">Please select option</span>
            </div>
            <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3 radio_btns noPadding">
              <div class="inputFields">
                <div>
                  <label class="formLabel"><span class="red-text">*</span>Was There a Death?</label>
                </div>
                <div [ngClass]="{'has-error': caseCreateForm.value.submitted && !wasThereDeath}">
                <p>
                  <input type="radio" name="wasThereDeath" class="keep_radio_btn" value="No"
                    formControlName="wasThereDeath" [(ngModel)]="wasThereDeath" /><label>No</label>
                </p>
                <p>
                  <input type="radio" name="wasThereDeath" class="return_radio_btn" value="Yes"
                    formControlName="wasThereDeath" [(ngModel)]="wasThereDeath" /><label>Yes</label>
                </p>
                <p>
                  <input type="radio" name="wasThereDeath" class="return_radio_btn" value="Unknown"
                    formControlName="wasThereDeath" [(ngModel)]="wasThereDeath" /><label>Unknown</label>
                </p>
              </div>
              </div>
              <span *ngIf="caseCreateForm.value.submitted && !wasThereDeath" style="color: red; font-size:15px;">Please select option</span>
            </div>


            <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3 radio_btns noPadding">
              <div class="inputFields">
                <label class="formLabel radioLabel"><span class="red-text">*</span>Requested Action</label>
                <div>
                    <div [ngClass]="{'has-error': caseCreateForm.value.submitted && !actionRequest}">
                        <p>
                          <input type="radio" name="actionRequest" class="keep_radio_btn" value="{{ actionItems.option1.id }}"
                            formControlName="actionRequest"  [(ngModel)]="actionRequest"  />
                          <label>{{ actionItems.option1.value }}</label>
                        </p>
                        <p>
                          <input type="radio" name="actionRequest" class="return_radio_btn"
                            value="{{ actionItems.option2.id }}" formControlName="actionRequest" [(ngModel)]="actionRequest" />
                          <label>{{ actionItems.option2.value }}</label>
                        </p>
                  </div>
                </div>
                <span *ngIf="caseCreateForm.value.submitted && !actionRequest" style="color: red; font-size:15px;">Please select option</span>
              </div>
            </div>
          </div>
          <div class="row">
            <input type="hidden" formControlName="statusMessageList">
            <ul>
              <li *ngFor="let statusMessage of statusMessageList">
                <span [innerHTML]="statusMessage">{{statusMessage}}</span>
              </li>
            </ul>
          </div>
        </div>

      </div>

    </div>
  </div>
</form>
