
<div class="modal common-modal-wrapper" [ngClass]="{'show': showModal, 'hide': !showModal}">
  <div class="modal-backdrop in"></div>
  <div [ngClass]="{'modal-dialog': defaultWidth, 'fullModal-dialog':!defaultWidth}">
      <div class="modal-content">
          <div class="modal-header">
            <div  class="col-md-10 col-lg-10 col-sm-10 col-xs-10">
              <ng-container *ngTemplateOutlet="modalTital"></ng-container>
            </div>
          <div>
            <span class="cta-link close" (click)="close()">
              <span class="icon icon-close-large"></span>
                    <span class="label">Close</span>
                    </span>
          </div>           
          </div>
          <div class="modal-body">
            <ng-container *ngTemplateOutlet="customBody"></ng-container>
          </div>
          <div class="modal-footer">
              <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
          </div>
      </div>
  </div>
</div>
