import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-items-selection-modal',
  templateUrl: './app-items-selection-modal.component.html',
  styleUrls: ['./app-items-selection-modal.component.scss']
})
export class AppItemsSelectionModalComponent implements OnInit {
  @Input('tableData') tableData: any[];
  @Input('tableCols') tableColsInput: string[];
  @Input('tableColsLabelInput') tableColsLabelInput: string[];
  @Input('multiValueSelect') multiValueSelect: boolean=true;
  @Input('addItemButtonName') addItemButtonName: string;
  @Output() getSelectedItems = new EventEmitter();
  tableDataSrc: any[];
  tableCols: string[];
  tableColsLabel: string[];
  selectedItems: any = [];
  searchText: string = "";
  hasSelectedAllItems: boolean = false;
 
  constructor() {
    // This is intentional
   }

  ngOnInit(): void {
    
    this.tableDataSrc = this.tableData;
    this.tableCols = this.tableColsInput;
    this.tableColsLabel = this.tableColsLabelInput;
    this.selectedItems = [];
  }

  selectAllItems(){
    this.selectedItems = [];
    for (var i = 0; i < this.tableDataSrc.length; i++) {
      if(!this.tableDataSrc[i].disabled){
        this.tableDataSrc[i].selected = this.hasSelectedAllItems;
      }
    }
    
    this.tableDataSrc.forEach(element => {

      if (!element.disabled && element.selected) {
        this.selectedItems.push(element)
      }
    });
  }

  addSelectedItem(value){
    this.selectedItems = [];
    this.selectedItems.push(value)
  }
  toggleCheckBox(value){
      const index = this.selectedItems.indexOf(value);
      index === -1 ? this.selectedItems.push(value) : this.selectedItems.splice(index, 1);
      this.hasSelectedAllItems = false;
  }

  validateAddItemButton(): boolean{
    let buttonDisabled = true;
    if( this.selectedItems.length > 0){
      buttonDisabled = false;
    }
    return buttonDisabled;
  }

  
  addItems(){
    this.getSelectedItems.emit(this.selectedItems);
  }
  
   checkBoxDisabled(){
    let count = 0;
    let flag = false;
    if(this.tableDataSrc){
      this.tableDataSrc.forEach(element => {
        if(element.disabled){
          count ++
        }
        flag = (this.tableDataSrc.length == count);
    });

  }
    return flag;
  }
 
}