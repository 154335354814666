
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Attachment } from '../../../model/attachment';
import { CaseResponse, ErrorResponse } from '../../../model/caseResponse';
import { Router } from '@angular/router';
import { Contact, ContactResponse } from '../../../model/contact';
import { CaseRequest } from '../../../model/caseRequest';
import { AttachmentService } from '../../../services/attachment.service';
import { User } from '../../../shared/User';
import { CasesService } from '../../../services/cases.service';
import { CategoryIdConstants } from '../../../constants/categoryId.constants';
import { LogService } from '../../../services/log.service';
import { Title } from '@angular/platform-browser';
import { OrderSearchService } from 'src/app/csp/services/order-search.service';
import * as moment from 'moment';

@Component({
  selector: 'order-details-case',
  templateUrl: './order-details-case.component.html',
  styleUrls: ['./order-details-case.component.scss']
})

export class OrderDetailsCaseComponent implements OnInit {
  @Input('products') public localStorageData: any;
  @Output() updateModalStatus: EventEmitter<any> = new EventEmitter<any>();
  @Output() fromFormB: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() formBAttachments: EventEmitter<Array<Attachment>>  = new EventEmitter<Array<Attachment>>();
  
  public isAttachmentModalVisible: boolean = false;
  public isRecipientModalVisible: boolean = false;
  public isChecked: boolean = false;
  public attachments: Array<Attachment> = [];
  getCheckedValue :any = true;
  public user: any;
  public caseCreateForm: FormGroup;
  public selectedContactsList: Contact[] = [];
  errorResponse;
  submittedCaseId: string;
  submittedItemCaseId: any = [];
  cspShowSpinner: boolean = false;
  isSubmitCaseModalVisible: boolean = false;
  checkValid: any = false;
  highlightedText:any = "black";
  public selectedContactResponse: ContactResponse;
  navigationURL = '';
  name: string = "Continue Case Submit";
  heading: string = "Submit a Case";
  message: string = "If you cancel before submitting the case, you will lose any changes you have made.";
  //selectedReturnReason: any;
  shippedDate: Date;
  todayDate: Date = new Date();
  minShortDated : Date; 
  maxShortDated : Date = new Date();

  constructor(private attachmentService: AttachmentService,
    private casesService: CasesService,
    private router: Router,
    private titleService:Title,
    private logService: LogService,
    private orderSearchService: OrderSearchService,
    private cd : ChangeDetectorRef
  ) { }


  ngOnInit() {
    localStorage.removeItem("OrderCaseList")
    const sharedAttachment = this.attachmentService.sharedAttachment;
    if (sharedAttachment) {
      this.attachments = this.attachments.concat(sharedAttachment);
    }
    this.user = User.getUser();
    this.initForm();
    window.dispatchEvent(new Event('formDirty'));
    window.addEventListener('navigationPrevented', this.updateShowModalStatus.bind(this));
    this.titleService.setTitle("Create a Case");
    this.orderSearchService.searchOrder(this.localStorageData.orderNumber).subscribe((data: any) => {
      
      const orderDetailsDataFromAPI = data;
      if (orderDetailsDataFromAPI.header && orderDetailsDataFromAPI.orderLines) {
        let orderDetailsList = orderDetailsDataFromAPI.orderLines.orderLines;
        for (const lineItem of this.localStorageData.lineItems) {
          for(const tempOrderData of orderDetailsList){
              if(tempOrderData.lineNumber == lineItem.lineNumber){
                
                let expirationStartDate: Date = this.getShippedDate(tempOrderData,orderDetailsDataFromAPI.header);
                lineItem.expirationStartDate = expirationStartDate;
                lineItem.nonReturnable = tempOrderData.nonReturnable;
                lineItem.nonReturnableReason =  tempOrderData.nonReturnableReason;
                this.minShortDated=expirationStartDate;
                if(expirationStartDate){
                  const shortDate=new Date(expirationStartDate.getTime() + 59 * 24 * 60 * 60 * 1000);
                  this.maxShortDated=shortDate;
                }
  
                break;
                }
              }
        }

        }
    })
    
  }
  checkboxValue(checked : any){
    this.getCheckedValue = checked.target.checked;
    this.checkValid = checked.target.checked ? true : false;
}

  getShippedDate(tempOrderData: any, header: any) {
    let expirationStartDate : Date;
    
    for (let index = 0; index < tempOrderData.fulfillment.length; index++) {
      const element = tempOrderData.fulfillment[index];
      let statusElement = element.status;
       
        if(statusElement === "Shipped" && element.shippedDate){
          let tempShippedDate = element.shippedDate;
          expirationStartDate = new Date(tempShippedDate);
         break;
        }else if(statusElement === "Dropship"){
          let tempDropshipDate = header.orderedTime;
          expirationStartDate =  new Date(tempDropshipDate);
          expirationStartDate.setDate(expirationStartDate.getDate() + 7);
          break;
        }
        
    }
    
    return expirationStartDate;
  }
  deleteFormItem(i){
    let formBArray=this.caseCreateForm.get('items') as FormArray;
    if(formBArray.length>1) {
      const deletedItem = formBArray.value[i];
      formBArray.removeAt(i);
      this.localStorageData.lineItems.splice(i,1);
    }
  }
  updateShowModalStatus(event) {
    console.log("Inside form b update show modal status");
    window.dispatchEvent(new CustomEvent('cah:spinnerHide'));
      if(event.detail){
        this.navigationURL = event.detail;
      }
      event.preventDefault();
      event.stopPropagation();
      this.isSubmitCaseModalVisible = true;  
    
  }

  discardChanges(): void {
    console.log("Inside Forb B Discard modal");
    let logMessage: string = "AppId:26095 : ";
    if (this.attachments.length > 0) {
      const validAttachments = this.attachments.filter(value => value.ecmDocId);
      if (this.attachments.length == validAttachments.length) {
        logMessage = logMessage + "Case Creation Abandoned having Attachments attached from Form B: " + JSON.stringify(this.attachments);
      } else {
        logMessage = logMessage + "Case Creation Abandoned having error during attachment upload from Form B: " + JSON.stringify(this.attachments);
      }
    } else {
      logMessage = logMessage + "Case Creation Abandoned from Form B. ";
    }
    this.isSubmitCaseModalVisible = false;
    this.removeAllAttachments(this.attachments);
    this.selectedContactsList = [];
    this.logService.logMessage(logMessage).subscribe((data: any) => {
      if(!window.location.hostname.includes("localhost")){
        window.dispatchEvent(new Event('formClean'));
        window.location.href = this.navigationURL;
      }
    },(error)=>{
      if(!window.location.hostname.includes("localhost")){
        window.dispatchEvent(new Event('formClean'));
        window.location.href = this.navigationURL;
      }
    });
    // this.cspAllowNavigation.emit();

  }

  hideDiscardModal() {
    this.isSubmitCaseModalVisible = false;
  }

  removeAllAttachments(attachments: Array<Attachment>): void {
    for (let i = 0; i < attachments.length; i++) {
      this.removeAttachment(i);
    }
  }

  private initForm() {
    let _items = new FormArray([]);
    this.caseCreateForm = new FormGroup({
      "userId": new FormControl(""),
      "firstName": new FormControl(""),
      "lastName": new FormControl(""),
      "email": new FormControl(""),
      "phoneNumber": new FormControl(""),
      "soldToAccountNumber": new FormControl(""),
      "soldToName": new FormControl(""),
      "shipToAccountNumber": new FormControl(""),
      "shipToName": new FormControl(""),
      "poNumber": new FormControl(""),
      "orderNumber": new FormControl(""),
      "items": _items,

    });

    if (this.user && this.user != null) {
      this.caseCreateForm.patchValue({
        "userId": this.user.userId,
        "firstName": this.user.firstName,
        "lastName": this.user.lastName,
        "email": this.user.email,
        "phoneNumber": this.user.phoneNumber,
        "soldToAccountNumber": this.user.soldToAccountNumber,
        "soldToName": this.user.soldToName,
        "shipToAccountNumber": this.user.shipToAccountNumber,
        "shipToName": this.user.shipToName,
        "poNumber": this.localStorageData.poNumber,
        "orderNumber": this.localStorageData.orderNumber,

      });
    }

    if (this.localStorageData != null ) {

      for (const lineItem of this.localStorageData.lineItems) {
        // var lineItem_status=lineItem.fulfillment;
        _items.push(
          new FormGroup({
            "itemId": new FormControl(lineItem.itemId),
            "quantityOrdered": new FormControl(lineItem.quantity),
            "unitOfMeasure": new FormControl(lineItem.unitOfMeasure),
            "requestTypeId": new FormControl("", Validators.required),
            "lineNumber": new FormControl(lineItem.lineNumber),
            "itemDesc": new FormControl(lineItem.title),
            "comments": new FormControl(""),
            "missingFromOrder": new FormControl(""),

            // "statusMessage": new FormControl(lineItem_status)
          })


        );
      }
    }

  }

  get recievedLineItems(): FormArray {
    return this.caseCreateForm.get('items') as FormArray;
  }

  public missingOrderChange(orderMissing: string, formArrayIndex: number): void {
    const ctrl = this.recievedLineItems.controls[formArrayIndex] as FormGroup;

    if (orderMissing === 'Yes') {
        ctrl.addControl('uomRecieved_missing', new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z\\-\\\' ]+')]));
        ctrl.addControl('action_missing', new FormControl('', Validators.required));
        ctrl.addControl('quantityRecieved_missing', new FormControl('', Validators.required));
    } else {
        ctrl.removeControl('uomRecieved_missing');
        ctrl.removeControl('action_missing');
        ctrl.removeControl('quantityRecieved_missing');
    }
}
public onRequestTypeSelect(event: Event, formArrayIndex: number) {
  // this.cspFormChanged.emit();
  const requestTypeId = (event.target as HTMLInputElement).value;
  const numericNumberReg = '^-?[0-9]\\d*(\\.\\d{1,2})?$';
  const quantityRecieved_16_Control = new FormControl("", [Validators.required, Validators.pattern(numericNumberReg)]);
  const quantityRecieved_17_Control = new FormControl("", [Validators.required, Validators.pattern(numericNumberReg)]);
  const quantityDamaged_Control = new FormControl("", [Validators.required, Validators.pattern(numericNumberReg)]);
  const action_16_Control = new FormControl("", Validators.required);
  const action_17_Control = new FormControl("", Validators.required);
  const action_18_Control = new FormControl("", Validators.required);
  const lotNoControl = new FormControl("", Validators.required);
  const sampleAvailableControl = new FormControl("", Validators.required);
  const wasThereInjuryControl = new FormControl("", Validators.required);
  const wasThereDeathControl = new FormControl("", Validators.required);
  const requestedActionControl = new FormControl("", Validators.required);
  const eventDateControlControl = new FormControl(null, Validators.required);
  const comments_8Control = new FormControl("", Validators.required);
  const returnReasonControl = new FormControl("", Validators.required);
  
  const lineItemGroup = this.recievedLineItems.at(formArrayIndex) as FormGroup;

  if (requestTypeId === "RT_19") {
    (this.recievedLineItems.controls[formArrayIndex] as FormGroup).addControl("itemIdincorrect", new FormControl("", Validators.required));

    (this.recievedLineItems.controls[formArrayIndex] as FormGroup).addControl("quantityRecieved_19", new FormControl("", Validators.required));
    (this.recievedLineItems.controls[formArrayIndex] as FormGroup).addControl("uomRecieved_19", new FormControl("", [Validators.required, Validators.pattern('^[a-zA-Z \-\']+')]));
    (this.recievedLineItems.controls[formArrayIndex] as FormGroup).addControl("action_19", new FormControl("", Validators.required));
  }

  if (requestTypeId === "RT_16") {
    (this.recievedLineItems.controls[formArrayIndex] as FormGroup).addControl("quantityRecieved_16", quantityRecieved_16_Control);
    (this.recievedLineItems.controls[formArrayIndex] as FormGroup).addControl("uomRecieved_16", new FormControl("", [Validators.required, Validators.pattern('^[a-zA-Z \-\']+')]));
    (this.recievedLineItems.controls[formArrayIndex] as FormGroup).addControl("action_16", action_16_Control);

  }
  else if (requestTypeId === "RT_17") {
    (this.recievedLineItems.controls[formArrayIndex] as FormGroup).addControl("quantityRecieved_17", quantityRecieved_17_Control);
    (this.recievedLineItems.controls[formArrayIndex] as FormGroup).addControl("uomRecieved_17", new FormControl("", [Validators.required, Validators.pattern('^[a-zA-Z \-\']+')]));
    (this.recievedLineItems.controls[formArrayIndex] as FormGroup).addControl("action_17", action_17_Control);

  }
  else if (requestTypeId === "RT_18") {
    (this.recievedLineItems.controls[formArrayIndex] as FormGroup).addControl("quantityDamaged", quantityDamaged_Control);
    (this.recievedLineItems.controls[formArrayIndex] as FormGroup).addControl("action_18", action_18_Control);
    (this.recievedLineItems.controls[formArrayIndex] as FormGroup).addControl("damageType", new FormControl("", Validators.required));


  } else if (requestTypeId === "RT_8") {
    (this.recievedLineItems.controls[formArrayIndex] as FormGroup).addControl("lotNo", lotNoControl);
    (this.recievedLineItems.controls[formArrayIndex] as FormGroup).addControl("sampleAvailable", sampleAvailableControl);
    (this.recievedLineItems.controls[formArrayIndex] as FormGroup).addControl("wasThereInjury", wasThereInjuryControl);
    (this.recievedLineItems.controls[formArrayIndex] as FormGroup).addControl("wasThereDeath", wasThereDeathControl);
    (this.recievedLineItems.controls[formArrayIndex] as FormGroup).addControl("actionRequest_8", requestedActionControl);
    (this.recievedLineItems.controls[formArrayIndex] as FormGroup).addControl("eventDate", eventDateControlControl);
    (this.recievedLineItems.controls[formArrayIndex] as FormGroup).addControl("comments_8", comments_8Control);

  }
  else if(requestTypeId==="RT_22"){
    (this.recievedLineItems.controls[formArrayIndex] as FormGroup).addControl("quantityShortDated", new FormControl("", Validators.required));
    (this.recievedLineItems.controls[formArrayIndex] as FormGroup).addControl("uomShortdated", new FormControl("", [Validators.required, Validators.pattern('^[a-zA-Z \-\']+')]));
    (this.recievedLineItems.controls[formArrayIndex] as FormGroup).addControl("action_22", new FormControl("", Validators.required));
    (this.recievedLineItems.controls[formArrayIndex] as FormGroup).addControl("comments_22",  new FormControl("", Validators.required));
    (this.recievedLineItems.controls[formArrayIndex] as FormGroup).addControl("eventDate_22", eventDateControlControl);
    (this.recievedLineItems.controls[formArrayIndex] as FormGroup).addControl("invalidExpirationDate", new FormControl(false));    
  }
  else if (requestTypeId === "RT_23") {
    (this.recievedLineItems.controls[formArrayIndex] as FormGroup).addControl("lotNo_23", lotNoControl);
    (this.recievedLineItems.controls[formArrayIndex] as FormGroup).addControl("sampleAvailable_23", sampleAvailableControl);
    (this.recievedLineItems.controls[formArrayIndex] as FormGroup).addControl("wasThereInjury_23", wasThereInjuryControl);
    (this.recievedLineItems.controls[formArrayIndex] as FormGroup).addControl("wasThereDeath_23", wasThereDeathControl);
    (this.recievedLineItems.controls[formArrayIndex] as FormGroup).addControl("actionRequest_23", requestedActionControl);
    (this.recievedLineItems.controls[formArrayIndex] as FormGroup).addControl("eventDate_23", eventDateControlControl);
    (this.recievedLineItems.controls[formArrayIndex] as FormGroup).addControl("comments_23", comments_8Control);
  }else if (requestTypeId === "CA1_116"){
    (this.recievedLineItems.controls[formArrayIndex] as FormGroup).addControl("returnReason", returnReasonControl);
    
    const returneData = this.checkReturnEligibility(formArrayIndex);
    (this.recievedLineItems.controls[formArrayIndex] as FormGroup).addControl("shippedElegibilityFlag", new FormControl(returneData.shippedElegibilityFlag));
    (this.recievedLineItems.controls[formArrayIndex] as FormGroup).addControl("dropShipFlag", new FormControl(returneData.dropShipFlag));
    (this.recievedLineItems.controls[formArrayIndex] as FormGroup).addControl("inAndOutFlag", new FormControl(returneData.inAndOutFlag));
    (this.recievedLineItems.controls[formArrayIndex] as FormGroup).addControl("nonReturnableFlag", new FormControl(returneData.nonReturnableFlag));
    (this.recievedLineItems.controls[formArrayIndex] as FormGroup).addControl("nonReturnableReason", new FormControl(returneData.nonReturnableReason));

    
  }
  //*************************************************
  // Remove Controls not needed for the request type
  //*************************************************
      const controlsToRemove = {
        "RT_16": ["action_16","uomRecieved_16", "quantityRecieved_16"],
        "RT_17": ["quantityRecieved_17","uomRecieved_17", "action_17"],
        "RT_18": ["quantityDamaged", "action_18", "damageType"],
        "RT_8": ["lotNo", "sampleAvailable", "wasThereInjury", "wasThereDeath", "actionRequest_8", "eventDate", "comments_8"],
        "RT_22": ["uomShortdated", "comments_22", "quantityShortDated", "action_22", "eventDate_22", "invalidExpirationDate"],
        "RT_23": ["sampleAvailable_23", "wasThereInjury_23", "lotNo_23", "comments_23", "wasThereDeath_23", "eventDate_23", "actionRequest_23"],
        "RT_19": ["itemIdincorrect", "quantityRecieved_19", "uomRecieved_19", "action_19", "uomRecieved_missing", "action_missing", "quantityRecieved_missing"],
        "CA1_116": ["returnReason", "inAndOutFlag", "shippedElegibilityFlag", "dropShipFlag", "nonReturnableFlag", "nonReturnableReason"]
      };
  
      for (const [typeId, controls] of Object.entries(controlsToRemove)) {
           if (requestTypeId !== typeId) {
            controls.forEach(control => lineItemGroup.removeControl(control));
          }
          if (requestTypeId !== "CA1_116") {
            this.removeControlForReturnReason(formArrayIndex);
          }
      }
  
}
  
  public submitForm(): void {

    this.highlightedText=this.checkValid===true ? "black" : "red";
    this.caseCreateForm.value.submitted = true;
    if(!this.caseCreateForm.valid)
    {
      setTimeout(function(){
        const firstElementWithError:HTMLElement = document.querySelector('.mandatory-error');
        
        if (firstElementWithError) 
        {
          firstElementWithError.focus();        
          firstElementWithError.scrollIntoView({behavior: 'smooth', block: 'center'});
        } }, 500);
      return;
    }


    this.cspShowSpinner = true;
    var finalCaseSubmit = [];
    
    var item_length = this.caseCreateForm.value.items.length;
    for (var i = 0; i < item_length; i++) {
      var reqType = this.caseCreateForm.value.items[i].requestTypeId;
      var returnReason = this.caseCreateForm.value.items[i].returnReason;
      if (reqType !== "") {
        if(reqType !== "CA1_116"){
          var caseRequestOrderDetails = {
            firstName: this.user.firstName,
            lastName: this.user.lastName,
            email: this.user.email,
            phoneNumber: this.user.phoneNumber,
            userName: this.user.userId,
            categoryId: CategoryIdConstants.CATEGORYID[reqType].id,
            requestType: reqType,
            requestTypeDesc: CategoryIdConstants.CATEGORYID[reqType].value,
            purchaseOrderNumber: this.localStorageData.poNumber,
            orderId: this.localStorageData.orderNumber,
            shipTo: this.user.shipToAccountNumber,
            soldTo: this.user.soldToAccountNumber,
            attachments: this.getAttachments(),
            additionalEmails: this.getEmails(),
            items: []
          }
        }
        else if(reqType === "CA1_116"){
          if (returnReason !== "") {
            var caseRequestOrderDetails = {
              firstName: this.user.firstName,
              lastName: this.user.lastName,
              email: this.user.email,
              phoneNumber: this.user.phoneNumber,
              userName: this.user.userId,
              categoryId: CategoryIdConstants.CATEGORYID[returnReason].id,
              requestType: returnReason,
              requestTypeDesc: CategoryIdConstants.CATEGORYID[returnReason].value,
              purchaseOrderNumber: this.localStorageData.poNumber,
              orderId: this.localStorageData.orderNumber,
              shipTo: this.user.shipToAccountNumber,
              soldTo: this.user.soldToAccountNumber,
              attachments: this.getAttachments(),
              additionalEmails: this.getEmails(),
              items: []
            }
          }
        }
      }
      var caseRequestOrderDetails_16: CaseRequest;
      var caseRequestOrderDetails_17: CaseRequest;
      var caseRequestOrderDetails_18: CaseRequest;
      var caseRequestOrderDetails_1: CaseRequest;
      var caseRequestOrderDetails_8: CaseRequest;
      var caseRequestOrderDetails_22: CaseRequest;
      var caseRequestOrderDetails_23: CaseRequest;
      var caseRequestOrderDetails_19missing: CaseRequest;
      var caseRequestOrderDetails_27: CaseRequest;
      var caseRequestOrderDetails_28: CaseRequest;
      var caseRequestOrderDetails_29: CaseRequest;
      var caseRequestOrderDetails_30: CaseRequest;

      if (reqType === "RT_16") {
        var item_16 = {
          itemId: this.caseCreateForm.value.items[i].itemId,
          lineItemId: this.caseCreateForm.value.items[i].lineNumber,
          lineItemDesc: this.caseCreateForm.value.items[i].itemDesc,
          uom: this.caseCreateForm.value.items[i].unitOfMeasure,
          quantityOrdered: this.caseCreateForm.value.items[i].quantityOrdered,
          quantityReceived: this.caseCreateForm.value.items[i].quantityRecieved_16,
          uomReceived: this.caseCreateForm.value.items[i].uomRecieved_16,
          actionRequest: this.caseCreateForm.value.items[i].action_16,
          comments: this.caseCreateForm.value.items[i].comments.replace(/\n/g, '<br/>'),

        }
        if (caseRequestOrderDetails_16) {
          caseRequestOrderDetails_16.items.push(item_16)
        } else {
          caseRequestOrderDetails_16 = caseRequestOrderDetails;
          caseRequestOrderDetails_16.items.push(item_16)
        }

      }
      else if (reqType === "RT_17") {
        var item_17 = {
          itemId: this.caseCreateForm.value.items[i].itemId,
          lineItemId: this.caseCreateForm.value.items[i].lineNumber,
          lineItemDesc: this.caseCreateForm.value.items[i].itemDesc,
          uom: this.caseCreateForm.value.items[i].unitOfMeasure,
          quantityOrdered: this.caseCreateForm.value.items[i].quantityOrdered,
          quantityReceived: this.caseCreateForm.value.items[i].quantityRecieved_17,
          uomReceived: this.caseCreateForm.value.items[i].uomRecieved_17,
          actionRequest: this.caseCreateForm.value.items[i].action_17,
          comments: this.caseCreateForm.value.items[i].comments.replace(/\n/g, '<br/>'),

        }
        if (caseRequestOrderDetails_17) {
          caseRequestOrderDetails_17.items.push(item_17)
        } else {
          caseRequestOrderDetails_17 = caseRequestOrderDetails;
          caseRequestOrderDetails_17.items.push(item_17)
        }
      }
      else if (reqType === "RT_22") {
        var item_22 = {
          itemId: this.caseCreateForm.value.items[i].itemId,
          lineItemId: this.caseCreateForm.value.items[i].lineNumber,
          lineItemDesc: this.caseCreateForm.value.items[i].itemDesc,
          uom: this.caseCreateForm.value.items[i].unitOfMeasure,
          uomReceived: this.caseCreateForm.value.items[i].uomShortdated,
          quantityOrdered: this.caseCreateForm.value.items[i].quantityOrdered,
          quantityReceived: this.caseCreateForm.value.items[i].quantityShortDated,
          comments: this.caseCreateForm.value.items[i].comments_22.replace(/\n/g, '<br/>'),
          eventDate: moment(this.caseCreateForm.value.items[i].eventDate_22).format('MM/DD/YYYY'),
          actionRequest: this.caseCreateForm.value.items[i].action_22
        }
        if (caseRequestOrderDetails_22) {
          caseRequestOrderDetails_22.items.push(item_22)
        } else {
          caseRequestOrderDetails_22 = caseRequestOrderDetails;
          caseRequestOrderDetails_22.items.push(item_22)
        }
      }
      else if (reqType === "RT_18") {
        var item_18 = {
          itemId: this.caseCreateForm.value.items[i].itemId,
          lineItemId: this.caseCreateForm.value.items[i].lineNumber,
          lineItemDesc: this.caseCreateForm.value.items[i].itemDesc,
          uom: this.caseCreateForm.value.items[i].unitOfMeasure,
          quantityOrdered: this.caseCreateForm.value.items[i].quantityOrdered,
          quantityDamaged: this.caseCreateForm.value.items[i].quantityDamaged,
          damageType: this.caseCreateForm.value.items[i].damageType,
          actionRequest: this.caseCreateForm.value.items[i].action_18,
          comments: this.caseCreateForm.value.items[i].comments.replace(/\n/g, '<br/>'),
        }
        if (caseRequestOrderDetails_18) {
          caseRequestOrderDetails_18.items.push(item_18)
        } else {
          caseRequestOrderDetails_18 = caseRequestOrderDetails;
          caseRequestOrderDetails_18.items.push(item_18)
        }

      }
      else if (reqType === "RT_1") {
        var item_1 = {
          itemId: this.caseCreateForm.value.items[i].itemId,
          lineItemId: this.caseCreateForm.value.items[i].lineNumber,
          lineItemDesc: this.caseCreateForm.value.items[i].itemDesc,
          uom: this.caseCreateForm.value.items[i].unitOfMeasure,
          quantityOrdered: this.caseCreateForm.value.items[i].quantityOrdered,
          comments: this.caseCreateForm.value.items[i].comments.replace(/\n/g, '<br/>'),

        }
        if (caseRequestOrderDetails_1) {
          caseRequestOrderDetails_1.items.push(item_1);
        } else {
          caseRequestOrderDetails_1 = caseRequestOrderDetails;

          caseRequestOrderDetails_1.items.push(item_1);
        }
      }
      else if (reqType === "RT_8") {
        var item_8 = {
          itemId: this.caseCreateForm.value.items[i].itemId,
          lineItemId: this.caseCreateForm.value.items[i].lineNumber,
          lineItemDesc: this.caseCreateForm.value.items[i].itemDesc,
          uom: this.caseCreateForm.value.items[i].unitOfMeasure,
          quantityOrdered: this.caseCreateForm.value.items[i].quantityOrdered,
          lotNo: this.caseCreateForm.value.items[i].lotNo,
          sampleAvailable: this.caseCreateForm.value.items[i].sampleAvailable,
          wasThereInjury: this.caseCreateForm.value.items[i].wasThereInjury,
          wasThereDeath: this.caseCreateForm.value.items[i].wasThereDeath,
          actionRequest: this.caseCreateForm.value.items[i].actionRequest_8,
          eventDate: moment(this.caseCreateForm.value.items[i].eventDate).format('MM/DD/YYYY'),
          comments: this.caseCreateForm.value.items[i].comments_8.replace(/\n/g, '<br/>'),

        }
        if (caseRequestOrderDetails_8) {
          caseRequestOrderDetails_8.items.push(item_8)
        }
        else {
          caseRequestOrderDetails_8 = caseRequestOrderDetails;
          caseRequestOrderDetails_8.items.push(item_8)
        }
      }
      else if (reqType === "RT_23") {
        var item_23 = {
          itemId: this.caseCreateForm.value.items[i].itemId,
          lineItemId: this.caseCreateForm.value.items[i].lineNumber,
          lineItemDesc: this.caseCreateForm.value.items[i].itemDesc,
          uom: this.caseCreateForm.value.items[i].unitOfMeasure,
          quantityOrdered: this.caseCreateForm.value.items[i].quantityOrdered,
          lotNo: this.caseCreateForm.value.items[i].lotNo_23,
          sampleAvailable: this.caseCreateForm.value.items[i].sampleAvailable_23,
          wasThereInjury: this.caseCreateForm.value.items[i].wasThereInjury_23,
          wasThereDeath: this.caseCreateForm.value.items[i].wasThereDeath_23,
          comments: this.caseCreateForm.value.items[i].comments_23.replace(/\n/g, '<br/>'),
          eventDate: moment(this.caseCreateForm.value.items[i].eventDate_23).format('MM/DD/YYYY'),
          actionRequest: this.caseCreateForm.value.items[i].actionRequest_23
        }
        if (caseRequestOrderDetails_23) {
          caseRequestOrderDetails_23.items.push(item_23)
        }else {
          caseRequestOrderDetails_23 = caseRequestOrderDetails;
          caseRequestOrderDetails_23.items.push(item_23)
        }
      }
      else if (reqType === "RT_19") {

        var item_19 = {
          itemId: this.caseCreateForm.value.items[i].itemIdincorrect,
          lineItemId: this.caseCreateForm.value.items[i].lineNumber,
          lineItemDesc: this.caseCreateForm.value.items[i].itemDesc,
          uomReceived: this.caseCreateForm.value.items[i].uomRecieved_19,
          actionRequest: this.caseCreateForm.value.items[i].action_19,
          quantityReceived: this.caseCreateForm.value.items[i].quantityRecieved_19,
          comments: this.caseCreateForm.value.items[i].comments.replace(/\n/g, '<br/>'),
        }
        var item_19missing = {
          itemId: this.caseCreateForm.value.items[i].itemId,
          uomReceived: this.caseCreateForm.value.items[i].uomRecieved_missing,
          uom: this.caseCreateForm.value.items[i].unitOfMeasure,
          actionRequest: this.caseCreateForm.value.items[i].action_missing,
          quantityReceived: this.caseCreateForm.value.items[i].quantityRecieved_missing,
          quantityOrdered: this.caseCreateForm.value.items[i].quantityOrdered,
          itemMissing: "X"
        }

        if (caseRequestOrderDetails_19missing) {
          caseRequestOrderDetails_19missing.items.push(item_19)
          if (this.caseCreateForm.value.items[i].missingFromOrder === "Yes") {
            caseRequestOrderDetails_19missing.missingItems.push(item_19missing)
            caseRequestOrderDetails_19missing.missingFromOrder = this.caseCreateForm.value.items[i].missingFromOrder;
          }

        }
        else {
          caseRequestOrderDetails_19missing = caseRequestOrderDetails;
          caseRequestOrderDetails_19missing.missingFromOrder = this.caseCreateForm.value.items[i].missingFromOrder;
          caseRequestOrderDetails_19missing.missingItems = [];

          caseRequestOrderDetails_19missing.items.push(item_19)

          if (this.caseCreateForm.value.items[i].missingFromOrder === "Yes") {
            caseRequestOrderDetails_19missing.missingItems.push(item_19missing)
          }

        }
      }
      else if (reqType === "CA1_116" && returnReason ==="RT_27" ){
        var item_27 = {
          itemId: this.caseCreateForm.value.items[i].itemId,
          lineItemDesc: this.caseCreateForm.value.items[i].itemDesc,
          lineItemId: this.caseCreateForm.value.items[i].lineNumber,
          uom: this.caseCreateForm.value.items[i].unitOfMeasure,
          uomReceived: this.caseCreateForm.value.items[i].uomRecieved_27,
          quantityOrdered: this.caseCreateForm.value.items[i].quantityOrdered,
          quantityReceived: this.caseCreateForm.value.items[i].quantityRecieved_27,
          comments: this.caseCreateForm.value.items[i].comments_27.replace(/\n/g, '<br/>')
        }
        if (caseRequestOrderDetails_27) {
          caseRequestOrderDetails_27.items.push(item_27)
        }else {
          caseRequestOrderDetails_27 = caseRequestOrderDetails;
          caseRequestOrderDetails_27.items.push(item_27)
        }
      }
      else if (reqType === "CA1_116" && returnReason ==="RT_28" ){
        var item_28 = {
          itemId: this.caseCreateForm.value.items[i].itemId,
          lineItemDesc: this.caseCreateForm.value.items[i].itemDesc,
          uomReceived: this.caseCreateForm.value.items[i].uomRecieved_28,
          quantityOrdered: this.caseCreateForm.value.items[i].quantityOrdered,
          quantityReceived: this.caseCreateForm.value.items[i].quantityRecieved_28,
          uom: this.caseCreateForm.value.items[i].unitOfMeasure,
          lineItemId: this.caseCreateForm.value.items[i].lineNumber,
          comments: this.caseCreateForm.value.items[i].comments_28.replace(/\n/g, '<br/>')
        }
        if (caseRequestOrderDetails_28) {
          caseRequestOrderDetails_28.items.push(item_28)
        }else {
          caseRequestOrderDetails_28 = caseRequestOrderDetails;
          caseRequestOrderDetails_28.items.push(item_28)
        }
      }
      else if (reqType === "CA1_116" && returnReason ==="RT_29" ){
        var item_29 = {
          itemId: this.caseCreateForm.value.items[i].itemId,
          lineItemDesc: this.caseCreateForm.value.items[i].itemDesc,
          uomReceived: this.caseCreateForm.value.items[i].uomRecieved_29,
          quantityOrdered: this.caseCreateForm.value.items[i].quantityOrdered,
          quantityReceived: this.caseCreateForm.value.items[i].quantityRecieved_29,
          uom: this.caseCreateForm.value.items[i].unitOfMeasure,
          lineItemId: this.caseCreateForm.value.items[i].lineNumber,
          comments: this.caseCreateForm.value.items[i].comments_29.replace(/\n/g, '<br/>')
        }
        if (caseRequestOrderDetails_29) {
          caseRequestOrderDetails_29.items.push(item_29)
        }else {
          caseRequestOrderDetails_29 = caseRequestOrderDetails;
          caseRequestOrderDetails_29.items.push(item_29)
        }
      }
      else if (reqType === "CA1_116" && returnReason ==="RT_30" ){
        var item_30 = {
          itemId: this.caseCreateForm.value.items[i].itemId,
          lineItemId: this.caseCreateForm.value.items[i].lineNumber,
          lineItemDesc: this.caseCreateForm.value.items[i].itemDesc,
          uom: this.caseCreateForm.value.items[i].unitOfMeasure,
          quantityOrdered: this.caseCreateForm.value.items[i].quantityOrdered,
          quantityReceived: this.caseCreateForm.value.items[i].quantityRecieved_30,
          uomReceived: this.caseCreateForm.value.items[i].uomRecieved_30,
          lotNo: this.caseCreateForm.value.items[i].lotNo_30,
          comments: this.caseCreateForm.value.items[i].comments_30.replace(/\n/g, '<br/>')
        }
        if (caseRequestOrderDetails_30) {
          caseRequestOrderDetails_30.items.push(item_30)
        }else {
          caseRequestOrderDetails_30 = caseRequestOrderDetails;
          caseRequestOrderDetails_30.items.push(item_30)
        }
      }


    }

    finalCaseSubmit.push(caseRequestOrderDetails_16, caseRequestOrderDetails_17, caseRequestOrderDetails_18, 
                          caseRequestOrderDetails_1, caseRequestOrderDetails_19missing, caseRequestOrderDetails_8,
                          caseRequestOrderDetails_23,caseRequestOrderDetails_22,caseRequestOrderDetails_27, 
                          caseRequestOrderDetails_28,caseRequestOrderDetails_29,caseRequestOrderDetails_30);

    finalCaseSubmit = finalCaseSubmit.filter(function (element) {
      return element !== undefined;
    });
    //this.cspFormChangedReset.emit();
    for (var z = 0; z < finalCaseSubmit.length; z++) {
      const finalCaseSubmitData = finalCaseSubmit[z];
      if(finalCaseSubmitData.missingFromOrder=="Yes"){
        var found = finalCaseSubmitData.missingItems.find(element =>
          (element.actionRequest == "08")
        );
      }
      else{
        var found = finalCaseSubmitData.items.find(element =>
          (element.actionRequest == "06" || element.actionRequest == "04" || element.actionRequest == "13")
        );
      }
      if (found != undefined) {
        this.setCaseCategoryAndRequestType(found,finalCaseSubmitData);
      }
      // console.log("caseRequest", finalCaseSubmit[z]);

      this.casesService.createOrderDetailsCase(finalCaseSubmitData).subscribe(
        (caseResponse: CaseResponse) => {
          if (!caseResponse) {
            this.cspShowSpinner = false;

            this.errorResponse = caseResponse;
          } else {
            this.submittedItemCaseId.push(caseResponse)
            this.errorResponse = null;
            if (this.submittedItemCaseId.length == finalCaseSubmit.length) {
              window.dispatchEvent(new Event('formClean'));
              this.cspShowSpinner = false;
              localStorage.setItem("OrderCaseList", JSON.stringify(this.submittedItemCaseId));
              this.router.navigate(['/case/order-case-acknowledgement']);
            }
            let logMessage: string = "AppId:26095 : " + "Case Created from Form B. " +
              "Case details : " + JSON.stringify(caseResponse);

            if (this.selectedContactResponse != null && this.selectedContactResponse.totalAdded > 0 && this.submittedItemCaseId.length == 1) {
              logMessage = logMessage + " Contacts Added : { "
                + " manuallyAdded : " + this.selectedContactResponse.manuallyAdded
                + ", fromAddressBookAdded : " + this.selectedContactResponse.fromAddressBook
                + ", totalRecipients : " + this.selectedContactResponse.totalAdded
                + " }"
            }
            this.logService.logMessage(logMessage).subscribe();
          }
        },
        (error: ErrorResponse) => {
       
            this.cspShowSpinner = false;
          
          this.errorResponse = error;
          let logMessage: string = "AppId:26095 : Case Creation error from Form B. ";
            if(this.errorResponse.error && this.errorResponse.error.description){
              logMessage = logMessage + this.errorResponse.error.errorCode + " : " + this.errorResponse.error.description;
            }else if(this.errorResponse.error){
              logMessage = logMessage + this.errorResponse.error.errorCode + " : " + this.errorResponse.error.errorMessage;
            }
            else{
                logMessage = logMessage + this.errorResponse.message;
            }
        this.logService.logMessage(logMessage).subscribe();
        } 
      );

    }

  }
  setCaseCategoryAndRequestType(found: any, finalCaseSubmitData: any) {
        
    
    if("04".match(found.actionRequest)){
      finalCaseSubmitData.categoryId = "CA1_156";
      finalCaseSubmitData.requestType = "RT_24";
      finalCaseSubmitData.requestTypeDesc = "Reorder and CR/RE";
    }
    else if("06".match(found.actionRequest)){
      finalCaseSubmitData.categoryId = "CA1_156";
      finalCaseSubmitData.requestType = "RT_21";
      finalCaseSubmitData.requestTypeDesc = "Reorder and CR/RE";
    }
    else if("13".match(found.actionRequest)){
      finalCaseSubmitData.categoryId = "CA1_156";
      finalCaseSubmitData.requestType = "RT_26";
      finalCaseSubmitData.requestTypeDesc = "Reorder and CR/RE";
    } else if("08".match(found.actionRequest)){
      finalCaseSubmitData.categoryId = "CA1_156";
      finalCaseSubmitData.requestType = "RT_25";
      finalCaseSubmitData.requestTypeDesc = "Reorder and CR/RE";
    }
  }

  public getAttachments() {
    const tempAttachment = this.attachments.filter((value, index) => {
      if (value.ecmDocId) {
        return value;
      }
    });
    return tempAttachment.map((value, index) => {
      return {
        fileName: value.name,
        fileType: value.type,
        id: value.id,
        ecmDocId: value.ecmDocId
      };
    });


  }

  public getEmails() {
    if (this.selectedContactsList) {
      var emailList = this.selectedContactsList.map(value => value.email);
      return emailList;
    }
    else {
      return [];
    }
  }
  public showAttachmentModal(): void {
    this.isAttachmentModalVisible = true;
  }


  public hideAttachmentModal(): void {
    this.isAttachmentModalVisible = false;
  }


  public attachUploadedFiles(_attachments): void {
    if (_attachments) {
      this.attachments = [].concat(_attachments);
      this.attachmentService.attachmentsSubject.next(this.attachments.filter(value => value.ecmDocId) || []);
    } else {
      this.attachmentService.attachmentsSubject.next([]);
    }
  }


  public removeAttachment(index: number): void {
    window.dispatchEvent(new CustomEvent('cah:spinnerShow'));
    this.attachments.splice(index, 1);
    this.attachmentService.attachmentsSubject.next(this.attachments);
    window.dispatchEvent(new CustomEvent('cah:spinnerHide'));
  }

  public onSelectRecipientClick() {
    this.isRecipientModalVisible = true;
    this.isChecked = true;
    this.selectedContactsList = [];
    this.selectedContactResponse = null;
  }


  public hideRecipientModal() {
    this.isRecipientModalVisible = false;
    this.isChecked = false;
  }


  public getRecipientValue(contactResponse: ContactResponse) {
    this.selectedContactResponse = contactResponse;
    this.selectedContactsList = contactResponse.contactList;
  }


  public onReturnReasonSelect(event: Event, formArrayIndex: number){
   
    const returnReason = (event.target as HTMLInputElement).value;
    const lotNoControl_30 = new FormControl("", Validators.required);
    const quantityRecieved_116_Control = new FormControl("", [Validators.required]);
    this.removeControlForReturnReason(formArrayIndex);
    const receivedLineItems = this.recievedLineItems.controls[formArrayIndex] as FormGroup;

    switch(returnReason) {
        case 'RT_27': {
            receivedLineItems.addControl("quantityRecieved_27", quantityRecieved_116_Control);
            receivedLineItems.addControl("uomRecieved_27", new FormControl("", [Validators.required]));
            receivedLineItems.addControl("restockingFeeAcknowledge_27", new FormControl("", [Validators.requiredTrue]));
            this.checkValid= false;
            this.highlightedText= "black";
            receivedLineItems.addControl("comments_27", new FormControl(""));
            break;
        }
        case 'RT_28': {
            receivedLineItems.addControl("quantityRecieved_28", quantityRecieved_116_Control);
            receivedLineItems.addControl("uomRecieved_28", new FormControl("", [Validators.required]));
            receivedLineItems.addControl("restockingFeeAcknowledge_28", new FormControl("", [Validators.requiredTrue]));
            this.checkValid= false;
            this.highlightedText= "black";
            receivedLineItems.addControl("comments_28", new FormControl("", Validators.required));
            break;
        }
        case 'RT_29': {
            receivedLineItems.addControl("quantityRecieved_29", quantityRecieved_116_Control);
            receivedLineItems.addControl("uomRecieved_29", new FormControl("", [Validators.required]));
            receivedLineItems.addControl("restockingFeeAcknowledge_29", new FormControl("", [Validators.requiredTrue]));
            this.checkValid= false;
            this.highlightedText= "black";
            receivedLineItems.addControl("comments_29", new FormControl("", Validators.required));
            break;

        }
        case 'RT_30': {
            receivedLineItems.addControl("lotNo_30", lotNoControl_30);
            receivedLineItems.addControl("quantityRecieved_30", quantityRecieved_116_Control);
            receivedLineItems.addControl("uomRecieved_30", new FormControl("", [Validators.required]));
            receivedLineItems.addControl("comments_30", new FormControl(""));
            break;

        }
    }
}
  
  public checkReturnEligibility(formArrayIndex):any{
    const fulfillmentList = this.localStorageData.lineItems[formArrayIndex].fulfillment;
    let nonReturnable = this.localStorageData.lineItems[formArrayIndex].nonReturnable;
    let nonReturnableReason = "";
    let shippedElegibilityFlag:boolean = false;
    let dropShipFlag:boolean = false;
    let inAndOutFlag:boolean = false;
    let nonReturnableFlag:boolean = false;
    fulfillmentList.forEach(element => {
      if( element.status == "Inandout"){
        inAndOutFlag = true;
      }
      else if( element.status == "Shipped" && element.shippedDate){
        if(nonReturnable){
          nonReturnableFlag = true;
          nonReturnableReason = this.localStorageData.lineItems[formArrayIndex].nonReturnableReason; // Display only First reason
        }else{
          let todayDate : Date = new Date();
          let shippedDate: Date = new Date(element.shippedDate)
      
          var ShippedTime = todayDate.getTime() - shippedDate.getTime(); 
          var ShippedDays = ShippedTime / (1000 * 3600 * 24);
          if(ShippedDays>93){
            shippedElegibilityFlag = true;
          }else{
            shippedElegibilityFlag = false;
          }
        } 
      }else if(element.status == "Dropship"){
        if(nonReturnable){
          nonReturnableFlag = true;
          nonReturnableReason = this.localStorageData.lineItems[formArrayIndex].nonReturnableReason; // Display only First reason
        }
        dropShipFlag = true;
      }
      
    });

    return {"shippedElegibilityFlag":shippedElegibilityFlag,"dropShipFlag":dropShipFlag,"inAndOutFlag":inAndOutFlag,
            "nonReturnableFlag":nonReturnableFlag,"nonReturnableReason":nonReturnableReason};
  }

  removeControlForReturnReason(formIndex: number) {
    const controlsToRemove = [
      "restockingFeeAcknowledge_27",
      "restockingFeeAcknowledge_28",
      "restockingFeeAcknowledge_29",
      "quantityRecieved_27",
      "uomRecieved_27",
      "quantityRecieved_28",
      "uomRecieved_28",
      "quantityRecieved_29",
      "uomRecieved_29",
      "lotNo_30",
      "quantityRecieved_30",
      "uomRecieved_30",
      "comments_27",
      "comments_28",
      "comments_29",
      "comments_30"
    ];
  
    const formGroup = this.recievedLineItems.controls[formIndex] as FormGroup;
  
    for (const control of controlsToRemove) {
      formGroup.removeControl(control);
    }
  }

  setInvalidExpDateflag(expDateFlag, index){
    this.recievedLineItems.controls[index].get('invalidExpirationDate').setValue(expDateFlag);
    console.log(this.recievedLineItems.controls[index].get('invalidExpirationDate'))
  }
}
