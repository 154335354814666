export class CategoryIdConstants {

    public static readonly CATEGORYID = {
        "RT_1": {
            "id": "CA1_150",
            "value": "Order Status"
        },
        "RT_8": {
            "id": "CA1_151",
            "value": "Product Complaints"
        },
        "RT_16": {
            "id": "CA1_154",
            "value": "Shortages"
        },
        "RT_17": {
            "id": "CA1_154",
            "value": "Shipping Overages"
        },
        "RT_18": {
            "id": "CA1_154",
            "value": "Damaged Goods"
        },
        "RT_19": {
            "id": "CA1_154",
            "value": "Incorrect Material Delivered"
        },
        "RT_22": {
            "id": "CA1_154",
            "value": "Short-dated Product"
        },
        "RT_23": {
            "id": "CA1_154",
            "value": "Expired Product"
        },
        "RT_30":{
            "id": "CA1_116",
            "value": "Product Recall"
        },"RT_27": {"id": "CA1_116",
        "value": "No Longer Needed"
        },"RT_28": {"id": "CA1_116",
        "value": "Incorrect Quantity/UOM Ordered"
        },"RT_29": {"id": "CA1_116",
        "value": "Incorrect Product Ordered"
        }
    }
}
