export class ExportReport{

    public static readonly REPORT_HEADERS = new Array(
        'Case Number',
        'Contact Method',
        'Case Status', 
        'PO Number',
        'Issue Type', 
        'Request Type',
        'Sold To',
        'Ship To',
        'Created Date (EST)',
        'Last Modified Date (EST)',
        'Contact Name'
    );

    public static readonly REPORT_COLUMNS = new Array(
        'id',
        'channel',
        'status',
        'purchaseOrderNumber',
        'description',
        'requestTypeDescription',
        'soldToId',
        'shipToId',
        'openDateTime',
        'lastModifiedDateTime',
        'contactName'
    );

}