<div class="pickertable" *ngIf="isFromFormA">
  <form [formGroup]="caseCreateForm">
    <div formArrayName="items">
      <div class="calendar" [formGroupName]="formIndex" [ngClass]="{'error-date':  caseCreateForm.value.submitted && !eventDate}">
        <input type="text" placeholder="Select a Date" class="form-control date-range-picker-input"
          [bsValue]="eventDate"
          [minDate]="minDate" [maxDate]="maxDate"
          formControlName="eventDate"
          [(ngModel)]="eventDate"
          (bsValueChange)="onValueChange($event)"
          [bsConfig]="{ isAnimated: true, displayMonths: 1, dateInputFormat: 'MM/DD/YYYY' , showWeekNumbers: false ,selectFromOtherMonth:true,keepDatesOutOfRules: false }"
          #datePicker="bsDatepicker"
          bsDatepicker
          placement="bottom">
        <button id="dateCalenderBtn" class="btn btn-light date-range-picker-btn"
        (click)="datePicker.toggle(); " type="button">
          <img class="calender-img" src="/etc/designs/market/base/assets/icons/calendar_icon.svg" alt="">
        </button>
      </div>
      
    </div>
    <span *ngIf="caseCreateForm.value.submitted && !eventDate" style="color: red; font-size:15px;">Date is required</span>
  </form>
</div>
<!-- For formB -->
<div>
  <div class="pickertable" *ngIf="reqType === 'RT_23' ">
    <form [formGroup]="caseCreateForm">
      <div formArrayName="items">
        <div [formGroupName]="formIndex" [ngClass]="{'error-date':  caseCreateForm.value.submitted && !eventDate_23}" style=" height: 40px;">
          <input type="text" placeholder="Select a Date" class="form-control date-range-picker-input-formB"
          [bsValue]="eventDate"
          [minDate]="minDate" [maxDate]="maxDate"
          formControlName="eventDate_23"
          [(ngModel)]="eventDate_23"
          (bsValueChange)="onValueChange($event)"
          [bsConfig]="{ isAnimated: true, displayMonths: 1, dateInputFormat: 'MM/DD/YYYY' , showWeekNumbers: false ,selectFromOtherMonth:true,keepDatesOutOfRules: false }"
          #datePicker="bsDatepicker"
          bsDatepicker
          placement="bottom">
          <button id="dateCalenderBtnFormB" class="btn btn-light date-range-picker-btn" (click)="datePicker.toggle();"
            type="button">
            <img class="calender-img" src="/etc/designs/market/base/assets/icons/calendar_icon.svg" alt="">
          </button>
        </div>
        <span class="text-danger" *ngIf="caseCreateForm.value.submitted && !eventDate_23">Required field</span>
      </div>
    </form>
  </div>
  <div class="pickertable" *ngIf="reqType === 'RT_8' ">
    <form [formGroup]="caseCreateForm">
      <div formArrayName="items">
        <div [formGroupName]="formIndex" [ngClass]="{'error-date':  caseCreateForm.value.submitted && !eventDate }" style=" height: 40px;">
          <input type="text" placeholder="Select a Date" class="form-control date-range-picker-input-formB"
          [bsValue]="eventDate"
          [minDate]="minDate" [maxDate]="maxDate"
          formControlName="eventDate"
          [(ngModel)]="eventDate"
          (bsValueChange)="onValueChange($event)"
          [bsConfig]="{ isAnimated: true, displayMonths: 1, dateInputFormat: 'MM/DD/YYYY' , showWeekNumbers: false ,selectFromOtherMonth:true,keepDatesOutOfRules: false }"
          #datePicker="bsDatepicker"
          bsDatepicker
          placement="bottom">
          <button id="dateCalenderBtnFormB" class="btn btn-light date-range-picker-btn" (click)="datePicker.toggle(); "
            type="button">
            <img class="calender-img" src="/etc/designs/market/base/assets/icons/calendar_icon.svg" alt="">
          </button>    
        </div>
        <span class="text-danger" *ngIf="caseCreateForm.value.submitted && !eventDate">Required field</span>
      </div>
    </form>
  </div>
  <div class="pickertable" *ngIf="reqType === 'RT_22' ">
    <form [formGroup]="caseCreateForm">
      <div formArrayName="items">
        <div [formGroupName]="formIndex" [ngClass]="{'error-date':  caseCreateForm.value.submitted && !eventDate_22}" style=" height: 40px;">
          <input type="text" placeholder="Select a Date" class="form-control date-range-picker-input-formB"
          [bsValue]="eventDate"
          [minDate]="minDate" [maxDate]="maxDate"
          formControlName="eventDate_22"
          [(ngModel)]="eventDate_22"
          (bsValueChange)="onValueChange($event)"
          [bsConfig]="{ isAnimated: true, displayMonths: 1, dateInputFormat: 'MM/DD/YYYY' , showWeekNumbers: false ,selectFromOtherMonth:true,keepDatesOutOfRules: false }"
          #datePicker="bsDatepicker"
          bsDatepicker
          placement="bottom">
          <button id="dateCalenderBtnFormB" class="btn btn-light date-range-picker-btn" (click)="datePicker.toggle();"
            type="button">
            <img class="calender-img" src="/etc/designs/market/base/assets/icons/calendar_icon.svg" alt="">
          </button> 
        </div>
        <span class="text-danger" *ngIf="caseCreateForm.value.submitted && !eventDate_22">Required field</span>
      </div>  
    </form>
  </div>
</div> 
