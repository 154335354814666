import {
  Component, Input, OnInit, ViewEncapsulation, Output, EventEmitter,  ElementRef, ViewChild} from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { BsDatepickerDirective } from 'ngx-bootstrap/datepicker';


@Component({
  selector: 'app-market-help-date-picker',
  templateUrl: './market-help-date-picker.component.html',
  styleUrls: ['./market-help-date-picker.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MarketHelpDatePickerComponent implements OnInit {
  currentDate: Date = new Date();
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() placeHolder: string;
  resetPlaceHolder: string[] = [];
  resetClicked: boolean = false;

  @ViewChild('datePicker', { static: true }) datePicker:  BsDatepickerDirective;

  @Input() caseCreateForm:FormGroup
  @Input() reqType:any;
  @Input() formIndex : number = 0;
  @Output() changedDate: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() invalidDate = new EventEmitter();

  date = new Date();
 

  htmlElement: HTMLElement;
  isFromFormA: boolean = false;

  constructor(private elementRef: ElementRef) {
    // empty
  }

  ngOnInit() {
    if(this.reqType == null || this.reqType== '' ){
      this.isFromFormA = true;
    } 
  }

  get formContols() :FormGroup{
    let formArray=this.getFormArray();
    let formGroup=formArray.controls[this.formIndex] as FormGroup;
   return formGroup
  }
  getFormArray() :FormArray {
    return this.caseCreateForm.get('items') as FormArray;
  }

  onValueChange(value:Date=new Date()) {
    this.invalidDate.emit(value);
  }

}
