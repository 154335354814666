import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ChatCsrfRequestResponse } from '../model/chatCsrfRequestResponse';


@Injectable({
  providedIn: 'root'
})
export class ChatService {
	private baseApiUrl: string;

  constructor(private http: HttpClient) {
    this.baseApiUrl = environment.baseApiUrl;
   }

   getCSRFToken(): Observable<any> {
      return this.http.get(this.baseApiUrl + '/medical-market-chats/help/v1/chatsCSRF');
   }

   registerStatistic(chatCsrfRequest : ChatCsrfRequestResponse):Observable<any>{
     return this.http.post(this.baseApiUrl + '/medical-market-chats/help/v1/chatsStatistics',chatCsrfRequest);
   }
   
  getAgentAvailability(chatCsrfRequest : ChatCsrfRequestResponse): Observable<any> {
       return this.http.post(this.baseApiUrl + '/medical-market-chats/help/v1/chatsAgentAvailability',chatCsrfRequest)
  }

}
