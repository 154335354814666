/**
 * https://confluence.cardinalhealth.com/display/EA/Cases+API
 */
import { Injectable } from '@angular/core';
import { throwError} from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { CaseDetail } from '../model/caseDetailResponse';
import { CaseMasterData } from '../model/caseMasterData';
import { CaseRequest } from '../model/caseRequest';
import { CaseResponse } from '../model/caseResponse';
import { CaseUpdateRequest } from '../model/caseUpdateRequest';
import { CaseSearchResponse } from '../model/caseSearchResponse';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { User } from "../shared/User";

@Injectable({ providedIn: "root" })
export class CasesService {

	// public header: Object;
	private baseApiUrl: string;
	// public generatedTokenHeader = AuthHeader.getGeneratedTokenHeader();
	hostname: string;

	constructor(private http: HttpClient) {
		this.baseApiUrl = environment.baseApiUrl;
	}

	//Create case api
	public createCase(caseRequest: CaseRequest): Observable<CaseResponse> {
		const soldToId = User.getUser().soldToAccountNumber;
		const shipToId = caseRequest.shipTo;
		return this.http.post(this.baseApiUrl + `/medical-market-cases/soldtos/${soldToId}/shiptos/${shipToId}/v1/cases`, caseRequest)		
	}

	//Create OrderDetailcase api
	public createOrderDetailsCase(caseRequest: CaseRequest): Observable<CaseResponse> {
    const soldToId = caseRequest.soldTo;
		const shipToId = caseRequest.shipTo;
		return  this.http.post(this.baseApiUrl + `/medical-market-cases/soldtos/${soldToId}/shiptos/${shipToId}/v1/cases`, caseRequest)
	}


	//It returns the complete details of a case
	public getCaseDetails(id: string): Observable<CaseDetail> {
    const { soldToAccountNumber: soldToId, shipToAccountNumber: shipToId } = User.getUser();
		return this.http.get(this.baseApiUrl + `/medical-market-cases/soldtos/${soldToId}/shiptos/${shipToId}/v1/cases/${id}`)
	}


	//It returns all the case metadata
	public getCaseMasterData(): Observable<CaseMasterData> {
		return this.http.get(this.baseApiUrl+"/medical-market-cases/v1/cases/masterdata")			
	}
	
	//It will update a case for given id in request
	public updateCase(id: string, shipToId: string, caseUpdateRequest: CaseUpdateRequest): Observable<any> {
		const soldToId = User.getUser().soldToAccountNumber;
		return this.http.put(this.baseApiUrl + `/medical-market-cases/soldtos/${soldToId}/shiptos/${shipToId}/v1/cases/${id}`, caseUpdateRequest)
	}


	public getFilteredResults(servletURI: string, selectedCase: string): Observable<any> {
		let formData: string = 'filter=' + selectedCase;
		const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
		return this.http.post(servletURI, formData, { headers: headers },)
			.pipe(catchError(error => throwError(error)));
	}


	public search(filter: any): Observable<CaseSearchResponse> {

		let requestdata = {
			fromDate: filter.fromDate,
			toDate: filter.toDate,
			status: filter.status,
			channelTypes: (filter.caseNumber == "" || filter.caseNumber == undefined) ? filter.channelTypes : "",
			caseNumber: filter.caseNumber,
			shipto: filter.shipto,
			export: false,
			poNumber: filter.poNumber
		}
		const soldtoid = User.getUser().soldToAccountNumber;
		return this.http.post(this.baseApiUrl+ `/medical-market-cases/soldtos/${soldtoid}/v1/cases`, requestdata)
		
	}

	// public getGeneratedToken() {
	// 	return this.http.get(this.baseApiUrl+ `/medical-market-tokengenerator/v2/generator/token`, this.generatedTokenHeader)
	// }

	public exportReport(requestdata: any): Observable<CaseSearchResponse> {
		const soldtoid = User.getUser().soldToAccountNumber;
		return this.http.post(this.baseApiUrl+ `/medical-market-cases/soldtos/${soldtoid}/v1/cases`, requestdata)
		
	}
		

}
