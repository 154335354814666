import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { HelpHomeComponent } from './components/help-home/help.component'
import { CreateCaseComponent } from './components/create-case/create-case.component';
import { SearchHelpTicketsComponent } from './components/search-help-tickets/search-help-tickets.component';
import { CustomMultiselectDropdownComponent } from './components/multiselect-dropdown/custom-multiselect-dropdown.component'
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateRangeComponent } from './components/date-range/date-range.component';
import { CommentBoxComponent } from './components/comment-box/comment-box.component';
import { SendEmailCopyComponent } from './components/send-email-copy/send-email-copy.component';
import { OrderInqueryFormComponent } from './components/create-case/order-inquery-form/order-inquery-form.component';
import { ProductQuestionsFormComponent } from './components/create-case/product-questions-form/product-questions-form.component';
import { ShippingDiscrepancyFormComponent } from './components/create-case/shipping-discrepancy-form/shipping-discrepancy-form.component';
import { InvoiceIssuesFormComponent } from './components/create-case/invoice-issues-form/invoice-issues-form.component';
import { AttachmentComponent } from './components/attachment/attachment.component';
import { ModalComponent } from './components/common/modal/modal.component';
import { SubmitCaseDiscardComponent } from './components/submit-case-discard/submit-case-discard.component';
import { MarketHelpDatePickerComponent } from './components/common/market-help-date-picker/market-help-date-picker.component';
import { NumericOnlyDirective } from './directives/numeric-only.directive';
import { CaseDetailComponent } from './components/case-detail/case-detail.component';
import { AlphabetOnlyDirective } from './directives/alphabet-only.directive';
import { DecimalsDirective } from './directives/decimals.directive';
import { CaseAcknowledgementComponent } from './components/create-case/case-acknowledgement/case-acknowledgement.component';
import { OrderDetailsCaseComponent } from './components/create-case/order-details-case/order-details-case.component';
import { SelectRecipientComponent } from './components/create-case/select-recipient/select-recipient.component';
import { FilterPipe } from '../../pipes/filter.pipe';
import { OrderCaseAcknowledgementComponent } from './components/create-case/order-case-acknowledgement/order-case-acknowledgement.component'
import { MissingItemsFormComponent } from './components/create-case/missing-items-form/missing-items-form.component';
import { ShortDatedProductComponent } from './components/create-case/short-dated-product/short-dated-product.component';
import { ExpiredProductComponent } from './components/create-case/expired-product/expired-product.component';
import { OrderSearchComponentComponent } from './components/create-case/order-search-component/order-search-component.component';
import { AppItemsSelectionModalComponent } from './components/common/app-items-selection-modal/app-items-selection-modal.component';
import { ItemsFilterPipe } from 'src/pipes/itemsModal.filter.pipe';
import { InvoiceSearchComponentComponent } from './components/create-case/invoice-search-component/invoice-search-component.component';
import { ReturnFormComponent } from './components/create-case/return-form/return-form.component';
import { DragDropFileDirective } from './directives/drag-drop-file.directive';
import { WindowRef } from './services/windowRef';
import { BsDatepickerModule, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { TooltipComponent } from './components/common/market-tooltip/tooltip/tooltip.component';
import { TooltipDirective } from './components/common/market-tooltip/tooltip.directive';

@NgModule({
  declarations: [
    HelpHomeComponent,
    CreateCaseComponent,
    SearchHelpTicketsComponent,
    CustomMultiselectDropdownComponent,
    DateRangeComponent,
    CommentBoxComponent,
    SendEmailCopyComponent,
    OrderInqueryFormComponent,
    ProductQuestionsFormComponent,
    ShippingDiscrepancyFormComponent,
    InvoiceIssuesFormComponent,
    AttachmentComponent,
    ModalComponent,
    SubmitCaseDiscardComponent,
    MarketHelpDatePickerComponent,
    NumericOnlyDirective,
    CaseDetailComponent,
    AlphabetOnlyDirective,
    DecimalsDirective,
    CaseAcknowledgementComponent,
    OrderDetailsCaseComponent,
    SelectRecipientComponent,
    FilterPipe,
    ItemsFilterPipe,
    OrderCaseAcknowledgementComponent,
    MissingItemsFormComponent,
    ShortDatedProductComponent,
    ExpiredProductComponent,
    OrderSearchComponentComponent,
    AppItemsSelectionModalComponent,
    InvoiceSearchComponentComponent,
    ReturnFormComponent,
    DragDropFileDirective,
    TooltipComponent,
    TooltipDirective,
  ],

  imports: [
    CommonModule,
    NgSelectModule,
    RouterModule,
    FormsModule,
    BsDatepickerModule.forRoot(),
    ReactiveFormsModule,
  ],
  exports: [
    HelpHomeComponent,
    CreateCaseComponent,
    SearchHelpTicketsComponent,
    CaseDetailComponent,
    CaseAcknowledgementComponent,
    OrderCaseAcknowledgementComponent
  ],
  providers: [
    WindowRef, BsDatepickerConfig
  ],
  schemas: [NO_ERRORS_SCHEMA]

})
export class CspModule { }
