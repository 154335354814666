<div class="commentSection" [formGroup]="caseCreateForm">
  <p class="commentTitle"><span class="red-text">*</span>Comments</p>
  <div class="textArea">
     <textarea placeholder="Begin typing your comments here..." class="form-control" id="comments"
     formControlName="comments"
     [ngClass]="{'has-error':  caseCreateForm.value.submitted && caseCreateForm.controls.comments.errors?.required}"
     ></textarea>
     <span *ngIf="caseCreateForm.value.submitted && caseCreateForm.controls.comments.errors?.required" style="color: red; font-size:15px;">Comments are required</span> 
  </div> 
  <!-- submit{{caseCreateForm.value.submitted}}
  required{{caseCreateForm.controls.comments.errors.required}} -->
  </div>  