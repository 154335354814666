<section id="createCaseSection">
  <div class="spinner-main" [ngClass]="{'spinner-show': cspShowSpinner}">
    <div class="spinnerDiv">
    </div>
  </div>
  <nav class="breadcrumb">
    <div *ngIf="!isCreateCaseFromOrderDetails" class="nav-left hidden-xs">
      <a (click)="backClick()" data-id="previous-page-link" class="cta-link primary previous_link">
        <span class="previous-arrow"></span>
        Previous Page
      </a>
    </div>
    <span *ngIf="!isCreateCaseFromOrderDetails" class="separator hidden-xs dib">|</span>
    <h2 data-id="caseDetailHeading" class="h2">Submit a Case</h2>
  </nav>


  <div class="row">
    <div class="caseDetailInfo">
      <p>
        <span class="semibold">Full Name:</span><span>{{ user.lastName }}, {{ user.firstName }}</span>
      </p>
      <p>
        <span class="semibold">Phone #:</span><span>{{ user.phoneNumber }}</span>
      </p>
      <p>
        <span class="semibold">Email:</span><span>{{ user.email }}</span>
      </p>
      <p>
        <span class="semibold">Sold To:</span><span>{{ user.soldToAccountNumber }} - {{ user.soldToName }}</span>
      </p>
      <p *ngIf="isCreateCaseFromOrderDetails">
        <span class="semibold">Ship To:</span><span>{{ lineItems.shipTo.id }} - {{ lineItems.shipTo.name }}</span>
      </p>
      <p *ngIf="isCreateCaseFromOrderDetails">
        <span class="semibold">PO #:</span><span>{{lineItems.poNumber}}</span>
      </p>
    </div>
  </div>

  <div class="row">
    <h2 class="pageSubHeading">Case Creation Form</h2>
    <div class="divider_2px"></div>
  </div>

  <div id="error-create-case" data-id="errorContainer" *ngIf="errorCreateCase" class="error-create-case">
    <p>
      <span data-id="errorIcon" class="icon-error-medium"></span><span data-id="errorMsg"
        class="error-text">{{errMessage}}</span>
    </p>
  </div>

  <div class="row caseCreateFormContainer">

    <!-- Following component to be rendered if create case form comes from order Details page. -->
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 noPadding" *ngIf="isCreateCaseFromOrderDetails">
      <order-details-case [products]="lineItems" (fromFormB)="fromFormB($event)"
        (updateModalStatus)="updateShowModalStatus($event)" (formBAttachments)="formBAttachments($event)">
      </order-details-case>
    </div>

    <!-- Following component to be rendered if create case form is a fresh form. -->
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 noPadding" *ngIf="!isCreateCaseFromOrderDetails">
       <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 selection-left noPadding">

        <div class="dropdownFieldsMH" *ngIf="issueType">
          <label class="formLabel"><span class="red-text">*</span>Issue Type</label>
          <ng-select class="custom" [items]="issueType" (change)="loadRequestType($event)" placeholder="Select one"
            bindLabel="text" [clearable]="false" [searchable]="false" [(ngModel)]="selectedIssueType"
            [ngClass]="{'has-error': caseCreateForm.value.submitted && !selectedIssueType}">
          </ng-select>
          <!-- {{caseCreateForm.value.submitted}}
          {{selectedIssueType | json}} -->
          <span *ngIf="caseCreateForm.value.submitted && !selectedIssueType" style="color: red; font-size:15px;">Issue type is required</span> 
        </div><br>

        
        <div class="dropdownFieldsMH" *ngIf="requestType.length">
          <label class="formLabel"><span class="red-text">*</span>Request Type</label>
          <ng-select class="custom" [items]="requestType" (change)="loadPONumberComponent($event)"
            placeholder="Select one" bindLabel="text" [clearable]="false" [searchable]="false"
            [(ngModel)]="selectedRequestType"
            [ngClass]="{'has-error':  caseCreateForm.value.submitted && !selectedRequestType}"></ng-select>
            <span *ngIf="caseCreateForm.value.submitted && !selectedRequestType" style="color: red; font-size:15px;">Request type is required</span>
        </div>
        <br>

        <div *ngIf="isReturnForm && isNoLongerNeededForm ||isPurchasedTooManyForm || isIncorrectProductOrderedForm">
          <span style="color: #e41f35;"> &bull; Product returns may be subject to 
            <a [tooltip] = '"Return policy:
                      &bull; All return items not locally stocked will be subject to locally determined supplier restocking fee.&#13;
                      &bull; All returns not due to Medical Distribution ordering or delivery error will be subject to a minimum of 15% restocking fee.&#13;
                      &bull; If an unauthorized return is accepted, there will be a minimum of 25% restocking fee."' [position]='"below"'>restocking fee.</a>
          </span>
        </div>
        <div *ngIf="isPOSearchVisible">
          <order-search-component [shipToData]="shipToData" [showError]="caseCreateForm.value.submitted && !selectedOrderDetails" (setPONumber)="setPONumber($event)" (onSearchIconClick)="resetFormOnClickOfSearch()">
          </order-search-component> 
          <span *ngIf="caseCreateForm.value.submitted && !selectedOrderDetails" style="color: red; font-size:15px;">Please enter a value</span>
          
        </div>
        <div *ngIf="isInvoiceSearchVisible">
          <invoice-search-component (setInvoiceNumber)="setInvoiceNumber($event)" [showError]="caseCreateForm.value.submitted && !caseCreateForm.value.invoiceId" (onSearchIconClick)="resetFormOnClickOfSearch()"
          [pricingDiscrepancy]="isPricingDiscrepancy" >
          </invoice-search-component>
          <span *ngIf="caseCreateForm.value.submitted && !caseCreateForm.value.invoiceId" style="color: red; font-size:15px;">Please enter a value</span>
                    
        </div>
       
        <div *ngIf="showproductQuestionCheckBox">
          <input type="checkbox" [(ngModel)]="productRelatedQuestion.selected"  (change)="toggleProdQuestionCheckbox()"/>
          <span> {{productRelatedQuestion.message}}</span>
        </div>
        <div class="dropdownFieldsMH" *ngIf="isShiptoVisible && shipToData.length">
          <label class="formLabel"><span class="red-text">*</span>Ship To</label>
          <ng-select class="custom" [items]="shipToData" (change)="loadFormOnShipToSelect($event)"
          placeholder="Select a Ship To" bindLabel="text" [clearable]="false" [searchable]="true"
          [(ngModel)]="selectedShipTo"
          [ngClass]="{'has-error': caseCreateForm.value.submitted && !selectedShipTo}"></ng-select>
        
          
          <span *ngIf="caseCreateForm.value.submitted && !selectedShipTo" style="color: red; font-size:15px;">ShipTo is required</span> 
        </div>
        <br>
        
        <div *ngIf="(isIncorrectDeliveryLocation || isReturnIncorrectDeliveryLocation) && !isPOSearchVisible">
          <div class="dropdownFieldsMH">
            <label class="formLabel">PO # or Cardinal Health Order #</label>
            <input *ngIf="!(incorrectMaterial && sometingMissing)" type="text" maxlength="35"
              placeholder="Enter PO # or Order #..." class="form-control po-input"
               [(ngModel)]="poNumber" />
               <!-- [ngClass]="{'has-error': caseCreateForm.value.submitted && !poNumber}"
               />
               <span *ngIf="caseCreateForm.value.submitted && !poNumber" style="color: red; font-size:15px;">PO/Order number is required</span> -->
          </div>
        </div>



        <div class="dropdownFieldsMH" *ngIf="missingFromOrder.length">
          <label class="formLabel">
            <span class="red-text">*</span>Was something missing from your order?
          </label>
          <ng-select class="custom" [items]="missingFromOrder" (change)="changeActionRequest($event)"
            placeholder="Select one" bindLabel="text" [clearable]="false" [searchable]="false"
            [(ngModel)]="selectedMissingFromOrder"
            [ngClass] ="{'has-error':  caseCreateForm.value.submitted && !selectedMissingFromOrder}">
          </ng-select>
          <span *ngIf="caseCreateForm.value.submitted && !selectedMissingFromOrder" style="color: red; font-size:15px;">Field is required</span>
        </div><br>
        
      </div>

      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 selection-right noPadding">
        <form [formGroup]="caseCreateForm">
          <app-comment-box [caseCreateForm]="caseCreateForm"></app-comment-box>
          <div>
            <span *ngIf="isIncorrectDeliveryLocation || isReturnIncorrectDeliveryLocation" class="red-text">* </span>
            <div class="cta-btn secondary black_button" (click)="showAttachmentModal()">Include Attachment(s)</div>
            <div>
              <span class="red-text" *ngIf="isIncorrectDeliveryLocation || isReturnIncorrectDeliveryLocation">
                NOTE: Please provide a picture of shipping label or box.
              </span>
            </div>
          </div>

          <app-modal id="orderResolutionCancelModal" [showModal]="isAttachmentModalVisible"
            [customBody]="addAttachments" (onCloseModal)="hideAttachmentModal()">
            <ng-template #addAttachments>
              <csp-attachment id="addAtttachmentSection" [fromPage]='"Form A."' (onCloseModal)="hideAttachmentModal()"
                (attachFiles)="attachUploadedFiles($event)"></csp-attachment>
            </ng-template>
          </app-modal>
          <div class="attachments-list" *ngIf="attachments">
            <div *ngFor="let attachment of attachments; let index = index" class="clearfix">
              <span class="icon icon-file-small" [ngClass]="{ 'upload-error-icon': !attachment.ecmDocId }"></span>
              <span class="attachment-name"
                [ngClass]="{ 'upload-error-attachment': !attachment.ecmDocId }">{{ attachment.name }}</span>
              <span class="icon icon-close-small" *ngIf="attachment.ecmDocId" (click)="removeAttachments(index)"></span>
              <span *ngIf="!attachment.ecmDocId" class="upload-error-msg">File did not upload successfully.</span>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div class="custom-control custom-checkbox check">
                <input type="checkbox" [checked]="isChecked" class="custom-control-input" id="sendEmailCopies"
                  (change)="onSelectRecipientClick()">
                <label class="custom-control-label" for="sendEmailCopies">Select to send copies of this case to other
                  recipients (5 max)</label>
              </div>
              <div style="cursor: pointer;">
                <app-modal id="addRecipientModal" [showModal]="isRecipientModalVisible" [customBody]="addRecipient"
                  (onCloseModal)="hideRecipientModal()">
                  <ng-template #addRecipient>
                    <app-select-recipient id="addRecipientSection" (getRecipients)="getRecipientValue($event)"
                      (onCloseModal)="hideRecipientModal()">
                    </app-select-recipient>
                  </ng-template>
                </app-modal>
                <div *ngIf="selectedContactsList.length > 0">
                  <div *ngFor="let recipient of selectedContactsList; let i = index">
                    <div *ngIf="recipient.firstName!=='' && recipient.lastName !=='' " class="pl-4 my-1 recipient">
                      {{ recipient.firstName }} {{ recipient.lastName }}</div>
                    <div *ngIf="recipient.firstName==='' ||  recipient.lastName==='' " class="pl-4 my-1 recipient">
                      {{ recipient.email }}</div>

                  </div>
                </div>
              </div>
            </div>
            <div>
            </div>
          </div>
        </form>
      </div>
      
      <div class="row">
        <form id="caseCreateForm" [formGroup]="caseCreateForm">
          <div class="selectDropdownDiv">
          <input type="hidden" formControlName="shipTo" />
          <input type="hidden" formControlName="selectedIssueType" />
          <input type="hidden" formControlName="selectedRequestType" />
          <!-- order inquery form-->
          <div *ngIf="isOrderInquiryForm">

            <div formArrayName="items" *ngFor="
                 let orderInquiry of getFormAsArray.controls;
                 let i = index
               ">
              <app-order-inquery-form 
                            [caseCreateForm]="caseCreateForm" 
                            [formIndex]="i"
                            (deleteFormItem)="deleteFormItem($event)"
                            >
            </app-order-inquery-form>
            </div>              
          </div>         
          
          <!--product questions form-->
          <div *ngIf="isProductQuestionForm">
            <div *ngFor="
                 let productQuestions of getFormAsArray.controls;
                 let i = index
               ">
              <app-product-questions-form [caseCreateForm]="caseCreateForm"
                 [productComplaints]="isProductComplaints"  (deleteFormItem)="deleteFormItem($event)"
                [formIndex]="i" [actionItems]="productComplaintsActionitems" [isProdQuestWithoutOrdDetails] = "productRelatedQuestion.selected"
                ></app-product-questions-form>
            </div>
          </div>
          <span *ngIf="caseCreateForm.value.submitted && selectedOrderDetails && !getFormAsArray?.controls.length" style="color: red; font-size:15px;">Please select and add product</span>
          <span *ngIf="caseCreateForm.value.submitted && isPricingDiscrepancy && caseCreateForm.value.invoiceId && !getFormAsArray?.controls.length" style="color: red; font-size:15px;">Please select and add product</span>
          
          <!--shipping discrepancy form-->
          <div *ngIf="isShippingDiscrepancyForm">
            <div *ngIf="isIncorrectMaterialDelivered && wasSometingMissing" class="row">
              <div formArrayName="missingItems" *ngFor="let shippingDiscrepancy of getMissingItemsFormAsArray.controls;
                  let j=index">
                <app-missing-items-form [caseCreateForm]="caseCreateForm" [formIndex]="j"
                  [incorrectMaterial]="isIncorrectMaterialDelivered" [sometingMissing]="wasSometingMissing"
                  [isZeroAllowed]="false" [actionItems]="missingItemsFormActionitems"
                  (deleteFormItem)="deleteFormItem($event)"
                 >
                </app-missing-items-form>
              </div>
            </div>
            <!-- <span *ngIf="caseCreateForm.value.submitted && isIncorrectMaterialDelivered && selectedOrderDetails && !wasSometingMissing" style="color: red; font-size:15px;">Please select and add product</span> -->
            <div formArrayName="items" *ngFor="
                 let shippingDiscrepancy of getFormAsArray.controls;
                 let i = index
               ">
              <app-shipping-discrepancy-form [caseCreateForm]="caseCreateForm" [formIndex]="i"
                [incorrectMaterial]="isIncorrectMaterialDelivered"
                [incorrectDeliveryLocation]="isIncorrectDeliveryLocation" [quantityDamaged]="isQuantityDamaged"
                [sometingMissing]="wasSometingMissing" [isZeroAllowed]="isShippingDiscreapancyNumericZero"
                [actionItems]="shippingDiscreapancyActionitems"
                (deleteFormItem)="deleteFormItem($event)"></app-shipping-discrepancy-form>
            </div>
          </div>
          
          <!-- shortdated product form -->
          <div *ngIf="isShortDatedProductForm">
            <div formArrayName="items" *ngFor="let shortDatedProduct of getFormAsArray.controls; let i = index">
              <short-dated-product [caseCreateForm]="caseCreateForm" [formIndex]="i"
                [actionItems]="shippingDiscreapancyActionitems" (deleteFormItem)="deleteFormItem($event)"></short-dated-product>
            </div>
          </div>
          <!-- Expired product form -->
          <div *ngIf="isExpiredProductForm">
            <div formArrayName="items" *ngFor="let expiredProduct of getFormAsArray.controls; let i = index">
              <expired-product [caseCreateForm]="caseCreateForm" [actionItems]="expiredProductActionitems" [formIndex]="i"
              (deleteFormItem)="deleteFormItem($event)"></expired-product>
            </div>
          </div>
          <!--invoice issues form-->
          <div *ngIf="isInvoiceIssueForm">
            <div *ngIf="isPricingDiscrepancy">
              <div formArrayName="items" *ngFor="
                   let shippingDiscrepancy of getFormAsArray.controls;
                   let i = index
                 ">
                <app-invoice-issues-form [caseCreateForm]="caseCreateForm" [pricingDiscrepancy]="isPricingDiscrepancy"
                  [incorrectFee]="isIncorrectFees" [allowDecimal]="allowDecimal" [formIndex]="i"
                  (deleteFormItem)="deleteFormItem($event)" >
                </app-invoice-issues-form>
              </div>
            </div>
            
            <div *ngIf="!isPricingDiscrepancy">
              <app-invoice-issues-form [caseCreateForm]="caseCreateForm" [pricingDiscrepancy]="isPricingDiscrepancy"
                [incorrectFees]="isIncorrectFees" [incorrectPO]="isIncorrectPO" [shipToData] = "shipToData"
                [incorrectPartner]="isIncorrectPartner" [allowDecimal]="allowDecimal"></app-invoice-issues-form>
            </div>
          </div>    
          <!-- Return Forms Start-->
          <div *ngIf="isReturnForm">
            <div *ngIf="isReturnIncorrectMaterialDelivered && wasSometingMissing">
              <div formArrayName="missingItems" *ngFor="let shippingDiscrepancy of getMissingItemsFormAsArray.controls;
                                  let j=index">
                <app-missing-items-form [caseCreateForm]="caseCreateForm" [formIndex]="j"
                  [incorrectMaterial]="isReturnIncorrectMaterialDelivered" [sometingMissing]="wasSometingMissing"
                  [isZeroAllowed]="false" [actionItems]="missingItemsFormActionitems" (deleteFormItem)="deleteFormItem($event)">
                </app-missing-items-form>
              </div>
              </div>
            <div formArrayName="items" *ngFor="let retrunFormItem of getFormAsArray.controls; let i = index">
              
              <return-form *ngIf="!isReturnShippingDiscForm && !isReturnShortDatedProductForm && !isReturnExpiredProductForm" [caseCreateForm]="caseCreateForm" [formIndex]="i"
              [isNoLongerNeededForm] = "isNoLongerNeededForm" [isPurchasedTooManyForm] = "isPurchasedTooManyForm"
              [isProductRecallForm] = "isProductRecallForm" [isIncorrectProductOrderedForm] = "isIncorrectProductOrderedForm"
              (deleteFormItem)="deleteFormItem($event)"></return-form>
              
              <app-shipping-discrepancy-form *ngIf="isReturnShippingDiscForm" [caseCreateForm]="caseCreateForm" [formIndex]="i"
              [incorrectDeliveryLocation]="isReturnIncorrectDeliveryLocation" [quantityDamaged]="isQuantityDamaged" [returnShippingOveragesForm]="isReturnShippingOveragesForm"
              [actionItems]="shippingDiscreapancyActionitems"
              [sometingMissing]="wasSometingMissing" [incorrectMaterial]="isReturnIncorrectMaterialDelivered"
              [isZeroAllowed]="isShippingDiscreapancyNumericZero" (deleteFormItem)="deleteFormItem($event)"></app-shipping-discrepancy-form>
              
             
              <short-dated-product *ngIf="isReturnShortDatedProductForm" [caseCreateForm]="caseCreateForm" [formIndex]="i"
                    [actionItems]="shippingDiscreapancyActionitems" (deleteFormItem)="deleteFormItem($event)"
                     ></short-dated-product> 
              <expired-product *ngIf="isReturnExpiredProductForm" [caseCreateForm]="caseCreateForm" [actionItems]="expiredProductActionitems" [formIndex]="i"
              (deleteFormItem)="deleteFormItem($event)"></expired-product>
            </div>
           
          </div>
          
        </div>

        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 add-more-link noPadding">
          
          <div *ngIf="!(isIncorrectMaterialDelivered || isReturnIncorrectMaterialDelivered)" style="float: left;">
            <p>
              <span *ngIf="canAddForms" class="plus_icon">
                <span class="expand_icon"></span>
                <a class="add_product_link" (click)="addForm()">Add another Product</a>
              </span>
            </p>
          </div>
          <div *ngIf="isIncorrectMaterialDelivered || isReturnIncorrectMaterialDelivered" style="float: left;">

            <p>
              <span *ngIf="canAddForms && wasSometingMissing"
                class="plus_icon">
                <span class="expand_icon"></span>
                <a class="add_product_link" (click)="addForm()">Add another Missing Item</a>
              </span>
              
            </p>
            <p>
              <span *ngIf="canAddForms" class="plus_icon">
                <span class="expand_icon"></span>
                <a class="add_product_link" (click)="addIncorrectMaterialForm()">Add another Incorrect Material</a>
              </span>
             
            </p>
          </div>
        </div>
        <div *ngIf="showRestockingAcknowledge">
          <input id="acknowledgeCheckBox" type="checkbox" formControlName="restockingFeeAcknowledge" (click)="checkboxValue($event)"
          /><span [style.color]="highlightedText"> * I acknowledge that product returns may be subject to 
            <a [tooltip] = '"Return policy:
            &bull; All return items not locally stocked will be subject to locally determined supplier restocking fee.&#13;
            &bull; All returns not due to Medical Distribution ordering or delivery error will be subject to a minimum of 15% restocking fee.&#13;
            &bull; If an unauthorized return is accepted, there will be a minimum of 25% restocking fee."'
            >restocking fee.</a>
          </span>
        </div>
      </form>

        <app-modal id="showLineItemsModal" [showModal]="showSelectMaterialModal" [customBody]="showLineItemsModal"
          [modalTital]="showModalTitle" [defaultWidth]="false" (onCloseModal)="hideSelectMaterialModal()">
          <ng-template #showModalTitle>
            <div *ngIf="(isIncorrectMaterialDelivered || isReturnIncorrectMaterialDelivered) && wasSometingMissing" class="titleDesc col-md-6 col-lg-6 col-sm-6 col-xs-6">
              <span> Please select the item(s) missing from your order. </span>
            </div>
            <div class="col-md-6 col-lg-6 col-sm-6 col-xs-6">
              <span class="modalTitle">{{materialList.length}} Items Found</span>
            </div>
          </ng-template>
          <ng-template #showLineItemsModal>
            <app-items-selection-modal *ngIf="showSelectMaterialModal" [tableCols]="selectMaterialColums" [tableData]="materialList"
              [tableColsLabelInput]="selectMaterialColumsLabel" [multiValueSelect]="true" [addItemButtonName]="addItemButtonName"
              (getSelectedItems)="getSelectedLineItem($event)">
            </app-items-selection-modal>
            
          </ng-template>
        </app-modal>
      
      </div>
    </div>

    <div *ngIf="!isCreateCaseFromOrderDetails" class="col-md-12 col-lg-12 col-sm-12 col-xs-12 add-more-link noPadding">
      <div class="buttonSection">
        <a (click)="onCancel($event)" data-id="createCaseCancel" class="cta-link primary create-case-cancel">
          Cancel
        </a>
        <span class="vertical-devider"></span>
        <button type="submit" class="cta-btn primary red_button" (click)="submitForm()">
          <!-- [disabled]="submitButtonValidation() || caseCreateForm.invalid" (click)="submitForm()"> -->
          Submit Case
        </button>
      </div>
  </div>
    <app-modal id="submitCaseDiscard" [showModal]="isSubmitCaseModalVisible" [customBody]="submitCaseDiscard"
      (onCloseModal)="hideDiscardModal()">
      <ng-template #submitCaseDiscard>
        <app-submit-case-discard id="submitCaseDiscardModal" (onCloseModal)="hideDiscardModal()"
          (discardChange)="discardChanges()" [name]="name" [header]="heading" [message]="message">
        </app-submit-case-discard>
      </ng-template>
    </app-modal>
  </div>
</section>