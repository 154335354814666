import { HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { _throw as observableThrowError } from 'rxjs/observable/throw'


@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  hostname: any;
  baseApiUrl: any;
  constructor(private http: HttpClient) {
    this.hostname = window && window.location && window.location.hostname;
    this.baseApiUrl = environment.baseApiUrl;
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem("app-token");
    if (request.headers.get("skip")) {
      return next.handle(request);
    }
    else {
      var modifiedRequest = request.clone({
        setHeaders: {
          "Authorization": `${token}`,
          "x-api-key": environment.xapikey,
          "Access-Control-Allow-Origin": "*",
          "x-http-method": "GET"
        },
      });


      return next.handle(modifiedRequest)
        .pipe(
          catchError((error: HttpErrorResponse) => {
            if (error.status == 401) {
              return this.handle401Error(request, next);
            } else {
              return throwError(error);
            }
          })
        )
    }
  }

  refreshToken() {
    return this.http.get(this.baseApiUrl + `/medical-market-tokengenerator/v2/generator/token`, this.getGeneratedTokenHeader())

  }
  getNewRequest(req: HttpRequest<any>, res): HttpRequest<any> {
    let accessToken = res["access-token"];
    localStorage.setItem("app-token", accessToken);

    return req.clone({
      setHeaders: {
        "Authorization": `${accessToken}`,
        "x-api-key": environment.xapikey,
        "Access-Control-Allow-Origin": "*",
        "x-http-method": "GET"
      },
    });
  }

  handle401Error(req: HttpRequest<any>, next: HttpHandler) {

    return this.refreshToken().pipe(
      switchMap((res) => {
        if (!req.headers.get("skip")) {
          return next.handle(this.getNewRequest(req, res));
        }

      }), catchError(error => {
        
        if (!this.hostname.includes('localhost')) {
          let localUrl = environment.baseRoutingUrl + '/help';
          window.location.href = environment.baseRoutingUrl + '/login' + '?RelayState=' + encodeURIComponent(localUrl);
        }
       
        return observableThrowError(error);
      })
    )
  }
  getGeneratedTokenHeader() {
    const appToken = localStorage.getItem("app-token");
    const idToken = localStorage.getItem("idtoken");
    const sessionId = localStorage.getItem("sessionid");
    return {
      headers: new HttpHeaders()
        .set('XAuthorization', appToken)
        .set("x-api-key", environment.xapikey)
        .set("idtoken", idToken)
        .set("sessionid", sessionId)
        .set("skip", "true")
    }
  }
}
