import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HelpHomeComponent } from './csp/components/help-home/help.component'
import { CreateCaseComponent } from './csp/components/create-case/create-case.component';
import { SearchHelpTicketsComponent } from './csp/components/search-help-tickets/search-help-tickets.component';
import { CaseDetailComponent } from './csp/components/case-detail/case-detail.component';
import { CaseAcknowledgementComponent } from './csp/components/create-case/case-acknowledgement/case-acknowledgement.component';
import { OrderCaseAcknowledgementComponent } from './csp/components/create-case/order-case-acknowledgement/order-case-acknowledgement.component';
const routes: Routes = [
  { path: '', component: HelpHomeComponent },
  { path: 'help', component: HelpHomeComponent },
  { path: 'case/create', component: CreateCaseComponent },
  { path: 'case/search', component: SearchHelpTicketsComponent },
  { path: 'case/details/:id', component: CaseDetailComponent },
  { path: 'case/case-acknowledgement/:id', component: CaseAcknowledgementComponent },
  { path: 'case/order-case-acknowledgement', component: OrderCaseAcknowledgementComponent },
  { path: 'office', component: CaseDetailComponent },
  { path: '**', redirectTo: "/help", pathMatch: "full" }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
