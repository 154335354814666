<div id="submitCaseFormModal">
  <h4 data-id="createCaseTitle" class="modal-title">{{header}}</h4>
  <div class="submitCaseBody">
    <p data-id="createCaseMessage1" *ngIf="isCancel">{{message}}</p>
    <p data-id="createCaseMessage2" *ngIf="!isCancel">{{message}}</p>
  </div>

  <div class="submitCasefooter">
    <a  (click)="discardChanges()" data-id="createCaseDiscard" class="cta-link primary discard-link">
      Discard Changes</a>
      <span class="vertical-devider"></span>
      <button data-id="createCaseContinueBtn" class="cta-btn primary submit-continue-btn" (click)="hideModal()">{{name}}</button>
  </div>
</div>