<!-- ########################## CASE SUBMITTED ########################## -->
<ng-container *ngIf="ItemIdList">
  <div class="create-case-success-wrapper">
    <div class="navigation-link-container">
      <ul>
        <li><a data-id="MarketHomeLink" [href]="[baseRoutingUrl]" class="cta-link primary">Market Home</a></li>
        <li><a data-id="CaseSearchLink" routerLink="/case/search" class="cta-link primary">Case Search</a></li>
        <li><a data-id="HelpLink" routerLink="/help" class="cta-link primary">Help</a></li>
      </ul>
    </div>
    <div class="success-msg">
      <span class="confirmation-icon"></span>
      <span class="text-message highlight">

        We have successfully submitted your Case(s).


      </span>
      <div class="itemList" *ngFor="let i of ItemIdList">
        <a class="case" data-id="caseID" [routerLink]="['/case/details/',i.caseId]">{{i.caseId}}</a>
        <hr>
        <div class="reqType">
          Request Type : <span class="reqDesc"> {{i.requestTypeDesc}}</span>
        </div>
        <div class="row lineItems" *ngFor="let line of i.lineItemDetails">
          <div class="col-xs-12 col-sm-2 col-md-2">
          <p class="itemheader"> Item Line#  :</p> <p>{{line.lineItemId}}</p>
          </div>
          <div class="col-xs-12 col-sm-4 col-md-4 offset-md-1">
          <p class="itemheader"> Item Description :</p> <p  [innerHTML]='line.lineItemDesc'>{{line.lineItemDesc}}</p>
          </div>
        </div>
        
      </div>
    </div>

  </div>
</ng-container>