import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { User } from '../shared/User';

@Injectable({
  providedIn: 'root'
})
export class OrderSearchService {
 
  private baseApiUrl: string;
  constructor(private http: HttpClient) {
    this.baseApiUrl = environment.baseApiUrl;
  }
  public searchOrder(orderNumber: string): Observable<any> {
    const soldToId = User.getUser().soldToAccountNumber;
		const shipToId = User.getUser().shipToAccountNumber;
		return this.http.get(this.baseApiUrl + `/medical-market-orders/soldtos/${soldToId}/shiptos/${shipToId}/v2/orders/${orderNumber}`)
	}
  public searchPONumber(poNumberSearchString: string): Observable<any> {
    
    let todayDate : Date = new Date();
    let backDate :Date = new Date();
    backDate.setMonth(todayDate.getMonth() - 12);
    const datepipe: DatePipe = new DatePipe('en-US');
    let endDate = datepipe.transform(todayDate, 'YYYY-MM-dd');
    let startDate = datepipe.transform(backDate, 'YYYY-MM-dd');
    const soldToId = User.getUser().soldToAccountNumber;
    const searchData = {purchaseOrderNumber:poNumberSearchString,endDate:endDate,startDate:startDate,soldToId:soldToId,includeValueLinkOnlyOrders:false};
    return this.http.post(this.baseApiUrl + `/medical-market-orders/soldtos/${soldToId}/v1/orders?noblock=true`,searchData);
  }
  
  public searchInvoice(invoiceNumber: string): Observable<any>  {
    const soldToId = User.getUser().soldToAccountNumber;
		const shipToId = User.getUser().shipToAccountNumber;
		return this.http.get(this.baseApiUrl +`/medical-market-invoices/soldtos/${soldToId}/shiptos/${shipToId}/v2/invoices/${invoiceNumber}`)

  }
}
