import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[numericOnly]'
})
export class NumericOnlyDirective {

  @Input() zeroNotAllowed:boolean=false;
  @Input() decimalAllowed:boolean=false;

  constructor(private _el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event) {
    const initalValue = this._el.nativeElement.value;
    if(this.zeroNotAllowed) {
      this._el.nativeElement.value = initalValue.replace(/^[^1-9]*|[^0-9]/g, '');
    }
    else if(this.decimalAllowed) {
      this._el.nativeElement.value = initalValue.replace(/[^1-9\.]*/g, '');
    }
    else {
      this._el.nativeElement.value = initalValue.replace(/[^0-9]*/g, '');
    }

    if ( initalValue !== this._el.nativeElement.value) {
      event.stopPropagation();
    }
  }
}
