import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnInit {

  position: string = 'right';
  theme: string = 'light';
  tooltip = '';
  left = 0;
  top = 0;
  visible = false;


  ngOnInit(): void {
    // intentional
  }

}


