export class CasesConstants {
   
	
	public static readonly CUSTOM_ERROR_MSG = 'An error occurred while updating your request. Please contact Customer Service for more assistance.';
	public static readonly CASE_LOCKED_MSG = 'Customer service is currently working on this Case.  Please wait for a few minutes and try again.';
	public static readonly CASE_LOCKED_KEY = 'is being processed by user';
    public static readonly CASE_LOCKED_MSG_LINE_1= "We are in the process of updating this Help Case and cannot save your update at this time.";
    public static readonly CASE_LOCKED_MSG_LINE_2= "Please wait a while and try again.";
    
    public static readonly CHANNELS = [
        {
            'id': '401',
            'value': 'E-Mail'
        },
        {
            'id': 'FAX',
            'value': 'Fax'
        },
        {
            'id': 'Z01',
            'value': 'Phone'
        },
        {
            'id': 'Z10',
            'value': 'Web'
        }

    ]
}