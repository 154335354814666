<form [formGroup]="caseCreateForm">
  <div formArrayName="items" *ngIf="pricingDiscrepancy">
    <div [formGroupName]="formIndex">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 invoice-issues-form noPadding" *ngIf="pricingDiscrepancy">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 noPadding">
          <p>
            <span *ngIf="getFormArray().length > 1" class="icon icon-close-small" (click)="deleteForm()"></span>
            Product #{{ formIndex + 1 }} -
            <input type="hidden" formControlName="itemDescription">
            <span [innerHTML]="itemDescription"> {{ itemDescription }}</span>
          </p>
        </div>
        <div class="row">
        <div class="col-xs-12 col-sm-4 col-md-3 col-lg-3 item_Div noPadding">
          <div class="inputFields">
            <label class="formLabel">Item #</label>
            <input type="text" maxlength="18" readonly placeholder="Item #" class="form-control item_input"
              formControlName="itemId" />
          </div>
        </div>
        <div class="col-xs-12 col-sm-2 col-md-3 col-lg-2 qty_Div noPadding">
          <div class="inputFields">
            <label class="formLabel">QTY Ordered</label>
            <input type="text" maxlength="15" readonly placeholder="QTY" class="form-control qty_input"
              formControlName="quantityOrdered" numericOnly [zeroNotAllowed]="true" />
          </div>
        </div>
        <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2 uom_Div noPadding">
          <div class="inputFields">
            <label class="formLabel">UOM</label>
            <input type="hidden" maxlength="3" readonly placeholder="UOM" class="form-control uom_input" formControlName="uom"
              alphabetOnly />
              <input type="text" readonly  class="form-control uom_input" formControlName="uomprice" />
          </div>
        </div>
        <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 cust_expected_price noPadding">
          <div class="inputFields">
            <label class="formLabel"><span class="red-text">*</span>Customer Expected Price</label>
            <input type="text" maxlength="7" placeholder="Price" class="form-control received_qty_input"
              formControlName="customerExpectedPrice" appDecimals  
              [ngClass]="{'has-error':  caseCreateForm.value.submitted && !caseCreateForm.controls.customerExpectedPrice?.value}"
              />
              <span *ngIf="caseCreateForm.value.submitted && !caseCreateForm.controls.customerExpectedPrice?.value" style="color: red; font-size:15px;">Customer Expected Price is required</span>
            
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="incorrectPO">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 invoice-icorrectPO noPadding">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 noPadding">
        <p> Your Selected Invoice Information</p>
      </div>
      <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 item_Div noPadding">
        <div class="inputFields">
          <label class="formLabel"> Original PO #</label>
          <input type="text" maxlength="35" readonly placeholder="Original PO #" class="form-control material_input"
            formControlName="originalPurchaseOrderNumber"  />
        </div>
      </div>
      <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 qty_Div">
        <div class="inputFields">
          <label class="formLabel">Original Sold To #</label>
          <input type="text" maxlength="10" readonly placeholder="Original Sold To #" class="form-control qty_input"
            formControlName="originalAccountNumber" />
        </div>
      </div>
      <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 uom_Div">
        <div class="inputFields">
          <label class="formLabel">Original Ship To #</label>
          <input type="text" maxlength="10" readonly placeholder="Original Ship To #" class="form-control uom_input"
            formControlName="originalSolToShipTo" />
        </div>
      </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 invoice-icorrectPO noPadding">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 noPadding">
        <p>Enter Your Updated Invoice Information</p>
      </div>
      <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 item_Div noPadding">
        <div class="inputFields">
          <label class="formLabel"><span class="red-text">*</span>Requested PO #</label>
          <input type="text" maxlength="35" placeholder="Requested PO #" class="form-control material_input"
            formControlName="revisedPurchaseOrderNumber"  
            [ngClass]="{'has-error':  caseCreateForm.value.submitted && !caseCreateForm.controls.revisedPurchaseOrderNumber?.value}"
              />
              <span *ngIf="caseCreateForm.value.submitted && !caseCreateForm.controls.revisedPurchaseOrderNumber?.value" style="color: red; font-size:15px;">Requested PO is required</span>
            
        </div>
      </div>
      <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 qty_Div">
        <div class="inputFields">
          <label class="formLabel">Requested Sold To #</label>
          <input type="text" minlength="8" maxlength="10" placeholder="Requested Sold To #" class="form-control qty_input"
            formControlName="revisedAccountNumber" numericOnly />            
        </div>
      </div>
      <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 shipToDropdown">
        <div class="inputFields">
          <label class="formLabel">Requested Ship To #</label>
            <ng-select class="custom" [items]="shipToData" (change)="setShipToOnSelect($event)"
              placeholder="Select a Ship To" bindLabel="text" [clearable]="true" [searchable]="true"
              formControlName="revisedSolToShipTo" [(ngModel)]="selectedShipTo"></ng-select>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="incorrectPartner">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 invoice-icorrectPO noPadding">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 noPadding">
        <p>Your Selected Invoice Information</p>
      </div>
      <div class="row">
      <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 item_Div noPadding">
        <div class="inputFields">
          <label class="formLabel">Original PO #</label>
          <input type="text" maxlength="35" readonly placeholder="Original PO #" class="form-control material_input"
            formControlName="originalPurchaseOrderNumber" />
        </div>
      </div>
      <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 qty_Div">
        <div class="inputFields">
          <label class="formLabel">Original Sold To #</label>
          <input type="text" readonly maxlength="10" placeholder="Original Sold To #" class="form-control qty_input"
            formControlName="originalAccountNumber" />
        </div>
      </div>
      <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 uom_Div">
        <div class="inputFields">
          <label class="formLabel">Original Ship To #</label>
          <input type="text" readonly maxlength="10" placeholder="Original Ship To #" class="form-control uom_input"
            formControlName="originalSolToShipTo" />
        </div>
      </div>
      </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 invoice-icorrectPO noPadding">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 noPadding">
        <p>Enter Your Updated Invoice Information</p>
      </div>
      <div class="row">
      <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 item_Div noPadding">
        <div class="inputFields">
          <label class="formLabel">Requested PO #</label>
          <input type="text" maxlength="35" placeholder="Requested PO #" class="form-control material_input"
            formControlName="revisedPurchaseOrderNumber" />
        </div>
      </div>
      <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 qty_Div">
        <div class="inputFields">
          <label class="formLabel"><span class="red-text">*</span>Requested Sold To #</label>
          <input type="text" minlength="8" maxlength="10" placeholder="Requested Sold To #" class="form-control qty_input"
            formControlName="revisedAccountNumber" numericOnly 
            [ngClass]="{'has-error':  caseCreateForm.value.submitted && !caseCreateForm.controls.revisedAccountNumber?.value}"
              />
              <span *ngIf="caseCreateForm.value.submitted && !caseCreateForm.controls.revisedAccountNumber?.value" style="color: red; font-size:15px;">Requested Sold TO is required</span>
        </div>
      </div>
      <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 shipToDropdown">
        <div class="inputFields">

          <label class="formLabel">Requested Ship To #</label>
            <ng-select class="custom" [items]="shipToData" (change)="setShipToOnSelect($event)"
              placeholder="Select a Ship To" bindLabel="text" [clearable]="true" [searchable]="true"
              formControlName="revisedSolToShipTo" [(ngModel)]="selectedShipTo"></ng-select>
        </div>
      </div>
      </div>
    </div>

  </div>
  <div *ngIf="incorrectFees">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 invoice-icorrectPO noPadding">
      <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 radio_btns noPadding">
        <div class="inputFields">
          <label class="formLabel"
            ><span class="red-text">*</span>Incorrect Fee name
          </label>
          <div [ngClass]="{'has-error': caseCreateForm.value.submitted && !caseCreateForm.controls.incorrectFeeName?.value}">
            <div class="radio customRadio" >
              <input
                type="radio"
                name="incorrectFeeName"
                id="mini_order_change"
                class="keep_radio_btn"
                formControlName="incorrectFeeName"
                value="01"
              /><label>Minimum Order Charge</label>
            </div>
            <div class="radio customRadio">
              <input
                type="radio"
                name="incorrectFeeName"
                id="trans_charge"
                class="return_radio_btn"
                formControlName="incorrectFeeName"
                value="02"
              /><label>Transportation Charges</label>
            </div>
            <div class="radio customRadio">
              <input
                type="radio"
                name="incorrectFeeName"
                id="shipping"
                class="return_radio_btn"
                formControlName="incorrectFeeName"
                value="03"
              /><label>Shipping and Handling</label>
            </div>
            <div class="radio customRadio">
              <input
                type="radio"
                name="incorrectFeeName"
                id="fees"
                class="keep_radio_btn"
                formControlName="incorrectFeeName"
                value="04"
              /><label>Restocking Fees</label>
            </div>
            <div class="radio customRadio">
              <input
                type="radio"
                name="incorrectFeeName"
                id="shipFee"
                class="return_radio_btn"
                formControlName="incorrectFeeName"
                value="05"
              /><label>Dropship Fee</label>
            </div>
            <div class="radio customRadio">
              <input
                type="radio"
                name="incorrectFeeName"
                id="other"
                class="return_radio_btn"
                formControlName="incorrectFeeName"
                value="06"
              /><label>Other</label>
            </div>
        </div>
          <span *ngIf="caseCreateForm.value.submitted && !caseCreateForm.controls.incorrectFeeName?.value" style="color: red; font-size:15px;">Please select option</span>
        </div>
      </div>
    </div>
  </div>

</form>
