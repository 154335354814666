import { Component, OnInit, ChangeDetectorRef, Renderer2, ViewChild, ElementRef, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MultiselectDropdownModel } from '../../model/multiselect-dropdown.model';
import { Account, ShipToListResponse } from '../../model/shipToListResponse';
import { CasesService } from '../../services/cases.service';
import { CaseSearchService } from '../../services/case-search.service';
import { Observable } from 'rxjs';
import { CaseSearchResponse } from '../../model/caseSearchResponse';
import { CaseSearchRequestPayload } from "../../model/case-search-request-payload.model";
import { CasesConstants } from "../../constants/cases.constants";
import { CustomMultiselectDropdownComponent } from "../multiselect-dropdown/custom-multiselect-dropdown.component"
import { DateRangeComponent } from "../date-range/date-range.component";
import * as moment_ from 'moment';
import { LogService } from '../../services/log.service';
import { Title } from '@angular/platform-browser';
import {environment} from '../../../../../src/environments/environment'
import { ExportReport } from '../../constants/exportReport.constants';


const moment = moment_;

export enum SearchCaseDropdownValue {
  caseNumber = 1,
  poNumber = 2
}

@Component({
  selector: 'csp-search-help-tickets',
  templateUrl: './search-help-tickets.component.html',
  styleUrls: ['./search-help-tickets.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SearchHelpTicketsComponent implements OnInit {

  private baseRoutingUrl: string;
  searchByPONumber: boolean;
  searchByCaseId: boolean;
  baseMarketHelpUrl: string;
  constructor(private changeDetectorRef: ChangeDetectorRef,
    private titleService:Title,
    private casesService: CasesService, private caseSearchService: CaseSearchService,
    private activatedRoute: ActivatedRoute, private router: Router,
    private renderer2: Renderer2,
    private logService: LogService
    ) {
        this.baseRoutingUrl =  environment.baseRoutingUrl;
        this.baseMarketHelpUrl = environment.baseMarketHelpUrl;
     }
  
  shipTos: Array<MultiselectDropdownModel> = [];
  channels: Array<MultiselectDropdownModel> = [];
  caseStatus: Array<MultiselectDropdownModel> = [];
  shipToLabel = "All Ship To's"
  channelLabel = "All Methods"
  caseStatuslabel = "All Status"
  numChannel = "Channels"
  numShipTos = "ShipTo's"
  numCaseStatus = "Status"
  searchResults: CaseSearchResponse;
  searchcasesOriginalArray: CaseSearchResponse;
  searchValue: SearchCaseDropdownValue = SearchCaseDropdownValue.caseNumber;
  SearchCaseDropdownValue = [
    { value: 1, label: 'Case Number' },
    { value: 2, label: 'PO Number' }
  ];

  searchPayload: CaseSearchRequestPayload;
  searchInputVal = "";
  setdefaultStartDate: string;
  setdefaultEndDate: string;
  activeSortingLabel: number = 0;
  caseLabels = [
    'id',
    'channel',
    'purchaseOrderNumber',
    'description',
    'status',
    'shipToId',
    'openDate',
    'lastModifiedDate',
    'contactName'
  ];
  lastActiveFilterElement: HTMLElement;
  searchResultsArrayCopy = [];
  contactSearchValue: string;
  contactSearchRender: any;
  caseSearchRender: any;
  fromDatetoDate: any;
  cspShowSpinner: boolean = false;
  errorDetail: any;
  isWaitingForResponse = false;
  exceedsMaxSearchResultCount: boolean = false;
  caseStatusValues = {
    inProcessStatus: 'In Process',
    waitingForCustomerStatus: 'Waiting for Customer Response',
    requestResolvedStatus: 'Request Resolved'
  };
  isMobile = true;
  mobileStatusOrder = ['Waiting for Customer Response', 'In Process', 'Request Resolved'];
  errmsg: string;
  highlightError: boolean = false;
  
  @ViewChild('contactSearch',{static: true}) contactSearch: ElementRef;
  @ViewChild('caseResult',{static: true}) caseResult: ElementRef;
  @ViewChild('caseNoSection',{static: true}) caseNoSection: ElementRef;
  @ViewChild('caseSearch',{static: true}) caseSearch: ElementRef;
  @Output() onDateFilterChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() cspCustomCaseSearch = new EventEmitter();
  @ViewChild(DateRangeComponent) daterange: DateRangeComponent;
  @ViewChild('shipto',{static: true}) shiptoComponent: CustomMultiselectDropdownComponent;
  @ViewChild('casestatus',{static: true}) casestatusComponent: CustomMultiselectDropdownComponent;
  @ViewChild('channel',{static: true}) channelComponent: CustomMultiselectDropdownComponent;
  
  ngOnInit() {
    this.cspShowSpinner = true;
    this.titleService.setTitle("Search");
    window.dispatchEvent(new Event('formClean'));
    this.isMobile = (window.innerWidth > 767) ? false : true;
    this.searchPayload = {
      fromDate: "",
      toDate: "",
      caseNumber: "",
      shipto: "",
      channelTypes: [],
      status: "",
      export: false,
      poNumber: ""
    }
    
    this.activatedRoute.queryParams.subscribe((params: Observable<Params>) => {
      this.loadChannelData(params);
      this.loadShipToData(params);
      this.loadStatusData(params);
      this.fromDatetoDate = params;

      if (Object.keys(params).length > 0) {
        this.fetchResults(params);
      }
    });

    this.searchByCaseId = true;
  }
  setSearchCaseDropdownValue(event)  {
    if(SearchCaseDropdownValue.caseNumber==event.value){
      this.searchByPONumber = false;
      this.searchByCaseId = true;
    }
    else{
      this.searchByPONumber = true;
       this.searchByCaseId = false;
    }
  }
  loadChannelData(channelInfo) {
    if (!this.channels.length) {
      this.casesService.getCaseMasterData().subscribe(data => {
        data.channelTypes.forEach(element => {
          let checked = channelInfo.channel ? (channelInfo.channel && (channelInfo.channel.length == 25 ? false : (channelInfo.channel.indexOf(element.id) != -1))) : false;
          this.channels.push({ id: element.id, value: element.value, checked: checked });
        })
        this.channelComponent.initialLoad();
      },
      error=>{
        // if (error.status == '401') {
        //   this.casesService.getGeneratedToken().subscribe((data: any) => {
        //     let accessToken = data["access-token"];
        //     localStorage.setItem("app-token", accessToken);
        //     this.loadChannelData(channelInfo)
        //   }, (error) => {
        //     console.log('Generated Token Error: ' + error);
            
        //   });
        // }
       
      });
    }
  }

  loadShipToData(appliedShiptoId) {
    if (!this.shipTos.length) {
      this.caseSearchService.getShipToValues().subscribe((data: ShipToListResponse) => {
        data.shipTos.forEach((element: Account) => {
          let checked = appliedShiptoId.shipto === element.id;
          this.shipTos.push({ id: element.id, value: element.id + ' - ' + element.name, checked: checked });
        })
        this.shiptoComponent.initialLoad();
        this.cspShowSpinner = false;
      },
        (error) => {
          // if (error.status == '401') {
          //   this.casesService.getGeneratedToken().subscribe((data: any) => {
          //     let accessToken = data["access-token"];
          //     localStorage.setItem("app-token", accessToken);
          //     this.loadShipToData(appliedShiptoId);
          //   }, (error) => {
          //     console.log('Generated Token Error: ' + error);
          //     this.cspShowSpinner = false;
          //     this.errorDetail = error;
          //   });
          // }
          // else {
            this.cspShowSpinner = false;
          this.errorDetail = error;
          // }

        });
    }
  }

  loadStatusData(appliedStatusId) {
    this.caseStatus = [];
    let allstatusSelected = appliedStatusId.status && appliedStatusId.status.split("|").length;

    if (!appliedStatusId.status) {
      this.caseStatus.push({ id: "1", value: "In Process", checked: true });
      this.caseStatus.push({ id: "2", value: "Waiting for Customer Response", checked: true });
      this.caseStatus.push({ id: "3", value: "Request Resolved", checked: false });
    } else {
      this.caseStatus.push({ id: "1", value: "In Process", checked: (allstatusSelected != 3 && appliedStatusId.status.split("|").indexOf("In Process") != -1) });
      this.caseStatus.push({ id: "2", value: "Waiting for Customer Response", checked: (allstatusSelected != 3 && appliedStatusId.status.split("|").indexOf("Waiting for Customer Response") != -1) });
      this.caseStatus.push({ id: "3", value: "Request Resolved", checked: (allstatusSelected != 3 && appliedStatusId.status.split("|").indexOf("Request Resolved") != -1) });

    }

  }

  

  resetFilter(event) {
    event.preventDefault();

    this.caseStatus = [];

    this.caseStatus.push({ id: "1", value: "In Process", checked: true });
    this.caseStatus.push({ id: "2", value: "Waiting for Customer Response", checked: true });
    this.caseStatus.push({ id: "3", value: "Request Resolved", checked: false });

    this.shipTos.forEach((element) => {

      element.checked = false;
    });

    this.channels.forEach((element) => {

      element.checked = false;
    });

    this.shiptoComponent.setDefaultSelection();
    this.shiptoComponent.setdisplayMessage();

    this.casestatusComponent.loadData(this.caseStatus);
    this.casestatusComponent.setdisplayMessage();

    this.channelComponent.setDefaultSelection();
    this.channelComponent.setdisplayMessage();

    //Reset filter values
    let getTodayResetToDate = moment().format("MM/DD/YYYY");

    let getEndResetFromDate = moment().subtract(7, 'days').format("MM/DD/YYYY");

    this.searchPayload = {
      fromDate: getEndResetFromDate,
      toDate: getTodayResetToDate,
      status: "In Process|Waiting for Customer Response",
      shipto: "",
      channelTypes: ['401', 'FAX', 'Z01', 'Z10'],
      caseNumber: "",
      export: false,
      poNumber: ""
    }

  }

  onChannelChange(channelData) {
    this.searchPayload.channelTypes = [];
    let selectedchannelType = [];
    channelData.forEach((status) => {
      if (status.checked) {
        selectedchannelType.push(status.id);
      }
    });
    this.searchPayload.channelTypes = selectedchannelType;
  }

  updateURL(formData) {
    const queryParams: Params = {};
    queryParams['caseid'] = formData.caseNumber;
    queryParams['shipto'] = formData.shipto;
    queryParams['fromdate'] = formData.fromDate;
    queryParams['todate'] = formData.toDate;
    queryParams['status'] = formData.status;
    queryParams['channel'] = this.generateChannelTypeString(formData.channelTypes);
    queryParams['poNumber'] = formData.poNumber;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: queryParams
    });
  }

  fetchResults(params) {
     var startTime = performance.now();
    this.cspShowSpinner = true;

    this.casesService.search({
      caseNumber: params.caseid,
      fromDate: params.fromdate,
      toDate: params.todate,
      shipto: params.shipto,
      status: params.status,
      channelTypes: params.channel,
      poNumber: params.poNumber
    }).subscribe((data: CaseSearchResponse) => {
      var duration : number = performance.now() - startTime;
      // console.log(" run time for search: "+duration)
      this.cspShowSpinner = false;
      this.searchcasesOriginalArray = data;
      this.searchResults = JSON.parse(JSON.stringify(data));
      this.exceedsMaxSearchResultCount = this.searchResults.exceedsMaxSearchResultCount;
      this.searchResultsArrayCopy = this.searchResults.caseHeaders;
      this.isWaitingForResponse = false;
      if (data.caseCount > 0) {
        if (this.isMobile) {
          this.sortCasesMobile();
        }
        this.isWaitingForResponse = this.searchcasesOriginalArray.caseHeaders.filter(
          obj => obj.status === this.caseStatusValues.waitingForCustomerStatus).length > 0 ? true : false;
        this.errorDetail = null;
      }
      let logMessage: string = "AppId:26095 : " + "Case Search button clicked. Case Search Criteria : " + JSON.stringify(params) 
            + " : result count : " + this.searchResults.caseCount;
      
      let roundedDuration  = Math.round((duration + Number.EPSILON) * 100) / 100;
      let logSearchTimeMessage: string = "AppId:26095 : " + "Case Search button clicked. Time taken for case search : " +roundedDuration;
      this.logService.logMessage(logSearchTimeMessage).subscribe();
       
      this.logService.logMessage(logMessage).subscribe();
      if(this.exceedsMaxSearchResultCount){
        let exceedMaxCountMessage: string = "AppId:26095 : " + "Case Search button clicked. Case search exceeds max search count";
        this.logService.logMessage(exceedMaxCountMessage).subscribe();
      }

    },
      (error) => {
        // if (error.status == '401') {
        //   this.casesService.getGeneratedToken().subscribe((data: any) => {
        //     let accessToken = data["access-token"];
        //     localStorage.setItem("app-token", accessToken);
        //     this.fetchResults(params);
        //   }, (error) => {
        //     console.log('Generated Token Error: ' + error);
        //     // this.cspShowSpinner = false;

        //     this.errorDetail = error;
        //     window.location.reload();
        //   });
        // }
       
          this.cspShowSpinner = false;
          this.errorDetail = error;
          let logMessage: string = "AppId:26095 : " + "Error on Case Search button clicked. Error Case Search Criteria : " 
          + JSON.stringify(params) + " : Error : ";
                                
          if(this.errorDetail.status == 500 && this.errorDetail.error){
            logMessage = logMessage + this.errorDetail.error.errorCode + " : " + this.errorDetail.error.description;
          }else if(this.errorDetail.error){
            logMessage = logMessage + this.errorDetail.error.errorCode + " : " + this.errorDetail.error.errorMessage;
          }
          else{
              logMessage = logMessage + this.errorDetail.message;
          }
          this.logService.logMessage(logMessage).subscribe();
        }
       
       // error path
    );
  }

  sortCasesMobile() {
    this.searchResults = JSON.parse(JSON.stringify(this.searchcasesOriginalArray));
    this.searchResults.caseHeaders.sort(({ status: caseAStatus, openDate: caseAopenDate },
      { status: caseBStatus, openDate: caseBopenDate }) => {
      return this.mobileStatusOrder.indexOf(caseAStatus) - this.mobileStatusOrder.indexOf(caseBStatus)
        || new Date(caseBopenDate).getTime() - new Date(caseAopenDate).getTime();
    });
  }

  onCaseStatusSelect(statusData) {
    this.searchPayload.status = "";
    let selectedcaseStatus = [];
    statusData.forEach((status) => {
      if (status.checked) {
        selectedcaseStatus.push(status.value);
      }
    });
    this.searchPayload.status = selectedcaseStatus.join('|');
  }


  onShipToSelect(shiptoData) {
    this.searchPayload.shipto = "";
    shiptoData.forEach((shipTo) => {
      if (shipTo.checked) {
        this.searchPayload.shipto = shipTo.id;
      }
    });
  }
  onDateRangeChange(selectedDate) {
    this.searchPayload.fromDate = moment(selectedDate[0]).format("MM/DD/YYYY");
    this.searchPayload.toDate = moment(selectedDate[1]).format("MM/DD/YYYY");
  }
  /**
   * This caseSearchSubmit() is used to top level dropdown value selected search
   */
  
  caseSearchSubmit(buttonType) {
   
    if (buttonType == "caseButton" && this.searchByCaseId && this.searchInputVal.length != 10) {
      this.errmsg = "Please enter a valid Case number";
      this.highlightError = true;
    } else if(buttonType == "caseButton" && this.searchByPONumber && this.searchInputVal.length < 5){
      this.errmsg = "Please enter a valid PO number";
      this.highlightError = true;
    }else {
      this.cspCustomCaseSearch.emit();
      this.searchInputVal = buttonType == "filterButton" ? "" : this.searchInputVal;

      this.searchPayload = {
        fromDate: this.searchPayload.fromDate,
        toDate: this.searchPayload.toDate,
        status: this.searchPayload.status ? (this.searchPayload.status != "All Status" ? this.searchPayload.status : "In Process|Waiting for Customer Response|Request Resolved") : "In Process|Waiting for Customer Response",
        shipto: this.searchPayload.shipto == "SelectAll" ? "" : this.searchPayload.shipto,
        channelTypes: this.searchPayload.channelTypes == "SelectAll" ? ['401', 'FAX', 'Z01', 'Z10'] : this.searchPayload.channelTypes,
        caseNumber: this.searchInputVal && buttonType == "caseButton" && this.searchByCaseId ? this.searchInputVal : "",
        export: false,
        poNumber: this.searchInputVal && buttonType =="caseButton" && this.searchByPONumber ? this.searchInputVal : "",
      }

      this.updateURL(this.searchPayload);
    }
  }

  generateChannelTypeString(channels) {
    let channelTypes = '[';
    for (var i = 0; i < channels.length; i++) {
      channelTypes += "'" + channels[i] + "',";
    }
    channelTypes = channelTypes.slice(0, channelTypes.length - 1);
    channelTypes += "]";
    return channelTypes;
  }

  /**
   * This method will return channel type name based on channel id
   * @param id
   */
  getChannelType(id) {
    let filteredObj = CasesConstants.CHANNELS.filter((key, value) => {
      return key.id === id
    })[0];
    return filteredObj.value;
  }

  /**
 * This methord helps to sort the search results table column in "ascending" and "ascending"
 * @param element - which element target sorting
 * @param key - which label sorting
 * @param type - date/string/number
 */
//   sortCases(element: HTMLElement | string, key: string, type: string) {
//     const orderBy = (typeof element === 'string') ? element : element.classList.contains('ascending') ?
//       'descending' : 'ascending';
//     this.updateSortingArrows(orderBy, element);
//     this.activeSortingLabel = this.caseLabels.indexOf(key);
//     this.lastActiveFilterElement = (typeof element === 'string') ? undefined : element;

//     // This is to make sure all the sorting takes place relative to the orginal array received
//     this.searchResults.caseHeaders.sort((caseA, caseB) => {
//       if (type === 'date' && orderBy === 'ascending') {
//         return (caseA[key] === "") ? -1 : (caseB[key] === "") ?
//           1 : (caseA[key] === caseB[key]) ? 0 : new Date(caseA[key]).getTime() - new Date(caseB[key]).getTime();
//       } else if (type === 'date' && orderBy === 'descending') {
//         return (caseA[key] === "") ? 1 : (caseB[key] === "") ?
//           -1 : (caseA[key] === caseB[key]) ? 0 : new Date(caseB[key]).getTime() - new Date(caseA[key]).getTime();
//       } else if (type === 'string' && orderBy === 'ascending') {
//         return (caseA[key] == null || "") ? -1 : (caseB[key] == null || "") ?
//           1 : (caseA[key] === caseB[key]) ? 0 : (caseA[key] > caseB[key]) ? 1 : -1;
//       } else if (type === 'string' && orderBy === 'descending') {
//         return (caseA[key] == null || "") ? 1 : (caseB[key] == null || "") ?
//           -1 : (caseA[key] === caseB[key]) ? 0 : (caseA[key] > caseB[key]) ? -1 : 1;
//       } else if (type === 'number' && orderBy === 'ascending') {
//         return caseA[key] - caseB[key];
//       } else if (type === 'number' && orderBy === 'descending') {
//         return caseB[key] - caseA[key];
//       }
//     });

//   }

  
/**
 * This method helps to sort the search results table column in "ascending" and "descending".
 * @param element - which element target sorting.
 * @param key - which label sorting.
 * @param type - date/string/number.
 * Code refactored using Copilot
 */
sortCases(element: HTMLElement | string, key: string, type: string) {
  const orderBy = (typeof element === 'string') ? element : (element.classList.contains('ascending') ? 'descending' : 'ascending');
  this.updateSortingArrows(orderBy, element);

  this.activeSortingLabel = this.caseLabels.indexOf(key);
  this.lastActiveFilterElement = (typeof element === 'string') ? undefined : element;

  // This is to make sure all the sorting takes place relative to the original array received.
  this.searchResults.caseHeaders.sort((caseA, caseB) => {
    if (type === 'date') {
      const a = new Date(caseA[key]).getTime();
      const b = new Date(caseB[key]).getTime();
      return orderBy === 'ascending' ? a - b : b - a;
    } else if (type === 'string') {
      const a = caseA[key] || '';
      const b = caseB[key] || '';
      return orderBy === 'ascending' ? a.localeCompare(b) : b.localeCompare(a);
    } else {
      return orderBy === 'ascending' ? caseA[key] - caseB[key] : caseB[key] - caseA[key];
    }
  });
}

  /**
 * Filter the contact based on contact search value
 */
  contactSearchListener() {
    this.contactSearchRender = this.renderer2.listen('window', 'click', (e: Event) => {
      let element: HTMLElement = e.target as HTMLElement;

      if (e.target !== this.contactSearch.nativeElement && !this.caseResult.nativeElement.contains(element)) {
        this.searchResults.caseHeaders = this.searchResultsArrayCopy;
        this.contactSearchValue = "";
      }

    });
  }

  /**
   * Search results table contact filter by contact name
   */
  filterByContact() {
    if (!this.contactSearchRender) {
      this.contactSearchListener();
    }

    if (this.contactSearchValue) {
      this.searchResults.caseHeaders = this.searchResultsArrayCopy.
        filter(element => {
          if (element.contactName && element.contactName.toLowerCase().includes(this.contactSearchValue.toLowerCase()))
            return element
        });
    }
    else {
      this.searchResults.caseHeaders = this.searchResultsArrayCopy;
    }
  }
  /**
 * Changing the sorting up arraow and down arrow based on click
 */
  updateSortingArrows(orderBy: string, element: HTMLElement | string) {
    if (orderBy === 'ascending') {
      this.renderer2.addClass(element, 'ascending');
     // this.renderer.setElementClass(element, 'ascending', true);
    } else if ((typeof element !== 'string') && element.classList.contains('ascending')) {
      //this.renderer.setElementClass(element, 'ascending', false);
      this.renderer2.removeClass(element, 'ascending')
    }
  }

  /**
   * To validate case number lenth
   */
  validateCaseNumber(inputValue) {
    if (!this.caseSearchRender) {
      this.caseSearchListener();
    }
    if (inputValue.length < 10) {
      this.errmsg = "Enter a valid 10 digit Case number";
      this.highlightError = true;
    } else {
      this.errmsg = "";
      this.highlightError = false;
    }
  }
  /**
   * Methos to clear case number functionality
   */
  clearInputValue() {
    this.searchInputVal = "";
  }

  caseSearchListener() {
    this.caseSearchRender = this.renderer2.listen('window', 'click', (e: Event) => {
      let element: HTMLElement = e.target as HTMLElement;

      if (e.target !== this.caseSearch.nativeElement && !this.caseNoSection.nativeElement.contains(element) && this.errmsg != "" && this.highlightError) {

        this.searchInputVal = "";
        this.errmsg = "";
        this.highlightError = false;
      }

    });
  }

  validatePONumber(inputValue) {
    if (!this.caseSearchRender) {
      this.caseSearchListener();
    }
    if (inputValue.length < 5 ) {
      this.errmsg = "Enter at least 5 characters of the PO number";
      this.highlightError = true;
    } else {
      this.errmsg = "";
      this.highlightError = false;
    }
  }

  downloadFile() {
    //let csvData = this.ConvertToCSV(data, ['id','channel', 'contactName', 'description', 'dueDate','lastModifiedDate','openDate','purchaseOrderNumber','shipToId','shipToName','status']);
   
    //let stringDate = this.datePipe.transform(current,"yyyyMMdd_HHmm");
    let stringDate = moment().format("YYYYMMDD_HHmm");
    const filename = stringDate+'_helpcasedata';
    let searchPayloadForDownload = {
      fromDate: this.searchPayload.fromDate,
      toDate: this.searchPayload.toDate,
      status: this.searchPayload.status ? (this.searchPayload.status != "All Status" ? this.searchPayload.status : "In Process|Waiting for Customer Response|Request Resolved") : "In Process|Waiting for Customer Response",
      shipto: this.searchPayload.shipto == "SelectAll" ? "" : this.searchPayload.shipto,
      channelTypes: this.generateChannelTypeString(this.searchPayload.channelTypes == "SelectAll" ? ['401', 'FAX', 'Z01', 'Z10'] : this.searchPayload.channelTypes),
      caseNumber: (this.searchByCaseId && this.searchInputVal.length == 10) ? this.searchInputVal : "",
      export: false,
      poNumber: (this.searchByPONumber && this.searchInputVal.length > 4) ? this.searchInputVal : ""
    }
    searchPayloadForDownload.export = true;
    this.casesService.exportReport(searchPayloadForDownload).subscribe((data: CaseSearchResponse) => {
      const headerList = ExportReport.REPORT_HEADERS;
      const selectedColList = ExportReport.REPORT_COLUMNS;
      const exportData = data.caseHeaders;  
      const caseCount = data.caseCount;
      let exportLogMessage: string = "AppId:26095 : " + "Case Export button clicked. Export Result: less than or equal to hundred. Case Export Criteria : ";
      if(caseCount> 100){
        exportLogMessage = "AppId:26095 : " + "Case Export button clicked. Export Result: more than hundred. Case Export Criteria : ";
      }
      exportLogMessage = exportLogMessage + JSON.stringify(searchPayloadForDownload);
      this.logService.logMessage(exportLogMessage).subscribe();
      let csvData = this.exportToCsv(exportData,headerList,selectedColList);
      let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;encoding:utf-8' });
      let dwldLink = document.createElement("a");
      let url = URL.createObjectURL(blob);
      dwldLink.setAttribute("href", url);
      dwldLink.setAttribute("download", filename  + ".csv");
      dwldLink.style.visibility = "hidden";
      document.body.appendChild(dwldLink);
      dwldLink.click();
      document.body.removeChild(dwldLink);
    }, (exportError) => {
      this.cspShowSpinner = false;
        
      let exportErrorLogMessage: string = "AppId:26095 : " + "Case Export Error. Export Error : ";
      if(exportError.status == 500 && exportError.error){
        exportErrorLogMessage = exportErrorLogMessage + exportError.error.errorCode + " : " + exportError.error.description;
      }else if(exportError.error){
        exportErrorLogMessage = exportErrorLogMessage + exportError.error.errorCode + " : " + exportError.error.errorMessage;
      }
      else{
        exportErrorLogMessage = exportErrorLogMessage + exportError.message;
      }
      this.logService.logMessage(exportErrorLogMessage).subscribe();
    });
    
}

 public exportToCsv(rows: object[], headerList: string[], columns: string[]): string {
  if (!rows || !rows.length) {
    return;
  }
  var separator = ',';
  const csvContent =
      headerList.join(separator) +
    '\n'   +
    rows.map(row => {

      return  columns.map(k => {
        let cell = row[k] === null || row[k] === undefined ? '' : row[k];
        
        if("id".match(k)){
          const caseIdRouteURL = this.baseRoutingUrl + "/case/details/";
          const baseMHUrl = this.baseMarketHelpUrl + "/office?url="
          const caseNumber = cell;
          const hyperlink = '=HYPERLINK("'+baseMHUrl+caseIdRouteURL+caseNumber+'","'+caseNumber+'")';
          cell = hyperlink;
        }
       // =HYPERLINK("https://markethelpstg.cardinalhealth.com/office?url=https://marketstg.cardinalhealth.com/case/details/8103920663","8103920663")
        if("purchaseOrderNumber".match(k)){
            const modifiedCell = cell+'\t';
            cell = modifiedCell;
          }
        if("channel".match(k)){
          const contactMethod = cell;
          cell = this.getChannelType(contactMethod);
        }

        cell = cell.toString().replace(/"/g, '""');
        if (cell.search(/("|,|\n)/g) >= 0) {
          cell = `"${cell}"`;
        }
        return cell;
      }).join(separator);
    }).join('\n');
  return csvContent;
}

}
