import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

@Component({
  selector: 'return-form',
  templateUrl: './return-form.component.html',
  styleUrls: ['./return-form.component.scss']
})
export class ReturnFormComponent implements OnInit {
  @Input() caseCreateForm : FormGroup;
  @Input() formIndex : number = 0;
  @Input() isNoLongerNeededForm: boolean = false;
  @Input() isPurchasedTooManyForm: boolean = false;
  @Input() isProductRecallForm:boolean = false;
  @Input() isIncorrectProductOrderedForm:boolean = false;
  @Output() deleteFormItem : EventEmitter<any> = new EventEmitter<any>();
  quantityReceived : Number;
  uomReceived : string;
  lotNo : any;
  constructor() { 
      // This is intentional
  }

  
  get formContols() :FormGroup{
    let formArray=this.getFormArray();
    let formGroup=formArray.controls[this.formIndex] as FormGroup;
   return formGroup
  }
  get itemDescription(): FormGroup{
    let noLongerNeededItemFormArray=this.getFormArray();
    let noLongerNeededItemFormGroup=noLongerNeededItemFormArray.controls[this.formIndex] as FormGroup;
    
      return noLongerNeededItemFormGroup.get('itemDescription').value;
  }
  get statusMessageList() : FormGroup{
    let noLongerNeededItemFormArray=this.getFormArray();
    let noLongerNeededItemFormGroup=noLongerNeededItemFormArray.controls[this.formIndex] as FormGroup;
    
      return noLongerNeededItemFormGroup.get('statusMessageList').value;
  }
  ngOnInit() {
        // This is intentional
  }
  deleteForm() :void { 
  let noLongerNeededItemFormArray=this.caseCreateForm.get('items') as FormArray;
  if(noLongerNeededItemFormArray.length>1) {
    const deletedItem = noLongerNeededItemFormArray.value[this.formIndex];
    this.deleteFormItem.emit(deletedItem);
    noLongerNeededItemFormArray.removeAt(this.formIndex);
  }
  }

  getFormArray() :FormArray {
    return this.caseCreateForm.get('items') as FormArray;
  }
}
