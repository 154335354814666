import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../../../environments/environment';


@Component({
  selector: 'order-case-acknowledgement',
  templateUrl: './order-case-acknowledgement.component.html',
  styleUrls: ['./order-case-acknowledgement.component.scss']
})
export class OrderCaseAcknowledgementComponent implements OnInit {
  public ItemIdList: Array<any> = [];
  ackUrl:string="";
  baseRoutingUrl: string = environment.baseRoutingUrl;
  hostname:string=""; 
  constructor(private router: Router,
    private titleService:Title,
    private route: ActivatedRoute) {
    
   }

  ngOnInit() {
    window.scrollTo(0, 0);

   var sharedCaseId=JSON.parse(localStorage.getItem("OrderCaseList"));
   sharedCaseId.sort((a,b) => a.caseId.localeCompare(b.caseId));
    if (sharedCaseId) {
        this.ItemIdList = this.ItemIdList.concat(sharedCaseId);
    }
    this.titleService.setTitle("Order Acknowledgement");

  }
}