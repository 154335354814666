import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { NgSelectData } from 'src/app/csp/model/singleselect-dropdown.model';
import { OrderSearchService } from 'src/app/csp/services/order-search.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'order-search-component',
  templateUrl: './order-search-component.component.html',
  styleUrls: ['./order-search-component.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class OrderSearchComponentComponent implements OnInit {
  @Input() shipToData : Array<any>;
  @Input() showError : boolean;
  @Output() setPONumber = new EventEmitter();
  @Output() onSearchIconClick = new EventEmitter();
  orderData: any;
  isVisibleOrderDetail: boolean = false;
  cspShowSpinner: boolean = false;
  //searchValue: SearchOrderDropdownValue = SearchOrderDropdownValue.orderNumber;
  SearchOrderDropdownValue: NgSelectData[] = [
    { value: 1, label: 'PO Number'},
    { value: 2, label: 'Order Number' }
  ];
  selectedItem: NgSelectData;
  searchByPONumber: boolean;
  searchByOrderNumber: boolean;
  isOrdrSearchError: boolean;
  orderSearchErrorMsg: string;
  orderSearchErrorMsgLine2: string;
  orderSearchErrorMsgLine3: string;
  searchInputVal: string;
  createCase2Visible:boolean;
  showPOOrdersModal: boolean;
  public poOrdersDetails: any[] = [];
  poOrdersColums: string[];
  poOrdersColumnLabels: string[];
  addItemButtonName = "Select Order";
  constructor( private orderSearchService: OrderSearchService) { }

  ngOnInit(): void {
    this.searchByPONumber = true;
    this.searchByOrderNumber = false;
    this.isVisibleOrderDetail = false;
    this.isOrdrSearchError = false;
    this.selectedItem = this.SearchOrderDropdownValue[0];
    this.clearInputString();
  }
  public orderNumberSearch(orderNumberSearchString: string) {
    this.cspShowSpinner = true;
    this.orderSearchService.searchOrder(orderNumberSearchString).subscribe((data: any) => {
      this.cspShowSpinner = false;
      const orderDetails = data
      if (orderDetails.header) {
        this.isVisibleOrderDetail = true;
        this.isOrdrSearchError = false;
        const orderHeaderdetails = orderDetails.header;
        this.orderData = {
                            orderNumber: orderHeaderdetails.orderNumber,
                            shipTo: '00'+orderHeaderdetails.shipToAddress.id,
                            purchaseOrderNumber: orderHeaderdetails.purchaseOrderNumber,
                            orderedTime: orderHeaderdetails.orderedTime,
                            orderedBy: orderHeaderdetails.orderedBy,
                            orderTypeDesc: orderHeaderdetails.orderTypeDesc,
                            shipToDesc: orderHeaderdetails.shipToAddress.name
                          };
        const tempOrderData = this.orderData;                 
        const orderDetailsResponse = {
          orderData:this.orderData,
          orderLineItems:orderDetails.orderLines
        }
        var result = this.shipToData.filter(function (o1) {
          return o1.id === tempOrderData.shipTo; // return the ones with equal id
          
     });
     if(result && result.length > 0)
     {
      this.setPONumber.emit(orderDetailsResponse);
     }
     else{ 

       this.cspShowSpinner = false;
       this.isVisibleOrderDetail = false;
       this.isOrdrSearchError = true;
       this.orderSearchErrorMsg = "No results found.";
       this.orderSearchErrorMsgLine2 = "Please confirm the Order number entered.";
       this.orderSearchErrorMsgLine3 = "Please confirm that you have access to the Ship To account associated with the Order number entered.";
    }
      } else {
        this.cspShowSpinner = false;
        this.isVisibleOrderDetail = false;
        this.isOrdrSearchError = true;
        this.orderSearchErrorMsg = "No results found.";
        this.orderSearchErrorMsgLine2 = "Please confirm the Order number entered.";
        this.orderSearchErrorMsgLine3 = "Please confirm that you have access to the Ship To account associated with the Order number entered.";


      }
    }, (error) => {
      this.cspShowSpinner = false;
      this.isVisibleOrderDetail = false;
      this.isOrdrSearchError = true;
      this.orderSearchErrorMsg = "No results found.";
      this.orderSearchErrorMsgLine2 = "Please confirm the Order number entered.";
      this.orderSearchErrorMsgLine3 = "Please confirm that you have access to the Ship To account associated with the Order number entered.";
    });
  }

  public poNumberSearch(poNumberSearchString: string) {
    this.poOrdersDetails = [];
    this.orderSearchService.searchPONumber(poNumberSearchString).subscribe((data: any) => {
      const poTempdata = data;
      if (poTempdata && poTempdata.orders && poTempdata.orders.length == 1) {
        this.orderNumberSearch(poTempdata.orders[0].orderNumber);
      } else if (poTempdata && poTempdata.orders && poTempdata.orders.length > 1) {
        this.cspShowSpinner = false;
        this.isVisibleOrderDetail = false;
        
        
         this.poOrdersColums = ['ordernumber','ponumber','buyer','ordertotal','shipto','datetime'];
         this.poOrdersColumnLabels = ['Order Number','PO Number','Buyer','Order Total', 'ShipTo','Date & Time'];


        var formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        });
       
        
        poTempdata.orders.forEach(req => {
           var   orderTempData = {
            ordernumber:req.orderNumber,
            ponumber:req.purchaseOrderNumber,
            buyer:req.orderedBy,
            ordertotal: formatter.format(req.orderTotal),
            shipto:req.shipToAddress.name + " "+ req.shipToAddress.id,
            datetime: this.convertDateFormat(req.orderedTime),
            shiptoId: req.shipToAddress.id,
            selected: false,
            disabled: false
           }
           var result = this.shipToData.filter(function (o1) {
                return o1.id === req.shipToAddress.id; // return the ones with equal id
                
           });
           if(result && result.length > 0)
           {
            this.poOrdersDetails.push(orderTempData);
           }
           }
        );
           if(this.poOrdersDetails.length == 0)
           {
              this.cspShowSpinner = false;
              this.isVisibleOrderDetail = false;
              this.isOrdrSearchError = true;
              this.orderSearchErrorMsg = "No results found.";
              this.orderSearchErrorMsgLine2 = "Please confirm the PO # entered.";
              this.orderSearchErrorMsgLine3 = "Please confirm that you have access to the PO # entered.";
           }
           else{
            this.showPOOrdersModal = true;
           }
      } else {
        this.cspShowSpinner = false;
        this.isVisibleOrderDetail = false;
        this.isOrdrSearchError = true;
        this.orderSearchErrorMsg = "No results found.";
        this.orderSearchErrorMsgLine2 = "Please confirm the PO # entered.";
        this.orderSearchErrorMsgLine3 = "Please confirm that you have access to the PO # entered.";
      }
    }, (error) => {
      this.cspShowSpinner = false;
      this.isVisibleOrderDetail = false;
      this.isOrdrSearchError = true;
      this.orderSearchErrorMsg = "No results found.";
      this.orderSearchErrorMsgLine2 = "Please confirm the PO # entered.";
      this.orderSearchErrorMsgLine3 = "Please confirm that you have access to the PO # entered.";
    });
  }
  
  public searchResultForInputValue(searchInputValueString: string) {
    this.onSearchIconClick.emit();
    this.cspShowSpinner = true;
    this.isVisibleOrderDetail = false;
    this.isOrdrSearchError = false;
    this.poOrdersDetails = [];
    this.orderData = {};
    if (this.searchByPONumber) {
      this.poNumberSearch(searchInputValueString);
    } else if (this.searchByOrderNumber) {
      this.orderNumberSearch(searchInputValueString);
    }
  }
  public setSearchOrderDropdownValue(event) {
    this.searchInputVal = "";
    this.orderSearchErrorMsg = "";
    this.orderSearchErrorMsgLine2 = "";
    this.orderSearchErrorMsgLine3 = "";
   
    this.isOrdrSearchError = false;
    if (event.value == 1) {
      this.searchByPONumber = true;
      this.searchByOrderNumber = false;
    }
    else {
      this.searchByPONumber = false;
      this.searchByOrderNumber = true;
    }
  }

  public clearInputString(){
    this.searchInputVal = "";
  }
  public hidePOOrdersModal(){
    this.showPOOrdersModal = false;
  }
  public getSelectedOrderNumber(selectedOrderNum:any){
    this.hidePOOrdersModal();
    let orderNum = selectedOrderNum[0].ordernumber;
    this.orderNumberSearch(orderNum);
  }
  public convertDateFormat(orderedTime: any) {
    const datepipe: DatePipe = new DatePipe('en-US')
    let formattedDate = datepipe.transform(orderedTime, 'MM-dd-YY hh:mm a')
    return formattedDate;
 }
}

