import { Component, Input , Output, EventEmitter, TemplateRef, ElementRef, OnChanges, Renderer2  } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})

export class ModalComponent implements OnChanges {
    @Input() showModal: boolean = false;
    @Input() customBody: TemplateRef<ElementRef>;
    @Input() headerTemplate: TemplateRef<ElementRef>;
    @Input() footerTemplate: TemplateRef<ElementRef>;
    @Input() modalTital: TemplateRef<ElementRef>;
    @Input() defaultWidth: boolean = true;
    @Output() onCloseModal: EventEmitter<void> = new EventEmitter<void>();
    constructor(private renderer2: Renderer2) {}
    close(): void {
      this.showModal = false;
      this.onCloseModal.emit();
    }
    ngOnChanges(): void {
      if(this.showModal) {
        this.renderer2.addClass(document.body, 'prevent-scrolling');
      }else {
        this.renderer2.removeClass(document.body, 'prevent-scrolling');
      }
    }
}
