<div  class="spinner-main" [ngClass]="{'spinner-show': cspShowSpinner}">
  <div class="spinnerDiv" > 
  </div>
</div>
<div id="submitCaseFormModal">
 
  <div class="row">
    <div class="col-md-3 search-input">
      <strong>Add Recipients</strong>
    </div>
    <div class="col-md-6 search-input">
      <input type="text" name="addEmail" maxlength="100" class="form-control addEmail" [(ngModel)]="addEmail"
        (keyup)="validateEmail(addEmail)" placeholder="Enter a new Email Address" />
      <span *ngIf="!validEmail" class="text-danger">Enter a valid email address</span>

    </div>
    <div class="col-md-1">
      <button class="primary addbtn" [disabled]="!validEmail || this.addEmail.length===0 " data-id='additionEmailAddBtn'
        (click)="addEmailAddress()">Add</button>
    </div>
  </div>

  <div class="row">
    <div class="col-md-3 search-input">
      <strong>Select Contacts</strong>
    </div>
    <div class="col-md-6 search-input">
      <input type="text" id="searchtext-input" name="searchText" maxlength="100" class="form-control search"
        [(ngModel)]="searchText" placeholder="Search by Name" />
    </div>
  </div>
  
  <div>
    
   
    <div>
      <ul class="recipient-list">
        <li *ngFor="let recipient of recipientsAddress;let index=index">
          <span class="recipient-mail">{{recipient}}</span>
          <span class="icon icon-close-small" (click)="removeRecipient(index)"></span>
        </li>
      </ul>
    </div>





    <table class="table table-borderless" aria-describedby="Add Recipient">
      <thead>
        <tr>
          <td  class="checkBoxWidth">
            <input type="checkbox" [(ngModel)]="hasSelectedAllConatcts" (change)="selectAllContacts()" />
          </td>
          <td  style="width: 48%; padding-left: 0.5rem; color: #e41f35;">Select All
            <div *ngIf="moreThanFive">* (5 Recipients Max)</div>
          </td>
          <th scope="col" style="width: 48%">
            <button class="cta-btn primary red_button" style="float: right"
              [disabled]="((selectedContacts.length + recipientsAddress.length) <= 0) || ((selectedContacts.length + recipientsAddress.length) > 5)"
              (click)="addRecipients()">
              Add Recipients
            </button>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let contact of contacts | filter : searchText">
          <td class="checkBoxWidth">
            <input type="checkbox" [(ngModel)]="contact.selected" (change)="selectContact(contact)" />
          </td>
          <td class="contact">{{ contact.firstName }} {{ contact.lastName }}</td>
          <td class="contact">{{ contact.email }}</td>
        </tr>
      </tbody>
    </table>
  </div>
