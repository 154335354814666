import { Pipe, PipeTransform } from '@angular/core';
import { Contact } from '../app/csp/model/contact';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(contacts: Contact[], searchText: string): any[] {
    if (searchText.trim() === "" || searchText === null) {
      return contacts;
    }
    return contacts.filter(
      (recipient => recipient.firstName.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
                    recipient.lastName.toLowerCase().indexOf(searchText.toLowerCase()) > -1)
    );
  }

}
