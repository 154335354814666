import { Component, Input, EventEmitter, Output, OnInit} from '@angular/core';

@Component({
  selector: 'app-submit-case-discard',
  templateUrl: './submit-case-discard.component.html',
  styleUrls: ['./submit-case-discard.component.scss']
})
export class SubmitCaseDiscardComponent implements OnInit {

  isCancel: boolean = false;
  @Output() onCloseModal = new EventEmitter();
  @Output() discardChange = new EventEmitter();
  @Input()  name;
  @Input()  header;
  @Input()  message;
  constructor() {
      // This is intentional
  }

  ngOnInit() {
        // This is intentional
  }

  hideModal() {
    this.onCloseModal.emit();
  }

  discardChanges() {
  this.discardChange.emit();
  }
}
