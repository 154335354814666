import { Component, EventEmitter, Output, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { Contact, ContactResponse } from "../../../model/contact";
import { AddressBookService } from "../../../services/address-book.service";
import {  ErrorResponse } from '../../../model/caseResponse';


@Component({
  selector: "app-select-recipient",
  templateUrl: "./select-recipient.component.html",
  styleUrls: ["./select-recipient.component.scss"],
})

export class SelectRecipientComponent implements OnInit, OnDestroy {
  @Output() onCloseModal = new EventEmitter();
  @Output() getRecipients = new EventEmitter();
  public contacts : any = [];
  public hasSelectedAllConatcts: boolean = false;
  public selectedContacts: any = [];
  public recipientsAddress:any=[];
  public enableAddRecipientBtn: boolean = false;
  public searchText: string = "";
  public addEmail:string='';
  public contactsSub: Subscription;
  public moreThanFive:boolean=false;
  validEmail: boolean=true;
  cspShowSpinner: boolean = false;
  errorResponse: ErrorResponse;



  constructor(private addressBookService: AddressBookService) {}


  ngOnInit() {
    
    this.cspShowSpinner = true;
    this.contactsSub = this.addressBookService.fetchAddressBook().subscribe((fetchedContacts: Contact[]) =>
    {
    this.cspShowSpinner = false;
    this.contacts = fetchedContacts;
    },
    (error: ErrorResponse) => {
      this.cspShowSpinner = false;

      this.errorResponse = error;
    } );
    
   
  }


  public hideModal() {
    this.onCloseModal.emit();
    this.selectedContacts = [];
    this.contacts.forEach(element => {
      element.selected = false;
    });
    this.hasSelectedAllConatcts = false;
  }

  public addEmailAddress() :void{
    this.recipientsAddress.push(this.addEmail);
    this.addEmail='';
    if((this.selectedContacts.length + this.recipientsAddress.length) >5){

      this.moreThanFive=true;
    }
    else{
      this.moreThanFive=false;

    }
  }

  removeRecipient(index:number) :void{
    this.recipientsAddress.splice(index,1);
    if((this.selectedContacts.length + this.recipientsAddress.length) > 5){

      this.moreThanFive=true;
    }
    else{
      this.moreThanFive=false;

    }
    }

public validateEmail(email){
  
  const emailValidationPattern = new RegExp(['^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)',
  '|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}',
  '\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'].join(''));
  
  // this.validEmail=emailValidationPattern.test(email)
  if(emailValidationPattern.test(email)){
    this.validEmail=true;
  }
  else{
    this.validEmail=false;
  }

  if((this.recipientsAddress.length) >5){
    this.moreThanFive=true;
  } else {
    this.moreThanFive=false;
  }

  

}

  public selectAllContacts() {
    this.selectedContacts = [];
    
    for (var i = 0; i < this.contacts.length; i++) {
      this.contacts[i].selected = this.hasSelectedAllConatcts;

    }

    this.contacts.forEach((element) => {
      if (element.selected) {
        this.selectedContacts.push(element);
      }
    });
    
    if((this.selectedContacts.length + this.recipientsAddress.length) >5){

      this.moreThanFive=true;
    }
    else{
      this.moreThanFive=false;

    }


  }


  public selectContact(value) {
    
    const index = this.selectedContacts.indexOf(value);
    index === -1 ? this.selectedContacts.push(value) : this.selectedContacts.splice(index, 1);
    
   if((this.selectedContacts.length + this.recipientsAddress.length) > 5){

      this.moreThanFive=true;
    }
    else{
      this.moreThanFive=false;

    }
  }


  public addRecipients() {
  
    var selectedRecipients: Contact[]=[] ;
    this.selectedContacts.forEach(value => {
      const { contactId, firstName, lastName, email } = value;
      selectedRecipients.push({ contactId, firstName, lastName, email });
    });

    this.recipientsAddress.forEach(value => {
    selectedRecipients.push({
      contactId: null,
      firstName: '',
      lastName: '',
      email: value
      });
    });
    
    let contactResponse : ContactResponse;
    contactResponse = {
      contactList : selectedRecipients,
      manuallyAdded : this.recipientsAddress.length,
      fromAddressBook : this.selectedContacts.length,
      totalAdded : selectedRecipients.length
    };
    this.getRecipients.emit(contactResponse);
    this.onCloseModal.emit();
    this.hasSelectedAllConatcts = false;
  }


  ngOnDestroy(): void {
    this.contactsSub.unsubscribe();
  }


}
