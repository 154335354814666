// This file will be copied to environment.ts during build and during deploument it will read from User Provided Vars in PCF Cardinal App Manager


let xapikey;
let baseApiUrl;
let baseRoutingUrl;
let baseMarketHelpUrl;
var prodFlag;
var liveChatEndpoint; 
var liveChatSuffix;
var pureconnectChatBus;
var pureconnectWidgetHive;
var pureconnectWidgetsData;

const xapikeyDev = 'nxW2IQtimGawX55MSAwVC1TmA5AQidYw1C5Fg7Ti';
const baseApiUrlDev = 'https://api.dev.cardinalhealth.com';
const baseRoutingUrlDev = 'https://marketdev.cardinalhealth.com';
const baseMarketHelpUrlDev = 'https://markethelpdev.cardinalhealth.com';
const liveChatEndpointDev = 'https://wdoh0019ininm01.dev.npch.int:8443';
const liveChatSuffixDev = '/I3PortalChat/chatOrCallback.html';
const pureconnectChatBusDev = 'https://d2uinmo2bcbdsn.cloudfront.net/pureconnect-widgets/35/cxbus.min.js';
const pureconnectWidgetHiveDev ='https://widgets.hive.genesys.com';
const pureconnectWidgetsDataDev = 'eyJpZCI6Ik1hcmtldCBIZWxwIENoYXQiLCJpY1NlcnZlcnMiOlsid3NvaDAwMTBpbmluYzAxLnN0Zy5jYXJkaW5hbGhlYWx0aC5uZXQiXSwicmV2ZXJzZVByb3h5VXJsIjoiaHR0cHM6Ly9wbW9kLWluaW4uc3RnLmNhcmRpbmFsaGVhbHRoLm5ldC9jbGllbnQvYXBpIiwidXNlQ2FhUyI6ZmFsc2UsImNhYXNVcmwiOm51bGwsInVzZUh0dHBzIjp0cnVlfQ';

const xapikeyStg = 'nxW2IQtimGawX55MSAwVC1TmA5AQidYw1C5Fg7Ti';
const baseApiUrlStg = 'https://api.stage.cardinalhealth.com';
const baseRoutingUrlStg = 'https://marketstg.cardinalhealth.com';
const baseMarketHelpUrlStg = 'https://markethelpstg.cardinalhealth.com';
const liveChatEndpointStg = 'https://pmod-inin.stg.cardinalhealth.net';
const liveChatSuffixStg = '/crm-cic_I3PortalChat/chatOrCallback.html';
const pureconnectChatBusStg = 'https://d2uinmo2bcbdsn.cloudfront.net/pureconnect-widgets/35/cxbus.min.js';
const pureconnectWidgetHiveStg ='https://widgets.hive.genesys.com';
const pureconnectWidgetsDataStg = 'eyJpZCI6Ik1hcmtldCBIZWxwIENoYXQiLCJpY1NlcnZlcnMiOlsid3NvaDAwMTBpbmluYzAxLnN0Zy5jYXJkaW5hbGhlYWx0aC5uZXQiXSwicmV2ZXJzZVByb3h5VXJsIjoiaHR0cHM6Ly9wbW9kLWluaW4uc3RnLmNhcmRpbmFsaGVhbHRoLm5ldC9jbGllbnQvYXBpIiwidXNlQ2FhUyI6ZmFsc2UsImNhYXNVcmwiOm51bGwsInVzZUh0dHBzIjp0cnVlfQ';

const xapikeyProd = 'bXs8JgIw1l7vIDIQNBDvbVgQHewcIwv4rYuQTMr4';
const baseApiUrlProd = 'https://api.cardinalhealth.com';
const baseRoutingUrlProd = 'https://market.cardinalhealth.com';
const baseMarketHelpUrlProd = 'https://markethelp.cardinalhealth.com';
const liveChatEndpointProd = 'https://pmod-inin.cardinalhealth.net';
const liveChatSuffixProd = '/crm-cic_I3PortalChat/chatOrCallback.html';
const pureconnectChatBusProd = 'https://d2uinmo2bcbdsn.cloudfront.net/pureconnect-widgets/35/cxbus.min.js';
const pureconnectWidgetHiveProd ='https://widgets.hive.genesys.com';
const pureconnectWidgetsDataProd = 'eyJpZCI6Ik1hcmtldCBIZWxwIENoYXQiLCJpY1NlcnZlcnMiOlsiaW5pbjEiLCJpbmluMiJdLCJyZXZlcnNlUHJveHlVcmwiOiJodHRwczovL3Btb2QtaW5pbi5jYXJkaW5hbGhlYWx0aC5uZXQvd2lkZ2V0Y2hhdC9hcGkiLCJ1c2VDYWFTIjpmYWxzZSwiY2Fhc1VybCI6bnVsbCwidXNlSHR0cHMiOnRydWV9';
  
  if (location.hostname.includes('market.cardinalhealth')) {
    xapikey = xapikeyProd;
    baseApiUrl = baseApiUrlProd;
    baseRoutingUrl = baseRoutingUrlProd;
    baseMarketHelpUrl = baseMarketHelpUrlProd;
    liveChatEndpoint = liveChatEndpointProd;
    liveChatSuffix = liveChatSuffixProd;
    prodFlag = true;
    pureconnectChatBus = pureconnectChatBusProd;
    pureconnectWidgetHive = pureconnectWidgetHiveProd;
    pureconnectWidgetsData = pureconnectWidgetsDataProd;

  }else if (location.hostname.includes('marketstg.cardinalhealth')) {
    xapikey = xapikeyStg;
    baseApiUrl = baseApiUrlStg;
    baseRoutingUrl = baseRoutingUrlStg;
    baseMarketHelpUrl = baseMarketHelpUrlStg;
    liveChatEndpoint = liveChatEndpointStg;
    liveChatSuffix = liveChatSuffixStg;
    prodFlag = false;
    pureconnectChatBus = pureconnectChatBusStg;
    pureconnectWidgetHive = pureconnectWidgetHiveStg;
    pureconnectWidgetsData = pureconnectWidgetsDataStg;

  } else {
    xapikey = xapikeyDev;
    baseApiUrl = baseApiUrlDev;
    baseRoutingUrl = baseRoutingUrlDev;
    baseMarketHelpUrl = baseMarketHelpUrlDev;
    liveChatEndpoint = liveChatEndpointDev;
    liveChatSuffix = liveChatSuffixDev;
    prodFlag =false;
    pureconnectChatBus = pureconnectChatBusDev;
    pureconnectWidgetHive = pureconnectWidgetHiveDev;
    pureconnectWidgetsData = pureconnectWidgetsDataDev;
  }
  
  
  export const environment= {
    production: prodFlag,
    xapikey:xapikey,
    baseApiUrl: baseApiUrl,
    baseRoutingUrl: baseRoutingUrl,
    baseMarketHelpUrl: baseMarketHelpUrl,
    liveChatEndpoint: liveChatEndpoint,
    liveChatSuffix: liveChatSuffix,
    pureconnectChatBus : pureconnectChatBus,
    pureconnectWidgetHive : pureconnectWidgetHive,
    pureconnectWidgetsData : pureconnectWidgetsData
  };


