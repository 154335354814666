<div class="multiselect-dropdown" (click)="toggleOpen()" data-id="multiselect-dropdown">
  <span class="multiselect-display-message" data-id="multiselect-display-message">{{displayMessage}}</span>
  <span class="multiselect-toggle-btn" data-id="multiselect-toggle-btn" ></span>
</div>

<div class="multiselect-dropdown-opened" *ngIf="isOpen">
  <div class="clearfix"></div>
  <div class="multiselect-dropdown-search-box">
    <input class="multiselect-dropdown-search-input" placeholder="Search" (input)="search()" data-id="multiselect-dropdown-search-input" [(ngModel)]="searchTerm" ngDefaultControl [ngModelOptions]="{standalone: true}" />
    <span class="multiselect-dropdown-search-icon" data-id="multiselect-dropdown-search-icon" ></span>
  </div>
  <div class="clearfix"></div>
  <ul class="multiselect-dropdown-list" data-id="multiselect-dropdown-list" >
      <ng-container *ngFor="let item of viewableItems">
        <li class="multiselect-item">
          <div class="multiselect-cursor" data-id="multiselect-item-checkbox" (click)="handleClick(item)" >
            <div class="multiselect-check-input">
              <input type="checkbox" class="multiselect-item-checkbox" data-id="multiselect-line-checked-item" [checked]="item.checked" />
              <div class="multiselect-item-checkbox-small"></div>
            </div>
            <div class="multiselect-item-text">
              <span data-id="multiselect-item-text">{{item.value}}</span>
            </div>
          </div>
        </li>
      </ng-container>
  </ul>
</div>
