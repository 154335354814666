import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';

@Component({
  selector: 'short-dated-product',
  templateUrl: './short-dated-product.component.html',
  styleUrls: ['./short-dated-product.component.scss']
})
export class ShortDatedProductComponent implements OnInit {

  @Input() caseCreateForm : FormGroup;
  @Input() formIndex : number = 0;
  @Input() actionItems : any;
  
  @Output() deleteFormItem : EventEmitter<any> = new EventEmitter<any>();
  date = new Date();
  shippedDate:Date;
  incorrectDateFlag: boolean;
  quantityReceived : Number;
  uomReceived : Number;
  actionRequest : any;
  constructor() {
      // This is intentional
  }

  ngOnInit() {
  this.getShippedDate();
  const maxDate=new Date(this.shippedDate.getTime() + 59 * 24 * 60 * 60 * 1000);
  this.date=maxDate;
  }
  getShippedDate(): any {
    let formArray=this.getFormArray();
    let formGroup=formArray.controls[this.formIndex] as FormGroup;
    let shippedDateItem = formGroup.controls["shipedDate"].value;
    this.shippedDate = new Date(shippedDateItem[0]);
    if(shippedDateItem[1]){
      this.shippedDate.setDate(this.shippedDate.getDate() + 7);
    }
  }
  
  

get formContols() :FormGroup{
  let formArray=this.getFormArray();
  let formGroup=formArray.controls[this.formIndex] as FormGroup;
  return formGroup
}
get itemDescription(){
  let formArray=this.getFormArray();
  let formGroup=formArray.controls[this.formIndex] as FormGroup;
  
  return formGroup.get('itemDescription').value;
  }
  get statusMessageList(){
    let formArray=this.getFormArray();
    let formGroup=formArray.controls[this.formIndex] as FormGroup;
    
      return formGroup.get('statusMessageList').value;
  }
  deleteForm() :void {
    let formArray=this.caseCreateForm.get('items') as FormArray;
    if(formArray.length>1) {
      const deletedItem = formArray.value[this.formIndex];
      this.deleteFormItem.emit(deletedItem);
      formArray.removeAt(this.formIndex);
    }
  }

  getFormArray() :FormArray {
    return this.caseCreateForm.get('items') as FormArray;
  }
  setInvalidDateflag(flagValue){
    this.incorrectDateFlag = flagValue;
  }
}
