<div id="invoiceSearchDiv">
    <div  class="spinner-main" [ngClass]="{'spinner-show': cspShowSpinner}">
      <div class="spinnerDiv" > 
      </div>
    </div>
    
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 invoicePart" #invoiceSearchSection>
      <div><label class="formLabel"><span class="red-text">*</span>Invoice #</label></div>
      <div class="col-md-3 col-lg-3 col-sm-5 col-xs-5 ">
         <div id="invoice-search-box" style="padding-right: 5px;">
            <ng-select class="custom" [items]="InvoiceDropdownValue"
            [(ngModel)]="selectedItem" [clearable]="false" [searchable]="false">
            </ng-select>
         </div>
      </div>
      <div class="col-md-9 col-lg-9 col-sm-7 col-xs-7 search-right-div">
    
         <input  id="search-invoice-box" autocomplete="off" #invoiceSearch 
            class="search-box invoiceInput" maxlength="10" numericOnly (keyup.enter)="searchInvoiceForInputValue(searchInputVal)"
            type="text" [(ngModel)]="searchInputVal" [ngModelOptions]="{standalone: true}"
            ngDefaultControl 
            placeholder="Key in your Invoice Number and press Enter" [ngClass]="{'has-error': showError}" />
         <span title="Clear" class="icon-close-red" (click)="clearInputString()"></span>
         <span class="vertical-devider"></span>
         <button title="Search" class="searchIconButton" (click)="searchInvoiceForInputValue(searchInputVal)">
           <span class="search-icon"></span>
          </button>
         
      </div>
    </div>
    
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 invoice-detail-block" *ngIf="isVisibleInvoiceDetail">
        <label class="formLabel">Invoice #:</label><span> {{ invoiceData.invoiceNumber}}</span><br>
        <label class="formLabel">Invoice Type:</label><span> {{ invoiceData.type}}</span><br>
        <label class="formLabel">Sold To:</label><span> {{ invoiceData.soldTo}}</span><br>
        <label class="formLabel">Ship To:</label><span> {{ invoiceData.shipTo}}</span><br>
        <label class="formLabel">Total Due:</label><span>  {{ invoiceData.totalDue}}</span><br>
        <label class="formLabel">Date Due:</label><span>  {{ invoiceData.dueDate}}</span><br>
        <label class="formLabel">PO #:</label><span>  {{ invoiceData.purchaseOrderNumber}}</span><br>
        <label class="formLabel">Order #:</label><span>  {{ invoiceData.orderNumber}}</span><br><br>
    
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 order-detail-block" *ngIf="isInvoiceSearchError">
        <span class="red-text">{{ invoiceSearchErrorMsg }}</span><br><br>
        <ul style="list-style-type: circle; padding-left: 30px;">
          <li>{{ invoiceSearchErrorMsgLine2 }}</li>
          <li>{{ invoiceSearchErrorMsgLine3 }}</li><br>
        </ul>
        
      </div>
    </div>
    