import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { CasesService } from '../../services/cases.service';
import { Account, ShipToListResponse } from '../../model/shipToListResponse';
import { CaseMasterData } from '../../model/caseMasterData';
import { IdValue } from '../../model/idValue';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { CaseSearchService } from '../../services/case-search.service';
import { CaseRequest } from '../../model/caseRequest';
import { AttachmentService } from '../../services/attachment.service';
import { Attachment } from '../../model/attachment';
import { CaseResponse, ErrorResponse } from '../../model/caseResponse';
import { Location } from '@angular/common';
import { User } from "../../shared/User";
import { StatusRequestTypeMapping } from '../../constants/status-requestType.mapping.constants';
import { Contact, ContactResponse } from '../../model/contact';
import { CreateCaseErrors } from '../../constants/createCaseErrors'
import { LogService } from '../../services/log.service';
import { Title } from '@angular/platform-browser';
import { Item } from '../../model/Item';
import { OrderSearchComponentComponent } from './order-search-component/order-search-component.component';
import { RequestTypeStatusMapping } from '../../constants/requestType-status.mapping.constant';
import { InvoiceSearchComponentComponent } from './invoice-search-component/invoice-search-component.component';
import * as moment from 'moment';


@Component({
  selector: 'csp-create-case',
  templateUrl: './create-case.component.html',
  styleUrls: ['./create-case.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreateCaseComponent implements OnInit {
  isProductSection: boolean = false;
  isOrederResolution: boolean = false;
  isShippingDiscrepncy: boolean = false;
  isInvoiceIssues: boolean = false;
  
  isShippingDiscreapancyNumericZero: boolean = true;
  public shipToData: Array<any> = [];
  getCheckedValue :any = true;
  public issueType: Array<any> = [];
  public requestType: Array<any> = [];
  public missingFromOrder: Array<any> = [];
  public selectedMissingFromOrder: any;
  public selectedRequestType: any;
  public selectedIssueType: any = null;
  public isProductComplaints: boolean = false;
  public isIncorrectMaterialDelivered: boolean = false;
  public wasSometingMissing: boolean = false;
  public isIncorrectDeliveryLocation: boolean = false;
  public isQuantityDamaged: boolean = false;
  public isShortDatedProductForm: boolean = false;
  public isExpiredProductForm: boolean = false;
  public isPricingDiscrepancy: boolean = false;
  public isIncorrectFees: boolean = false;
  public isRecipientModalVisible: boolean = false;
  public isChecked: boolean = false;
  public errorCreateCase: boolean = false;
  public selectedContactsList: Contact[] = [];
  public selectedContactResponse: ContactResponse;
  isSubmitCaseModalVisible: boolean = false;
  public isPOSearchVisible: boolean = false;
  public showproductQuestionCheckBox: boolean = false;
  public productRelatedQuestion =
                {
                  selected:false,
                  message:"I have a question not related to an existing order."
                };

  name: string = "Continue Case Submit";
  heading: string = "Submit a Case";
  message: string = "If you cancel before submitting the case, you will lose any changes you have made.";
  masterData: CaseMasterData;
  caseCreateForm: FormGroup;
  attachments: Array<Attachment> = [];
  isAttachmentModalVisible: boolean = false;
  errorResponse;
  errMessage: string = "";
  selectedShipTo: any = null;
  submittedCaseId: string;
  shipTo: string;
  recipientsEmail: Array<string> = Array();
  canAddForms: boolean = false;
  shippingDiscreapancyActionitems = {
    option1: Object,
    option2: Object
  }
  missingItemsFormActionitems = {
    option1: Object,
    option2: Object
  }
  productComplaintsActionitems = {
    option1: Object,
    option2: Object
  }
  expiredProductActionitems = {
    option1: Object,
    option2: Object
  }

  allowDecimal: boolean = true;
  isOrderInquiryForm: boolean = false;
  isShippingDiscrepancyForm: boolean = false;
  isProductQuestionForm: boolean = false;
  isInvoiceIssueForm: boolean = false;
  isFormChanged: boolean = false;
  
  @ViewChild(OrderSearchComponentComponent) orderSearchComponent: OrderSearchComponentComponent;
  @ViewChild(InvoiceSearchComponentComponent) invoiceSearchComponent: InvoiceSearchComponentComponent;
  
  isCancel: boolean = false;
  actionRequestOptions: Map<string, object> = new Map<string, object>();

  public user: any;
  public isCreateCaseFromOrderDetails: boolean = false;
  public lineItems:any;
  cspShowSpinner: boolean = false;
  isIncorrectPO: boolean = false;
  isIncorrectPartner: boolean = false;
  navigationURL = '';
  isNavigateAway: boolean = false;
  attachmentsFormB: Array<Attachment> = [];
  isFromFormB: boolean = false;
  materialList: any=[];
  showSelectMaterialModal: boolean = false;
  selectMaterialColums: string[];
  selectMaterialColumsLabel: string[];
  addItemButtonName: string;
  selectedOrderDetails: any;
  isShiptoVisible: boolean;
  poNumber: string;
  highlightedText:any = 'black';
  checkValid: boolean = false;
  isInvoiceSearchVisible: boolean;
  currency = new Intl.NumberFormat('en-US', {style: 'currency',currency: 'USD'});
  isReturnForm: boolean;
  isNoLongerNeededForm: boolean;
  isPurchasedTooManyForm: boolean;
  isProductRecallForm: boolean;
  isIncorrectProductOrderedForm: boolean;
  isReturnShippingDiscForm: boolean;
  isReturnShortDatedProductForm: boolean;
  isReturnExpiredProductForm: boolean;
  isReturnShippingOveragesForm: boolean;
  showRestockingAcknowledge: boolean;
  isReturnIncorrectDeliveryLocation: boolean;
  isReturnIncorrectMaterialDelivered: boolean;
  invoiceFlag: boolean = false;
  // selectedIssueTypeRequired: boolean;
  // selectedRequestTypeRequired: boolean;
  // poNumberRequired: boolean;
  // constructor
  constructor(
    private titleService: Title,
    private router: Router,
    private casesService: CasesService,
    private formBuilder: FormBuilder,
    private caseSearchService: CaseSearchService,
    private attachmentService: AttachmentService,
    private location: Location,
    private route: ActivatedRoute,
    private logService: LogService,
 
  ) {
    const sharedAttachment = this.attachmentService.sharedAttachment;
    // If any shared attachment exists
    if (sharedAttachment) {
      this.attachments = this.attachments.concat(sharedAttachment);
    }
    this.createCaseForm();


  }

  ngOnInit() {
    this.user = User.getUser();
    this.getShipTo();
    this.getMasterData();
    window.dispatchEvent(new Event('formClean'));
    window.addEventListener('navigationPrevented', this.updateShowModalStatus.bind(this));
    this.route.queryParams.subscribe((params: Params) => {
      if (params["orderNumber"] && params["orderNumber"] != null) {
        this.isCreateCaseFromOrderDetails = true;
        const lineItemsFromLocalStorage = JSON.parse(localStorage.getItem("lineItemDetails"));
        this.convertPayload(lineItemsFromLocalStorage);
      }
    });
    this.titleService.setTitle("Create a Case");
  }
    
  public convertPayload(lineItemsFromLocalStorage: any) {
    if (lineItemsFromLocalStorage != null) {
      for (const lineItem of lineItemsFromLocalStorage.lineItems) {
        var RT_List = [];
        for (let i = 0; i < lineItem.fulfillment.length; i++) {
          var RT_List_local = [];
          var status = lineItem.fulfillment[i].status;
          var statusMap = StatusRequestTypeMapping.STATUS_REQ_TYPE_MAP;
          RT_List_local = (statusMap.get(status));
          if (RT_List.length === 0) {
            if(RT_List_local) {
              RT_List = RT_List_local;
            }else{
              const temp_RT_List = (statusMap.get("DefaultStatus"));
              RT_List = temp_RT_List
            }
          }
          else if(RT_List_local) {
            RT_List_local.forEach(req => {
              if (!(RT_List.some(el => el.id === req.id))) {
                RT_List.push(req);
              }
            }
            );
          }
        }
        if (RT_List) {
          lineItem.requestTypes = RT_List;
        }

      }

    }
    this.lineItems = lineItemsFromLocalStorage;
  }


  updateShowModalStatus(event) {
    window.dispatchEvent(new CustomEvent('cah:spinnerHide'));
    if (this.isFormChanged) {
      if (event.detail) {
        this.navigationURL = event.detail;
      }
      event.preventDefault();
      event.stopPropagation();
      this.isNavigateAway = true;
      this.isSubmitCaseModalVisible = true;
    }
  }

  onCancel(event: MouseEvent) {
    this.isCancel = true;
    if (!this.selectedIssueType) {
      event.preventDefault();
      event.stopPropagation();
      this.navigateToLandingPage();
    }
    else {
      this.showDiscardModal(event);
    }

  }

  navigateToLandingPage() {
    this.router.navigate(['/help']);
  }

  /**
   * showAttachmentModal
   */
  showDiscardModal(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isSubmitCaseModalVisible = true;
  }

  /**
   * hideAttachmentModal
   */
  hideDiscardModal() {
    this.isCancel = false;
    this.isSubmitCaseModalVisible = false;
  }



  //This method is to get master data from API
  getMasterData(): void {
    this.errMessage = CreateCaseErrors.MASTERDATA_ERROR_MSG;
    this.errorCreateCase = false;
    this.casesService.getCaseMasterData().subscribe((data: CaseMasterData) => {
      this.masterData = data;
      this.getIssueTypes(data);
    }, (error) => {
      this.errorCreateCase = true;
      this.errorResponse = error;
    });

  }

  //This method is to get shipto data from service
  getShipTo(): void {
    this.errMessage = CreateCaseErrors.SHIPTO_ERROR_MSG;

    this.errorCreateCase = false;
    this.cspShowSpinner = true;

    this.shipToData = [];
    this.caseSearchService.getShipToValues().subscribe((data: ShipToListResponse) => {
      this.cspShowSpinner = false;
      data.shipTos.forEach((element: Account) => {
        this.shipToData.push({ id: element.id, text: element.id + ' - ' + element.name });
      })
      this.shipToData.sort((a, b) => 0 - (a.id > b.id ? -1 : 1));
    }, (error: ErrorResponse) => {
      this.cspShowSpinner = false;
      this.errorCreateCase = true;
      this.errorResponse = error;
    });

  }

  /**
   * This method is to get issue types from master data
   */

  getIssueTypes(masterData): void {

    this.issueType = [];
    masterData.caseCategories.forEach(element => {
      if (element.category.id != 'CA1_155' && element.category.id != 'CA1_152') {
        this.issueType.push({ id: element.category.id, text: element.category.value });
      }
    })
  }

  loadRequestType(issueType: any): void {
     this.changeForm();
    this.showproductQuestionCheckBox = false;
    this.productRelatedQuestion.selected = false;
    this.selectedRequestType = null;
    this.canAddForms = false;
    this.requestType = [];
    this.missingFromOrder = [];
    this.selectedMissingFromOrder = null;
    this.selectedOrderDetails = null;
    for (let i = 0; i < this.masterData.caseCategories.length; i++) {
      if (this.masterData.caseCategories[i].category.id == issueType.id) {
        this.masterData.caseCategories[i].caseSubCategories.forEach((requestType: IdValue) => {
          this.requestType.push({ id: requestType.id, text: requestType.value });
        });
        break;
      }
    }
    this.masterData.actionRequest.forEach((data) => {
      this.actionRequestOptions.set(data.caseSubCategoryId, data.actionRequestedOptions);
    });
    this.resetComponentInputsAndFlags();
    this.resetFormOnClickOfSearch();
  }

  reCreateForm(selectedMaterialData:any) {

    if (this.selectedIssueType.id == 'CA1_150') {
      this.createOrderInquiryForm(selectedMaterialData);
      this.isOrderInquiryForm = true;
      this.canAddForms = true;
    }
    else if (this.selectedIssueType.id == 'CA1_151') {
      if (this.selectedRequestType.id == 'RT_8') {
        this.createProductQuestionsWithComplaints(selectedMaterialData);
      }
      else {
        if(this.productRelatedQuestion.selected){
          this.createProductQuestionsWithoutOrderDetails();
        }else{
          this.createProductQuestionsWithoutComplaints(selectedMaterialData);
        }
      }
      this.canAddForms = true;
      this.isProductQuestionForm = true;
    }
    else if (this.selectedIssueType.id == 'CA1_154') {
      this.isShippingDiscrepancyForm = true;
      this.canAddForms = true;
      if (this.selectedRequestType.id === 'RT_18') {
        this.isQuantityDamaged = true;
      }
      if (this.selectedRequestType.id === 'RT_19') {
        this.isIncorrectMaterialDelivered = true;
        this.isShippingDiscrepancyForm = true;
        this.canAddForms = true;
      }
      if (this.selectedRequestType.id === 'RT_20') {
        this.isIncorrectDeliveryLocation = true;

        this.canAddForms = true;
      }
      if(this.selectedRequestType.id === 'RT_22'){
        this.isShortDatedProductForm = true;
        this.isShippingDiscrepancyForm = false;
      }
      if(this.selectedRequestType.id === 'RT_23'){
        this.isExpiredProductForm = true;
        this.isShippingDiscrepancyForm = false;
      }
      this.createShippingDiscrepancyForm(selectedMaterialData);
    }
    else if (this.selectedIssueType.id == 'CA1_153') {
      if (this.selectedRequestType.id == 'RT_11') {
        this.canAddForms = true;
        this.createInvoiceIssueFormForPricingDiscrepancy(selectedMaterialData);
      } else if (this.selectedRequestType.id == 'RT_12') {
        this.createInvoiceIssueFormForIncorrectFee();
      } else if (this.selectedRequestType.id == 'RT_9') {
        this.createInvoiceIssueFormForIncorrectPO(selectedMaterialData);
      }
      else if (this.selectedRequestType.id == 'RT_10') {
        this.createInvoiceIssueFormForIncorrectPartner(selectedMaterialData);
      }
      this.isInvoiceIssueForm = true;
    }
    else if(this.selectedIssueType.id == 'CA1_116'){
      this.isReturnForm = true;
      this.isReturnShippingDiscForm = false;
      if(this.selectedRequestType.id == 'RT_27'){
        this.isNoLongerNeededForm = true;
        this.showRestockingAcknowledge = true;
        this.highlightedText = 'black';
        this.checkValid = false;
      }else if(this.selectedRequestType.id == 'RT_28'){
        this.isPurchasedTooManyForm = true;
        this.showRestockingAcknowledge = true;
        this.highlightedText = 'black';
        this.checkValid = false;
      }else if(this.selectedRequestType.id == 'RT_29'){
        this.isIncorrectProductOrderedForm = true;
        this.showRestockingAcknowledge = true;
        this.highlightedText = 'black';
        this.checkValid = false;
      }
      else if(this.selectedRequestType.id == 'RT_30'){
        this.isProductRecallForm = true;
        this.showRestockingAcknowledge = false;
      }else if(this.selectedRequestType.id == 'RT_31'){
        //Bug Fix RF-388
        this.isReturnIncorrectMaterialDelivered = false;
        this.showRestockingAcknowledge = false;
        this.isReturnShippingDiscForm = true;
        this.isQuantityDamaged = true;
      }else if(this.selectedRequestType.id == 'RT_32'){
        this.showRestockingAcknowledge = false;
        this.isReturnExpiredProductForm =  true;
      }else if(this.selectedRequestType.id == 'RT_33'){
        this.isReturnIncorrectDeliveryLocation = true;
        this.isReturnIncorrectMaterialDelivered = false;
        this.isReturnShippingDiscForm = true;
      }else if(this.selectedRequestType.id == 'RT_34'){
        this.isReturnIncorrectMaterialDelivered = true;
        this.isReturnIncorrectDeliveryLocation = false;
        this.isReturnShippingDiscForm = true;
      }
      else if(this.selectedRequestType.id == 'RT_35'){
        this.showRestockingAcknowledge = false;
        this.isReturnShippingDiscForm = true;
        this.isReturnShippingOveragesForm = true;
      }else if(this.selectedRequestType.id == 'RT_36'){
        this.showRestockingAcknowledge = false;
        this.isReturnShortDatedProductForm = true;
      }
      this.canAddForms = true;
      this.createReturnForm(selectedMaterialData);
    }
  }

  checkboxValue(check : any){
    this.getCheckedValue = check.target.checked;
    this.checkValid = this.getCheckedValue ? true : false;
}

  createInvoiceIssueFormForPricingDiscrepancy(selectedInvoiceData:any): void {
    this.caseCreateForm.addControl('invoiceId', new FormControl(selectedInvoiceData.invoiceNumber, [Validators.required, Validators.minLength(10)]));
    this.caseCreateForm.addControl('items', new FormArray([this.addPricingDiscrepancyitems(selectedInvoiceData)]));
  }

  createInvoiceIssueFormForIncorrectFee(): void {
    this.caseCreateForm.addControl('invoiceId', new FormControl('', [Validators.required, Validators.minLength(10)]));
    this.caseCreateForm.addControl('incorrectFeeName', new FormControl('', [Validators.required]));
  }
  createInvoiceIssueFormForIncorrectPO(selectedInvoiceData:any): void {
    this.caseCreateForm.addControl('invoiceId', new FormControl('', [Validators.required, Validators.minLength(10)]));
    this.caseCreateForm.addControl('originalPurchaseOrderNumber', new FormControl(selectedInvoiceData.purchaseOrderNumber, Validators.required));
    this.caseCreateForm.addControl('originalAccountNumber', new FormControl(selectedInvoiceData.soldTo.id));
    this.caseCreateForm.addControl('originalSolToShipTo', new FormControl(selectedInvoiceData.shipTo.id));
    this.caseCreateForm.addControl('revisedPurchaseOrderNumber', new FormControl('', Validators.required));
    this.caseCreateForm.addControl('revisedAccountNumber', new FormControl(''));
    this.caseCreateForm.addControl('revisedSolToShipTo', new FormControl(''));
  }
  createInvoiceIssueFormForIncorrectPartner(selectedInvoiceData:any): void {
    this.caseCreateForm.addControl('invoiceId', new FormControl('', [Validators.required, Validators.minLength(10)]));
    this.caseCreateForm.addControl('originalPurchaseOrderNumber', new FormControl(selectedInvoiceData.purchaseOrderNumber));
    this.caseCreateForm.addControl('originalAccountNumber', new FormControl(selectedInvoiceData.soldTo.id, Validators.required));
    this.caseCreateForm.addControl('originalSolToShipTo', new FormControl(selectedInvoiceData.shipTo.id));
    this.caseCreateForm.addControl('revisedPurchaseOrderNumber', new FormControl(''));
    this.caseCreateForm.addControl('revisedAccountNumber', new FormControl('', Validators.required));
    this.caseCreateForm.addControl('revisedSolToShipTo', new FormControl(''));
  }

  createProductQuestionsWithComplaints(selectedMaterialData:any): void {
    this.caseCreateForm.addControl('purchaseOrderNumber', new FormControl(this.selectedOrderDetails.orderData.purchaseOrderNumber));
    this.caseCreateForm.addControl('items', new FormArray([this.addPrductComplaintsItem(selectedMaterialData)]));
  }

  createProductQuestionsWithoutComplaints(selectedMaterialData:any): void {
    this.caseCreateForm.addControl('purchaseOrderNumber', new FormControl(this.selectedOrderDetails.orderData.purchaseOrderNumber, [Validators.required]));
    this.caseCreateForm.addControl('items', new FormArray([this.addCommonItems(selectedMaterialData)]));
  }
  createProductQuestionsWithoutOrderDetails(){
    this.caseCreateForm.addControl('purchaseOrderNumber', new FormControl(''));
    this.caseCreateForm.addControl('items', new FormArray([this.addProductQuestionItemsWithoutOrderDetails()]));

  }

  createOrderInquiryForm(selectedMaterialData:any): void {
    this.caseCreateForm.addControl('purchaseOrderNumber', new FormControl(this.selectedOrderDetails.orderData.purchaseOrderNumber, [Validators.required]));
    this.caseCreateForm.addControl('items', new FormArray([this.addCommonItems(selectedMaterialData)]));
  }

  createShippingDiscrepancyForm(selectedMaterialData:any): void {
    if (this.isIncorrectMaterialDelivered) {
      this.caseCreateForm.addControl('purchaseOrderNumber', new FormControl(this.selectedOrderDetails.orderData.purchaseOrderNumber, [Validators.required]));
      this.caseCreateForm.addControl('items', new FormArray([this.addShippingDiscrepencyIncorrectMaterialItems()]));
      if(this.wasSometingMissing){
        this.caseCreateForm.addControl('missingItems', new FormArray([this.addShippingDiscrepencyMissingItems(selectedMaterialData)]));
      }
    } else if (this.isIncorrectDeliveryLocation) {
      this.caseCreateForm.addControl('purchaseOrderNumber', new FormControl(''));
      this.caseCreateForm.addControl('items', new FormArray([this.addShippingDiscrepencyIncorrectDeliveryItems()]));
    } else if (this.isQuantityDamaged) {
      this.caseCreateForm.addControl('purchaseOrderNumber', new FormControl(this.selectedOrderDetails.orderData.purchaseOrderNumber, [Validators.required]));
      this.caseCreateForm.addControl('items', new FormArray([this.addShippingDiscrepencyQtyDamagedItems(selectedMaterialData)]));
    } else if (this.isShortDatedProductForm) {
      this.caseCreateForm.addControl('purchaseOrderNumber', new FormControl(this.selectedOrderDetails.orderData.purchaseOrderNumber, [Validators.required]));
      this.caseCreateForm.addControl('items', new FormArray([this.addShortDatedProductItems(selectedMaterialData)]));
    } else if(this.isExpiredProductForm){
      this.caseCreateForm.addControl('purchaseOrderNumber', new FormControl(this.selectedOrderDetails.orderData.purchaseOrderNumber,  [Validators.required])); 
      this.caseCreateForm.addControl('items', new FormArray([this.addExpiredProductItems(selectedMaterialData)]));
      }
    else {
      this.caseCreateForm.addControl('purchaseOrderNumber', new FormControl(this.selectedOrderDetails.orderData.purchaseOrderNumber, [Validators.required]));
      this.caseCreateForm.addControl('items', new FormArray([this.addShippingDiscrepencyItems(selectedMaterialData)]));
    }

  }

  createReturnForm(selectedMaterialData:any): void{
    if(this.isNoLongerNeededForm || this.isPurchasedTooManyForm || this.isIncorrectProductOrderedForm){
      this.caseCreateForm.addControl('purchaseOrderNumber', new FormControl(this.selectedOrderDetails.orderData.purchaseOrderNumber, [Validators.required]));
      this.caseCreateForm.addControl('restockingFeeAcknowledge', new FormControl('', [Validators.requiredTrue]));
      this.caseCreateForm.addControl('items', new FormArray([this.addReturnCommonItems(selectedMaterialData)]));
    }else if(this.isProductRecallForm){
      this.caseCreateForm.addControl('purchaseOrderNumber', new FormControl(this.selectedOrderDetails.orderData.purchaseOrderNumber, [Validators.required]));
      this.caseCreateForm.addControl('items', new FormArray([this.addReturnProductRecallItems(selectedMaterialData)]));
    }else if(this.isQuantityDamaged){
      this.caseCreateForm.addControl('purchaseOrderNumber', new FormControl(this.selectedOrderDetails.orderData.purchaseOrderNumber, [Validators.required]));
      this.caseCreateForm.addControl('items', new FormArray([this.addShippingDiscrepencyQtyDamagedItems(selectedMaterialData)]));
    }else if(this.isReturnShortDatedProductForm){
      this.caseCreateForm.addControl('purchaseOrderNumber', new FormControl(this.selectedOrderDetails.orderData.purchaseOrderNumber, [Validators.required]));
      this.caseCreateForm.addControl('items', new FormArray([this.addShortDatedProductItems(selectedMaterialData)]));
    }else if(this.isReturnExpiredProductForm){
      this.caseCreateForm.addControl('purchaseOrderNumber', new FormControl(this.selectedOrderDetails.orderData.purchaseOrderNumber, [Validators.required]));
      this.caseCreateForm.addControl('items', new FormArray([this.addExpiredProductItems(selectedMaterialData)]));
    }else if(this.isReturnShippingOveragesForm){
      this.caseCreateForm.addControl('purchaseOrderNumber', new FormControl(this.selectedOrderDetails.orderData.purchaseOrderNumber, [Validators.required]));
      this.caseCreateForm.addControl('items', new FormArray([this.addShippingDiscrepencyItems(selectedMaterialData)]));
    }else if(this.isReturnIncorrectDeliveryLocation){
      this.caseCreateForm.addControl('purchaseOrderNumber', new FormControl(''));
      this.caseCreateForm.addControl('items', new FormArray([this.addShippingDiscrepencyIncorrectDeliveryItems()]));
    }else if(this.isReturnIncorrectMaterialDelivered){
      this.caseCreateForm.addControl('purchaseOrderNumber', new FormControl(this.selectedOrderDetails.orderData.purchaseOrderNumber, [Validators.required]));
      this.caseCreateForm.addControl('items', new FormArray([this.addShippingDiscrepencyIncorrectMaterialItems()]));
      if(this.wasSometingMissing){
        this.caseCreateForm.addControl('missingItems', new FormArray([this.addShippingDiscrepencyMissingItems(selectedMaterialData)]));
      }
    }
  }
  submitButtonValidation(): boolean {
    let enableDisableFlag: boolean = true;
    if (this.isIncorrectDeliveryLocation || this.isReturnIncorrectDeliveryLocation) {
      if (this.attachments && this.attachments.length > 0) {
        enableDisableFlag = false;
      } else {
        const caseRequest: CaseRequest = this.caseCreateForm.value;

        for (let i = 0; i < caseRequest.items.length; i++) {
          if (caseRequest.items[i].itemId !== "" && caseRequest.items[i].itemId != null) {
            enableDisableFlag = false;
            break;
          }
        }
      }

    } else {
      enableDisableFlag = false;
    }
    return enableDisableFlag;
  }

  changeActionRequest(missingFromOrderData: any): void {

    if (missingFromOrderData.id === 'Yes') {
      this.resetFormFlags();
      this.removeAllControls();
      this.setDefaultControls();
      this.wasSometingMissing = true;
      if(this.selectedRequestType.id == 'RT_34'){
        this.isReturnIncorrectMaterialDelivered = true;
      }else{
        this.isIncorrectMaterialDelivered = true;
      }
      this.prepateMaterialLines(this.selectedOrderDetails);
      
    } else {
     
      this.wasSometingMissing = false;
      this.caseCreateForm.removeControl('items');
        this.caseCreateForm.removeControl('missingItems');
        this.caseCreateForm.patchValue({
          shipTo: this.selectedOrderDetails.orderData.shipTo,
          selectedIssueType: this.selectedIssueType.id,
          selectedRequestType: this.selectedRequestType.id,
          purchaseOrderNumber: this.selectedOrderDetails.orderData.purchaseOrderNumber
    
        });
      // this.caseCreateForm.get('shipTo').setValue(this.selectedOrderDetails.orderData.shipTo);
      // this.caseCreateForm.get('selectedIssueType').setValue(this.selectedIssueType.id);
      // this.caseCreateForm.get('selectedRequestType').setValue(this.selectedRequestType.id);
      // this.caseCreateForm.get('purchaseOrderNumber').setValue(this.selectedOrderDetails.orderData.purchaseOrderNumber);
     
      this.loadrequestTypeForm(this.selectedRequestType);
    }
   
  }
 
 loadPONumberComponent(requestType: any):void{
  
  this.missingFromOrder = [];
  this.selectedMissingFromOrder = null;
  this.isIncorrectDeliveryLocation = false;
  this.isReturnIncorrectDeliveryLocation = false;
  this.selectedShipTo = null;
  this.poNumber = "";
  this.selectedOrderDetails = null;
  this.productRelatedQuestion.selected = false;
  if(this.isPOSearchVisible){
    this.orderSearchComponent.ngOnInit();
  } 
  if(this.isInvoiceSearchVisible){
    this.invoiceSearchComponent.ngOnInit();
  }
  this.resetFormFlags();
  this.removeAllControls();
  this.setDefaultControls();
  this.selectedRequestType = requestType;
 
  if (this.selectedIssueType?.id == 'CA1_153'){
    this.resetComponentInputsAndFlags();
    this.isInvoiceSearchVisible = true;
    if (this.selectedRequestType.id === 'RT_11'){
      this.isPricingDiscrepancy = true;
    }
  }
  else if (this.selectedIssueType?.id == 'CA1_151') {
    if (this.selectedRequestType.id == 'RT_8') {
       this.showproductQuestionCheckBox = false;
    }
    else {
      this.showproductQuestionCheckBox = true;
      
    }
    this.isPOSearchVisible = true;
  }
  else if (this.selectedRequestType?.id === 'RT_20' || this.selectedRequestType?.id === 'RT_33') {
    this.isShiptoVisible = true;
    this.isPOSearchVisible = false;
    this.loadrequestTypeForm(this.selectedRequestType);
    this.isShippingDiscrepancyForm = false;
    this.isReturnForm = false;
    this.isReturnShippingDiscForm = false;
  }
  else{
    this.isPOSearchVisible = true;
  }

 }

 loadFormOnShipToSelect(shipTo: any){

   this.selectedShipTo = shipTo;
  if(this.selectedRequestType.id === 'RT_20'){
    this.isShippingDiscrepancyForm = true;
    this.caseCreateForm.patchValue({
      shipTo: shipTo.id,
      selectedIssueType: this.selectedIssueType.id,
      selectedRequestType: this.selectedRequestType.id
    });
    // this.caseCreateForm.get('shipTo').setValue(shipTo.id);
    // this.caseCreateForm.get('selectedIssueType').setValue(this.selectedIssueType.id);
    // this.caseCreateForm.get('selectedRequestType').setValue(this.selectedRequestType.id);
    
  }
  if(this.selectedRequestType.id === 'RT_33'){
    this.isReturnForm = true;
    this.isReturnShippingDiscForm = true;
    this.caseCreateForm.patchValue({
      shipTo: shipTo.id,
      selectedIssueType: this.selectedIssueType.id,
      selectedRequestType: this.selectedRequestType.id
    });
    // this.caseCreateForm.get('shipTo').setValue(shipTo.id);
    // this.caseCreateForm.get('selectedIssueType').setValue(this.selectedIssueType.id);
    // this.caseCreateForm.get('selectedRequestType').setValue(this.selectedRequestType.id);
    
  }
  if(this.showproductQuestionCheckBox && this.productRelatedQuestion.selected){
    this.caseCreateForm.patchValue({
      shipTo: shipTo.id,
      selectedIssueType: this.selectedIssueType.id,
      selectedRequestType: this.selectedRequestType.id
    });
    // this.caseCreateForm.get('shipTo').setValue(shipTo.id);
    // this.caseCreateForm.get('selectedIssueType').setValue(this.selectedIssueType.id);
    // this.caseCreateForm.get('selectedRequestType').setValue(this.selectedRequestType.id);

    this.loadrequestTypeForm(this.selectedRequestType);
  }
}

  loadrequestTypeForm(requestType: any,selectedMaterialData?:any): void {
    this.selectedRequestType = requestType;

    this.reCreateForm(selectedMaterialData);
    
    if (this.selectedRequestType.id === 'RT_8') {
      this.isProductComplaints = true;
      let options = this.actionRequestOptions.get(this.selectedRequestType.id);
      if (options) {
        this.productComplaintsActionitems.option1 = options[0];
        this.productComplaintsActionitems.option2 = options[1];
      }
    }
    else if (this.selectedRequestType.id === 'RT_11') {
      this.isPricingDiscrepancy = true;
      this.allowDecimal = false;
    }
    else if (this.selectedRequestType.id === 'RT_12') {
      this.isIncorrectFees = true;
    }
    else if (this.selectedRequestType.id === 'RT_9') {
      this.isIncorrectPO = true;
    }
    else if (this.selectedRequestType.id === 'RT_10') {
      this.isIncorrectPartner = true;
    }
    else if (this.selectedRequestType.id === 'RT_16') {
      this.isShippingDiscreapancyNumericZero = false;
      let options = this.actionRequestOptions.get(this.selectedRequestType.id);
      if (options) {
        this.shippingDiscreapancyActionitems.option1 = options[0];
        this.shippingDiscreapancyActionitems.option2 = options[1];
      }
    }
    else if (this.selectedRequestType.id === 'RT_17' || this.selectedRequestType.id === 'RT_18'
      || this.selectedRequestType.id === 'RT_19' || this.selectedRequestType.id === 'RT_20'
      || this.selectedRequestType.id === 'RT_35' || this.selectedRequestType.id === 'RT_33' || this.selectedRequestType.id === 'RT_34'
      || this.selectedRequestType.id === 'RT_31' || this.selectedRequestType.id === 'RT_36') {

      if (this.selectedRequestType.id === 'RT_19' || this.selectedRequestType.id === 'RT_34') {
        let options = this.actionRequestOptions.get(this.selectedRequestType.id);
        if (this.selectedMissingFromOrder.id === 'Yes') {
          if (options) {
            this.missingItemsFormActionitems.option1 = options[0];
            this.missingItemsFormActionitems.option2 = options[1];
            this.shippingDiscreapancyActionitems.option1 = options[2];
            this.shippingDiscreapancyActionitems.option2 = options[3];
          }
          
        }else{
          if(options){
            this.shippingDiscreapancyActionitems.option1 = options[2];
            this.shippingDiscreapancyActionitems.option2 = options[3];
          }
        }
       

      } else if (this.selectedRequestType.id === 'RT_20' || this.selectedRequestType.id === 'RT_33') {
        let options = this.actionRequestOptions.get(this.selectedRequestType.id);
        if (options)
          this.shippingDiscreapancyActionitems.option1 = options[0];
      } else {
        let options = this.actionRequestOptions.get(this.selectedRequestType.id);
        if (options) {
          this.shippingDiscreapancyActionitems.option1 = options[0];
          this.shippingDiscreapancyActionitems.option2 = options[1];
        }
      }
    }else if(this.selectedRequestType.id === 'RT_22'){
      let options = this.actionRequestOptions.get(this.selectedRequestType.id);
      if (options) {
        this.shippingDiscreapancyActionitems.option1 = options[0];
        this.shippingDiscreapancyActionitems.option2 = options[1];
      }
    }else if(this.selectedRequestType.id ==='RT_23' || this.selectedRequestType.id === 'RT_32'){
      let options = this.actionRequestOptions.get(this.selectedRequestType.id);
      if (options) {
        this.expiredProductActionitems.option1 = options[0];
        this.expiredProductActionitems.option2 = options[1];
      }
    }
    

    this.caseCreateForm.patchValue({
      requestType: requestType
    })


  }

  changeForm() {
    this.isFormChanged = true;
    window.dispatchEvent(new Event('formDirty'));
  }

  resetComponentInputsAndFlags(): void {
    this.isProductComplaints = false;
    this.isPricingDiscrepancy = false;
    this.isIncorrectFees = false;
    this.isIncorrectPO = false;
    this.isIncorrectPartner = false;
    this.isShippingDiscreapancyNumericZero = true;
    this.shippingDiscreapancyActionitems.option1 = null;
    this.shippingDiscreapancyActionitems.option2 = null;
    this.allowDecimal = true;
    this.wasSometingMissing = false;
    this.missingItemsFormActionitems.option1 = null;
    this.missingItemsFormActionitems.option2 = null;
    this.missingFromOrder = [];
    this.isPOSearchVisible = false;
    this.isInvoiceSearchVisible = false;
    this.resetFormFlags();
  }
  
  resetFormFlags() {
    this.isOrderInquiryForm = false;
    this.isProductQuestionForm = false;
    this.isShippingDiscrepancyForm = false;
    this.isInvoiceIssueForm = false;
    this.isShortDatedProductForm = false;
    this.isExpiredProductForm = false;
    this.canAddForms = false;
    this.isProductComplaints = false;
    this.isShiptoVisible = false;
    this.isQuantityDamaged = false;
    this.isIncorrectMaterialDelivered = false;
    this.isIncorrectDeliveryLocation = false;
    this.isReturnForm = false;
    this.isReturnShippingDiscForm = false;
    this.isNoLongerNeededForm = false;
    this.isPurchasedTooManyForm = false;
    this.isProductRecallForm = false;
    this.isIncorrectProductOrderedForm = false;
    this.isReturnShortDatedProductForm = false;
    this.isReturnExpiredProductForm =  false;
    this.isReturnShippingOveragesForm = false;
    this.showRestockingAcknowledge = false;
    this.isReturnIncorrectDeliveryLocation  = false;
   
  }
  createCaseForm() {
    this.caseCreateForm = this.formBuilder.group({

    });
    this.setDefaultControls();
  }

  removeAllControls() {
    Object.keys(this.caseCreateForm.controls).forEach(keyName => {

      if (keyName != 'comments') {
        if (keyName != 'shipTo') {
          this.caseCreateForm.removeControl(keyName);
        }
      }
    })
  }

  setDefaultControls() {
    const emailValidationPattern = new RegExp(['^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)',
      '|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}',
      '\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'].join(''));
    this.caseCreateForm.addControl('shipTo', new FormControl('', [Validators.required]));
    this.caseCreateForm.addControl('comments', new FormControl('', [Validators.required]));
    this.caseCreateForm.addControl('selectedIssueType', new FormControl('', [Validators.required]));
    this.caseCreateForm.addControl('selectedRequestType', new FormControl('', [Validators.required]));
    this.caseCreateForm.addControl('additionalEmails', new FormControl('', [Validators.pattern(emailValidationPattern)]));
  }

  addCommonItems(selectedMaterialData:any): FormGroup {
    return this.formBuilder.group({
      itemId: [selectedMaterialData?selectedMaterialData.materialNumber:'', Validators.required],
      quantityOrdered: [selectedMaterialData?selectedMaterialData.quantityOrdered:'', [Validators.required, Validators.pattern(/^0*([^0]\d*)$/), Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      uom: [selectedMaterialData?selectedMaterialData.unitOfMeasure:'', [Validators.required, Validators.pattern('^[a-zA-Z \-\']+')]],
      manufacturerName: [''],
      vendorMaterialNumber: [''],
      itemDescription: [{value:selectedMaterialData?selectedMaterialData.itemDescription:'',disabled:true}],
      statusMessageList: [{value:selectedMaterialData?selectedMaterialData.statusMessageList:'',disabled:true}],
    })
  }

  addProductQuestionItemsWithoutOrderDetails(){
    return this.formBuilder.group({
      itemId: ['', Validators.required],
      manufacturerName: [''],
      vendorMaterialNumber: [''],
    })
  }

  addPrductComplaintsItem(selectedMaterialData:any): FormGroup {
    return this.formBuilder.group({
      itemId: [selectedMaterialData?selectedMaterialData.materialNumber:'', Validators.required],
      quantityOrdered: [selectedMaterialData?selectedMaterialData.quantityOrdered:'', [Validators.required, Validators.pattern(/^0*([^0]\d*)$/), Validators.pattern(/^[0-9]*$/)]],
      uom: [selectedMaterialData?selectedMaterialData.unitOfMeasure:'', [Validators.required, Validators.pattern('^[a-zA-Z \-\']+')]],
      lotNo: ['', Validators.required],
      eventDate: ['', [Validators.required]],
      sampleAvailable: ['', Validators.required],
      wasThereInjury: ['', Validators.required],
      wasThereDeath: ['', Validators.required],
      actionRequest: ['', Validators.required],
      itemDescription: [{value:selectedMaterialData?selectedMaterialData.itemDescription:'',disabled:true}],
      statusMessageList: [{value:selectedMaterialData?selectedMaterialData.statusMessageList:'',disabled:true}],
    })
  }
  

  addPricingDiscrepancyitems(selectedMaterialData:any): FormGroup {
    return this.formBuilder.group({
      itemId: new FormControl(selectedMaterialData.materialNumber, Validators.required),
      quantityOrdered: new FormControl(selectedMaterialData.quantityOrdered, [Validators.required, Validators.pattern(/^0*([^0]\d*)$/), Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
      uom: new FormControl(selectedMaterialData.unitOfMeasurement, [Validators.required, Validators.pattern('^[a-zA-Z \-\']+')]),
      uomprice: [{value:selectedMaterialData.priceOfUOM,disabled:true}],
      customerExpectedPrice: new FormControl('', [Validators.required, Validators.pattern(/^\d*(?:\.\d{1,5})?$/)]),
      itemDescription: [{value:selectedMaterialData.shortDescription,disabled:true}],
    })
  }

  addShippingDiscrepencyItems(selectedMaterialData:any): FormGroup {
    return this.formBuilder.group({
      itemId: [selectedMaterialData?selectedMaterialData.materialNumber:'', Validators.required],
      quantityOrdered: [selectedMaterialData?selectedMaterialData.quantityOrdered:'', [Validators.required, Validators.min(1), Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      quantityReceived: ['', Validators.required],
      uom: [selectedMaterialData?selectedMaterialData.unitOfMeasure:'', [Validators.required, Validators.pattern('^[a-zA-Z \-\']+')]],
      uomReceived: ['', [Validators.required, Validators.pattern('^[a-zA-Z \-\']+')]],
      actionRequest: ['', Validators.required],
      itemDescription: [{value:selectedMaterialData?selectedMaterialData.itemDescription:'',disabled:true}],
      statusMessageList: [{value:selectedMaterialData?selectedMaterialData.statusMessageList:'',disabled:true}],

    })
  }

  addShippingDiscrepencyQtyDamagedItems(selectedMaterialData:any): FormGroup {
    return this.formBuilder.group({
      itemId: [selectedMaterialData?selectedMaterialData.materialNumber:'', Validators.required],
      quantityOrdered: [selectedMaterialData?selectedMaterialData.quantityOrdered:'', [Validators.required, Validators.min(1), Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      quantityDamaged: ['', Validators.required],
      uom: [selectedMaterialData?selectedMaterialData.unitOfMeasure:'', [Validators.required, Validators.pattern('^[a-zA-Z \-\']+')]],
      damageType: ['', Validators.required],
      actionRequest: ['', Validators.required],
      itemDescription: [{value:selectedMaterialData?selectedMaterialData.itemDescription:'',disabled:true}],
      statusMessageList: [{value:selectedMaterialData?selectedMaterialData.statusMessageList:'',disabled:true}],
    })
  }

  addShippingDiscrepencyMissingItems(selectedMaterialData:any): FormGroup {
    return this.formBuilder.group({
      itemId: [selectedMaterialData?selectedMaterialData.materialNumber:'', Validators.required],
      quantityOrdered: [selectedMaterialData?selectedMaterialData.quantityOrdered:'', [Validators.required, Validators.min(1), Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      quantityReceived: ['', Validators.required],
      uom: [selectedMaterialData?selectedMaterialData.unitOfMeasure:'', [Validators.required, Validators.pattern('^[a-zA-Z \-\']+')]],
      uomReceived: ['', [Validators.required, Validators.pattern('^[a-zA-Z \-\']+')]],
      actionRequest: ['', Validators.required],
      itemMissing: ['X'],
      itemDescription: [{value:selectedMaterialData?selectedMaterialData.itemDescription:'',disabled:true}],
      statusMessageList: [{value:selectedMaterialData?selectedMaterialData.statusMessageList:'',disabled:true}],

    })
  }
  addShippingDiscrepencyIncorrectMaterialItems(): FormGroup {
    return this.formBuilder.group({
      itemId: ['', Validators.required],
      quantityReceived: ['', Validators.required],
      uomReceived: ['', [Validators.required, Validators.pattern('^[a-zA-Z \-\']+')]],
      actionRequest: ['', Validators.required]
    })
  }
  addShippingDiscrepencyIncorrectDeliveryItems(): FormGroup {
    return this.formBuilder.group({
      itemId: [''],
      quantityReceived: ['', Validators.required],
      uomReceived: ['', [Validators.required, Validators.pattern('^[a-zA-Z \-\']+')]],
      actionRequest: ['', Validators.required],
      itemDescription: [{value:'',disabled:true}],
      statusMessageList: [{value:'',disabled:true}],

    })
  }
  addIncorrectMaterialForm() {
    (<FormArray>this.caseCreateForm.get('items')).push(this.addShippingDiscrepencyIncorrectMaterialItems())
  }
  addShortDatedProductItems(selectedMaterialData:any): FormGroup{
    return this.formBuilder.group({
      itemId: [selectedMaterialData?selectedMaterialData.materialNumber:'', Validators.required],
      quantityOrdered: [selectedMaterialData?selectedMaterialData.quantityOrdered:'', [Validators.required,Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      uom: [selectedMaterialData?selectedMaterialData.unitOfMeasure:'', [Validators.required, Validators.pattern('^[a-zA-Z \-\']+')]],
      quantityReceived: ['', [Validators.required,Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      uomReceived: ['', [Validators.required, Validators.pattern('^[a-zA-Z \-\']+')]],
      actionRequest: ['', Validators.required],
      itemDescription: [{value:selectedMaterialData?selectedMaterialData.itemDescription:'',disabled:true}],
      statusMessageList: [{value:selectedMaterialData?selectedMaterialData.statusMessageList:'',disabled:true}],
      eventDate: ['', [Validators.required]],
      shipedDate: [{value:selectedMaterialData.shipedDate,disabled:true}]
    })
  }

  addExpiredProductItems(selectedMaterialData:any):FormGroup{
    return this.formBuilder.group({
      itemId: [selectedMaterialData?selectedMaterialData.materialNumber:'', Validators.required],
      quantityOrdered: [selectedMaterialData?selectedMaterialData.quantityOrdered:'', [Validators.required, Validators.pattern(/^0*([^0]\d*)$/), Validators.pattern(/^[0-9]*$/)]],
      uom: [selectedMaterialData?selectedMaterialData.unitOfMeasure:'', [Validators.required, Validators.pattern('^[a-zA-Z \-\']+')]],
      lotNo: ['', Validators.required],
      eventDate: ['', Validators.required],
      sampleAvailable: ['', Validators.required],
      wasThereInjury: ['', Validators.required],
      wasThereDeath: ['', Validators.required],
      actionRequest: ['', Validators.required],
      itemDescription: [{value:selectedMaterialData?selectedMaterialData.itemDescription:'',disabled:true}],
      statusMessageList: [{value:selectedMaterialData?selectedMaterialData.statusMessageList:'',disabled:true}],
      shipedDate: [{value:selectedMaterialData.shipedDate,disabled:true}]
    })
  }
  
  addReturnCommonItems(selectedMaterialData:any):FormGroup{
    return this.formBuilder.group({
      itemId: [selectedMaterialData.materialNumber, Validators.required],
      quantityOrdered: [selectedMaterialData.quantityOrdered, [Validators.required, Validators.min(1), Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      uom: [selectedMaterialData.unitOfMeasure, [Validators.required, Validators.pattern('^[a-zA-Z \-\']+')]],
      quantityReceived: ['', [Validators.required,Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      uomReceived: ['', [Validators.required, Validators.pattern('^[a-zA-Z \-\']+')]],
      itemDescription: [{value:selectedMaterialData.itemDescription,disabled:true}],
      statusMessageList: [{value:selectedMaterialData.statusMessageList,disabled:true}],

    })
  }
  addReturnProductRecallItems(selectedMaterialData:any):FormGroup{
    return this.formBuilder.group({
      itemId: [selectedMaterialData.materialNumber, Validators.required],
      quantityOrdered: [selectedMaterialData.quantityOrdered, [Validators.required, Validators.min(1), Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      uom: [selectedMaterialData.unitOfMeasure, [Validators.required, Validators.pattern('^[a-zA-Z \-\']+')]],
      quantityReceived: ['', [Validators.required,Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      uomReceived: ['', [Validators.required, Validators.pattern('^[a-zA-Z \-\']+')]],
      lotNo: ['', Validators.required],
      itemDescription: [{value:selectedMaterialData.itemDescription,disabled:true}],
      statusMessageList: [{value:selectedMaterialData.statusMessageList,disabled:true}],

    })
  }

  addForm() {
    if (this.selectedRequestType.id === 'RT_20' || this.selectedRequestType.id === 'RT_33'){
      this.addFormById();
    }else if(this.selectedIssueType.id === 'CA1_151' && this.productRelatedQuestion.selected){
      this.addFormById();
    }
    else{
      this.showSelectMaterialModal = true;
    }
  }

  addFormById(selectedMaterialData?:any) {
    if (this.selectedIssueType.id == 'CA1_150') {
      (<FormArray>this.caseCreateForm.get('items')).push(this.addCommonItems(selectedMaterialData))
    }
    else if (this.selectedIssueType.id == 'CA1_154') {
      if (this.selectedRequestType.id === 'RT_19') {
        if(this.wasSometingMissing){
          (<FormArray>this.caseCreateForm.get('missingItems')).push(this.addShippingDiscrepencyMissingItems(selectedMaterialData));
        }else{
          (<FormArray>this.caseCreateForm.get('items')).push(this.addShippingDiscrepencyIncorrectMaterialItems());
        }
      } else if (this.selectedRequestType.id === 'RT_20') {
        (<FormArray>this.caseCreateForm.get('items')).push(this.addShippingDiscrepencyIncorrectDeliveryItems())
      } else if (this.selectedRequestType.id === 'RT_18') {
        (<FormArray>this.caseCreateForm.get('items')).push(this.addShippingDiscrepencyQtyDamagedItems(selectedMaterialData))
      } else if (this.selectedRequestType.id === 'RT_22') {
        (<FormArray>this.caseCreateForm.get('items')).push(this.addShortDatedProductItems(selectedMaterialData));
      } else if (this.selectedRequestType.id === 'RT_23'){
        (<FormArray>this.caseCreateForm.get('items')).push(this.addExpiredProductItems(selectedMaterialData));
      }
      else {
        (<FormArray>this.caseCreateForm.get('items')).push(this.addShippingDiscrepencyItems(selectedMaterialData))
      }

    }
    else if (this.selectedIssueType.id == 'CA1_151') {
      if (this.selectedRequestType.id == 'RT_8') {
        (<FormArray>this.caseCreateForm.get('items')).push(this.addPrductComplaintsItem(selectedMaterialData))
      }
      else {
        if(this.productRelatedQuestion.selected){
          (<FormArray>this.caseCreateForm.get('items')).push(this.addProductQuestionItemsWithoutOrderDetails());
        }else{
          (<FormArray>this.caseCreateForm.get('items')).push(this.addCommonItems(selectedMaterialData))
        }
      }
    }else if(this.selectedIssueType.id == 'CA1_153'){
      if (this.selectedRequestType.id == 'RT_11') {
        (<FormArray>this.caseCreateForm.get('items')).push(this.addPricingDiscrepancyitems(selectedMaterialData))
      }
    }else if(this.selectedIssueType.id == 'CA1_116'){
      if (this.selectedRequestType.id == 'RT_27' || 
          this.selectedRequestType.id == 'RT_28' ||
          this.selectedRequestType.id == 'RT_29'
      ) {
        (<FormArray>this.caseCreateForm.get('items')).push(this.addReturnCommonItems(selectedMaterialData))
      }else if(this.selectedRequestType.id == 'RT_30'){
        (<FormArray>this.caseCreateForm.get('items')).push(this.addReturnProductRecallItems(selectedMaterialData))
      }else if(this.selectedRequestType.id == 'RT_31'){
        (<FormArray>this.caseCreateForm.get('items')).push(this.addShippingDiscrepencyQtyDamagedItems(selectedMaterialData))
      }else if(this.selectedRequestType.id == 'RT_32'){
        (<FormArray>this.caseCreateForm.get('items')).push(this.addExpiredProductItems(selectedMaterialData))
      }else if (this.selectedRequestType.id === 'RT_33') {
        (<FormArray>this.caseCreateForm.get('items')).push(this.addShippingDiscrepencyIncorrectDeliveryItems())
      } 
      else if(this.selectedRequestType.id == 'RT_34'){
        if(this.wasSometingMissing){
          (<FormArray>this.caseCreateForm.get('missingItems')).push(this.addShippingDiscrepencyMissingItems(selectedMaterialData));
        }else{
          (<FormArray>this.caseCreateForm.get('items')).push(this.addShippingDiscrepencyIncorrectMaterialItems());
        }
      }else if(this.selectedRequestType.id == 'RT_35') {
        (<FormArray>this.caseCreateForm.get('items')).push(this.addShippingDiscrepencyItems(selectedMaterialData))
      }else if(this.selectedRequestType.id == 'RT_36'){
        (<FormArray>this.caseCreateForm.get('items')).push(this.addShortDatedProductItems(selectedMaterialData))
      }
    }
  }

  submitForm() {
    this.highlightedText = this.checkValid===true ? "black" : "red";
    this.caseCreateForm.value.submitted = true;
    if (this.submitButtonValidation() || this.caseCreateForm.invalid)
    {
      setTimeout(function(){
      const firstElementWithError:HTMLElement = document.querySelector('.has-error, .error-date');
      if (firstElementWithError) 
      {
        firstElementWithError.focus();        
        firstElementWithError.scrollIntoView({behavior: 'smooth', block: 'center'});
      } }, 500);
      return
    }
     
    this.cspShowSpinner = true;   
    const caseRequest: CaseRequest = this.caseCreateForm.value;
    caseRequest.firstName = User.getUser().firstName;
    caseRequest.lastName = User.getUser().lastName;
    caseRequest.email = User.getUser().email;
    caseRequest.phoneNumber = User.getUser().phoneNumber;
    caseRequest.userName = User.getUser().userId;
    caseRequest.requestTypeDesc = this.selectedRequestType.text;
    caseRequest.requestType = this.selectedRequestType.id;
    caseRequest.categoryId = this.selectedIssueType.id;
    if(this.selectedOrderDetails && this.selectedOrderDetails.orderData){
      caseRequest.orderId = this.selectedOrderDetails.orderData.orderNumber;
    }
    caseRequest.comments = caseRequest.comments.replace(/\n/g, '<br/>');

    if(this.isIncorrectDeliveryLocation || this.isReturnIncorrectDeliveryLocation){
      caseRequest.purchaseOrderNumber = this.poNumber;
    }
    if (this.recipientsEmail && this.recipientsEmail.length > 0) {
      caseRequest.additionalEmails = this.recipientsEmail;
    }
    else {
      caseRequest.additionalEmails = [];
    }
    if(this.selectedRequestType.id == 'RT_11'){
      this.caseCreateForm.value.items.forEach(element => {
        if(element.customerExpectedPrice){
          element.customerExpectedPrice = this.currency.format(element.customerExpectedPrice);
        }
      });
    }
    if (this.selectedRequestType.id == 'RT_8' || this.selectedRequestType.id == 'RT_23' || this.selectedRequestType.id == 'RT_22'
    || this.selectedRequestType.id == 'RT_32' ||  this.selectedRequestType.id == 'RT_36') {
      this.caseCreateForm.value.items.forEach(element => {
        if(element.eventDate){
          element.eventDate = moment(element.eventDate).format('MM/DD/YYYY');
        }
      });
    }
    const tempAttachment = this.attachments.filter((value, index) => {
      if (value.ecmDocId) {
        return value;
      }
    });
    caseRequest.attachments = tempAttachment.map((value, index) => {
      return {
        fileName: value.name,
        id: value.id,
        fileType: value.type,
        ecmDocId: value.ecmDocId
      };
    });
    if ((this.isIncorrectMaterialDelivered || this.isReturnIncorrectMaterialDelivered) && this.selectedMissingFromOrder) {
      caseRequest.missingFromOrder = this.selectedMissingFromOrder.text;
      if(caseRequest.missingItems){
        var found = caseRequest.missingItems.find(element =>
          (element.actionRequest == "08" || element.actionRequest == "24")
        );
      }
    }
    else if(caseRequest.items){
      var found = caseRequest.items.find(element =>
        (element.actionRequest == "06" || element.actionRequest == "04" || element.actionRequest == "13"
        || element.actionRequest == "19" ||  element.actionRequest == "30")
      );
    }
  

    if (found != undefined) {
      this.setCaseCategoryAndRequestType(found,caseRequest);
      
    }
    this.casesService.createCase(caseRequest).subscribe(
      (caseResponse: CaseResponse) => {
        this.cspShowSpinner = false;

        if (!caseResponse.caseId) {
          this.errorResponse = caseResponse;
        } else {
          this.submittedCaseId = caseResponse.caseId;
          this.errorResponse = null;
          let logMessage: string = "AppId:26095 : " + "Case Created from Form A. " + 
                        "Case details : " + JSON.stringify(caseResponse);
          if (this.selectedContactResponse != null && this.selectedContactResponse.totalAdded > 0) {
            logMessage = logMessage + " , Contacts Added : { "
              + " manuallyAdded : " + this.selectedContactResponse.manuallyAdded
              + ", fromAddressBookAdded : " + this.selectedContactResponse.fromAddressBook
              + ", totalRecipients : " + this.selectedContactResponse.totalAdded
              + " }"
          }
  
          this.logService.logMessage(logMessage).subscribe(
            (data: any) => {

            });
          window.dispatchEvent(new Event('formClean'));
          this.router.navigate(['/case/case-acknowledgement', this.submittedCaseId]);
        }

      },

      (error: ErrorResponse) => {
        this.cspShowSpinner = false;
        this.errorResponse = error;
        let logMessage: string = "AppId:26095 : Case Creation error from Form A. ";
        if(this.errorResponse.error && this.errorResponse.error.description){
          logMessage = logMessage + this.errorResponse.error.errorCode + " : " + this.errorResponse.error.description;
        }else if(this.errorResponse.error){
          logMessage = logMessage + this.errorResponse.error.errorCode + " : " + this.errorResponse.error.errorMessage;
        }
        else{
            logMessage = logMessage + this.errorResponse.message;
        }
         this.logService.logMessage(logMessage).subscribe();
      } 
    );
    
   
}

  setCaseCategoryAndRequestType(found: Item,caseRequest: CaseRequest) {
      
    if("06".match(found.actionRequest)){
      caseRequest.categoryId = "CA1_156";
      caseRequest.requestType = "RT_21";
      caseRequest.requestTypeDesc = "Reorder and CR/RE";
    }
    else if("04".match(found.actionRequest) || "19".match(found.actionRequest)){
      caseRequest.categoryId = "CA1_156";
      caseRequest.requestType = "RT_24";
      caseRequest.requestTypeDesc = "Reorder and CR/RE";
    }
    else if("13".match(found.actionRequest) || "30".match(found.actionRequest)){
      caseRequest.categoryId = "CA1_156";
      caseRequest.requestType = "RT_26";
      caseRequest.requestTypeDesc = "Reorder and CR/RE";
    } else if("08".match(found.actionRequest) || "24".match(found.actionRequest)){
      caseRequest.categoryId = "CA1_156";
      caseRequest.requestType = "RT_25";
      caseRequest.requestTypeDesc = "Reorder and CR/RE";
    }
  }

  /**
   * showAttachmentModal
   */
  showAttachmentModal() {
    this.isAttachmentModalVisible = true;
  }

  /**
   * hideAttachmentModal
   */
  hideAttachmentModal() {
    this.isAttachmentModalVisible = false;
  }

  backClick() {
    if (this.isFormChanged) {
      this.isSubmitCaseModalVisible = true;
    } else {
      this.location.back();
    }
  }

  get getFormAsArray() {
    return (<FormArray>this.caseCreateForm.get('items'))
  }

  get getMissingItemsFormAsArray() {
    return (<FormArray>this.caseCreateForm.get('missingItems'))
  }

 

  /**
   * attachUploadedFiles
   */
  attachUploadedFiles(_attachments) {

    this.isFormChanged = true;
    window.dispatchEvent(new Event('formDirty'));
    if (_attachments) {
      // Replace included attachments
      this.attachments = [].concat(_attachments);
      this.attachmentService.attachmentsSubject.next(this.attachments.filter(
        value => value.ecmDocId
      ) || []
      );
    } else {
      this.attachmentService.attachmentsSubject.next([]);
    }
  }

  /**
   * removeAttachments
   * @param index
   */
  removeAttachments(index: number) {
    window.dispatchEvent(new CustomEvent('cah:spinnerShow'));
    this.attachments.splice(index, 1);
    this.attachmentService.attachmentsSubject.next(this.attachments);
    window.dispatchEvent(new CustomEvent('cah:spinnerHide'));
  }


  formBAttachments(formBAttachment) {
    this.attachmentsFormB = formBAttachment;
  }
  fromFormB(comingFromFromB) {
    this.isFromFormB = comingFromFromB;
    window.dispatchEvent(new Event('formDirty'));
  }
  discardChanges(): void {
    let logMessage: string = "AppId:26095 : ";
    if (this.isFromFormB) {
      if (this.attachmentsFormB.length > 0) {
        const validAttachments = this.attachmentsFormB.filter(value => value.ecmDocId);
        if (this.attachmentsFormB.length == validAttachments.length) {
          logMessage = logMessage + "Case Creation Abandoned having Attachments attached from Form B: " + JSON.stringify(this.attachments);
        } else {
          logMessage = logMessage + "Case Creation Abandoned having error during attachment upload from Form B: " + JSON.stringify(this.attachments);
        }
      } else {
        logMessage = logMessage + "Case Creation Abandoned from Form B. ";
      }
    } else {
      if (this.attachments.length > 0) {
        const validAttachments = this.attachments.filter(value => value.ecmDocId);
        if (this.attachments.length == validAttachments.length) {
          logMessage = logMessage + "Case Creation Abandoned having Attachments attached from Form A: " + JSON.stringify(this.attachments);
        } else {
          logMessage = logMessage + "Case Creation Abandoned having error during attachment upload from Form A: " + JSON.stringify(this.attachments);
        }
      } else {
        logMessage = logMessage + "Case Creation Abandoned from Form A. ";
      }
    }

    this.isFormChanged = false;
    this.isSubmitCaseModalVisible = false;
    this.removeAllAttachments(this.attachments);
    this.recipientsEmail = [];

    this.logService.logMessage(logMessage).subscribe((data: any) => {
      window.dispatchEvent(new Event('formClean'));
      if (this.isCancel) {
        this.navigateToLandingPage();
      } else if (this.isNavigateAway) {
        window.location.href = this.navigationURL;
      }
      else {
        this.location.back();
      }
    }, (error) => {
      window.dispatchEvent(new Event('formClean'));
      if (this.isCancel) {
        this.navigateToLandingPage();
      } else if (this.isNavigateAway) {
        window.location.href = this.navigationURL;
      }
      else {
        this.location.back();
      }
    });

  }

  removeAllAttachments(attachments: Array<Attachment>): void {
    for (let i = 0; i < attachments.length; i++) {
      this.removeAttachments(i);
    }
  }

  public onSelectRecipientClick() {
    this.isRecipientModalVisible = true;
    this.isChecked = true;
    this.selectedContactsList = [];
    this.recipientsEmail = [];
    this.selectedContactResponse = null;
  }

  public hideRecipientModal() {
    this.isRecipientModalVisible = false;
    this.isChecked = false;
  }


  public getRecipientValue(contactResponse: ContactResponse) {
    this.selectedContactResponse = contactResponse;
    this.selectedContactsList = contactResponse.contactList;
    this.selectedContactsList.forEach((selectedContact: Contact) => {
      this.recipientsEmail.push(selectedContact.email);
    });
  }
  public setPONumber(selectedOrderData){
    this.selectedOrderDetails = selectedOrderData;
    if(this.selectedRequestType.id === "RT_19" || this.selectedRequestType.id === "RT_34"){
      this.missingFromOrder.push({ id: 'Yes', text: 'Yes' });
      this.missingFromOrder.push({ id: 'No', text: 'No' });
    }else{
      this.prepateMaterialLines(selectedOrderData);
    }
    
  }
  prepateMaterialLines(selectedOrderData: any) {
    this.materialList = [];
    this.selectMaterialColums = ['materialNumber','itemDescription','quantityOrdered','unitOfMeasure','status'];
    this.selectMaterialColumsLabel = ['Cardinal Health Material #','Item Description', 'Quantity Ordered', 'UOM Ordered','Status'];
    this.addItemButtonName = "Add Product";
    this.lineItems = selectedOrderData.orderLineItems.orderLines;
    const orderDateTime = selectedOrderData.orderData.orderedTime;
    this.lineItems.forEach(lineItem => {
      const tempData = {
                         materialNumber:lineItem.materialNumber,
                         itemDescription:lineItem.title,
                         quantityOrdered:lineItem.quantity,
                         unitOfMeasure:lineItem.unitOfMeasure,
                         statusMessageList: this.prepareLineItemStatusMessage(lineItem),
                         disabled: this.returnStatusEligibilityForMaterial(lineItem),
                         status: this.prepareLineItemStatus(lineItem),
                         shipedDate: this.getShippedDate(lineItem,orderDateTime)
      }
      this.materialList.push(tempData);
      this.showSelectMaterialModal = true;
    });
  }
  getShippedDate(lineItem: any, orderDateTime:any) {
    let shippedDate = "";
    let dropShipStatus = false;
    for (let index = 0; index < lineItem.fulfillment.length; index++) {
      const element = lineItem.fulfillment[index];
      let statusElement = element.status;
       
        if(statusElement === "Shipped" && element.shippedDate){
          shippedDate = element.shippedDate;
         
         break;
        }else if(statusElement === "Dropship"){
          shippedDate = orderDateTime;
          dropShipStatus = true;
          break;
        }
    }
     return [shippedDate,dropShipStatus];

  }
  public getSelectedLineItem(selectedItems){
    this.hideSelectMaterialModal();
    const selectedMaterialData = selectedItems;
    
    selectedMaterialData.forEach(element => {
      if(!element.disabled){ 
        let index = this.materialList.findIndex(x => x.materialNumber === element.materialNumber);
        element.disabled = true;
        if(index){
          this.materialList.splice(index,1,element);
        }
        let itemsArray = this.caseCreateForm.get('items') as FormArray;
        if(itemsArray && itemsArray.length >0){
          this.addFormById(element);
        }else{
          this.loadrequestTypeForm(this.selectedRequestType,element);
        }
      }
    });
    if(this.selectedIssueType.id != 'CA1_153'){
      this.caseCreateForm.patchValue({
        shipTo: this.selectedOrderDetails.orderData.shipTo,
        selectedIssueType: this.selectedIssueType.id,
        selectedRequestType: this.selectedRequestType.id,
        purchaseOrderNumber: this.selectedOrderDetails.orderData.purchaseOrderNumber
  
      });
      
    }
  }
  deleteFormItem(deletedItemDetails){
    this.materialList.forEach(element => {
     if(element.materialNumber==deletedItemDetails.itemId){
      element.disabled = false;
      element.selected = false;
     }
    });
    
  }
  hideSelectMaterialModal(){
    this.showSelectMaterialModal = false;
  }
  public prepareLineItemStatus(lineItem: any) {
    let status = [];
    lineItem.fulfillment.forEach(element => {
      if(!status.includes(" "+element.status)){
        if(this.checkNonReturnableFlagAndShippedDate(element,lineItem) && element.status == 'Shipped'){
          if(lineItem.nonReturnable && !(this.selectedRequestType.id == 'RT_31' 
          || this.selectedRequestType.id == 'RT_32' || this.selectedRequestType.id == 'RT_33'||
          this.selectedRequestType.id == 'RT_35'|| this.selectedRequestType.id == 'RT_34'||
          this.selectedRequestType.id == 'RT_36')){
            if(!status.includes(" "+lineItem.nonReturnableReason))
            status.push(" " + lineItem.nonReturnableReason);
          }else{
            status.push(" Items Shipped more than 90 days ago are not eligible for Credit.");
          }
        }else if(this.selectedIssueType.id == "CA1_116" && element.status == 'Dropship'){
          if(this.checkNonReturnableFlagAndShippedDate(element,lineItem)){
            if(!status.includes(" "+lineItem.nonReturnableReason))
            status.push(" " +lineItem.nonReturnableReason);
          }else{
            status.push(" Returns for Drop Ship items are subject to the vendor's return policy.");
          }
        }else if(this.selectedIssueType.id == "CA1_116" && element.status == 'Inandout'){
          if(lineItem.nonReturnable){
            if(!status.includes(" "+lineItem.nonReturnableReason))
            status.push(" " + lineItem.nonReturnableReason);
          }
        }
        else{
          status.push(" "+element.status);
        }
      }
     });
     return status;
  }
  
  public prepareLineItemStatusMessage(lineItem: any) {
    let statusMessage = [];
    lineItem.fulfillment.forEach(element => {
      statusMessage.push(element.statusMessage);
      if(this.selectedIssueType.id == "CA1_116" && element.status == 'Dropship'){
            statusMessage.push("Returns for Drop Ship items are subject to the vendor's return policy.");
        }
     });
     return statusMessage;
  }

  public resetFormOnClickOfSearch(){
    this.removeAllControls();
    this.setDefaultControls();
    this.resetFormFlags();

    this.selectedMissingFromOrder = null;
  }
  public  returnStatusEligibilityForMaterial(lineItem: any) {
    
    let returnFlag = true;
    var statusMap = RequestTypeStatusMapping.REQ_TYPE_STATUS_MAP;
    const requestTypeId = this.selectedRequestType.id;
    let eligibleStatusList:Array<string> = statusMap.get(requestTypeId);
    for (let index = 0; index < lineItem.fulfillment.length; index++) {
      const element = lineItem.fulfillment[index];
      let statusElement = element.status;
       
        if(eligibleStatusList && eligibleStatusList.includes(statusElement)){
          if(this.checkNonReturnableFlagAndShippedDate(element,lineItem)){
            returnFlag = true;
                break;
          }else{
            returnFlag = false;
          }
        }
    }
     return returnFlag;
  }

  public setInvoiceNumber(invoiceDetails:any){
    if(this.selectedRequestType.id == 'RT_11'){
      const invoiceHeader = invoiceDetails.invoiceHeader;
      if(invoiceHeader.type == 'Purchase'){
        this.prepareInvoiceLineItems(invoiceDetails);
      }
    }else{
      this.loadrequestTypeForm(this.selectedRequestType,invoiceDetails.invoiceHeader);
    }
    this.caseCreateForm.patchValue({
      selectedIssueType: this.selectedIssueType.id,
      selectedRequestType: this.selectedRequestType.id,
      shipTo: invoiceDetails.invoiceHeader.shipTo.id,
      invoiceId: invoiceDetails.invoiceHeader.invoiceNumber
    });
    this.caseCreateForm.value.invoiceId = invoiceDetails.invoiceHeader.invoiceNumber;
  }
  prepareInvoiceLineItems(invoiceDetails: any) {
    this.materialList = [];
    this.selectMaterialColums = ['customerPOLineNumber','shortDescription','materialNumber','priceOfUOM','quantityOrdered','extendedPrice'];
    this.selectMaterialColumsLabel = ['Line Item Number','Description','Material #', 'Price/UOM', 'Quantity Ordered','Extended Price'];
    this.addItemButtonName = "Add Product";
  
    this.lineItems = invoiceDetails.invoiceLineItems;
    this.lineItems.forEach(lineItem => {
      const tempData = {
        customerPOLineNumber:lineItem.customerPOLineNumber,
        shortDescription:lineItem.shortDescription,
        materialNumber:lineItem.materialNumber,
        priceOfUOM:this.currency.format(lineItem.priceOfUnit) + ' ' + lineItem.unitOfMeasurement,
        quantityOrdered: lineItem.quantityOrdered,
        extendedPrice: this.currency.format(lineItem.extendedPrice),
        unitOfMeasurement: lineItem.unitOfMeasurement,
        priceOfUnit: lineItem.priceOfUnit,
        invoiceNumber: invoiceDetails.invoiceHeader.invoiceNumber

      }
      this.materialList.push(tempData);
      this.showSelectMaterialModal = true;
    });
  }

  public toggleProdQuestionCheckbox(){
    this.resetFormFlags();
    this.removeAllControls();
    this.setDefaultControls();
    this.selectedShipTo = null;
    this.selectedOrderDetails = null;
    if(this.productRelatedQuestion.selected){
      this.isShiptoVisible = true;
      this.isPOSearchVisible = false;

    }else{
      this.isShiptoVisible = false;
      this.isPOSearchVisible = true;
    }
  }

  public checkNonReturnableFlagAndShippedDate(element: any, lineItem: any): boolean {
    let shippedStatusFlag = false;

    const isDropship = ['RT_27', 'RT_28', 'RT_29'].includes(this.selectedRequestType.id) && element.status === 'Dropship';
    const isShipped = ['RT_27', 'RT_28', 'RT_29', 'RT_31', 'RT_32', 'RT_33', 'RT_34', 'RT_35', 'RT_36'].includes(this.selectedRequestType.id) && element.status === 'Shipped' && element.shippedDate;

    if (isDropship && lineItem.nonReturnable || isShipped && lineItem.nonReturnable && !['RT_31', 'RT_32', 'RT_33', 'RT_34', 'RT_35', 'RT_36'].includes(this.selectedRequestType.id)) {
        shippedStatusFlag = true;
    } else if (isShipped) {
        const todayDate = new Date();
        const shippedDate = new Date(element.shippedDate);

        const time = todayDate.getTime() - shippedDate.getTime();
        const days = time / (1000 * 3600 * 24);

        shippedStatusFlag = days > 93;
    }

    return shippedStatusFlag;
  }
}
