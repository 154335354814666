import { Injectable } from '@angular/core';


@Injectable()
export class WindowRef {
    _window() : any {
      // return the global native browser window object
      return window;
   }
   get nativeWindow() : any {
      return this._window();
   }
}