import { Component, OnInit, Input, ViewEncapsulation, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';

@Component({
  selector: 'app-invoice-issues-form',
  templateUrl: './invoice-issues-form.component.html',
  styleUrls: ['./invoice-issues-form.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class InvoiceIssuesFormComponent implements OnInit {

  @Input() caseCreateForm : FormGroup;
  @Input() pricingDiscrepancy : boolean=false;
  @Input() incorrectFees : boolean=false;
  @Input() incorrectPO : boolean=false;
  @Input() incorrectPartner : boolean=false;
  @Input() shipToData:  Array<any> = [];
  @Input() formIndex : number;
  @Input() allowDecimal : boolean;
  @Output() deleteFormItem : EventEmitter<any> = new EventEmitter<any>();
  selectedShipTo: any;
  customerExpectedPrice : Number;
  constructor() { 
      // This is intentional
  }

  get formContols() :FormGroup{
    let formArray=this.caseCreateForm.get('items') as FormArray;
    let formGroup=formArray.controls[this.formIndex] as FormGroup;
   return formGroup
  }
  get itemDescription(){
    let invoiceItemDescformArray=this.getFormArray();
    let invoiceItemDescformGroup=invoiceItemDescformArray.controls[this.formIndex] as FormGroup;
    
      return invoiceItemDescformGroup.get('itemDescription').value;
  }
  ngOnInit() {
        // This is intentional
  }

  getFormArray() :FormArray {
    return this.caseCreateForm.get('items') as FormArray;
  }
  deleteForm() :void {
    let formArray=this.caseCreateForm.get('items') as FormArray;
    if(formArray.length>1) {
      const deletedItem = formArray.value[this.formIndex];
      this.deleteFormItem.emit(deletedItem);
      formArray.removeAt(this.formIndex);
    }
  }
  setShipToOnSelect(selectedShipToData:any){
    this.selectedShipTo = selectedShipToData.id;
  }
}
