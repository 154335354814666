import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';

@Component({
  selector: 'app-product-questions-form',
  templateUrl: './product-questions-form.component.html',
  styleUrls: ['./product-questions-form.component.scss']
})
export class ProductQuestionsFormComponent implements OnInit {

  @Input() caseCreateForm : FormGroup;
  @Input() productComplaints : boolean=false;
  @Input() formIndex : number = 0;
  @Input() actionItems : any;
  @Input() isProdQuestWithoutOrdDetails : boolean = false;
  @Output() deleteFormItem : EventEmitter<any> = new EventEmitter<any>();
  sampleAvailable : any;
  wasThereInjury : any;
  wasThereDeath : any;
  actionRequest : any;
  maxDate:Date=new Date();

// For example initialize to specific date (09.10.2018 - 19.10.2018). It is also possible
// to set initial date range value using the selDateRange attribute.
private model: any = {beginDate: {year: 2018, month: 10, day: 9},
                         endDate: {year: 2018, month: 10, day: 19}};


  constructor() {
      // This is intentional
  }

  get formContols() :FormGroup{
    let formArray=this.getFormArray();
    let formGroup=formArray.controls[this.formIndex] as FormGroup;
   return formGroup
  }
  get itemDescription(){
    let productQuestionArray=this.getFormArray();
    let productQuestionFormGroup=productQuestionArray.controls[this.formIndex] as FormGroup;
    
      return productQuestionFormGroup.get('itemDescription').value;
  }
  get statusMessageList(){
    let productQuestionArray=this.getFormArray();
    let productQuestionFormGroup= productQuestionArray.controls[this.formIndex] as FormGroup;
    
      return productQuestionFormGroup.get('statusMessageList').value;
  }
  ngOnInit() {
        // This is intentional
  }

  deleteForm() :void {
    let formArray=this.caseCreateForm.get('items') as FormArray;
    if(formArray.length>1) {
      const deletedItem = formArray.value[this.formIndex];
      this.deleteFormItem.emit(deletedItem);
      formArray.removeAt(this.formIndex);
    }
  }

  getFormArray() :FormArray {
    return this.caseCreateForm.get('items') as FormArray;
  }

}
