import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

@Component({
  selector: 'expired-product',
  templateUrl: './expired-product.component.html',
  styleUrls: ['./expired-product.component.scss']
})
export class ExpiredProductComponent implements OnInit {

  @Input() caseCreateForm : FormGroup;
  @Input() formIndex : number = 0;
  @Input() actionItems : any;
  @Output() deleteFormItem : EventEmitter<any> = new EventEmitter<any>();
  shippedDate:Date;
  lotNo:number;
  actionRequest1 : any;
  wasThereDeath1 : any;
  wasThereInjury1 : any;
  sampleAvailable1 : any;
  

  ngOnInit() {
  this.getShippedDateForExpProduct();
}

// For example initialize to specific date (09.10.2018 - 19.10.2018). It is also possible
// to set initial date range value using the selDateRange attribute.
private model: any = {beginDate: {year: 2018, month: 10, day: 9},
                         endDate: {year: 2018, month: 10, day: 19}};


  
  get formContols() :FormGroup{
    let formArray=this.getFormArray();
    let formGroup=formArray.controls[this.formIndex] as FormGroup;
   return formGroup
  }
  get itemDescription(){
    let expiredProductFormArray=this.getFormArray();
    let expiredProductFormGroup=expiredProductFormArray.controls[this.formIndex] as FormGroup;
    
      return expiredProductFormGroup.get('itemDescription').value;
  }
  get statusMessageList(){
    let expiredProductFormArray=this.getFormArray();
    let expiredProductFormGroup= expiredProductFormArray.controls[this.formIndex] as FormGroup;
    
      return expiredProductFormGroup.get('statusMessageList').value;
  }


  getFormArray() :FormArray {
    return this.caseCreateForm.get('items') as FormArray;
  }

  deleteForm() :void {
    let formArray=this.caseCreateForm.get('items') as FormArray;
    if(formArray.length>1) {
      const deletedItem = formArray.value[this.formIndex];
      this.deleteFormItem.emit(deletedItem);
      formArray.removeAt(this.formIndex);
    }
  }

  getShippedDateForExpProduct(): any {
    let formArray=this.getFormArray();
    let formGroup=formArray.controls[this.formIndex] as FormGroup;
    let shippedDateItem = formGroup.controls["shipedDate"].value;
    this.shippedDate = new Date(shippedDateItem[0]);
    if(shippedDateItem[1]){
      this.shippedDate.setDate(this.shippedDate.getDate() + 7);
    }else{
      this.shippedDate.setDate(this.shippedDate.getDate() + 2);
    }
  }
}
