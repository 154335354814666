<form [formGroup]="caseCreateForm">

  <div formArrayName="items">
    <div [formGroupName]="formIndex">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 order_inquery_section noPadding">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 noPadding">
            <p>
            <span *ngIf="getFormArray().length > 1" class="icon icon-close-small" (click)="deleteForm()"></span>
            Product #{{ formIndex + 1 }} -
            <input type="hidden" formControlName="itemDescription">
            <span [innerHTML]="itemDescription"> {{ itemDescription }}</span>
          </p>
        </div>
        <div class = "row">
        <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3 health_Div">
          <div class="inputFields">
            <label class="formLabel">Cardinal Health Material #</label
            >
            <input
              type="text" readonly
              maxlength="18"
              placeholder="Enter Material #..."
              class="form-control material_input"
              formControlName="itemId"
            />
          </div>
        </div>
        <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2 qty_Div noPadding">
          <div class="inputFields">
            <label class="formLabel">QTY Ordered</label
            >
            <input
              type="text" readonly
              maxlength="15"
              placeholder="QTY"
              class="form-control qty_input"
              formControlName="quantityOrdered"
              numericOnly
              [zeroNotAllowed]="true"
            />
          </div>
        </div>
        <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2 uom_Div noPadding">
          <div class="inputFields">
            <label class="formLabel">UOM</label>
            <input
              type="text" readonly
              maxlength="3"
              placeholder="UOM"
              class="form-control uom_input"
              formControlName="uom"
              alphabetOnly
            />
          </div>
        </div>
        <div
            class="col-xs-12 col-sm-2 col-md-2 col-lg-2 manufact_name noPadding"
          >
            <div class="inputFields">
              <label class="formLabel">Manufacturer Name</label>
              <input
                type="text"
                placeholder="Manufacturer Name"
                maxlength="40"
                class="form-control manufacturer_input"
                formControlName="manufacturerName"
              />
            </div>
          </div>
        <div
          class="col-xs-12 col-sm-3 col-md-3 col-lg-3 prod_vendor_mat noPadding"
        >
          <div class="inputFields">
            <label class="formLabel"
              >Product Information Vendor Material #</label
            >
            <input
              type="text"
              maxlength="40"
              placeholder="Vendor Material #"
              class="form-control vendor_input"
              formControlName="vendorMaterialNumber"
            />
          </div>
        </div>
        </div>
        <div class="row">
          <input type="hidden" formControlName="statusMessageList">
          <ul>
            <li *ngFor="let statusMessage of statusMessageList">
              <span [innerHTML]="statusMessage">{{statusMessage}}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</form>
