import { Component,  OnInit, AfterViewInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { HelpConstants } from '../../constants/help.constants';
import { User } from '../../shared/User';
import { environment } from 'src/environments/environment';
import { ChatService } from '../../services/chat.service';
import { WindowRef } from '../../services/windowRef';
import { ChatCsrfRequestResponse } from '../../model/chatCsrfRequestResponse';
import { ChatAgentAvailabilityResponse } from '../../model/chatAgentAvailabilityResponse';

@Component({
    selector: 'csp-help',
    templateUrl: './help.component.html',
    styleUrls: ['./help.component.scss']
})
export class HelpHomeComponent implements OnInit,AfterViewInit {
   helpLandingList:any;
   cspShowSpinner:boolean = false;
   chatIframUrl:string;
   private baseApiUrl: string = environment.baseApiUrl;
	 hostname: string;
  chatCSRFdata: ChatCsrfRequestResponse;
  agentAvailable: boolean = false;
  showChat: string;

   constructor(private titleService:Title, private chatService:ChatService , private winRef:WindowRef) { 
        this.chatIframUrl = environment.liveChatEndpoint + environment.liveChatSuffix;
        this.loadScripts();
    }

    ngOnInit() {
   

     this.titleService.setTitle("Help");
    let canDoChatFlag = this.getCanDoChatFlag();
        if(canDoChatFlag){
            this.helpLandingList = HelpConstants.HELP_LANDING_LIST;
        }else{
            this.helpLandingList = HelpConstants.HELP_LANDING_LIST_WITHOUT_CHAT;
        };
     
          this.chatService.getCSRFToken().subscribe( (data : ChatCsrfRequestResponse) =>{
          this.chatService.registerStatistic(data).subscribe(()=>{
            this.chatCSRFdata = data;
            this.chatService.getAgentAvailability(this.chatCSRFdata).subscribe( (data: ChatAgentAvailabilityResponse)=> {
              if(data && data.agentAvailability && data.agentAvailability == "Available"){
                this.agentAvailable = true;
                 console.log("ChatAgent: " + data.agentAvailability);
              }else{
                this.agentAvailable = false
                console.log("ChatAgent: Not available" );
              }            
          })
          },(chatStatisticError)=>{
            console.log("ChatAgent Statistic Subscription Error");
          })
      },
      (CSRFtokenError)=>{
             console.log("ChatAgent CSRF Error" );
             this.agentAvailable = false
      })
    
   }
 

    
    ngAfterViewInit() {
        let canDoChatFlag = this.getCanDoChatFlag()

         if (canDoChatFlag) {
             let helpChatIconElement: HTMLElement = document.getElementsByClassName("help-chat-icon")[0] as HTMLElement;
             let helpChatTextElement: HTMLElement = this.getChatTextElement();
             helpChatIconElement.style.display = '';
             helpChatTextElement.style.display = '';
             this.handleChat(helpChatIconElement, helpChatTextElement);
         }

     }

    //Dynamically Inject Chat Scripts
    loadScripts() {

      this.hostname = window && window.location && window.location.hostname;
     

      const dynamicScripts = [
       environment.pureconnectChatBus,
       environment.pureconnectWidgetHive
      ];

      for (let i = 0; i < dynamicScripts.length; i++) {
        const node = document.createElement('script');
        node.src = dynamicScripts[i];
        node.type = 'text/javascript';
        node.async = false;
       //Get Genesys Chat Files and Set Attributes for the Chat Configuration
        if (node.src = environment.pureconnectWidgetHive ){
           node.setAttribute('pureconnect-widgets-data', environment.pureconnectWidgetsData)
        }     
          document.getElementsByTagName('head')[0].appendChild(node);
      }
    }

  getCanDoChatFlag() {
        let shipTosAttributes = JSON.parse(localStorage.getItem('shipTosAttributes'))
        let canDoChatFlag = false;
        let userData = User.getUser();

        if(shipTosAttributes && shipTosAttributes.isChatEnabled){
          canDoChatFlag = true;
        }

        if(userData && userData.rights){
          userData.rights.forEach(element => {
                if(element.name == "can_do_help_chat" && element.assigned != false){
                  canDoChatFlag = true;
                }
            });
        }
        
       return canDoChatFlag;
    }

    getChatTextElement():HTMLElement {
    	var linkTextElements = document.getElementsByClassName('linkText');
    	for(var i = 0; i < linkTextElements.length; i++){
  	  		var helpTextElement:HTMLElement = linkTextElements[i] as HTMLElement;
  	  		if(helpTextElement.innerText == 'Chat'){
  	  			return helpTextElement;
  	  		}
  	  	}
    }

    handleChat(helpChatIconElement,helpChatTextElement):void {

    	 let self = this;
  	    helpChatIconElement.addEventListener('click', function(event) { self.openChatWindow(); });
  	    helpChatTextElement.addEventListener('click', function(event) { self.openChatWindow(); });
    }
    
   
  
   

    getChatDisabled(helpList){
      var returnflag = false;
      if(helpList.imgUrl == "help-chat-icon"){
              const currentDate = new Date();
               let utcstr = currentDate.getUTCHours();
               let utcDay = currentDate.getUTCDay();
               //Enabling chat for Monday-Friday , 8:00 AM EST to 5:00 PM EST
            // PROD check Agent Availability if((utcDay >= 1 && utcDay <= 5) && (utcstr > 11 && utcstr < 24) && this.agentAvailable){
      
            //PROD - 8am-5pm
               if((utcDay >= 1 && utcDay <= 5) && (utcstr > 11 && utcstr < 21)){
                  returnflag = false; //chat disabled
                } else {
                        returnflag = true; //chat disabled
                    }
              
               }

              //Override Chat visibility if has showChat in localStorage
               if(localStorage.getItem('showChat')){
                 returnflag = false; //chat disabled
               }

               return returnflag;  
      }
      
    

      
   


      
      openChatWindow() {
        var marketUserData = User.getUser();
        var tempData = '{"chatUsername": "' + marketUserData.firstName + ' ' + marketUserData.lastName + '", "first":"' + marketUserData.firstName + '", "last": "' + marketUserData.lastName + '", "transcriptEmailAddress":"' + marketUserData.email + '", "phone":"' + marketUserData.phoneNumber + '", "transcriptOption":"false", "helpOptions":""} ';
        const soldToId = User.getUser().soldToAccountNumber;
        const shipToId = User.getUser().shipToAccountNumber;
        
        this.winRef.nativeWindow._genesys.widgets.bus.command('WebChat.open', {
          userdata: {},
          form: {
            autoSubmit: false,
            firstname: marketUserData.firstName,
            lastname: marketUserData.lastName,
            email:  marketUserData.email,
            emailtranscript: '',
            phone: marketUserData.phoneNumber,
            soldto: soldToId,
            defaultshipto: shipToId,
            helpoptions: '',
            subject: ''
          },
          formJSON: {
            wrapper: '<table></table>',
            inputs: [{
                id: 'cx_webchat_form_subject',
                name: 'subject',
                maxlength: '100',
                placeholder: 'Required',
                label: 'Subject',
                validate: function(event, form, input, label, $, CXBus, Common){
                  if(input && input.val()) { // test to make sure field has been entered
                    return true; // validation passed
                  } else {
                    return false;
                  }
                }
  
              }, 
              {
                maxlength: '100',
                name: 'emailtranscript',
                type: 'checkbox',
                placeholder: 'Required',
                id: 'cx_webchat_form_emailtranscript',
                label: 'Email Transcript'
              },{
              id: 'cx_webchat_form_firstname',
              name: 'firstname',
              maxlength: '100',
              placeholder: 'Required',
              label: 'First Name',
              type:"hidden",
            }, {
              name: 'lastname',
              id: 'cx_webchat_form_lastname',
              maxlength: '100',
              placeholder: 'Required',
              label: 'Last Name',
              type:"hidden",
            }, {
              maxlength: '100',
              name: 'email',
              placeholder: 'Required',
              id: 'cx_webchat_form_email',
              label: 'Email',
              type:"hidden",
            }, 
            {
              maxlength: '100',
              id: 'cx_webchat_form_phone',
              name: 'phone',
              label: 'Phone',
              placeholder: 'Required',
              value: marketUserData.phoneNumber,
              type:"hidden",
            }, {
              id: 'cx_webchat_form_helpoptions',
              placeholder: 'Required',
              label: 'Help Options',
              name: 'helpoptions',
              maxlength: '100',
              value:'',
              type:"hidden",
            }, {
              name: 'soldto',
              maxlength: '100',
              placeholder: 'Required',
              label: 'Sold To',
              id: 'cx_webchat_form_soldto',
              value: soldToId,
              type:"hidden",
            }, {
              name: 'defaultshipto',
              maxlength: '100',
              label: 'Default Ship To',
              id: 'cx_webchat_form_defaultshipto',
              placeholder: 'Optional',
              value:shipToId,
              type:"hidden",
            }]
          },
          markdown: false
        });
      }
}
