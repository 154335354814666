<form [formGroup]="caseCreateForm">
 
  <div formArrayName="items">
    <div [formGroupName]="formIndex">
      <div *ngIf="!incorrectMaterial">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 shipping_discrepancy_section noPadding">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 noPadding">
            <p *ngIf="!incorrectDeliveryLocation">
              <span *ngIf="getFormArray().length > 1" class="icon icon-close-small" (click)="deleteForm()"></span>
              Product #{{ formIndex + 1 }} -
              <input type="hidden" formControlName="itemDescription">
              <span [innerHTML]="itemDescription"> {{ itemDescription }}</span>
            </p>
            <p *ngIf="incorrectDeliveryLocation">
              <span *ngIf="getFormArray().length > 1" class="icon icon-close-small" (click)="deleteForm()"></span>
              Product #{{ formIndex + 1 }}
              <input type="hidden" formControlName="itemDescription">
            </p>
          </div>
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12  noPadding">
              <div class="col-xs-12 col-sm-4 col-md-3 col-lg-3 health_Div">

                <div class="inputFields">
                  
                    <label class="formLabel">Cardinal Health Material #</label>
                    <input *ngIf="!incorrectDeliveryLocation" type="text" readonly placeholder="Enter Material #..." maxlength="18"
                    class="form-control material_input" formControlName="itemId" />
                    <input *ngIf="incorrectDeliveryLocation" type="text" placeholder="Enter Material #..." maxlength="18"
                    class="form-control material_input" formControlName="itemId" />
                </div>

              </div>
              <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2 qty_Div noPadding" *ngIf="!incorrectDeliveryLocation">
                <div class="inputFields">
                  <div>
                    <label class="formLabel">QTY Ordered</label>
                  </div>
                  <input  type="text" readonly placeholder="QTY" class="form-control qty_input"
                    formControlName="quantityOrdered" numericOnly [zeroNotAllowed]="true" />
                </div>
              </div>
              <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2 uom_Div noPadding" *ngIf="!incorrectDeliveryLocation">
                <div class="inputFields">
                  <div>
                    <label class="formLabel">UOM</label>
                  </div>
                  <input type="text" readonly placeholder="UOM" class="form-control uom_input" maxlength="3"
                    formControlName="uom" alphabetOnly />
                </div>
              </div>
              <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2 received_qty_Div noPadding">
                <div class="inputFields">
                  <div *ngIf="quantityDamaged">
                    <label class="formLabel"><span class="red-text">*</span>QTY Damaged</label>
                  </div>
                  <div *ngIf="!quantityDamaged">
                    <label class="formLabel"><span class="red-text">*</span>QTY Received</label>
                  </div>
                  <input *ngIf="quantityDamaged" type="text" placeholder="QTY" class="form-control received_qty_input"
                    formControlName="quantityDamaged" numericOnly [zeroNotAllowed]="isZeroAllowed" [(ngModel)]="qtyDamaged"
                    [ngClass]="{'has-error':  caseCreateForm.value.submitted && !qtyDamaged}"
                    />
                    <span *ngIf="quantityDamaged && caseCreateForm.value.submitted && !qtyDamaged" style="color: red; font-size:15px;">QTY is required</span>
                  <input *ngIf="!quantityDamaged" type="text" placeholder="QTY" class="form-control received_qty_input"
                    formControlName="quantityReceived" numericOnly [zeroNotAllowed]="isZeroAllowed" [(ngModel)]="qtyReceived"
                    [ngClass]="{'has-error': caseCreateForm.value.submitted && !qtyReceived}"
                    />
                    <span *ngIf="!quantityDamaged && caseCreateForm.value.submitted && !qtyReceived" style="color: red; font-size:15px;">QTY is required</span>                    
                </div>
              </div>
              <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2 received_qty_Div noPadding">
                <div class="inputFields">
                  <div *ngIf="!incorrectDeliveryLocation && !quantityDamaged">
                    <label class="formLabel"><span class="red-text">*</span>UOM Received</label>
                  </div>
                  <input *ngIf=" !incorrectDeliveryLocation && !quantityDamaged" type="text" placeholder="UOM" class="form-control received_qty_input"
                    formControlName="uomReceived" alphabetOnly maxlength="3" [(ngModel)]="uomReceived"
                    [ngClass]="{'has-error': caseCreateForm.value.submitted && !uomReceived}"
                    />
                    <span *ngIf="!quantityDamaged && caseCreateForm.value.submitted && !uomReceived" style="color: red; font-size:15px;">UOM is required</span>
                </div>
              </div>
              <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2 uom_Div noPadding" *ngIf="incorrectDeliveryLocation">
                <div class="inputFields">
                  <div>
                    <label class="formLabel"><span class="red-text">*</span>UOM Received</label>
                  </div>
                  <input type="text" placeholder="UOM" maxlength="3" class="form-control uom_input"
                    formControlName="uomReceived" alphabetOnly [(ngModel)]="uomReceived"
                    [ngClass]="{'has-error':  caseCreateForm.value.submitted && !uomReceived}"
                    />
                    <span *ngIf="caseCreateForm.value.submitted && !uomReceived" style="color: red; font-size:15px;">UOM is required</span>
                </div>
              </div>
              <div *ngIf="quantityDamaged" class="col-xs-12 col-sm-2 col-md-2 col-lg-2 radio_btns noPadding">
                <div class="inputFields">
                  <label class="formLabel radioLabel"><span class="red-text">*</span>Damage Type</label>
                  <div>
                    <div [ngClass]="{'has-error': caseCreateForm.value.submitted && !damageType}">
                      <p>
                        <input type="radio" name="damageType" class="keep_radio_btn"
                          value="Broken" formControlName="damageType" [(ngModel)]="damageType" />
                        <label>Broken</label>
                      </p>
                      <p>
                        <input type="radio" name="damageType" class="return_radio_btn"
                          value="Packaging Destroyed" formControlName="damageType" [(ngModel)]="damageType" />
                        <label>Packaging Destroyed</label>
                      </p>
                      <p>
                        <input type="radio" name="damageType" class="return_radio_btn"
                          value="Crushed" formControlName="damageType" [(ngModel)]="damageType" />
                        <label>Crushed</label>
                      </p>
                      <p>
                        <input type="radio" name="damageType" class="return_radio_btn"
                          value="Leaking/Wet" formControlName="damageType" [(ngModel)]="damageType" />
                        <label>Leaking/Wet</label>
                      </p>
                      <p>
                        <input type="radio" name="damageType" class="return_radio_btn"
                          value="Dirty" formControlName="damageType" [(ngModel)]="damageType" />
                        <label>Dirty</label>
                      </p>
                  </div>
                  </div>
                  <span *ngIf="caseCreateForm.value.submitted && !damageType" style="color: red; font-size:15px;">Please select option</span>
                </div>
              </div>

              <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2 radio_btns noPadding">
                <div class="inputFields">
                  <label class="formLabel radioLabel"><span class="red-text">*</span>Action</label>
                  <div>
                    <div [ngClass]="{'has-error': caseCreateForm.value.submitted && !actionRequest}">
                      <p>
                        <input type="radio" name="actionRequest" class="keep_radio_btn"
                          value="{{ actionItems.option1.id }}" formControlName="actionRequest" [(ngModel)]="actionRequest" required/>
                        <label>{{ actionItems.option1.value }}</label>
                      </p>
                      <p *ngIf="!incorrectDeliveryLocation">
                        <input type="radio" name="actionRequest" class="return_radio_btn"
                          value="{{ actionItems.option2.id }}" formControlName="actionRequest"  [(ngModel)]="actionRequest" required/>
                        <label>{{ actionItems.option2.value }}</label>
                      </p>
                  </div>
                  </div>
                  <span *ngIf="caseCreateForm.value.submitted && !actionRequest" style="color: red; font-size:15px;">Please select option</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="!incorrectDeliveryLocation">
            <input type="hidden" formControlName="statusMessageList">
            <ul>
              <li *ngFor="let statusMessage of statusMessageList">
                <span [innerHTML]="statusMessage">{{statusMessage}}</span>
              </li>
            </ul>
          </div>

        </div>
      </div>
      <div *ngIf="incorrectMaterial">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 shipping_discrepancy_section noPadding">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 noPadding">
            <p style="font-weight: bold;">
              <span *ngIf="getFormArray().length > 1" class="icon icon-close-small" (click)="deleteForm()">
              </span>
              Incorrect Material #{{ formIndex + 1 }}
            </p>
          </div>
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12  noPadding">
              <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 health_Div">
                <div class="inputFields">
                  <div>
                    <label class="formLabel"><span class="red-text">*</span>Item # or Description</label>
                  </div>
                  <input type="text" placeholder="Enter Item # or Description" maxlength="18"
                    class="form-control material_input" formControlName="itemId" [(ngModel)]="itemId"
                    [ngClass]="{'has-error':  caseCreateForm.value.submitted && !itemId}"
                    />
                    <span *ngIf="caseCreateForm.value.submitted && !itemId" style="color: red; font-size:15px;">Item # or Description is required</span>
                </div>
              </div>
              <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2 received_qty_Div noPadding">
                <div class="inputFields">
                  <div>
                    <label class="formLabel"><span class="red-text">*</span>QTY Received</label>
                  </div>
                  <input type="text" placeholder="QTY" class="form-control received_qty_input"
                    formControlName="quantityReceived" numericOnly [zeroNotAllowed]="isZeroAllowed" [(ngModel)]="qtyReceived"
                    [ngClass]="{'has-error':  caseCreateForm.value.submitted && !qtyReceived}"
                    />
                    <!-- {{caseCreateForm.value.submitted}}
                    {{caseCreateForm.controls.quantityReceived?.value | json}}  -->
                    <span *ngIf="caseCreateForm.value.submitted && !qtyReceived" style="color: red; font-size:15px;">QTY is required</span>
                </div>
              </div>
              <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2 uom_Div noPadding">
                <div class="inputFields">
                  <div>
                    <label class="formLabel"><span class="red-text">*</span>UOM Received</label>
                  </div>
                  <input type="text" placeholder="UOM Received" maxlength="3" class="form-control uom_input"
                    formControlName="uomReceived" alphabetOnly [(ngModel)]="uomReceived"
                    [ngClass]="{'has-error':  caseCreateForm.value.submitted && !uomReceived}"
                    />
                    <span *ngIf="caseCreateForm.value.submitted && !uomReceived" style="color: red; font-size:15px;">UOM is required</span>
                </div>
              </div>
              <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3 radio_btns noPadding">
                <div class="inputFields">
                  <label class="formLabel radioLabel"><span class="red-text">*</span>Action</label>
                  <div>
                    <div [ngClass]="{'has-error': caseCreateForm.value.submitted && !actionRequest}">
                      <p>
                        <input type="radio" name="actionRequest" class="keep_radio_btn"
                          value="{{ actionItems.option1.id }}" formControlName="actionRequest" [(ngModel)]="actionRequest"/>
                          <label>{{ actionItems.option1.value }}</label>
                      </p>
                      <p>
                        <input type="radio" name="actionRequest" class="return_radio_btn"
                          value="{{ actionItems.option2.id }}" formControlName="actionRequest" [(ngModel)]="actionRequest"/>
                        <label>{{ actionItems.option2.value }}</label>
                      </p>
                  </div>
                  </div>
                  <span *ngIf="caseCreateForm.value.submitted && !actionRequest" style="color: red; font-size:15px;">Please select option</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
