<div id="itemSelectionModal">
 <div class=" row">
    <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 search-input ">
      <div style="text-align: right; padding-top: 5px;"><strong>Search</strong></div>
    </div>
    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 search-input">
      <input type="text" id="searchtext-input" name="searchText" maxlength="100" class="form-control search"
        [(ngModel)]="searchText" placeholder="Enter text to filter results" />
    </div>
    <div class ="addItemButton">
      <button class="cta-btn primary red_button "
      [disabled]="validateAddItemButton()" (click)="addItems()">
      {{ addItemButtonName }}
      </button>
    </div>
  </div>
  
  
  <table class="table " aria-describedby="Add Items">
    <thead>
      <tr>
        <th scope="checkbox">
          <input *ngIf="multiValueSelect" type="checkbox" [disabled]="checkBoxDisabled()" [(ngModel)]="hasSelectedAllItems" (change)="selectAllItems()"/>
        </th>
        <th scope="colLabel" *ngFor="let colLabel of tableColsLabel ">{{ colLabel }}</th>
      </tr>
    </thead>
    <tbody>
      <tr [ngClass]="{'gray-background': (tableData.disabled && !tableData.selected)}" *ngFor="let tableData of tableDataSrc| itemsFilter : searchText">
        <td>
          <input type="checkbox" *ngIf="multiValueSelect" [disabled]="tableData.disabled" 
                  [(ngModel)]="tableData.selected" (change)="toggleCheckBox(tableData)"/>
          <input type="radio" *ngIf="!multiValueSelect" name="selectedItem" class="keep_radio_btn" 
                  (change)="addSelectedItem(tableData)" />
        </td>
        <td *ngFor="let colData of tableCols">
            <span   [innerHTML]="tableData[colData]">{{ tableData[colData] }}</span>
        </td>
      </tr>
    </tbody>
    
  </table>
</div>