<!-- Widget Styles -- overrides id genesys_widgets_custom -->


<div class="helpMain">
   <div class="row" id="help-main-div">
    <div  class="spinner-main" [ngClass]="{'spinner-show': cspShowSpinner}">
       <div class="spinnerDiv" >
       </div>
   </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 searchIconDiv">
         <div class="pageHeading">
            <h1 class="pageTitle">
               Welcome to Cardinal Health Market<sup>SM</sup> Help!
            </h1>
            <p class="paragraphTextBody">
               Cardinal Health Market<sup>SM</sup> Help provides our customers with the ability to find answers to questions or issues they may encounter, create cases, and shows the contact information for our customer support team.
            </p>
         </div>
 
         <!-- The below button is for testing only to route to create case form from order details -->
     
         <div *ngFor="let helpList of helpLandingList;" class="col-xs-4 col-sm-3 col-md-3 col-lg-3 text-center helpiconsDiv">
            <nav>
         
             <a *ngIf="helpList.pageLink" 
             title={{helpList.title}}
             [ngClass]="{'disabled': getChatDisabled(helpList) }"
             class="navLinks" data-id="caseIcon" [routerLink]="[helpList.pageLink]">
                <span class="{{helpList.imgUrl}}"></span>
             <div class="linkText text-center" 
             [ngClass]="{'disabled': getChatDisabled(helpList) }"
             >{{helpList.textLink}}</div>
             </a>
             <a *ngIf="helpList.externalLink" class="navLinks" data-id="caseIcon" [href]="[helpList.externalLink]" target="_self">
                <span class="{{helpList.imgUrl}}"></span>
             <div class="linkText text-center">{{helpList.textLink}}</div>
             </a>
            </nav>
         </div>

         <!-- <input type="hidden" id="help-chat-target" value="MED_ProductSupport_Chat_Z1_English">
         <iframe  [hidden]="true" id="chat-portal-frame" title="Chat" src="https://wdoh0019ininm01.dev.npch.int:8443/I3PortalChat/chatOrCallback.html"></iframe> -->


         <!-- <input class="filter-url" type="hidden" value="/content/market/en_US/help/jcr:content/main-parsys/helpfilter.filter.html">
           <div id ="help-filter" value="MED_ProductSupport_Chat_Z1_English" [hidden]="true"></div> -->
      </div>
   </div>
</div>
