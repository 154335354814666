import { IdValue } from '../model/idValue';

export class StatusRequestTypeMapping  {
    public static readonly STATUS_REQ_TYPE_MAP = new Map<string, Array<IdValue>>([
        ["Shipped", [
                        {
                            "id": "RT_1",
                            "value": "Order Status"
                        },
                        {
                            "id": "RT_16",
                            "value": "Shortages"
                        },
                        {
                            "id": "RT_17",
                            "value": "Shipping Overages"
                        },
                        {
                            "id": "RT_18",
                            "value": "Damaged Goods"
                        },
                        {
                            "id": "RT_8",
                            "value": "Product Complaints"
                        },
                        {
                            "id": "RT_19",
                            "value": "Incorrect Material Delivered"
                        },
                        {
                            "id": "RT_22",
                            "value": "Short-dated Product"
                        },
                        {
                            "id": "RT_23",
                            "value": "Expired Product"
                        },
                        {
                            "id": "CA1_116",
                            "value": "Return Product"
                        }
                    ]
        ],
        ["Dropship", [
                        {
                            "id": "RT_1",
                            "value": "Order Status"
                        },
                        {
                            "id": "RT_16",
                            "value": "Shortages"
                        },
                        {
                            "id": "RT_17",
                            "value": "Shipping Overages"
                        },
                        {
                            "id": "RT_18",
                            "value": "Damaged Goods"
                        },
                        {
                            "id": "RT_8",
                            "value": "Product Complaints"
                        },
                        {
                            "id": "RT_19",
                            "value": "Incorrect Material Delivered"
                        },
                        {
                            "id": "RT_22",
                            "value": "Short-dated Product"
                        },
                        {
                            "id": "RT_23",
                            "value": "Expired Product"
                        },
                        {
                            "id": "CA1_116",
                            "value": "Return Product"
                        }
                    ]
        ],
        ["Pending", [
                        {
                        "id": "RT_1",
                        "value": "Order Status"
                        }
                    ]
        ],
        ["Scheduled for shipping", [
                        {
                        "id": "RT_1",
                        "value": "Order Status"
                        }
                    ]
        ],
        ["Backordered", [
                        {
                        "id": "RT_1",
                        "value": "Order Status"
                        }
                    ]
        ],
        ["OutOfStock", [
                        {
                        "id": "RT_1",
                        "value": "Order Status"
                        }
                    ]
        ],
        ["Inandout", [
                        {
                        "id": "RT_1",
                        "value": "Order Status"
                        },
                        {
                            "id": "CA1_116",
                            "value": "Return Product"
                        }
                    ]
        ],
        ["OnHold", [
            {
            "id": "RT_1",
            "value": "Order Status"
            }
            ]
        ],
        ["DefaultStatus",[
            {
                "id": "RT_1",
                "value": "Order Status"
            }
        ]]
    ]);
}
