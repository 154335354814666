<form [formGroup]="caseCreateForm">
    <div formArrayName="items">
      <div [formGroupName]="formIndex">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 return_no_longer_needed_section noPadding">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 noPadding">
              <p>
              <span *ngIf="getFormArray().length > 1" class="icon icon-close-small" (click)="deleteForm()"></span>
              Product #{{ formIndex + 1 }} -
              <input type="hidden" 
              formControlName="itemDescription">
              <span [innerHTML]="itemDescription"> {{ itemDescription }}</span>
            </p>
          </div>
          <div class = "row">
          <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3 health_Div">
            <div class="inputFields">
              <label class="formLabel">Cardinal Health Material #</label
              >
              <input
                type="text" readonly class="form-control material_input"
                placeholder="Enter Material #..."
                maxlength="18"
                formControlName="itemId"
              />
            </div>
          </div>
          <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2 qty_Div noPadding">
            <div class="inputFields">
              <label class="formLabel">QTY Ordered</label>
              <input type="text" readonly
                class="form-control qty_input"
                formControlName="quantityOrdered"
                maxlength="15"
                placeholder="QTY"  numericOnly  [zeroNotAllowed]="true"
              />
            </div>
          </div>
          <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2 uom_Div noPadding">
            <div class="inputFields">
              <label class="formLabel">UOM</label>
              <input
                type="text" readonly
                class="form-control uom_input"
                formControlName="uom"  maxlength="3"
                placeholder="UOM"
                alphabetOnly
              />
            </div>
          </div>
          <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2 qty_return noPadding">
              <div class="inputFields">
                <label class="formLabel"><span class="red-text">*</span>QTY to Return</label>
                <input
                  type="text"
                  maxlength="15"
                  placeholder="QTY to Return"
                  class="form-control qty_return_input"
                  formControlName="quantityReceived"
                  numericOnly
                  [zeroNotAllowed]="true"
                  [(ngModel)]="quantityReceived"
                  [ngClass]="{'has-error':  caseCreateForm.value.submitted && !quantityReceived}"
                />
              <span *ngIf="caseCreateForm.value.submitted && !quantityReceived" style="color: red; font-size:15px;">QTY is required</span>
                
              </div>
            </div>
          <div  class="col-xs-12 col-sm-3 col-md-3 col-lg-3 uom_return noPadding" >
            <div class="inputFields">
              <label class="formLabel"
                ><span class="red-text">*</span>UOM to Return</label
              >
              <input
                type="text"
                maxlength="3"
                placeholder="UOM to Return"
                class="form-control uom_return_input"
                formControlName="uomReceived"
                alphabetOnly
                [(ngModel)]="uomReceived"
                [ngClass]="{'has-error':  caseCreateForm.value.submitted && !uomReceived}"
                />
              <span *ngIf="caseCreateForm.value.submitted && !uomReceived" style="color: red; font-size:15px;">UOM is required</span>
              
            </div>
          </div>
          <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3 lot_div noPadding" *ngIf="isProductRecallForm">
            <div class="inputFields">
              <label class="formLabel"><span class="red-text">*</span>Lot #</label>
              <input
                type="text"
                placeholder="LOT #"
                class="form-control lot_div_input"
                formControlName="lotNo"
                [(ngModel)]="lotNo"
                [ngClass]="{'has-error':  caseCreateForm.value.submitted && !lotNo}"
                />
              <span *ngIf="caseCreateForm.value.submitted && !lotNo" style="color: red; font-size:15px;">Lot is required</span>
            
            </div>
          </div>
          </div>
          <div class="row">
            <input type="hidden" formControlName="statusMessageList">
            <ul>
              
              <li *ngFor="let statusMessage of statusMessageList">
                <span [innerHTML]="statusMessage">{{statusMessage}}</span>
              </li>
              <li *ngIf="isIncorrectProductOrderedForm">
                <span>If you need a replacement, please order the correct product via Product Search</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </form>
  