
import { ComponentRef, Directive, ElementRef, EmbeddedViewRef, HostListener, Input, ViewContainerRef } from '@angular/core';
import { TooltipComponent } from './tooltip/tooltip.component';

@Directive({
  selector: '[tooltip]'
})
export class TooltipDirective {

  @Input() tooltip = '';
  @Input() position: string = 'right';
  @Input() theme: string = 'white';

  private componentRef: ComponentRef<any> | null = null;

  constructor(private elementRef: ElementRef,
              public viewContainerRef: ViewContainerRef
              ) {
  }

  @HostListener('mouseenter')
  onMouseEnter(): void {
    this.componentRef = this.viewContainerRef.createComponent(TooltipComponent)
    const [tooltipDOMElement] = (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes; 
    if (this.componentRef !== null) {
      this.componentRef.instance.tooltip = this.tooltip;
      this.componentRef.instance.position = this.position;
      this.componentRef.instance.theme = this.theme;

      const {left, right, top, bottom} = this.elementRef.nativeElement.getBoundingClientRect();

      if(this.position ==='below')  {
          this.componentRef.instance.left = Math.round((right - left) / 2 + left);
          this.componentRef.instance.top = Math.round(bottom);
        }
      else if(this.position === 'right') {
          this.componentRef.instance.left = Math.round(right);
          this.componentRef.instance.top = Math.round(top + (bottom - top) / 2);
        }
    }
    document.body.appendChild(tooltipDOMElement);
    window.setTimeout(()=>{ this.componentRef.instance.visible = true;}, 0);
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    window.setTimeout(()=>{this.componentRef.destroy();}, 0);
  }

}