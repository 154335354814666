import { environment } from '../../../environments/environment';
export class HelpConstants {

	public static readonly HELP_LANDING_LIST = [
		
		{
			imgUrl: 'help-case-icon',
			textLink: 'Create New Help Case',
			pageLink: '/case/create'
		},
		{
			imgUrl: 'help-search-icon',
			textLink: 'Search and View Help Cases',
			pageLink: '/case/search'
		},
		
		{
			imgUrl: 'help-chat-icon',
			textLink: 'Chat',
			pageLink: '/help',
			title: 'Agent Chat is available Mon-Fri from 8am-5pm ET'
		},
    
		{
			imgUrl: 'help-order-icon',
			textLink: 'Order Resolution',
			externalLink: environment.baseRoutingUrl + '/ordrmgt/order-resolution'
		},
		{
			imgUrl: 'help-setting-icon',
			textLink: 'Account Setting',
			externalLink: environment.baseRoutingUrl + '/myacct/profile/profile-info'
		},
		{
			imgUrl: 'help-track-icon',
			textLink: 'Orders / Packing Slips / Tracking details',
			externalLink: environment.baseRoutingUrl + '/ordrmgt/orders/order-search'

		},
		{
			imgUrl: 'help-invoices-icon',
			textLink: 'Invoices',
			externalLink: environment.baseRoutingUrl + '/ordrmgt/invoices/invoice-search'
		},
		{
			imgUrl: 'help-faq-icon',
			textLink: 'FAQs',
			externalLink: environment.baseRoutingUrl + '/pages/faq'

		},
		{
			imgUrl: 'help-training-icon',
			textLink: 'Training',
			externalLink: environment.baseRoutingUrl + '/pages/Training'
    }
    
	];

	public static readonly HELP_LANDING_LIST_WITHOUT_CHAT = [
		
		{
			imgUrl: 'help-case-icon',
			pageLink: '/case/create',
			textLink: 'Create New Help Case',
		},
		{
			textLink: 'Search and View Help Cases',
			imgUrl: 'help-search-icon',
			pageLink: '/case/search'
		},
		{
			imgUrl: 'help-order-icon',
			externalLink: environment.baseRoutingUrl + '/ordrmgt/order-resolution',
			textLink: 'Order Resolution',
		},
		{
			textLink: 'Account Setting',
			externalLink: environment.baseRoutingUrl + '/myacct/profile/profile-info',
			imgUrl: 'help-setting-icon',
		},
		{
			textLink: 'Orders / Packing Slips / Tracking details',
			imgUrl: 'help-track-icon',
			externalLink: environment.baseRoutingUrl + '/ordrmgt/orders/order-search'

		},
		{
			textLink: 'Invoices',
			externalLink: environment.baseRoutingUrl + '/ordrmgt/invoices/invoice-search',
			imgUrl: 'help-invoices-icon',
		},
		{
			textLink: 'FAQs',
			imgUrl: 'help-faq-icon',
			externalLink: environment.baseRoutingUrl + '/pages/faq'

		},
		{
			textLink: 'Training',
			imgUrl: 'help-training-icon',
			externalLink: environment.baseRoutingUrl + '/pages/Training'
    }
    
	];
}
