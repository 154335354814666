import { Component, Input, Output, OnInit, EventEmitter, HostListener, ElementRef } from '@angular/core';
import { MultiselectDropdownModel, MultiselectDropdownRefreshModel } from '../../model/multiselect-dropdown.model';
import { selectAllValue } from '../../constants/custom-multiselect-dropdown.constants';

@Component({
  //moduleId: module.id,
  selector: 'custom-multiselect-dropdown',
  templateUrl: 'custom-multiselect-dropdown.component.html',
  styleUrls: ['custom-multiselect-dropdown.component.scss']
})
export class CustomMultiselectDropdownComponent implements OnInit {
  @Input() items: Array<MultiselectDropdownModel> = [];
  @Input() selectAllText: string;
  @Input() name: string;
  @Input() pageSize: number = 20;
  @Input() pagingThresholdPercentage: number = 20;
  @Input() removedFilter: MultiselectDropdownModel;
  @Input() refreshDropDown: MultiselectDropdownRefreshModel;
  @Input () isMultiSelect:boolean;

  @Output() onFilterChange: EventEmitter<MultiselectDropdownModel[]>
     = new EventEmitter<MultiselectDropdownModel[]>();

  displayMessage: string = '';
  isOpen: boolean = false;
  searchTerm: string = '';
  copiedItems: Array<MultiselectDropdownModel> = [];
  viewableItems: Array<MultiselectDropdownModel> = [];
  checkedItems:Array<MultiselectDropdownModel>=[];
  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    this.initialLoad();
  }
  initialLoad () {
    this.viewableItems = [];
    this.setDefaultSelection();
    this.loadData();
    this.setdisplayMessage();
    this.onFilterChange.emit(this.viewableItems);
  }

  setDefaultSelection() {

    if (this.viewableItems.length) {
      if (this.viewableItems[0].value == "All Ship To's" || this.viewableItems[0].value == "All Status" || this.viewableItems[0].value == "All Methods") {
        this.viewableItems.forEach((element, index) => {
          element.checked = index == 0;
        });
      }

    } else if (this.isCheckedAtLeastOne(this.items)) {
        this.viewableItems.push({ id: selectAllValue, value: this.selectAllText, checked: false });
    } else {
        this.viewableItems.push({ id: selectAllValue, value: this.selectAllText, checked: true });
    }

  }

  loadData(data = null) {
    /* to reset case status alone*/
    if (data != null) {
      this.viewableItems = [];
      this.viewableItems.push({ id: selectAllValue, value: this.selectAllText, checked: false });
      this.items = data;
    }

    /* to reset shipto and channel */
    this.items.forEach(element => {
      this.viewableItems.push(element);
    });
  }

  handleClick(item: MultiselectDropdownModel): void {
    (item.id===selectAllValue)?this.checkSelectAll():(this.isMultiSelect)?this.handleMultipleSelection(item):this.handleSingleSelection(item);
    this.onFilterChange.emit(this.viewableItems);
    this.setdisplayMessage();
    this.makeCopy();
  }

  handleSingleSelection(item:MultiselectDropdownModel):void {
    this.unCheckSelectAll();
    item.checked=!item.checked;
    this.unCheckOtherItems(item);
    if(!this.isCheckedAtLeastOne(this.viewableItems)) {
      this.checkSelectAll();
    }
  }
  handleMultipleSelection(item:MultiselectDropdownModel):void {
  this.unCheckSelectAll();
  item.checked = !item.checked;
  if(!this.isCheckedAtLeastOne(this.viewableItems)) {
    this.checkSelectAll();
  }
}

checkSelectAll() {
  this.viewableItems.forEach(element=>{
    (element.id===selectAllValue)?element.checked=true : element.checked=false;
  });
}

unCheckSelectAll() {
  this.viewableItems.forEach(element=>{
    if(element.id==selectAllValue) {
      element.checked=false;
    }
  })
}

unCheckOtherItems(item) {
this.viewableItems.forEach(element=>{
  if(element.id!=item.id) {
      element.checked=false;
  }
})
}

isCheckedAtLeastOne(dropDownData) : boolean {
  for(let i=0;i<dropDownData.length;i++) {
    if(dropDownData[i].checked==true)
       return true;
  }
 return false;
}

setdisplayMessage() {
  this.checkedItems=this.viewableItems.filter(element=>(element.checked==true));
  if(this.checkedItems.length==1) {
    this.displayMessage=this.checkedItems[0].value;
  }
  else if(this.checkedItems.length>1) {
    this.displayMessage= this.checkedItems.length.toString() + ' ' +this.name;
  }

}

search() {
 this.viewableItems=(this.searchTerm)?this.items.filter(element=> element.value.toLowerCase().includes(this.searchTerm.toLowerCase())):
 this.copiedItems;
}

makeCopy() {
  this.copiedItems=[];
  this.copiedItems.push({id:selectAllValue,value:this.selectAllText,checked:false});
  this.items.forEach(element=>{
    this.copiedItems.push(element);
  })
}
toggleOpen(): void {
  this.isOpen = !this.isOpen;
  this.makeCopy();
}

  @HostListener('document:click', ['$event'])
  handleOutsideClick(event: Event): void {
    if (!this.elementRef.nativeElement.contains(event.target) && this.isOpen) {
      this.toggleOpen();
    }
  }
}
