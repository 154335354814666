/**
 * https://confluence.cardinalhealth.com/display/EA/Attachments+API
 */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { UploadResponse } from '../model/uploadResponse';
import { Subject } from 'rxjs';
import { Attachment } from '../model/attachment';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { UploadRequest } from '../model/uploadRequest';
import { User } from '../shared/User';
import { CasesService } from './cases.service';
import { delay } from "rxjs/operators";


@Injectable({ providedIn: "root"})
export class AttachmentService {
  private apiUrl: string;
  private attachment: any;
  public attachmentsSubject: Subject<Array<Attachment>> = new Subject<Array<Attachment>>();
  public header: Object;
  hostname: any;

  get sharedAttachment() {
    return this.attachment;
  }

  set sharedAttachment(_attachment) {
    this.attachment = _attachment;
  }

  constructor(private http: HttpClient,
              private caseService: CasesService) {

                this.apiUrl = environment.baseApiUrl ;


    // this.hostname = window && window.location && window.location.hostname;
    // this.header= AuthHeader.getHeader(); //Lower Env X-API-Key
    // if (this.hostname.includes('localhost')){
    //   this.apiUrl = environment.baseApiUrl  + `/medical-market-attachments`;  //Dev
      
    // }else if (this.hostname.includes('marketdev') || this.hostname.includes('marketdev2')){
    //   this.apiUrl = environment.baseApiUrl  + `/medical-market-attachments`;  //Dev
     
    // } else if (this.hostname.includes('marketstg')){
    //   this.apiUrl = environment.baseApiUrl2  + `/medical-market-attachments`; //Stg
      
    // } else if (this.hostname.includes('market')){
    //   this.apiUrl = environment.baseApiUrl3  + `/medical-market-attachments`; //Prod
    //   this.header=AuthHeader.getHeaderProd(); //Prod X-API-Key
    // } 

   }

  public uploadFiles(requestData: UploadRequest): Observable<UploadResponse | any> {

  	// if (this.hostname.includes('marketdev') || this.hostname.includes('marketdev2') ||
		//  this.hostname.includes('marketstg') || this.hostname.includes('localhost')){ 
		// 	this.header=AuthHeader.getHeader(); 
		//   } 
		//   else if (this.hostname.includes('market')){
		// 	this.header = AuthHeader.getHeaderProd();
		//   }

    const formData = new FormData();
    formData.append("file", requestData.attachments[0].file, requestData.attachments[0].name);
    const { soldToAccountNumber: soldToId, shipToAccountNumber: shipToId } = User.getUser();
    return   this.http.post(this.apiUrl  + `/medical-market-attachments/soldtos/${soldToId}/shiptos/${shipToId}/v1/attachments`, formData).pipe(delay(1000))

  }


  // public deleteFiles(deleteRequest: DeleteAttachmentRequest): Observable<DeleteAttachmentResponse | any> {
  //   const { soldToAccountNumber: soldToId, shipToAccountNumber: shipToId } = User.getUser();
  //   const ecmDocId = deleteRequest.ecmDocIds
  //   return this.http.delete(this.apiUrl + `/medical-market-attachments/soldtos/${soldToId}/shiptos/${shipToId}/v1/attachments/${ecmDocId}`, this.header)
  //  ;
  
  // }

  public downloadFile(ecmDocId: string) {
    return this.http.get(this.apiUrl + `/medical-market-attachments/download/v1/attachments/${ecmDocId}`)
  }

 
}
