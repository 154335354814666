<div class="container-fluid px-0">
  <div class="spinner-main" [ngClass]="{'spinner-show': cspShowSpinner}">
    <div class="spinnerDiv">
    </div>
  </div>
  <form [formGroup]="caseCreateForm">

    <div class="row" formArrayName="items">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-4">

        <ng-container *ngFor="let item of recievedLineItems.controls; let i = index" , [formGroupName]="i">
          <div class="row">
            
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <span *ngIf="recievedLineItems.length > 1" class="icon icon-close-small close-icon-margin" (click)="deleteFormItem(i)"></span>
              <label> Product #{{i + 1}} : <span class="productTitle" [innerHTML]="localStorageData.lineItems[i].title">
                  {{localStorageData.lineItems[i].title }} </span>
              </label>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div class="table-responsive">
                <table class="table table-borderless" aria-describedby="Order Details">

                  <thead>
                    <tr>
                      <th scope="col" style="width: 100px;">Cardinal Health Material #</th>
                      <th scope="col" style="width: 60px;">QTY Ordered</th>
                      <th scope="col" style="width: 60px;">UOM</th>
                      <th scope="col" style="width: 240px;">
                        <span class="text-danger">*</span> Request Type
                      </th>
                      <th scope="col" style="width: 240px;" *ngIf="selectedRequestType.value === 'CA1_116'">
                        <span class="text-danger">*</span> Return Reason
                      </th>
                      <th scope="col" style="width: 135px;" *ngIf="selectedRequestType.value === 'RT_16'">
                        <span class="text-danger">*</span> QTY Received
                      </th>
                      <th scope="col" style="width: 135px;" *ngIf="selectedRequestType.value === 'RT_16'">
                        <span class="text-danger">*</span> UOM Received
                      </th>
                      <th  *ngIf="selectedRequestType.value === 'RT_22'"
                      scope="col" style="width: 135px;">
                        <span class="text-danger" >*</span> QTY Short-dated
                      </th>
                      <th scope="col" style="width: 135px;" *ngIf="selectedRequestType.value === 'RT_22'">
                        <span class="text-danger">*</span> UOM Short-dated
                      </th>
                      <th scope="col" style="width:220px" *ngIf="selectedRequestType.value === 'RT_22'">
                        <span class="text-danger">*</span> Expiration Date
                      </th>
                      <th scope="col" style="width: 135px" *ngIf="selectedRequestType.value === 'RT_17'">
                        <span class="text-danger">*</span> QTY Received
                      </th>
                      <th scope="col" style="width: 135px;" *ngIf="selectedRequestType.value === 'RT_17'">
                        <span class="text-danger">*</span> UOM Received
                      </th>
                      <th scope="col" style="width: 135px" *ngIf="selectedRequestType.value === 'RT_18'">
                        <span class="text-danger">*</span> QTY Damaged
                      </th>
                      <th scope="col" style="width: 135px" *ngIf="selectedRequestType.value === 'RT_18'">
                        <span class="text-danger">*</span> Damaged Type
                      </th>
                      <th scope="col" style="width: 200px" *ngIf="selectedRequestType.value === 'RT_17'">
                        <span class="text-danger">*</span> Action
                      </th>
                      <th scope="col" style="width: 200px" *ngIf="selectedRequestType.value === 'RT_18'">
                        <span class="text-danger">*</span> Action
                      </th>
                      <th scope="col" style="width: 200px" *ngIf="selectedRequestType.value === 'RT_16'">
                        <span class="text-danger">*</span> Action
                      </th>


                      <th scope="col" style="width:220px" *ngIf="selectedRequestType.value === 'RT_8'">
                        <span class="text-danger">*</span> LOT #
                      </th>

                      <th scope="col" style="width:220px" *ngIf="selectedRequestType.value === 'RT_8'">
                        <span class="text-danger">*</span> Event Date
                      </th>

                      <th *ngIf="selectedRequestType.value === 'RT_23'" style="width:220px"
                        scope="col">
                        <span class="text-danger">*</span> LOT #
                      </th>

                      <th scope="col" style="width:220px" *ngIf="selectedRequestType.value === 'RT_23'">
                        <span class="text-danger">*</span> Expiration Date
                      </th>

                      <th scope="col" style="width:220px" *ngIf="selectedRequestType.value === 'RT_19'">
                        <span class="text-danger">*</span>Was this item missing from your order?
                      </th>

                      <th scope="col" style="width:220px"
                        *ngIf="(selectedRequestType.value !== 'RT_8') && (selectedRequestType.value !== 'RT_19') 
                        && (selectedRequestType.value !== 'RT_22')  && (selectedRequestType.value !== 'RT_23') 
                        && (selectedRequestType.value !== 'CA1_116')">
                        Comments</th>


                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>
                        <input style="width:160px" type="text" class="form-control" [attr.disabled]="true"
                          formControlName="itemId">
                      </td>
                      <td>
                        <input style="width:100px" type="text" class="form-control" [attr.disabled]="true"
                          formControlName="quantityOrdered">
                      </td>
                      <td>
                        <input style="width:100px" type="text" class="form-control" [attr.disabled]="true"
                          formControlName="unitOfMeasure">
                      </td>
                      <td>

                        <select class="form-control" style="width:240px" formControlName="requestTypeId"
                          #selectedRequestType (change)="onRequestTypeSelect($event, i)"
                          [ngClass]="{'mandatory-error': caseCreateForm.value.submitted && item.get('requestTypeId').invalid}" >
                          <option [value]="null" disabled [selected]="true">Select One</option>
                          <option [value]="request.id"
                            *ngFor="let request of localStorageData.lineItems[i].requestTypes">{{request.value}}
                          </option>
                        </select>
                        <span class="text-danger"
                          *ngIf="caseCreateForm.value.submitted && item.get('requestTypeId').invalid">
                          <span class="text-danger" *ngIf="item.get('requestTypeId').errors.required ">Required
                            field</span>
                        </span>
                      </td>
                      <td *ngIf="selectedRequestType.value === 'CA1_116'">
                        <div [ngClass]="{'mandatory-error': (caseCreateForm.value.submitted && item.get('returnReason').invalid)}">
                          <div class="custom-control custom-radio">
                            <input id="{{'noLongerNeededLabel' + i}}" type="radio" value="RT_27" (change)="onReturnReasonSelect($event, i)"
                              class="custom-control-input" [attr.disabled]="(item.get('shippedElegibilityFlag').value || item.get('nonReturnableFlag').value||
                                                item.get('inAndOutFlag').value)?'true':null" formControlName="returnReason">
                            <label class="custom-control-label" for="{{'noLongerNeededLabel' + i}}">
                              No Longer Needed
                            </label>
                          </div>
                          <div class="custom-control custom-radio">
                            <input id="{{'incorrectQtyUomLabel' + i}}" type="radio" value="RT_28" (change)="onReturnReasonSelect($event, i)"
                              class="custom-control-input"
                              [attr.disabled]="item.get('nonReturnableFlag').value || (item.get('shippedElegibilityFlag').value || item.get('inAndOutFlag').value)?'true':null"
                              formControlName="returnReason">
                            <label class="custom-control-label" for="{{'incorrectQtyUomLabel' + i}}">
                              Incorrect Quantity/UOM Ordered
                            </label>
                          </div>
                          <div class="custom-control custom-radio">
                            <input id="{{'incorrectProductLabel' + i}}" type="radio" value="RT_29" (change)="onReturnReasonSelect($event, i)"
                              class="custom-control-input"
                              [attr.disabled]="item.get('nonReturnableFlag').value || (item.get('shippedElegibilityFlag').value || item.get('inAndOutFlag').value)?'true':null"
                              formControlName="returnReason">
                            <label class="custom-control-label" for="{{'incorrectProductLabel' + i}}">
                              Incorrect Product Ordered
                            </label>
                          </div>
                          <div class="custom-control custom-radio">
                            <input type="radio" id="{{'productRecallLable' + i}}" value="RT_30" class="custom-control-input"
                              formControlName="returnReason" (change)="onReturnReasonSelect($event, i)">
                            <label class="custom-control-label" for="{{'productRecallLable' + i}}">Product Recall</label>
                          </div>
                        </div>
                        <span class="text-danger" *ngIf="caseCreateForm.value.submitted && item.get('returnReason').invalid ||
                          (item.get('returnReason').dirty && item.get('returnReason').invalid)">
                            <span class="text-danger" *ngIf="item.get('returnReason').errors.required">Please select option</span>
                          </span>
                      </td>
                      <td *ngIf="selectedRequestType.value === 'RT_16'">
                        <input style="width:80px" type="text" class="form-control" placeholder="QTY"
                          formControlName="quantityRecieved_16" numericOnly
                          [ngClass]="{'mandatory-error': (caseCreateForm.value.submitted && item.get('quantityRecieved_16').invalid) 
                          || (item.get('quantityRecieved_16').dirty && item.get('quantityRecieved_16').invalid) }">
                        <span class="text-danger" *ngIf="(caseCreateForm.value.submitted && item.get('quantityRecieved_16').invalid) ||
                                                 (item.get('quantityRecieved_16').dirty && item.get('quantityRecieved_16').invalid) ">
                          <span class="text-danger" *ngIf="item.get('quantityRecieved_16').errors.required">Required field</span>
                        </span>
                        <span class="text-danger" *ngIf=" item.get('quantityRecieved_16').invalid">
                          <span  class="text-danger" *ngIf="item.get('quantityRecieved_16').errors.pattern">Numeric
                            values
                            only</span>
                        </span>
                      </td>
                      <td *ngIf="selectedRequestType.value === 'RT_16'" style="width: 220px; position: relative; top: -20px;">,
                        <input type="text" class="form-control" placeholder="UOM Received"  maxlength="3"
                            formControlName="uomRecieved_16" alphabetOnly
                            [ngClass]="{'mandatory-error': caseCreateForm.value.submitted && item.get('uomRecieved_16').invalid
                            || (item.get('uomRecieved_16').dirty && item.get('uomRecieved_16').invalid)}">
                          <span class="text-danger" *ngIf="caseCreateForm.value.submitted && item.get('uomRecieved_16').invalid ||
                                                  (item.get('uomRecieved_16').dirty && item.get('uomRecieved_16').invalid)">
                            <span class="text-danger" *ngIf="item.get('uomRecieved_16').errors.required">Required field</span>
                            <span class="text-danger" *ngIf=" item.get('uomRecieved_16').invalid">
                              <span class="text-danger" *ngIf="item.get('uomRecieved_16').errors.pattern">Alphabet values only</span>
                            </span>
                          </span>
                      </td>
                      <td *ngIf="selectedRequestType.value === 'RT_17'">
                        <input style="width:80px" type="text" class="form-control" placeholder="QTY"
                          formControlName="quantityRecieved_17" numericOnly
                          [ngClass]="{'mandatory-error': caseCreateForm.value.submitted && item.get('quantityRecieved_17').invalid
                          || (item.get('quantityRecieved_17').dirty && item.get('quantityRecieved_17').invalid)}">
                        <span class="text-danger" *ngIf="caseCreateForm.value.submitted && item.get('quantityRecieved_17').invalid ||
                        (item.get('quantityRecieved_17').dirty && item.get('quantityRecieved_17').invalid)">
                          <span class="text-danger" *ngIf="item.get('quantityRecieved_17').errors.required">Required
                            field</span>
                        </span>
                        <span class="text-danger" *ngIf=" item.get('quantityRecieved_17').invalid">
                          <span  class="text-danger" *ngIf="item.get('quantityRecieved_17').errors.pattern">Numeric
                            values
                            only</span>
                        </span>
                      </td>
                      <td *ngIf="selectedRequestType.value === 'RT_17'" style="width: 220px; position: relative; top: -20px;">,
                        <input type="text" class="form-control" placeholder="UOM Received"  maxlength="3"
                            formControlName="uomRecieved_17" alphabetOnly
                            [ngClass]="{'mandatory-error': caseCreateForm.value.submitted && item.get('uomRecieved_17').invalid
                            || (item.get('uomRecieved_17').dirty && item.get('uomRecieved_17').invalid)}">
                          <span class="text-danger" *ngIf="caseCreateForm.value.submitted && item.get('uomRecieved_17').invalid ||
                                                  (item.get('uomRecieved_17').dirty && item.get('uomRecieved_17').invalid)">
                            <span class="text-danger" *ngIf="item.get('uomRecieved_17').errors.required">Required field</span>
                            <span class="text-danger" *ngIf=" item.get('uomRecieved_17').invalid">
                              <span class="text-danger" *ngIf="item.get('uomRecieved_17').errors.pattern">Alphabet values only</span>
                            </span>
                          </span>
                      </td>
                      <td *ngIf="selectedRequestType.value === 'RT_22'">
                        <input type="text" class="form-control" placeholder="QTY"
                          formControlName="quantityShortDated" numericOnly pattern="[0-9]*"
                          [ngClass]="{'mandatory-error': (caseCreateForm.value.submitted && item.get('quantityShortDated').invalid) 
                          || (item.get('quantityShortDated').dirty && item.get('quantityShortDated').invalid) }">
                        <span class="text-danger" *ngIf="(caseCreateForm.value.submitted && item.get('quantityShortDated').invalid) ||
                        (item.get('quantityShortDated').dirty && item.get('quantityShortDated').invalid)">
                          <span class="text-danger" *ngIf="item.get('quantityShortDated').errors.required">Required
                            field</span>
                        </span>
                        <span class="text-danger" *ngIf=" item.get('quantityShortDated').invalid">
                          <span  class="text-danger" *ngIf="item.get('quantityShortDated').errors.pattern">Numeric
                            values
                            only</span>
                        </span>
                      </td>
                      <td *ngIf="selectedRequestType.value === 'RT_22'">
                        <input style="width: 200px;" type="text" class="form-control" placeholder="UOM" maxlength="3"
                          formControlName="uomShortdated" alphabetOnly
                          [ngClass]="{'mandatory-error': caseCreateForm.value.submitted && item.get('uomShortdated').invalid
                          || (item.get('uomShortdated').dirty && item.get('uomShortdated').invalid)}">
                          <span class="text-danger" *ngIf="caseCreateForm.value.submitted && item.get('uomShortdated').invalid ||
                          (item.get('uomShortdated').dirty && item.get('uomShortdated').invalid)">
                            <span class="text-danger" *ngIf="item.get('uomShortdated').errors.required">Required
                              field</span>
                          </span>
                          <span class="text-danger" *ngIf=" item.get('uomShortdated').invalid">
                            <span  class="text-danger" *ngIf="item.get('uomShortdated').errors.pattern">Alphabet
                              values
                              only</span>
                          </span>
                      </td>
                      <td *ngIf="selectedRequestType.value === 'RT_22' ">   
                          
                            <app-market-help-date-picker style="width:220px" 
                                [caseCreateForm]="caseCreateForm" [minDate]="minShortDated" [maxDate]="maxShortDated"
                                [reqType]="selectedRequestType.value" (invalidDate)= "setInvalidExpDateflag($event , i)">
                            </app-market-help-date-picker>                 
                               
                          <span *ngIf="!item.get('invalidExpirationDate').value" class="red-text">A product is short-dated when received within 60 days of expiration.</span>
                      </td>
                      <td *ngIf="selectedRequestType.value === 'RT_18'">
                        <input style="width:80px" type="text" class="form-control" placeholder="QTY"
                          formControlName="quantityDamaged" numericOnly
                          [ngClass]="{'mandatory-error': (caseCreateForm.value.submitted && item.get('quantityDamaged').invalid) 
                          || (item.get('quantityDamaged').dirty && item.get('quantityDamaged').invalid) }">
                        <span class="text-danger" *ngIf="(caseCreateForm.value.submitted && item.get('quantityDamaged').invalid) ||
                        (item.get('quantityDamaged').dirty && item.get('quantityDamaged').invalid)">
                          <span class="text-danger" *ngIf="item.get('quantityDamaged').errors.required">Required
                            field</span>
                        </span>
                        <span class="text-danger" *ngIf=" item.get('quantityDamaged').invalid">
                          <span  class="text-danger" *ngIf="item.get('quantityDamaged').errors.pattern">Numeric
                            values
                            only</span>
                        </span>
                      </td>
                      <td *ngIf="selectedRequestType.value === 'RT_18'">
                        <div [ngClass]="{'mandatory-error': (caseCreateForm.value.submitted && item.get('damageType').invalid)}">
                          <div style="width:140px" class="custom-control custom-radio">
                            <input id="{{'brokenDamageTypeLabel' + i}}" type="radio" value="Broken" class="custom-control-input"
                              formControlName="damageType">
                            <label class="custom-control-label" for="{{'brokenDamageTypeLabel' + i}}">
                              Broken
                            </label>
                          </div>
                          <div style="width:140px" class="custom-control custom-radio">
                            <input id="{{'packagingDistDamageTypeLabel' + i}}" type="radio" value="Packaging Destroyed"
                              class="custom-control-input" formControlName="damageType">
                            <label class="custom-control-label" for="{{'packagingDistDamageTypeLabel' + i}}">
                              Packaging Destroyed
                            </label>
                          </div>
                          <div style="width:140px" class="custom-control custom-radio">
                            <input id="{{'crushedDamageTypeLabel' + i}}" type="radio" value="Crushed" class="custom-control-input"
                              formControlName="damageType">
                            <label class="custom-control-label" for="{{'crushedDamageTypeLabel' + i}}">
                              Crushed
                            </label>
                          </div>
                          <div style="width:140px" class="custom-control custom-radio">
                            <input id="{{'leakingWetDamageTypeLabel' + i}}" type="radio" value="Leaking/Wet" class="custom-control-input"
                              formControlName="damageType">
                            <label class="custom-control-label" for="{{'leakingWetDamageTypeLabel' + i}}">
                              Leaking/Wet
                            </label>
                          </div>
                          <div style="width:140px" class="custom-control custom-radio">
                            <input id="{{'dirtyDamageTypeLabel' + i}}" type="radio" value="Dirty" class="custom-control-input"
                              formControlName="damageType">
                            <label class="custom-control-label" for="{{'dirtyDamageTypeLabel' + i}}">
                              Dirty
                            </label>
                          </div>
                        </div>
                        <span class="custom-control-input"
                          *ngIf="item.get('damageType').invalid && caseCreateForm.value.submitted">
                          <span class="text-danger" *ngIf="item.get('damageType').errors.required">Please select option</span>
                        </span>
                      </td>
                      <td *ngIf="selectedRequestType.value === 'RT_17'">
                        <div [ngClass]="{'mandatory-error': (caseCreateForm.value.submitted && item.get('action_17').invalid)}">
                          <div class="custom-control custom-radio">
                            <input type="radio" id="{{'keep' + i}}" value="01" class="custom-control-input" formControlName="action_17">
                            <label class="custom-control-label" for="{{'keep' + i}}">Keep</label>
                          </div>
                          <div class="custom-control custom-radio">
                            <input type="radio" id="{{'return' + i}}" value="02" class="custom-control-input" formControlName="action_17">
                            <label class="custom-control-label" for="{{'return' + i}}">Return</label>
                          </div>
                        </div>
                        <span class="text-danger"
                          *ngIf="item.get('action_17').invalid && caseCreateForm.value.submitted">
                          <span class="text-danger" *ngIf="item.get('action_17').errors.required">Please select option</span>
                        </span>

                      </td>
                      <td style="width:200px" *ngIf=" selectedRequestType.value === 'RT_18'">
                        <div [ngClass]="{'mandatory-error': (caseCreateForm.value.submitted && item.get('action_18').invalid)}">
                          <div style="width:120px" class="custom-control custom-radio">
                            <input type="radio" id="{{'creditOnly' + i}}" value="03" class="custom-control-input" formControlName="action_18">
                            <label class="custom-control-label" for="{{'creditOnly' + i}}">Credit Only</label>
                          </div>
                          <div style="width:120px" class="custom-control custom-radio">
                            <input type="radio" id="{{'creditAndReorder' + i}}" value="04" class="custom-control-input"
                              formControlName="action_18">
                            <label class="custom-control-label" for="{{'creditAndReorder' + i}}">Credit & Reorder</label>
                          </div>
                        </div>

                        <span class="text-danger"
                          *ngIf="item.get('action_18').invalid && caseCreateForm.value.submitted">
                          <span class="text-danger" *ngIf="item.get('action_18').errors.required">Please select option</span>

                        </span>
                      </td>
                   
                      <td style="width:200px" *ngIf="selectedRequestType.value === 'RT_16'">
                        <div [ngClass]="{'mandatory-error': (caseCreateForm.value.submitted && item.get('action_16').invalid)}">
                          <div class="custom-control custom-radio">
                            <input type="radio" id="{{'creditOnly' + i}}" value="05" class="custom-control-input" formControlName="action_16">
                            <label class="custom-control-label" for="{{'creditOnly' + i}}">Credit Only</label>
                          </div>
                          <div class="custom-control custom-radio">
                            <input type="radio" id="{{'creditAndReorder' + i}}" value="06" class="custom-control-input"
                              formControlName="action_16">
                            <label class="custom-control-label" for="{{'creditAndReorder' + i}}">Credit & Reorder</label>
                          </div>
                        </div>
                        <span class="text-danger"
                          *ngIf="item.get('action_16').invalid && caseCreateForm.value.submitted">
                          <span class="text-danger" *ngIf="item.get('action_16').errors.required">Please select option</span>

                        </span>
                      </td>
                      <td *ngIf="selectedRequestType.value === 'RT_8' ">
                        <input style="width:220px" type="text" class="form-control" placeholder="LOT #"
                          formControlName="lotNo" [ngClass]="{'mandatory-error': (caseCreateForm.value.submitted && item.get('lotNo').invalid)}">
                        <span class="text-danger" *ngIf="(caseCreateForm.value.submitted && item.get('lotNo').invalid) ||
                        (item.get('lotNo').dirty && item.get('lotNo').invalid)">
                          <span class="text-danger" *ngIf="item.get('lotNo').errors.required">Required field</span>
                        </span>
                      </td>
                      <td *ngIf="selectedRequestType.value === 'RT_8' ">
                        <app-market-help-date-picker style="width:220px" [caseCreateForm]="caseCreateForm" [maxDate] = "todayDate" [reqType]="selectedRequestType.value" 
                        >
                        </app-market-help-date-picker>
                      </td>

                      <td *ngIf="selectedRequestType.value === 'RT_23' ">
                        <input style="width:220px"  placeholder="LOT #"
                          formControlName="lotNo_23" type="text" class="form-control"
                          [ngClass]="{'mandatory-error': caseCreateForm.value.submitted && item.get('lotNo_23').invalid
                          || (item.get('lotNo_23').dirty && item.get('lotNo_23').invalid)}">
                        <span  *ngIf="caseCreateForm.value.submitted && item.get('lotNo_23').invalid ||
                        (item.get('lotNo_23').dirty && item.get('lotNo_23').invalid)" class="text-danger">
                          <span class="text-danger" *ngIf="item.get('lotNo_23').errors.required">Required field</span>
                        </span>
                      </td>
                      <td *ngIf="selectedRequestType.value === 'RT_23' ">
                        <app-market-help-date-picker style="width:220px ;max-height:32px" 
                            [caseCreateForm]="caseCreateForm" [maxDate]="minShortDated"
                            [reqType]="selectedRequestType.value" >
                        </app-market-help-date-picker>
                      </td>
                      <td style="width:220px" *ngIf=" selectedRequestType.value === 'RT_19'">
                        <div [ngClass]="{'mandatory-error': (caseCreateForm.value.submitted && caseCreateForm.value.items[i].missingFromOrder ==='')}">
                          <div class="custom-control custom-radio">
                            <input type="radio" id="{{'yes' + i}}" value="Yes" class="custom-control-input" formControlName="missingFromOrder"
                              (change)="missingOrderChange('Yes',i)">
                            <label class="custom-control-label" for="{{'yes' + i}}">Yes</label>
                          </div>
                            <div class="custom-control custom-radio">
                              <input type="radio" id="{{'no' + i}}" value="No" class="custom-control-input" formControlName="missingFromOrder"
                                (change)="missingOrderChange('No',i)">
                              <label class="custom-control-label" for="{{'no' + i}}">No</label>
                            </div>
                          </div>
                          <span class="text-danger" *ngIf="(caseCreateForm.value.submitted && caseCreateForm.value.items[i].missingFromOrder ==='')">
                            <span class="text-danger" *ngIf="caseCreateForm.value.items[i].missingFromOrder ===''">Please select option</span>
                          </span>
                      </td>

                      <td *ngIf="selectedRequestType.value !== 'RT_8' && selectedRequestType.value !== 'RT_19' 
                          && (selectedRequestType.value !== 'RT_22')  && (selectedRequestType.value !== 'RT_23')
                          && (selectedRequestType.value !== 'CA1_116') ">
                        <div class="pt-1">
                          <textarea style="width:220px" type="text" class="form-control-comments" id="comments" rows="3"
                            placeholder="Begin typing your comments here..." formControlName="comments"></textarea>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="selectedRequestType.value === 'RT_8'">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div class="table-responsive">
                <br>
                <table class="table table-borderless " aria-describedby="Request Type">
                  <thead>
                    <tr>
                      <th scope="col" style="width: 200px;">
                        <span class="text-danger">*</span> Sample Available?
                      </th>
                      <th scope="col" style="width: 200px;">
                        <span class="text-danger">*</span> Was There An Injury?
                      </th>
                      <th scope="col" style="width: 200px;">
                        <span class="text-danger">*</span> Was There A Death?
                      </th>
                      <th scope="col" style="width: 200px;">
                        <span class="text-danger">*</span> Requested Action
                      </th>
                      <th scope="col" style="width: 220px;">
                        <span class="text-danger">*</span>Comments
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style="width: 200px;">
                        <div [ngClass]="{'mandatory-error': (caseCreateForm.value.submitted && item.get('sampleAvailable').invalid)}">
                          <div class="custom-control custom-radio">
                            <input type="radio" id="{{'sampleAvailableno' + i}}" value="No" class="custom-control-input"
                              formControlName="sampleAvailable">
                            <label class="custom-control-label" for="{{'sampleAvailableno' + i}}">No</label>
                          </div>
                          <div class="custom-control custom-radio">
                            <input type="radio" id="{{'sampleAvailableYes' + i}}" value="Yes" class="custom-control-input"
                              formControlName="sampleAvailable">
                            <label class="custom-control-label" for="{{'sampleAvailableYes' + i}}">Yes</label>
                          </div>
                          <div class="custom-control custom-radio">
                            <input type="radio" id="{{'sampleAvailableUnknown' + i}}" value="Unknown" class="custom-control-input"
                              formControlName="sampleAvailable">
                            <label class="custom-control-label" for="{{'sampleAvailableUnknown' + i}}">Unknown</label>
                          </div>
                        </div>
                        <span class="text-danger" *ngIf="item.get('sampleAvailable').invalid && caseCreateForm.value.submitted">
                          <span class="text-danger" *ngIf="item.get('sampleAvailable').errors.required">Please select option</span>
                          </span>
                      </td>
                      <td style="width: 200px;">
                        <div [ngClass]="{'mandatory-error': (caseCreateForm.value.submitted && item.get('wasThereInjury').invalid)}">
                          <div class="custom-control custom-radio">
                            <input type="radio" id="{{'wasThereInjuryno' + i}}" value="No" class="custom-control-input"
                              formControlName="wasThereInjury">
                            <label class="custom-control-label" for="{{'wasThereInjuryno' + i}}">No</label>
                          </div>
                          <div class="custom-control custom-radio">
                            <input type="radio" id="{{'wasThereInjuryyes' + i}}" value="Yes" class="custom-control-input"
                              formControlName="wasThereInjury">
                            <label class="custom-control-label" for="{{'wasThereInjuryyes' + i}}">Yes</label>
                          </div>
                          <div class="custom-control custom-radio">
                            <input type="radio" id="{{'wasThereInjuryunknown' + i}}" value="Unknown" class="custom-control-input"
                              formControlName="wasThereInjury">
                            <label class="custom-control-label" for="{{'wasThereInjuryunknown' + i}}">Unknown</label>
                          </div>
                        </div>
                        <span class="text-danger"
                          *ngIf="item.get('wasThereInjury').invalid && caseCreateForm.value.submitted">
                          <span class="text-danger" *ngIf="item.get('wasThereInjury').errors.required">Please select option</span>

                        </span>
                      </td>
                      <td style="width: 200px;">
                        <div [ngClass]="{'mandatory-error': (caseCreateForm.value.submitted && item.get('wasThereDeath').invalid)}">
                          <div class="custom-control custom-radio">
                            <input type="radio" id="{{'wasThereDeathno' + i}}" value="No" class="custom-control-input"
                              formControlName="wasThereDeath">
                            <label class="custom-control-label" for="{{'wasThereDeathno' + i}}">No</label>
                          </div>
                          <div class="custom-control custom-radio">
                            <input type="radio" id="{{'wasThereDeathyes' + i}}" value="Yes" class="custom-control-input"
                              formControlName="wasThereDeath">
                            <label class="custom-control-label" for="{{'wasThereDeathyes' + i}}">Yes</label>
                          </div>
                          <div class="custom-control custom-radio">
                            <input type="radio" id="{{'wasThereDeathunknown' + i}}" value="Unknown" class="custom-control-input"
                              formControlName="wasThereDeath">
                            <label class="custom-control-label" for="{{'wasThereDeathunknown' + i}}">Unknown</label>
                          </div>
                        </div>
                        <span class="text-danger"
                          *ngIf="item.get('wasThereDeath').invalid && caseCreateForm.value.submitted">
                          <span class="text-danger" *ngIf="item.get('wasThereDeath').errors.required">Please select option</span>
                        </span>
                      </td>
                      <td style="width: 200px;">
                        <div [ngClass]="{'mandatory-error': (caseCreateForm.value.submitted && item.get('actionRequest_8').invalid)}">
                          <div class="custom-control custom-radio">
                            <input type="radio" id="{{'credit' + i}}" value="14" class="custom-control-input" formControlName="actionRequest_8">
                            <label class="custom-control-label" for="{{'credit' + i}}">Credit</label>
                          </div>
                          <div class="custom-control custom-radio">
                            <input type="radio" id="{{'replacement' + i}}" value="15" class="custom-control-input"
                              formControlName="actionRequest_8">
                            <label class="custom-control-label" for="{{'replacement' + i}}">Replacement</label>
                          </div>
                        </div>
                        <span class="text-danger"
                          *ngIf="item.get('actionRequest_8').invalid && caseCreateForm.value.submitted">
                          <span class="text-danger" *ngIf="item.get('actionRequest_8').errors.required">Please select option</span>

                        </span>
                      </td>
                      <td>
                        <div class="pt-1">
                          <textarea style="width: 220px; height: 62px;" type="text" class="form-control-comments"
                            id="comments" required placeholder="Begin typing your comments here..."
                            formControlName="comments_8" [ngClass]="{'mandatory-error': (caseCreateForm.value.submitted && item.get('comments_8').invalid)}"></textarea>
                        </div>
                        <span class="text-danger" *ngIf="caseCreateForm.value.submitted && item.get('comments_8').invalid ||
                          (item.get('comments_8').dirty && item.get('comments_8').invalid)">
                          <span class="text-danger" *ngIf="item.get('comments_8').errors.required">Required field</span>
                        </span>
                      </td>
                    </tr>
                  </tbody>

                </table>
              </div>
            </div>
          </div>

          <div
            *ngIf="selectedRequestType.value === 'RT_23'"  class="row" >
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div class="table-responsive">
                <br>
                <table class="table table-borderless " aria-describedby="Request Type">
                  <thead>
                    <tr>
                      <th scope="col" 
                      style="width: 200px;">
                        <span class="text-danger">*</span>
                         Sample Available?
                      </th>
                      <th scope="col" 
                      style="width: 200px;">
                        <span class="text-danger">*</span>
                         Was There An Injury?
                      </th>

                      <th scope="col" 
                      style="width: 200px;">
                        <span class="text-danger">*</span>
                         Was There A Death?
                      </th>
                      <th  style="width: 200px;"
                      scope="col">
                        <span class="text-danger">*</span>
                         Requested Action
                      </th>
                      <th  style="width: 220px;" scope="col">
                        <span class="text-danger">*</span>
                         Comments
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td style="width: 200px;">
                        <div [ngClass]="{'mandatory-error': (caseCreateForm.value.submitted && item.get('sampleAvailable_23').invalid)}">
                        <div class="custom-control custom-radio">
                          <input type="radio" id="{{'sampleAvailableno' + i}}" value="No" class="custom-control-input"
                            formControlName="sampleAvailable_23">
                          <label class="custom-control-label" for="{{'sampleAvailableno' + i}}">No</label>
                        </div>
                        <div class="custom-control custom-radio">
                          <input  id="{{'sampleAvailableYes' + i}}" type="radio"
                          value="Yes" 
                          class="custom-control-input"
                            formControlName="sampleAvailable_23">
                          <label class="custom-control-label" for="{{'sampleAvailableYes' + i}}">Yes

                          </label>
                        </div>

                        <div class="custom-control custom-radio">
                          <input type="radio"  value="Unknown"
                          id="{{'sampleAvailableUnknown' + i}}" class="custom-control-input" formControlName="sampleAvailable_23">
                          <label class="custom-control-label" for="{{'sampleAvailableUnknown' + i}}">Unknown</label>
                        </div>
                      </div>
                        <span class="text-danger"
                          *ngIf="item.get('sampleAvailable_23').invalid 
                          && caseCreateForm.value.submitted">
                          <span class="text-danger" 
                            *ngIf="item.get('sampleAvailable_23').errors.required">Please select option</span>
                        </span>
                      </td>
                      <td style="width: 200px;">
                        <div [ngClass]="{'mandatory-error': (caseCreateForm.value.submitted && item.get('wasThereInjury_23').invalid)}">
                        <div class="custom-control custom-radio">
                          <input 
                          type="radio" 
                          id="{{'wasThereInjuryno' + i}}" 
                          value="No" class="custom-control-input"
                          formControlName="wasThereInjury_23">
                          <label for="{{'wasThereInjuryno' + i}}" class="custom-control-label" >No</label>
                        </div>
                        <div class="custom-control custom-radio">
                          <input type="radio" id="{{'wasThereInjuryyes' + i}}" value="Yes" class="custom-control-input"
                            formControlName="wasThereInjury_23">
                          <label class="custom-control-label" for="{{'wasThereInjuryyes' + i}}">Yes</label>
                        </div>
                        <div class="custom-control custom-radio">
                          <input id="{{'wasThereInjuryunknown' + i}}" type="radio"  value="Unknown"
                            class="custom-control-input" 
                            formControlName="wasThereInjury_23">
                          <label class="custom-control-label" 
                          for="{{'wasThereInjuryunknown' + i}}">Unknown</label>
                        </div>
                      </div>
                        <span 
                        class="text-danger"
                          *ngIf="item.get('wasThereInjury_23').invalid && 
                          caseCreateForm.value.submitted">
                          <span class="text-danger" 
                          *ngIf="item.get('wasThereInjury_23').errors.required">Please select option</span>

                        </span>
                      </td>

                      <td style="width: 200px;">
                        <div [ngClass]="{'mandatory-error': (caseCreateForm.value.submitted && item.get('wasThereDeath_23').invalid)}">
                        <div class="custom-control custom-radio">
                          <input value="No" class="custom-control-input" formControlName="wasThereDeath_23" type="radio"
                            id="{{'wasThereDeathno' + i}}">
                          <label class="custom-control-label" for="{{'wasThereDeathno' + i}}">No</label>
                        </div>
                        <div class="custom-control custom-radio">
                          <input type="radio" id="{{'wasThereDeathyes' + i}}" value="Yes" class="custom-control-input"
                            formControlName="wasThereDeath_23">
                          <label class="custom-control-label" for="{{'wasThereDeathyes' + i}}">Yes</label>
                        </div>
                        <div class="custom-control custom-radio">
                          <input type="radio" value="Unknown" class="custom-control-input" formControlName="wasThereDeath_23"
                            id="{{'wasThereDeathunknown' + i}}">
                          <label class="custom-control-label" for="{{'wasThereDeathunknown' + i}}">Unknown</label>
                        </div>
                      </div>
                        <span class="text-danger" *ngIf="item.get('wasThereDeath_23').invalid && caseCreateForm.value.submitted">
                          <span *ngIf="item.get('wasThereDeath_23').errors.required" class="text-danger">Please select option</span>
                        
                        </span>
                        </td>
                        <td style="width: 200px;">
                          <div [ngClass]="{'mandatory-error': (caseCreateForm.value.submitted && item.get('actionRequest_23').invalid)}">
                            <div class="custom-control custom-radio">
                              <input type="radio" id="{{'credit' + i}}" value="16" class="custom-control-input"
                                formControlName="actionRequest_23">
                              <label class="custom-control-label" for="{{'credit' + i}}">Credit</label>
                            </div>
                            <div class="custom-control custom-radio">
                              <input type="radio" id="{{'replacement' + i}}" value="17" class="custom-control-input"
                                formControlName="actionRequest_23">
                              <label class="custom-control-label" for="{{'replacement' + i}}">Replacement</label>
                            </div>
                          </div>
                        <span class="text-danger"
                          *ngIf="item.get('actionRequest_23').invalid && 
                          caseCreateForm.value.submitted">
                          <span 
                          class="text-danger" *ngIf="item.get('actionRequest_23').errors.required">Please select option
                          </span>

                        </span>
                      </td>

                      <td>
                        <div class="pt-1">
                          <textarea 
                          style="width: 220px; height: 62px;" type="text" class="form-control-comments"
                            id="comments" required placeholder="Begin typing your comments here..."
                            formControlName="comments_23"
                            [ngClass]="{'mandatory-error': caseCreateForm.value.submitted && item.get('comments_23').invalid
                          || (item.get('comments_23').dirty && item.get('comments_23').invalid)}"></textarea>
                        </div>
                        <span class="text-danger" *ngIf="caseCreateForm.value.submitted && item.get('comments_23').invalid ||
                                                (item.get('comments_23').dirty && item.get('comments_23').invalid)">
                          <span class="text-danger" *ngIf="item.get('comments_23').errors.required">Required field</span>
                          
                        </span>
                      </td>
                    </tr>
                  </tbody>

                </table>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="selectedRequestType.value === 'RT_22'">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div class="table-responsive">
                <br>
                <table class="table table-borderless " aria-describedby="Request Type">
                  <thead>

                    <tr>
                      <th scope="col" style="width: 200px" >
                        <span class="text-danger">*</span> Action
                      </th>

                      <th scope="col" style="width: 220px;">
                        <span class="text-danger">*</span> Comments
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td style="width:200px" >
                        <div [ngClass]="{'mandatory-error': (caseCreateForm.value.submitted && item.get('action_22').invalid)}">
                          <div class="custom-control custom-radio">
                            <input type="radio" id="{{'creditOnly' + i}}" value="12" class="custom-control-input" formControlName="action_22">
                            <label class="custom-control-label" for="{{'creditOnly' + i}}">Credit Only</label>
                          </div>
                          <div class="custom-control custom-radio">
                            <input type="radio" id="{{'creditAndReorder' + i}}" value="13" class="custom-control-input"
                              formControlName="action_22">
                            <label class="custom-control-label" for="{{'creditAndReorder' + i}}">Credit & Reorder</label>
                          </div>
                        </div>
                        <span class="text-danger"
                          *ngIf="item.get('action_22').invalid && caseCreateForm.value.submitted">
                          <span class="text-danger" *ngIf="item.get('action_22').errors.required">Please select option</span>

                        </span>
                      </td>



                      <td>
                        <div class="pt-1">
                          <textarea style="width: 220px; height: 62px;" type="text" class="form-control-comments"
                            id="comments" required placeholder="Begin typing your comments here..."
                            formControlName="comments_22" 
                            [ngClass]="{'mandatory-error': caseCreateForm.value.submitted && item.get('comments_22').invalid
                          || (item.get('comments_22').dirty && item.get('comments_22').invalid)}"></textarea>
                            <span class="text-danger" *ngIf="caseCreateForm.value.submitted && item.get('comments_22').invalid ||
                                                                            (item.get('comments_22').dirty && item.get('comments_22').invalid)">
                              <span class="text-danger" *ngIf="item.get('comments_22').errors.required">Required field</span>
                            
                            </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>

                </table>
              </div>
            </div>
          </div>

          <div class="row"
            *ngIf="selectedRequestType.value === 'RT_19'  && caseCreateForm.value.items[i].missingFromOrder==='Yes'">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" style="margin-bottom: 2rem;">
              <p style="font-weight: bold; margin-bottom: 0.1rem;">
                Missing Item
              </p>
              <div class="table-responsive">
                <br>
                <table class="table table-borderless " aria-describedby="Request Type">
                  <thead>

                    <tr>

                      <th scope="col" style="width: 220px;">
                        <span class="text-danger">*</span> QTY Received
                      </th>

                      <th scope="col" style="width: 220px;">
                        <span class="text-danger">*</span> UOM Received
                      </th>
                      <th scope="col" style="width: 220px;">
                        <span class="text-danger">*</span> Requested Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style="width: 220px;">
                        <input type="text" class="form-control" placeholder="QTY"
                          formControlName="quantityRecieved_missing" numericOnly
                          [ngClass]="{'mandatory-error': caseCreateForm.value.submitted && item.get('quantityRecieved_missing').invalid
                          || (item.get('quantityRecieved_missing').dirty && item.get('quantityRecieved_missing').invalid)}">
                        <span class="text-danger" *ngIf="(caseCreateForm.value.submitted && item.get('quantityRecieved_missing').invalid) ||
                                                (item.get('quantityRecieved_missing').dirty && item.get('quantityRecieved_missing').invalid)">
                          <span class="text-danger" *ngIf="item.get('quantityRecieved_missing').errors.required">Required field</span>
                          
                        </span>
                        <span class="text-danger" *ngIf="caseCreateForm.value.submitted && item.get('quantityRecieved_missing').invalid">
                          <span class="text-danger" *ngIf="item.get('quantityRecieved_missing').errors.pattern">Numeric values only</span>
                        </span>

                      </td>
                      <td style="width: 220px;">
                        <input type="text" class="form-control" placeholder="UOM Received"  maxlength="3"
                          formControlName="uomRecieved_missing" alphabetOnly
                          [ngClass]="{'mandatory-error': caseCreateForm.value.submitted && item.get('uomRecieved_missing').invalid
                          || (item.get('uomRecieved_missing').dirty && item.get('uomRecieved_missing').invalid)}">
                        <span class="text-danger" *ngIf="caseCreateForm.value.submitted && item.get('uomRecieved_missing').invalid ||
                                                (item.get('uomRecieved_missing').dirty && item.get('uomRecieved_missing').invalid)">
                          <span class="text-danger" *ngIf="item.get('uomRecieved_missing').errors.required">Required field</span>
                          <span class="text-danger" *ngIf=" item.get('uomRecieved_missing').invalid">
                            <span class="text-danger" *ngIf="item.get('uomRecieved_missing').errors.pattern">Alphabet values only</span>
                          </span>
                        </span>
                      </td>
                      <td style="width: 220px;">
                        <div [ngClass]="{'mandatory-error': (caseCreateForm.value.submitted && item.get('action_missing').invalid)}">
                          <div class="custom-control custom-radio">
                            <input type="radio" id="{{'creditOnlymissing' + i}}" value="07" class="custom-control-input"
                              formControlName="action_missing">
                            <label class="custom-control-label" for="{{'creditOnlymissing' + i}}">Credit Only</label>
                          </div>
                          <div class="custom-control custom-radio">
                            <input type="radio" id="{{'creditAndReordermissing' + i}}" value="08" class="custom-control-input"
                              formControlName="action_missing">
                            <label class="custom-control-label" for="{{'creditAndReordermissing' + i}}">Credit and Reorder</label>
                          </div>
                        </div>
                        <span class="text-danger"
                          *ngIf="item.get('action_missing').invalid && caseCreateForm.value.submitted">
                          <span class="text-danger" *ngIf="item.get('action_missing').errors.required">Please select option</span>

                        </span>
                      </td>
                    </tr>
                  </tbody>

                </table>
              </div>
            </div>
          </div>

          <div class="row"
            *ngIf="selectedRequestType.value === 'RT_19' && caseCreateForm.value.items[i].missingFromOrder !=='' ">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <p style="font-weight: bold; margin-bottom: 0.1rem;">
                Incorrect Material
              </p>
              <div class="table-responsive">
                <br>
                <table class="table table-borderless " aria-describedby="Request Type">
                  <thead>

                    <tr>

                      <th scope="col" style="width: 220px;">
                        <span class="text-danger">*</span> Item # or Description
                      </th>
                      <th scope="col" style="width: 220px;">
                        <span class="text-danger">*</span> QTY Received
                      </th>

                      <th scope="col" style="width: 220px;">
                        <span class="text-danger">*</span> UOM Received
                      </th>
                      <th scope="col" style="width: 220px;">
                        <span class="text-danger">*</span> Requested Action
                      </th>
                      <th scope="col" style="width: 220px;">
                        Comments
                      </th>

                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td style="width: 220px;">
                        <input type="text" class="form-control" placeholder="Enter Item # or Description" maxlength="18"
                          formControlName="itemIdincorrect"
                          [ngClass]="{'mandatory-error': caseCreateForm.value.submitted && item.get('itemIdincorrect').invalid
                          || (item.get('itemIdincorrect').dirty && item.get('itemIdincorrect').invalid)}">
                        <span class="text-danger" *ngIf="caseCreateForm.value.submitted && item.get('itemIdincorrect').invalid ||
                        (item.get('itemIdincorrect').dirty && item.get('itemIdincorrect').invalid)">
                          <span class="text-danger" *ngIf="item.get('itemIdincorrect').errors.required">Required
                            field</span>
                        </span>
                      </td>

                      <td style="width: 220px;">
                        <input type="text" class="form-control" placeholder="QTY" formControlName="quantityRecieved_19" numericOnly
                        pattern = "[0-9]*"
                          [ngClass]="{'mandatory-error': caseCreateForm.value.submitted && item.get('quantityRecieved_19').invalid
                          || (item.get('quantityRecieved_19').dirty && item.get('quantityRecieved_19').invalid)}">
                        <span class="text-danger" *ngIf="caseCreateForm.value.submitted && item.get('quantityRecieved_19').invalid ||
                          (item.get('quantityRecieved_19').dirty && item.get('quantityRecieved_19').invalid)">
                          <span class="text-danger" *ngIf="item.get('quantityRecieved_19').errors.required">Required field</span>
                        </span>
                        <span class="text-danger" *ngIf="item.get('quantityRecieved_19').invalid">
                          <span  class="text-danger" *ngIf="item.get('quantityRecieved_19').errors.pattern">Numeric values only</span>
                        </span>
                      </td>
                      <td style="width: 220px;">
                        <input type="text" class="form-control" placeholder="UOM Received" maxlength="3"
                          formControlName="uomRecieved_19" alphabetOnly
                          [ngClass]="{'mandatory-error': caseCreateForm.value.submitted && item.get('uomRecieved_19').invalid
                          || (item.get('uomRecieved_19').dirty && item.get('uomRecieved_19').invalid)}">
                        <span class="text-danger" *ngIf="caseCreateForm.value.submitted && item.get('uomRecieved_19').invalid ||
                        (item.get('uomRecieved_19').dirty && item.get('uomRecieved_19').invalid)">
                          <span class="text-danger" *ngIf="item.get('uomRecieved_19').errors.required">Required
                            field</span>
                        </span>
                        <span class="text-danger" *ngIf=" item.get('uomRecieved_19').invalid">
                          <span  class="text-danger" *ngIf="item.get('uomRecieved_19').errors.pattern">Alphabet values only</span>
                        </span>
                      </td>

                      <td style="width: 220px;">
                        <div [ngClass]="{'mandatory-error': (caseCreateForm.value.submitted && item.get('action_19').invalid)}">
                          <div class="custom-control custom-radio">
                            <input type="radio" id="{{'keepDebit' + i}}" value="09" class="custom-control-input" formControlName="action_19">
                            <label class="custom-control-label" for="{{'keepDebit' + i}}">Keep/Debit</label>
                          </div>
                          <div class="custom-control custom-radio">
                            <input type="radio" id="{{'returnNoCredit' + i}}" value="10" class="custom-control-input"
                              formControlName="action_19">
                            <label class="custom-control-label" for="{{'returnNoCredit' + i}}">Return No Credit</label>
                          </div>
                        </div>
                        <span class="text-danger" *ngIf="item.get('action_19').invalid && caseCreateForm.value.submitted">
                          <span class="text-danger" *ngIf="item.get('action_19').errors.required">Please select option</span>
                        </span>
                      </td>

                      <td>
                        <div class="pt-1">
                          <textarea style="width: 220px; height: 62px;" type="text" class="form-control-comments"
                            id="comments" placeholder="Begin typing your comments here..."
                            formControlName="comments"></textarea>
                        </div>
                      </td>
                    </tr>
                  </tbody>

                </table>
              </div>
            </div>
          </div>

          <div class="row"  *ngIf="selectedRequestType.value === 'CA1_116'">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div class="table-responsive">
                <br>
                <table class="table table-borderless " aria-describedby="Retrun Reason">
                  <thead>

                    <tr>
                      <th scope="col" style="width: 135px;" *ngIf="selectedRequestType.value === 'CA1_116' && 
                          (item.get('returnReason').value === 'RT_27' || item.get('returnReason').value === 'RT_28' 
                          || item.get('returnReason').value === 'RT_29' || item.get('returnReason').value === 'RT_30' )">
                        <span class="text-danger">*</span> QTY to Return
                      </th>
                      <th scope="col" style="width: 135px;" *ngIf="selectedRequestType.value === 'CA1_116' && 
                      (item.get('returnReason').value === 'RT_27' || item.get('returnReason').value === 'RT_28' ||item.get('returnReason').value === 'RT_29'|| item.get('returnReason').value === 'RT_30')">
                        <span class="text-danger">*</span> UOM to Return
                      </th>
                      <th *ngIf="selectedRequestType.value === 'CA1_116' && item.get('returnReason').value === 'RT_30'" style="width:220px"
                        scope="col">
                        <span class="text-danger">*</span> LOT #:
                      </th>
                      <th *ngIf="selectedRequestType.value === 'CA1_116' && 
                      (item.get('returnReason').value === 'RT_27' 
                      || item.get('returnReason').value === 'RT_28' ||item.get('returnReason').value === 'RT_29'|| 
                      item.get('returnReason').value === 'RT_30')" 
                      scope="col" style="width: 220px;">
                        <span *ngIf="item.get('returnReason').value === 'RT_28' ||item.get('returnReason').value === 'RT_29'" class="text-danger">*</span> Comments
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td *ngIf="selectedRequestType.value === 'CA1_116' && (item.get('returnReason').value === 'RT_27')">
                        <input style="width:100px" type="text" class="form-control" placeholder="QTY to Return"
                          formControlName="quantityRecieved_27" numericOnly pattern="[0-9]*" [ngClass]="{'mandatory-error': caseCreateForm.value.submitted && item.get('quantityRecieved_27').invalid
                                                || (item.get('quantityRecieved_27').dirty && item.get('quantityRecieved_27').invalid)}">
                        <span class="text-danger" *ngIf="caseCreateForm.value.submitted && item.get('quantityRecieved_27').invalid
                                                || (item.get('quantityRecieved_27').dirty && item.get('quantityRecieved_27').invalid)">
                          <span class="text-danger" *ngIf="item.get('quantityRecieved_27').errors.required">Required
                            field</span>
                        </span>
                        <span class="text-danger" *ngIf=" item.get('quantityRecieved_27').invalid">
                          <span class="text-danger" *ngIf="item.get('quantityRecieved_27').errors.pattern">Numeric values only</span>
                        </span>
                      </td>
                      <td *ngIf="selectedRequestType.value === 'CA1_116' && (item.get('returnReason').value === 'RT_27')">
                        <input type="text" style="width:100px" class="form-control" placeholder="UOM to Return" maxlength="3"
                          formControlName="uomRecieved_27" [ngClass]="{'mandatory-error': caseCreateForm.value.submitted && item.get('uomRecieved_27').invalid
                                                || (item.get('uomRecieved_27').dirty && item.get('uomRecieved_27').invalid)}" alphabetOnly pattern="[a-zA-Z]*">
                        <span class="text-danger" *ngIf="(caseCreateForm.value.submitted && item.get('uomRecieved_27').invalid)
                                                || (item.get('uomRecieved_27').dirty && item.get('uomRecieved_27').invalid)">
                          <span class="text-danger" *ngIf="item.get('uomRecieved_27').errors.required">Required
                            field</span>
                        </span>
                        <span class="text-danger" *ngIf="item.get('uomRecieved_27').invalid ">
                          <span class="text-danger" *ngIf="item.get('uomRecieved_27').errors.pattern">Alphabet
                            values
                            only</span>
                        </span>
                      </td>
                      <td *ngIf="selectedRequestType.value === 'CA1_116' && item.get('returnReason').value === 'RT_27'">
                        <div class="pt-1">
                          <textarea style="width: 220px; height: 62px;" type="text" class="form-control-comments"
                            id="comments_27" placeholder="Begin typing your comments here..."
                            formControlName="comments_27"></textarea>
                        </div>
                      </td>
                      <td *ngIf="selectedRequestType.value === 'CA1_116' && (item.get('returnReason').value === 'RT_28')">
                        <input style="width:100px" type="text" class="form-control" placeholder="QTY to Return"
                          formControlName="quantityRecieved_28" numericOnly pattern="[0-9]*" 
                          [ngClass]="{'mandatory-error': caseCreateForm.value.submitted && item.get('quantityRecieved_28').invalid
                                                || (item.get('quantityRecieved_28').dirty && item.get('quantityRecieved_28').invalid)}">
                        <span class="text-danger" *ngIf="(caseCreateForm.value.submitted && item.get('quantityRecieved_28').invalid) ||
                                                  (item.get('quantityRecieved_28').dirty && item.get('quantityRecieved_28').invalid) ">
                          <span class="text-danger" *ngIf="item.get('quantityRecieved_28').errors.required">Required
                            field</span>
                        </span>
                        <span class="text-danger" *ngIf=" item.get('quantityRecieved_28').invalid">
                          <span class="text-danger" *ngIf="item.get('quantityRecieved_28').errors.pattern">Numeric values only</span>
                        </span>
                      </td>
                      <td *ngIf="selectedRequestType.value === 'CA1_116' && (item.get('returnReason').value === 'RT_28')">
                        <input type="text" style="width:100px" class="form-control" placeholder="UOM to Return"  maxlength="3"
                          formControlName="uomRecieved_28" alphabetOnly pattern="[a-zA-Z]*"
                          [ngClass]="{'mandatory-error': caseCreateForm.value.submitted && item.get('uomRecieved_28').invalid
                                                || (item.get('uomRecieved_28').dirty && item.get('uomRecieved_28').invalid)}">
                        <span class="text-danger" *ngIf="(caseCreateForm.value.submitted && item.get('uomRecieved_28').invalid) ||
                        (item.get('uomRecieved_28').dirty && item.get('uomRecieved_28').invalid) ">
                          <span class="text-danger" *ngIf="item.get('uomRecieved_28').errors.required">Required
                            field</span>
                        </span>
                        <span class="text-danger" *ngIf=" item.get('uomRecieved_28').invalid">
                          <span class="text-danger" *ngIf="item.get('uomRecieved_28').errors.pattern">Alphabet values only</span>
                        </span>
                      </td>
                      <td *ngIf="selectedRequestType.value === 'CA1_116' && item.get('returnReason').value === 'RT_28'">
                        <div class="pt-1">
                          <textarea style="width: 220px; height: 62px;" type="text" class="form-control-comments"
                            id="comments_28" placeholder="Begin typing your comments here..."
                            formControlName="comments_28" 
                            [ngClass]="{'mandatory-error': caseCreateForm.value.submitted && item.get('comments_28').invalid
                            || (item.get('comments_28').dirty && item.get('comments_28').invalid)}"></textarea>
                        </div>
                        <span class="text-danger" *ngIf="(caseCreateForm.value.submitted && item.get('comments_28').invalid) ||
                        (item.get('comments_28').dirty && item.get('comments_28').invalid) ">
                          <span class="text-danger" *ngIf="item.get('comments_28').errors.required">Required
                            field</span>
                        </span>
                      </td>
                      <td *ngIf="selectedRequestType.value === 'CA1_116' && (item.get('returnReason').value === 'RT_29')">
                        <input style="width:100px" type="text" class="form-control" placeholder="QTY to Return"
                          formControlName="quantityRecieved_29" numericOnly pattern="[0-9]*"
                          [ngClass]="{'mandatory-error': caseCreateForm.value.submitted && item.get('quantityRecieved_29').invalid
                                                || (item.get('quantityRecieved_29').dirty && item.get('quantityRecieved_29').invalid)}">
                        <span class="text-danger" *ngIf="(caseCreateForm.value.submitted && item.get('quantityRecieved_29').invalid) ||
                        (item.get('quantityRecieved_29').dirty && item.get('quantityRecieved_29').invalid)">
                          <span class="text-danger" *ngIf="item.get('quantityRecieved_29').errors.required">Required
                            field</span>
                          
                        </span>
                        <span class="text-danger" *ngIf=" item.get('quantityRecieved_29').invalid">
                          <span class="text-danger" *ngIf="item.get('quantityRecieved_29').errors.pattern">Numeric values only</span>
                        </span>
                      </td>
                      <td *ngIf="selectedRequestType.value === 'CA1_116' && (item.get('returnReason').value === 'RT_29')">
                        <input type="text" style="width:100px" class="form-control" placeholder="UOM to Return" maxlength="3"
                          formControlName="uomRecieved_29" alphabetOnly pattern="[a-zA-Z]*"
                          [ngClass]="{'mandatory-error': caseCreateForm.value.submitted && item.get('uomRecieved_29').invalid
                                                                      || (item.get('uomRecieved_29').dirty && item.get('uomRecieved_29').invalid)}">
                        <span class="text-danger" *ngIf="(caseCreateForm.value.submitted && item.get('uomRecieved_29').invalid) ||
                                              (item.get('uomRecieved_29').dirty && item.get('uomRecieved_29').invalid)">
                          <span class="text-danger" *ngIf="item.get('uomRecieved_29').errors.required">Required
                            field</span>
                        </span>
                        <span class="text-danger" *ngIf=" item.get('uomRecieved_29').invalid">
                          <span class="text-danger" *ngIf="item.get('uomRecieved_29').errors.pattern">Alphabet values only</span>
                        </span>
                      </td>
                      <td *ngIf="selectedRequestType.value === 'CA1_116' && item.get('returnReason').value === 'RT_29'">
                        <div class="pt-1">
                          <textarea style="width: 220px; height: 62px;" type="text" class="form-control-comments"
                            id="comments_29" placeholder="Begin typing your comments here..."
                            formControlName="comments_29"
                            [ngClass]="{'mandatory-error': caseCreateForm.value.submitted && item.get('comments_29').invalid
                            || (item.get('comments_29').dirty && item.get('comments_29').invalid)}"></textarea>
                        </div>
                        <span class="text-danger" *ngIf="(caseCreateForm.value.submitted && item.get('comments_29').invalid) ||
                        (item.get('comments_29').dirty && item.get('comments_29').invalid) ">
                          <span class="text-danger" *ngIf="item.get('comments_29').errors.required">Required
                            field</span>
                        </span>
                      </td>
                      <td *ngIf="selectedRequestType.value === 'CA1_116' && (item.get('returnReason').value === 'RT_30')">
                        <input style="width:100px" type="text" class="form-control" placeholder="QTY to Return"
                          formControlName="quantityRecieved_30" numericOnly pattern="[0-9]*"
                          [ngClass]="{'mandatory-error': caseCreateForm.value.submitted && item.get('quantityRecieved_30').invalid
                                                                      || (item.get('quantityRecieved_30').dirty && item.get('quantityRecieved_30').invalid)}">
                        <span class="text-danger" *ngIf="(caseCreateForm.value.submitted && item.get('quantityRecieved_30').invalid) ||
                        (item.get('quantityRecieved_30').dirty && item.get('quantityRecieved_30').invalid)">
                          <span class="text-danger" *ngIf="item.get('quantityRecieved_30').errors.required">Required
                            field</span>
                          
                        </span>
                        <span class="text-danger" *ngIf=" item.get('quantityRecieved_30').invalid">
                          <span class="text-danger" *ngIf="item.get('quantityRecieved_30').errors.pattern">Numeric values only</span>
                        </span>
                      </td>
                      <td *ngIf="selectedRequestType.value === 'CA1_116' && (item.get('returnReason').value === 'RT_30')">
                        <input type="text" style="width:100px" class="form-control" placeholder="UOM to Return"  maxlength="3"
                          formControlName="uomRecieved_30" alphabetOnly pattern="[a-zA-Z]*"
                          [ngClass]="{'mandatory-error': caseCreateForm.value.submitted && item.get('uomRecieved_30').invalid
                                                   || (item.get('uomRecieved_30').dirty && item.get('uomRecieved_30').invalid)}">
                        <span class="text-danger" *ngIf="(caseCreateForm.value.submitted && item.get('uomRecieved_30').invalid) ||
                        (item.get('uomRecieved_30').dirty && item.get('uomRecieved_30').invalid)">
                          <span class="text-danger" *ngIf="item.get('uomRecieved_30').errors.required">Required
                            field</span>
                        </span>
                        <span class="text-danger" *ngIf=" item.get('uomRecieved_30').invalid">
                          <span class="text-danger" *ngIf="item.get('uomRecieved_30').errors.pattern">Alphabet values only</span>
                        </span>
                      </td>
                      <td *ngIf="selectedRequestType.value === 'CA1_116' && item.get('returnReason').value === 'RT_30' ">
                        <input style="width:100px" type="text" class="form-control" placeholder="LOT #"
                          formControlName="lotNo_30"
                          [ngClass]="{'mandatory-error': caseCreateForm.value.submitted && item.get('lotNo_30').invalid
                                                      || (item.get('lotNo_30').dirty && item.get('lotNo_30').invalid)}">
                        <span class="text-danger" *ngIf="(caseCreateForm.value.submitted && item.get('lotNo_30').invalid) ||
                                (item.get('lotNo_30').dirty && item.get('lotNo_30').invalid)">
                          <span class="text-danger" *ngIf="item.get('lotNo_30').errors.required">Required field</span>
                        </span>
                      </td>
                      <td *ngIf="selectedRequestType.value === 'CA1_116' && item.get('returnReason').value === 'RT_30'">
                        <div class="pt-1">
                          <textarea style="width: 220px; height: 62px;" type="text" class="form-control-comments"
                            id="comments_30" placeholder="Begin typing your comments here..."
                            formControlName="comments_30"
                            [ngClass]="{'mandatory-error': caseCreateForm.value.submitted && item.get('comments_30').invalid
                            || (item.get('comments_30').dirty && item.get('comments_30').invalid)}"></textarea>
                        </div>
                        <span class="text-danger" *ngIf="(caseCreateForm.value.submitted && item.get('comments_30').invalid) ||
                        (item.get('comments_30').dirty && item.get('comments_30').invalid) ">
                          <span class="text-danger" *ngIf="item.get('comments_30').errors.required">Required
                            field</span>
                        </span>
                      </td>
                    </tr>
                  </tbody>

                </table>
              </div>
            </div>

          </div>

          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 status">
              <div class="paragraph-1 pl-0 d-flex align-items-center"
                *ngFor="let status of localStorageData.lineItems[i].fulfillment">

                <div>* {{status.statusMessage}}</div>
              </div>
            </div>
          </div>
          
          
          <div class="row">
            <div *ngIf="selectedRequestType.value === 'CA1_116' && 
            (item.get('dropShipFlag').value)" class="text-danger"> 
            <span style="font-weight: 500;"> Returns for Drop Ship items are subject to the vendor's return policy.</span>
          </div>
          <div *ngIf="selectedRequestType.value === 'CA1_116' &&  
          (item.get('shippedElegibilityFlag').value)" class="text-danger"> 
          <span style="font-weight: 500;"> Items Shipped more than 90 days ago are only eligible for Credit due to a Product Recall.</span>
        </div>
        <div *ngIf="selectedRequestType.value === 'CA1_116' && 
        (item.get('nonReturnableFlag').value)" class="text-danger"> 
        <span style="font-weight: 500;">{{ item.get('nonReturnableReason').value }}</span>
        </div>
        <br>
    </div>
        <div class="row" *ngIf="selectedRequestType.value === 'CA1_116' && 
          (item.get('returnReason').value === 'RT_27' || item.get('returnReason').value === 'RT_28'|| item.get('returnReason').value === 'RT_29')">
      
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 status">
              
                <div> 
                  <input type="checkbox" *ngIf="item.get('returnReason').value === 'RT_27'" formControlName="restockingFeeAcknowledge_27" (input)="checkboxValue($event)"/> 
                  <input type="checkbox" *ngIf="item.get('returnReason').value === 'RT_28'"  formControlName="restockingFeeAcknowledge_28" (input)="checkboxValue($event)"/> 
                  <input type="checkbox" *ngIf="item.get('returnReason').value === 'RT_29'"  formControlName="restockingFeeAcknowledge_29" (input)="checkboxValue($event)"/> 
                 
                  <span style="font-weight: 500;" [style.color]="highlightedText"> * I acknowledge that product returns may be subject to 
                   <a [tooltip] = '"Return policy:
                      &bull; All return items not locally stocked will be subject to locally determined supplier restocking fee.&#13;
                      &bull; All returns not due to Medical Distribution ordering or delivery error will be subject to a minimum of 15% restocking fee.&#13;
                      &bull; If an unauthorized return is accepted, there will be a minimum of 25% restocking fee."'>restocking fee.</a>
                  </span>
                </div>
              
            </div>
            <br>
        </div>

        </ng-container>


      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="custom-control custom-checkbox check">
          <input type="checkbox" [checked]="isChecked" class="custom-control-input" id="sendEmailCopies"
            (change)="onSelectRecipientClick()">
          <label class="custom-control-label" for="sendEmailCopies">Select to send copies of this case to other
            recipients (5 max)</label>
        </div>
        <div style="cursor: pointer;">
          <app-modal id="addRecipientModal" [showModal]="isRecipientModalVisible" [customBody]="addRecipient"
            (onCloseModal)="hideRecipientModal()">
            <ng-template #addRecipient>
              <app-select-recipient id="addRecipientSection" (getRecipients)="getRecipientValue($event)"
                (onCloseModal)="hideRecipientModal()">
              </app-select-recipient>
            </ng-template>
          </app-modal>
          <div *ngIf="selectedContactsList.length > 0">
            <div *ngFor="let recipient of selectedContactsList; let i = index">
              <div *ngIf="recipient.firstName!=='' && recipient.lastName !=='' " class="pl-4 my-1 recipient">{{
                recipient.firstName }} {{ recipient.lastName }}</div>
              <div *ngIf="recipient.firstName==='' || recipient.lastName==='' " class="pl-4 my-1 recipient">{{
                recipient.email }}</div>

            </div>
          </div>
        </div>
      </div>
      <div>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">

        <div class="attachments-list" *ngIf="attachments.length > 0">
          <div *ngFor="let attachment of attachments; let i = index" class="attachment">
            <span class="icon icon-file-small" [ngClass]="{ 'upload-error-icon': !attachment.ecmDocId }"></span>
            <span class="attachment-name" [ngClass]="{ 'upload-error-attachment': !attachment.ecmDocId }">{{
              attachment.name }}</span>
            <span class="icon icon-close-small" *ngIf="attachment.ecmDocId" (click)="removeAttachment(i)"></span>
            <span *ngIf="!attachment.ecmDocId" class="upload-error-msg">File did not upload successfully.</span>
          </div>
        </div>

        <app-modal id="addAttachmentModal" [showModal]="isAttachmentModalVisible" [customBody]="addAttachments"
          (onCloseModal)="hideAttachmentModal()">
          <ng-template #addAttachments>
            <csp-attachment id="addAtttachmentSection" [fromPage]='"Form B."' (onCloseModal)="hideAttachmentModal()"
              (attachFiles)="attachUploadedFiles($event)"></csp-attachment>
          </ng-template>
        </app-modal>

        <div class="cta-btn secondary black_button" (click)="showAttachmentModal()">Include Attachment(s)</div>

        <button class="cta-btn primary red_button" (click)="submitForm()">Submit
          Case</button>

      </div>
    </div>

  </form>
  <app-modal id="submitCaseDiscard" [showModal]="isSubmitCaseModalVisible" [customBody]="submitCaseDiscard"
    (onCloseModal)="hideDiscardModal()">
    <ng-template #submitCaseDiscard>
      <app-submit-case-discard id="submitCaseDiscardModal" (onCloseModal)="hideDiscardModal()"
        (discardChange)="discardChanges()" [name]="name" [header]="heading" [message]="message">
      </app-submit-case-discard>
    </ng-template>
  </app-modal>
</div>