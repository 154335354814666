<div id="attachmentModalDiv">
    <div  class="spinner-main" [ngClass]="{'spinner-show': cspShowSpinner}">
        <div class="spinnerDiv" > 
        </div>
    </div>
    <h4 class="modal-title">{{constants.title}}</h4>
              <div class="inner-content">
                  <p>{{notes}}</p>

                  <div class="dropzone" dragDropFile (fileDropped)="onFileDropped($event)">
                    <input type="file" #attachmentRef multiple id="attachmentRef" (change)="onFileChange($event.target.files)" >
                    <h3>Drag and Drop file here</h3>
                    <h3> or </h3>
                    <label  [attr.for]="attachments.length <= MAX_NO_OF_ALLOWED_FILES ? 'attachment' : ''" >Browse for file</label>
                </div>
                  <div class="attachment-wrapper">
                      <div class="attachments-list" *ngIf="attachments?.length">
                          <div *ngFor="let attachment of attachments; let index = index" class="clearfix">
                              <span class="icon icon-file-small"></span>
                              <span class="attachment-name">{{attachment.name}}</span>
                              <span class="icon icon-close-small" (click)="removeFileFromAttachmentsArray(index)"></span>
                          </div>
                        </div>
                  </div>
              </div>
              <div class="attachmentFooter">
              <div class="error-msg-section red attachments-list" *ngIf="invalidAttachments?.length">
                <ng-container *ngFor="let attachment of invalidAttachments; let index = index">
                    <span *ngIf="!attachment.isValidSize && (index === 0)" class="error-msg db tl">{{invalid_file_size_message}}</span>
                    <span *ngIf="!attachment.isValidName && (index === 0)" class="error-msg db tl">{{invalid_file_name_characters}}</span>
                    <span *ngIf="!attachment.isValidType && (index === 0)" class="error-msg db tl">
                         [ {{attachment.name.split('.').pop().toLowerCase()}} ] {{constants.unsupported_file_type}}
                   </span>
                    <span *ngIf="!attachment.isValidFileslimit && (index === 0)" class="error-msg db tl">{{constants.no_of_files_max_limit}}</span>
                </ng-container>
            </div>

            <!-- Button Groups -->
            <div class="btn-group clearfix">
                 <button data-id="attachment-upload-files-cta" type="button" class="attch-upload-btn cta-btn primary" (click)="uploadFilesToServer()">{{constants.upload}}</button>
            </div>
        </div>
</div>
