import { Enum } from './enume';

export class AttachmentsConstants extends Enum<string> {
    public static readonly FILE_SIZE_UNITS = new Array(
        'bytes',
        'KB',
        'MB',
        'GB'
    );

    public static readonly ALLOWED_FILE_TYPES = new Map<string, string>([
        ['xls', 'Excel'],
        ['xlsx', 'Excel'],
        ['doc', 'Word'],
        ['docx', 'Word'],
        ['pdf', 'pdf'],
        ['jpg', 'jpeg/jpg'],
        ['jpeg', 'jpeg/jpg'],
        ['png', 'png'],
        ['tif', 'tiff'],
        ['tiff', 'tiff'],
        ['gif', 'gif'],
        ['msg', 'outlook item'],
        ['ppt', 'ppt'],
        ['pptx', 'ppt'],
        ['html', 'html'],
        ['htm', 'html']
    ]);

    public static readonly ALLOWED_MIME_TYPES = new Map<string, string>([
        ['xls', 'application/vnd.ms-excel'],
        ['xlsx', 'application/vnd.ms-excel'],
        ['doc', 'application/msword'],
        ['docx', 'application/msword'],
        ['pdf', 'application/pdf'],
        ['jpg', 'image/jpeg'],
        ['jpeg', 'image/jpeg'],
        ['png', 'image/png'],
        ['tif', 'image/tiff'],
        ['tiff', 'image/tiff'],
        ['gif', 'image/gif'],
        ['msg', 'application/vnd.ms-outlook'],
        ['ppt', 'application/vnd.ms-powerpoint'],
        ['pptx', 'application/vnd.ms-powerpoint'],
        ['html', 'text/html'],
        ['htm', 'text/html']
    ]);

    public static readonly MAX_FILE_SIZE = new Enum(10 * 1024 * 1024);

    public static readonly MAX_NO_OF_ALLOWED_FILES = new Enum(10);
    public static readonly MAX_CHARS_PER_FILE_NAME = new Enum(40);
    public static readonly title = 'Attachments';
    public static readonly note1 = 'Browse for up to ';
    public static readonly note2 = ' files related to your case. File size may not exceed ';
    public static readonly note3 = 'MB per attachment. File name may not exceed ';
    public static readonly note4 = ' characters per file name. Compatible file types include Excel, Word, pdf, jpeg, png, tif, gif, outlook item, ppt and html.';
    public static readonly browse = 'Browse Files';
    public static readonly upload = 'Upload';
    public static readonly file_size_limit_message = 'File size must be less than ';
    public static readonly file_name_characters_limit_begin = 'File name must be ';
    public static readonly file_name_characters_limit_end = ' characters or fewer.';
    public static readonly unsupported_file_type = ' is not a supported file type.';
    public static readonly no_of_files_max_limit = 'Max 10 files can be uploaded.';
}
