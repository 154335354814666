<form #myForm="ngForm" novalidate>
  <input [attr.data-id]="'dateRangePicker'" type="text"
           class="form-control date-range-picker-input-search"
           #dateRangePicker="bsDaterangepicker"
           bsDaterangepicker
           placeholder="Please Select a Date"
           [minDate]="fromLimit"
           [maxDate]="toLimit"
           [outsideClick]="true"
           container="#formGroupContainer"
           placement="bottom"
           [(bsValue)]="dateRange"
           (bsValueChange)="onDateRangeChanged($event)"
           [bsConfig]="{ displayMonths: 1, adaptivePosition: false, showWeekNumbers: false, customTodayClass:'custom-today-class' }" >
          <button id="dateRangeCalenderBtn" class="btn btn-light date-range-picker-btn" (click)="dateRangePicker.toggle();"
            type="button">
            <img class="calender-range-img" src="/etc/designs/market/base/assets/icons/calendar_icon.svg" alt="">
          </button>
</form>