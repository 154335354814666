<div id="orderSearchDiv">
  <div  class="spinner-main" [ngClass]="{'spinner-show': cspShowSpinner}">
    <div class="spinnerDiv" > 
    </div>
  </div>
  
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 orderSearchPart" #orderSearchSection>
    <div><label class="formLabel"><span class="red-text">*</span>Cardinal Health Order #</label></div>
    <div class="col-md-3 col-lg-3 col-sm-5 col-xs-5 ">
       <div id="order-search-box" style="padding-right: 5px;">
          <ng-select class="custom" [items]="SearchOrderDropdownValue" [placeholder]="'PO Number'"
          [(ngModel)]="selectedItem" [clearable]="false" [searchable]="false" (change)="setSearchOrderDropdownValue($event)">
          </ng-select>
       </div>
    </div>
    <div class="col-md-9 col-lg-9 col-sm-7 col-xs-7 search-right-div">
  
       <input *ngIf="searchByPONumber" id="search-po-box" autocomplete="off" #orderSearch 
          class="search-box caseInput" maxlength="40" (keyup.enter)="searchResultForInputValue(searchInputVal)"
          type="text" [(ngModel)]="searchInputVal" 
          ngDefaultControl 
          placeholder="Key in your PO Number and press Enter" [ngClass]="{'has-error': showError}" />
          
       <input *ngIf="searchByOrderNumber" id="search-order-box" autocomplete="off" #orderSearch 
           class="search-box caseInput" maxlength="10" (keyup.enter)="searchResultForInputValue(searchInputVal)"
           type="text" [(ngModel)]="searchInputVal" 
           ngDefaultControl placeholder="Key in your Order Number and press Enter" [ngClass]="{'has-error': showError}" />
        
       <span title="Clear" class="icon-close-red" (click)="clearInputString()"></span>
       <span class="vertical-devider"></span>
       <button title="Search" class="searchIconButton" (click)="searchResultForInputValue(searchInputVal)">
         <span class="search-icon"></span>
        </button>
       
    </div>
    <app-modal id="showPOOrdersModal" [showModal]="showPOOrdersModal" [customBody]="showPOOrders"
      [modalTital]="showModalTitle" [defaultWidth]="false" 
      (onCloseModal)="hidePOOrdersModal()">
      <ng-template #showModalTitle>
        <div class="col-md-8 col-lg-8 col-sm-8 col-xs-8">
          <span class="modalTitle">{{poOrdersDetails.length}} Orders Found</span>
        </div>
      </ng-template>
      <ng-template #showPOOrders>
        <app-items-selection-modal *ngIf="showPOOrdersModal" 
              [tableCols]="poOrdersColums" 
              [tableData]="poOrdersDetails"
              [tableColsLabelInput]="poOrdersColumnLabels"
              [multiValueSelect]="false"
              [addItemButtonName]="addItemButtonName"
              (getSelectedItems)="getSelectedOrderNumber($event)"
        >
        </app-items-selection-modal>
      </ng-template>
     
    </app-modal>
  </div>
  
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 order-detail-block" *ngIf="isVisibleOrderDetail">
      <label class="formLabel">Order #:</label><span> {{ orderData.orderNumber}}</span><br>
      <label class="formLabel">Ship To:</label><span> {{ orderData.shipTo}}</span><br>
      <label class="formLabel">PO #:</label><span>  {{ orderData.purchaseOrderNumber}}</span><br>
      <label class="formLabel">Order Date:</label><span>  {{ orderData.orderedTime | date:'longDate'}}</span><br>
      <label class="formLabel">Order By:</label><span>  {{ orderData.orderedBy}}</span><br>
      <label class="formLabel">Order Type:</label><span>  {{ orderData.orderTypeDesc}}</span><br><br>
  
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 order-detail-block" *ngIf="isOrdrSearchError">
      <span class="red-text">{{ orderSearchErrorMsg }}</span><br><br>
      <ul style="list-style-type: circle; padding-left: 30px;">
        <li>{{ orderSearchErrorMsgLine2 }}</li>
        <li>{{ orderSearchErrorMsgLine3 }}</li><br>
      </ul>
      
    </div>
  </div>
  
