<!-- ########################## CASE SUBMITTED ########################## -->
<ng-container *ngIf="submittedCaseId">
  <div class="create-case-success-wrapper">
      <div class="navigation-link-container">
        <ul>
            <li><a data-id="MarketHomeLink" [href]="[baseRoutingUrl]" class="cta-link primary">Market Home</a></li>
            <li><a data-id="CaseSearchLink" routerLink="/case/search" class="cta-link primary">Case Search</a></li>
            <li><a data-id="HelpLink" routerLink="/help" class="cta-link primary">Help</a></li>
        </ul>
    </div>
      <div class="success-msg">
          <span class="confirmation-icon"></span>
          <span class="text-message highlight">

    Case <a data-id="caseID" [routerLink]="['/case/details',submittedCaseId]">{{submittedCaseId}}</a> has been submitted successfully.
  </span>
  
      </div>
     
  </div>
</ng-container>