import { Component, OnInit, Output, EventEmitter, ViewEncapsulation, ViewChild } from '@angular/core';
import {  BsDaterangepickerDirective } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DateRangeComponent implements OnInit {

  @Output() onFilterChange: EventEmitter<any>= new EventEmitter<any>();
  dateRange: Date[];
  date = new Date();
  fromLimit = new Date();
  toLimit = new Date();
  startDate = new Date();
  endDate = new Date();

  @ViewChild('dateRangePicker', { static: true }) dateRangePicker:  BsDaterangepickerDirective;

  constructor() {
      // This is intentional
  }

  ngOnInit() {
    this.fromLimit.setDate(this.fromLimit.getDate() - 89);
    this.startDate.setDate(this.date.getDate() - 7);
    this.endDate = this.date;
    this.dateRange = [this.startDate,this.endDate]
  }

  onDateRangeChanged(dateRange) {
    this.onFilterChange.emit(dateRange);
  }
  
}
