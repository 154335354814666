import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';

@Component({
  selector: 'app-shipping-discrepancy-form',
  templateUrl: './shipping-discrepancy-form.component.html',
  styleUrls: ['./shipping-discrepancy-form.component.scss']
})
export class ShippingDiscrepancyFormComponent implements OnInit {

  @Input() caseCreateForm : FormGroup;
  @Input() others :boolean=false;
  @Input() formIndex : number = 0;
  @Input() isZeroAllowed : boolean=false;
  @Input() actionItems : any;
  @Input() incorrectMaterial: boolean=false;
  @Input() sometingMissing: boolean=false;
  @Input() incorrectDeliveryLocation: boolean=false;
  @Input() quantityDamaged: boolean=false;
  @Input() returnShippingOveragesForm: boolean=false;
  @Output() deleteFormItem : EventEmitter<any> = new EventEmitter<any>();
  qtyDamaged : Number;
  qtyReceived : Number;
  itemId : string;
  uomReceived : string;
  actionRequest : any;
  damageType :any;
  
  constructor() {
      // This is intentional
  }


  get formContols() :FormGroup{
    let formArray=this.getFormArray();
    let formGroup=formArray.controls[this.formIndex] as FormGroup;
   return formGroup
  }
  ngOnInit() {
        // This is intentional
  }
  get itemDescription(): FormGroup{
    let formArray=this.getFormArray();
    let formGroup=formArray.controls[this.formIndex] as FormGroup;
    
      return formGroup.get('itemDescription').value;
  }
  get statusMessageList() : FormGroup{
    let formArray=this.getFormArray();
    let formGroup=formArray.controls[this.formIndex] as FormGroup;
    
      return formGroup.get('statusMessageList').value;
  }
  deleteForm() :void {
    let formArray=this.caseCreateForm.get('items') as FormArray;
    if(formArray.length>1) {
      if(!this.incorrectMaterial){
        const deletedItem = formArray.value[this.formIndex];
        this.deleteFormItem.emit(deletedItem);
      }
      formArray.removeAt(this.formIndex);
    }
  }

  getFormArray() :FormArray {
    return this.caseCreateForm.get('items') as FormArray;
  }
}
