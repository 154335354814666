import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';

@Component({
  selector: 'app-order-inquery-form',
  templateUrl: './order-inquery-form.component.html',
  styleUrls: ['./order-inquery-form.component.scss']
})
export class OrderInqueryFormComponent implements OnInit {

  @Input() caseCreateForm : FormGroup;
  @Input() formIndex : number = 0;
  @Output() deleteFormItem : EventEmitter<any> = new EventEmitter<any>();
  constructor() { 
    // This is intentional
  }

  get formContols() :FormGroup{
    let orderInquiryArray=this.getFormArray();
    let formGroup=orderInquiryArray.controls[this.formIndex] as FormGroup;
   return formGroup
  }
  get itemDescription(){
    let orderInquiryArray=this.getFormArray();
    let orderInquiryFormGroup=orderInquiryArray.controls[this.formIndex] as FormGroup;
    
      return orderInquiryFormGroup.get('itemDescription').value;
  }
  get statusMessageList(){
    let orderInquiryArray=this.getFormArray();
    let orderInquiryFormGroup= orderInquiryArray.controls[this.formIndex] as FormGroup;
    
      return orderInquiryFormGroup.get('statusMessageList').value;
  }

  ngOnInit() {
        // This is intentional
  }

  deleteForm() :void {
    let orderInquiryArray=this.caseCreateForm.get('items') as FormArray;
    if(orderInquiryArray.length>1) {
      const deletedItem = orderInquiryArray.value[this.formIndex];
      this.deleteFormItem.emit(deletedItem);
      orderInquiryArray.removeAt(this.formIndex);
    }
  }

  getFormArray() :FormArray {
    return this.caseCreateForm.get('items') as FormArray;
  }
}
