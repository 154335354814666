import { Component, Input, EventEmitter, Output, OnInit, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/forkJoin';
import { AttachmentsConstants } from '../../constants/attachments.constants'
import { Attachment } from '../../model/attachment';
import { UploadRequest } from '../../model/uploadRequest';
import { AttachmentService } from '../../services/attachment.service';
import { CasesService } from '../../services/cases.service';
import { LogService } from '../../services/log.service';
@Component({
    selector: 'csp-attachment',
    templateUrl: './attachment.component.html',
    styleUrls: ['./attachment.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AttachmentComponent implements OnInit {
    @Input('showModal') showModal;
    @Input('fromPage') fromPage: string;
    @Output() onCloseModal = new EventEmitter();
    @Output() attachFiles = new EventEmitter();
    @ViewChild('attachmentRef',{static: true}) attachmentRef: ElementRef;
    attachments: Array<Attachment> = new Array();
    invalidAttachments: Array<any> = new Array();

    MAX_NO_OF_ALLOWED_FILES = AttachmentsConstants.MAX_NO_OF_ALLOWED_FILES.value;
    MAX_FILE_SIZE_PER_ATTACHMENT = AttachmentsConstants.MAX_FILE_SIZE.value / (1024 * 1024);
    MAX_CHARS_PER_FILE_NAME = 40;
    readonly constants: typeof AttachmentsConstants = AttachmentsConstants;
    notes: string = AttachmentsConstants.note1 + AttachmentsConstants.MAX_NO_OF_ALLOWED_FILES.value + AttachmentsConstants.note2 + AttachmentsConstants.MAX_FILE_SIZE.value / (1024 * 1024) + AttachmentsConstants.note3 + AttachmentsConstants.MAX_CHARS_PER_FILE_NAME.value + AttachmentsConstants.note4;
    invalid_file_size_message: string = AttachmentsConstants.file_size_limit_message + AttachmentsConstants.MAX_FILE_SIZE.value / (1024 * 1024) + "MB.";
    invalid_file_name_characters: string = AttachmentsConstants.file_name_characters_limit_begin + AttachmentsConstants.MAX_CHARS_PER_FILE_NAME.value + AttachmentsConstants.file_name_characters_limit_end;

    cspShowSpinner: boolean = false;
    // constructor
    constructor(private attachmentService: AttachmentService,
        private casesService: CasesService,
                private logService: LogService) { }

    /**
     * isValidFileType
     * @param file
     */
    isValidFileType(file: File): boolean {
        let isFileValid = true;
        const fileExt = this.getFileExt(file);
        if (Array.from(AttachmentsConstants.ALLOWED_FILE_TYPES.keys()).indexOf(fileExt) === -1) {
            isFileValid = false;
        }
        return isFileValid;
    }

    /**
     * isValidFileSize
     * @param file
     */
    isValidFileSize(file: File): boolean {
        let isFileValid = true;
        const fileSize = file.size;

        if (fileSize > AttachmentsConstants.MAX_FILE_SIZE.value) {
            isFileValid = false;
        }
        return isFileValid;
    }

    /**
     * isValidFileSize
     * @param file
     */
    isValidFileName(file: File): boolean {
        const fileNameLength = file.name.length;
        return fileNameLength <= this.MAX_CHARS_PER_FILE_NAME;
    }

    isValidFileslimit(files: FileList) {
        return (files.length + this.attachments.length) <= this.MAX_NO_OF_ALLOWED_FILES ? true : false;
    }

    /**
     * getFileType
     * @param file
     */
    getFileType(fileExt: string): string {
        return AttachmentsConstants.ALLOWED_FILE_TYPES.get(fileExt);
    }

    /**
     * getMimeType
     * @param fileExt
     */
    getMimeType(fileExt: string): string {
        return AttachmentsConstants.ALLOWED_MIME_TYPES.get(fileExt);
    }

    /**
     * getFileExt
     * @param file
     */
    getFileExt(file: File) {
        return file.name.split('.').pop().toLowerCase();
    }

    /**
     * getFileSize
     * @param file
     */
    getFileSize(file: File): string {
        let unit = 0;
        let fileSize = file.size;
        while (fileSize >= 1024) {
            fileSize /= 1024;
            unit++;
        }
        return Math.round(fileSize) + ' ' + AttachmentsConstants.FILE_SIZE_UNITS[unit];
    }

    /**
     * onBrowse
     */
    // onBrowse() {
    //     this.attachmentRef.nativeElement.value = '';
    //     this.invalidAttachments = new Array();
    // }

    /**
     * onFileChange
     * @param event
     */
    onFileChange(selectedFiles) {
        this.invalidAttachments = new Array();
        // const files: FileList = (event.target as HTMLInputElement).files;
        const files: FileList = selectedFiles
        const isValidFileslimit = this.isValidFileslimit(files);
        for (let i = 0; i < files.length; i++) {
            const file: File = files[i];
            const isValidFileSize = this.isValidFileSize(file);
            const isValidFileType = this.isValidFileType(file);
            const isValidFileName = this.isValidFileName(file);

            if (isValidFileSize && isValidFileType && isValidFileName && isValidFileslimit) {
                // If file is valid, add file to valid attachments array
                this.addFileToAttachments(files[i]);
            } else {
                // Else, add file to invalid attachments array
                this.invalidAttachments.push({
                    'name': file.name,
                    'isValidSize': isValidFileSize,
                    'isValidType': isValidFileType,
                    'isValidFileslimit': isValidFileslimit,
                    'isValidName': isValidFileName
                });
            }
        }
        this.attachmentRef.nativeElement.value = '';
    }


    addFileToAttachments(file: File) {
        if (this.attachments.length < 10) {
            // eslint-disable-next-line no-debugger
            const isValidFileNameLength = file.name.length <= this.MAX_CHARS_PER_FILE_NAME ? true : false;
            if (isValidFileNameLength) {
                this.attachments.push({
                    'name': file.name,
                    'file': file
                });
            }
        }
    }


    removeFileFromAttachmentsArray(index: number) {
        if (this.attachments[index].ecmDocId !== undefined) {
            this.cspShowSpinner = true;
            this.attachments.splice(index, 1);
            this.attachmentService.attachmentsSubject.next(this.attachments);
            this.cspShowSpinner = false;
        } else {
            this.attachments.splice(index, 1);
        }
    }

    /**
     * uploadFilesToServer
     */
    uploadFilesToServer() {
        // const uploadRequestList :any;
        const newAttachments: Array<Attachment> = this.attachments.filter(
            (value: Attachment, index: number) => !value.ecmDocId
        ) || [];
        const uploadedAttachments: Array<Attachment> = this.attachments.filter(
            (value: Attachment, index: number) => value.ecmDocId
        ) || [];

        if (newAttachments.length > 0) {
            this.cspShowSpinner = true;
            
        } else {
            return;
        }

        // Iterate over all newly selected valid attachments
        // and create list of observables to join
        let count = 0;
        for (let i = 0; i < newAttachments.length; i++) {
            const uploadRequest: UploadRequest = {
                'attachments': new Array(newAttachments[i])
            };
            this.cspShowSpinner = true;
           
            this.attachmentService.uploadFiles(uploadRequest).subscribe(
                (data) => {
                   
                    const ecmData = data.attachment;
                    if (ecmData) {
                        Object.assign(newAttachments[i], {
                            'ecmDocId': ecmData.ecmDocId,
                            'id': ecmData.id,
                            'name': ecmData.id ? ecmData.name : newAttachments[i].name
                        });
                        let logMessage = "AppId:26095 : " + "Attachment uploaded from " + this.fromPage + " ";
                        logMessage = logMessage + "File name : " + ecmData.name + " doc id : " +ecmData.id;
                        this.logService.logMessage(logMessage).subscribe();
                    }

                    // Consolidate already uploaded attachments with newly uploaded attachments
                    this.attachments = uploadedAttachments.concat(newAttachments);
                    // Emit list of all valid attachments to including parent component
                    count++
                    if(newAttachments.length == count){
                        this.cspShowSpinner = false;
                        this.attachFiles.emit(this.attachments);
                        this.onCloseModal.emit();
                    }

                },
                (error) => {
                    // if (error.status == '401') {
                    //     this.casesService.getGeneratedToken().subscribe((data: any) => {
                    //         let accessToken = data["access-token"];
                    //         localStorage.setItem("app-token", accessToken);
                    //         this.uploadFilesToServer();
                    //     }, (error) => {
                    //         console.log('Generated Token Error: ' + error);
                    //         // window.dispatchEvent(new CustomEvent('cah:spinnerHide'));
                    //         window.location.reload();
                            
                    //     });
                    // }
                    // else {
                        count++;
                       
                        let uploadError = error;
                        let logMessage = "AppId:26095 : " + "Attachment upload error from " + this.fromPage + " ";
                        
                        if(uploadError.status==500 && uploadError.error){
                            logMessage = logMessage +  "Error : "  + uploadError.error.errorMessage;
                        }else{
                            logMessage = logMessage +  "Error : " + uploadError.message;
                        }
                                             
                        this.logService.logMessage(logMessage).subscribe();
                        if(newAttachments.length == count){
                            this.cspShowSpinner = false;
                            this.onCloseModal.emit();
                        }
                    // }
                })

        }
       

    }

    /**
     * closeModal
     */
    closeModal() {
        this.showModal = false;
        const validAttachments = this.attachments.filter(value => value.ecmDocId) || [];
        this.attachFiles.emit(validAttachments);
        this.onCloseModal.emit();
    }

    /**
     * ngOnInit
     */
    ngOnInit() {
        this.attachmentService.attachmentsSubject.subscribe((attachments: Array<Attachment>) => {
            const validAttachments = attachments.filter(value => value.ecmDocId) || [];
            const tempAttachments = attachments.filter(value => !value.ecmDocId) || [];
            this.attachments = tempAttachments.concat(validAttachments);
            this.invalidAttachments = [];
        });
    }

    onFileDropped($event){
        this.onFileChange($event);
    }

}
