import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { ShipToListResponse } from '../model/shipToListResponse';
import { CaseSearchResponse } from '../model/caseSearchResponse';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { User } from "../shared/User";
import { CasesService } from './cases.service';


@Injectable({ providedIn: "root" })
export class CaseSearchService {
	public header: Object;
	public retryFlag: boolean = false;
	private baseApiUrl: string = environment.baseApiUrl;
	hostname: string;


	constructor(private http: HttpClient,
		private caseService: CasesService) {

		this.baseApiUrl = environment.baseApiUrl;
	}

	public search(caseNumber: number, filter: any): Observable<CaseSearchResponse> {
		var channelTypes: string = "";
		if (filter.channelTypes.length > 0) {
			channelTypes = '[';
			Object.keys(filter.channelTypes).forEach(function (key) {
				channelTypes += "\'" + filter.channelTypes[key].id + "\',";
			})
			channelTypes = channelTypes.replace(/,\s*$/, "");
			channelTypes += ']';
		}
		let data = {
			fromDate: filter.fromDate,
			toDate: filter.toDate,
			status: filter.status,
			channelTypes: channelTypes
		}
		if (filter.shipto && filter.shipto != "all") {
			data["shipto"] = filter.shipto;
		}
		filter["caseNumber"] = caseNumber;
		filter.channelTypes = channelTypes;
		filter.shipto = filter.shipto == "all" ? '' : filter.shipto;

		const soldToId = User.getUser().soldToAccountNumber;
		return this.http.post(this.baseApiUrl + `/medical-market-cases/soldtos/${soldToId}/v1/cases`, filter)

	}

	public getShipToValues(): Observable<ShipToListResponse> {
    const queryParams = 'filterby=caseenabled';
		const { userId, soldToAccountNumber: soldToId } = User.getUser();
		return this.http.get(this.baseApiUrl + `/medical-market-accounts/users/${userId}/soldtos/${soldToId}/v1/shiptos?${queryParams}`)

	}

}
