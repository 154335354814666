import { Component, OnInit, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { CasesService } from '../../services/cases.service';
import { AttachmentService } from '../../services/attachment.service';
import { CaseDetail } from '../../model/caseDetailResponse';
import { CaseUpdateRequest } from '../../model/caseUpdateRequest';
import { Attachment } from '../../model/attachment';
import { CasesConstants } from '../../constants/cases.constants';
import { DownloadAttachmentResponse } from '../../model/DownloadAttachmentResponse';
import { Title } from '@angular/platform-browser';
import { LogService } from '../../services/log.service';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'csp-case-details',
  templateUrl: './case-detail.component.html',
  styleUrls: ['./case-detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CaseDetailComponent implements OnInit {
  navigationURL = '';
  isFormChanged: boolean;
  caseDetailError: boolean;
  private baseRoutingUrl: string;
  errMessageLine2: string;
  constructor(private casesService: CasesService, private route: ActivatedRoute,
    private titleService:Title,
    private location: Location, private attachmentService: AttachmentService,
    private logService: LogService) { }

  isNavigationBackCalled: boolean = false;
  isCaseDetailAttachModalVisible: boolean = false;
  isModalVisible: boolean = false;
  caseDetail = null;
  channel: string;
  allowNewResponse = false;
  noCommentsAdded: boolean = true;
  submitted: boolean = false;
  comments: string = '';
  errorDetail;
  errMessage: string = "";
  attachments: Array<Attachment> = [];
  name: string = "Continue Add Response";
  heading: string = "Add a Response";
  message: string = "If you leave before submitting the response, you will lose any changes you have made.";
  cspShowSpinner: boolean = false;
  updateCaseError: boolean = false;
  @Output() cspFormChanged = new EventEmitter();
  @Output() cspFormChangedReset = new EventEmitter();
  @Output() cspResetModalVisibility = new EventEmitter();
  @Output() cspAllowNavigation = new EventEmitter();
  @Output() cspAddedNewResponse = new EventEmitter();

  ngOnInit() {
    this.allowNewResponse = false;
    this.errMessage = CasesConstants.CUSTOM_ERROR_MSG;
    this.baseRoutingUrl = environment.baseRoutingUrl;
    this.getCaseDetails();
    window.dispatchEvent(new Event('formClean'));
    window.addEventListener('navigationPrevented', this.updateShowModalStatus.bind(this));
    if (this.attachmentService.sharedAttachment) {
      this.cspFormChanged.emit();
    }
    
    this.titleService.setTitle("Case Detail");
    window.scrollTo(0,0)

  }


  getCaseDetails() {
    this.casesService.getCaseDetails(this.route.snapshot.params.id).subscribe((caseDetail: CaseDetail) => {
      this.caseDetail = caseDetail;
       	var channelTypes = CasesConstants.CHANNELS;
       	var channelId = this.caseDetail.caseHeader.channel;
       	this.channel = channelTypes[channelTypes.map(function (item) { return item.id; }).indexOf(channelId)].value;
        let logMessage: string = "AppId:26095 : Case Detail For Case Id =  " + this.caseDetail.caseHeader.id;
        this.logService.logMessage(logMessage).subscribe();
    },(errorDetail) =>{
      this.caseDetailError = true;
      let errorLogMessage: string = "AppId:26095 : Case Detail Error for Case Id =  " +
            this.route.snapshot.params.id+ " Error: " +  JSON.stringify(errorDetail.error);
      
      this.logService.logMessage(errorLogMessage).subscribe();
    }
    );
  }

  showAttachmentModal() {
    this.isCaseDetailAttachModalVisible = true;
  }

  hideAttachmentModal() {
    this.isCaseDetailAttachModalVisible = false;
  }

  updateShowModalStatus(event) {
    window.dispatchEvent(new CustomEvent('cah:spinnerHide'));
    if(this.isFormChanged){
      if(event.detail){
        this.navigationURL = event.detail;
      }
      event.preventDefault();
      event.stopPropagation();
      this.isModalVisible = true;     
    }
  }


  submitResponse() {
    this.submitted = true;
    if(this.noCommentsAdded)
    {
      setTimeout(function(){
        const ElementWithError:HTMLElement = document.querySelector('.has-error');
        if (ElementWithError) 
        {
          ElementWithError.focus();                
        } }, 500);
      return;
    }
    const caseUpdateRequest: CaseUpdateRequest = {
      note: {
        message: this.comments.replace(/\n/g, '<br/>')
      }
    }
    if (this.attachments && this.attachments.length > 0) {
      const tempAttachment = this.attachments.filter((value, index) => {
        if (value.ecmDocId) { return value; }
      });

      caseUpdateRequest.attachments = tempAttachment.map((value, index) => {
        return { fileName: value.name, fileType: value.type, id: value.id, ecmDocId: value.ecmDocId };
      });
    }
    this.cspShowSpinner = true;
    this.updateCaseError = false;





    this.casesService.updateCase(this.route.snapshot.params.id, this.caseDetail.caseHeader.shipToId, caseUpdateRequest).subscribe(
      caseUpdateResponse => {
        this.submitted = false;
        this.cspShowSpinner = false;
        this.getCaseDetails();
        this.attachments = [];
        this.attachmentService.attachmentsSubject.next(this.attachments);
        this.allowNewResponse = false;
        this.comments = '';
        this.noCommentsAdded = true;
        this.errorDetail = null;
        this.updateCaseError = false;
        window.scrollTo(0,0)
        window.dispatchEvent(new Event('formClean'));
        this.cspAddedNewResponse.emit();
        let logMessage: string = "AppId:26095 : Case Details, Create New Response success : Case Id =  " + this.route.snapshot.params.id;

        this.logService.logMessage(logMessage).subscribe();
      },
      (error) => {
       this.cspShowSpinner = false;
        this.errorDetail = error;
        let logMessage: string = "AppId:26095 : Case Details, Create New Response Failed : Case Id =  " + this.route.snapshot.params.id + " : ";
        if (this.errorDetail.error["SY/530"] && this.errorDetail.error["SY/530"].includes(CasesConstants.CASE_LOCKED_KEY)) {
          this.updateCaseError = true;
          this.errMessage = CasesConstants.CASE_LOCKED_MSG_LINE_1;
          this.errMessageLine2 = CasesConstants.CASE_LOCKED_MSG_LINE_2;
          
          logMessage = logMessage + this.errMessage;
        }else 
        
        if(this.errorDetail.error){
          logMessage = logMessage + JSON.stringify(this.errorDetail.error)
        }
        window.scrollTo(0,0)
        
        this.logService.logMessage(logMessage).subscribe();
      });
    this.cspFormChangedReset.emit();
  }

  navigateAway() {
    this.attachments = [];
    this.attachmentService.sharedAttachment = [];
    window.dispatchEvent(new Event('formClean'));
    this.isModalVisible = false;
    if (this.isNavigationBackCalled) {
      this.location.back();
    } else if(this.isFormChanged) {
      window.location.href = this.navigationURL;
    }
  }

  backClick(e: MouseEvent) {
    e.preventDefault();
    const isFormDataChanged = (this.comments != '' || this.attachments.length > 0) ? true : false;
    if (!isFormDataChanged) {
      this.location.back();
    } else {
      this.isNavigationBackCalled = true;
      this.isModalVisible = true;
    }
  }
  
  hideModal() {
    this.isNavigationBackCalled=false
    this.isModalVisible = false;
  }

  attachUploadedFiles(_attachments) {
    window.dispatchEvent(new Event('formDirty'));
    this.isFormChanged = true;
    if (_attachments) {
      // Replace included attachments
      this.attachments = [].concat(_attachments);
      this.attachmentService.attachmentsSubject.next(this.attachments.filter(
        value => value.ecmDocId
      ) || []
      );
    } else {
      this.attachmentService.attachmentsSubject.next([]);
    }
  }

  downloadAttachment(ecmDocId: string,fileName: string){
    this.cspShowSpinner = true;
    this.attachmentService.downloadFile(ecmDocId).subscribe((data: DownloadAttachmentResponse) => {
      this.cspShowSpinner = false;
        const byteString = window.atob(data.fileByteArray);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
          int8Array[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([int8Array], { type: data.fileType });  
        
        var objectUrl = window.URL.createObjectURL(blob);
       
        var anchor = document.createElement("a");
        anchor.download = fileName;
        anchor.href = objectUrl;
        anchor.click();
        let downloadAttachmentLogMessage: string = "AppId:26095 : Download Attachment Success: "+  
              "Case Id: " + this.caseDetail.caseHeader.id + " File Name: " + fileName;
        this.logService.logMessage(downloadAttachmentLogMessage).subscribe();
       
    }, (error) => {
      
      const donwloadError = error;
      this.cspShowSpinner = false;
      let dnldAttErrLogMessage: string = "AppId:26095 : Download Attachment Error: "+  
              "Case Id: " + this.caseDetail.caseHeader.id + " , File Name: " + fileName +
              " , Error Status: "+ donwloadError.status +" , Error Message: "+ JSON.stringify(donwloadError.error);
      this.logService.logMessage(dnldAttErrLogMessage).subscribe();
    }
    );
  }
  
  removeAttachments(index: number) {
    this.cspShowSpinner = true;
    this.attachments.splice(index, 1);
    this.attachmentService.attachmentsSubject.next(this.attachments);
    this.cspShowSpinner= false;
  }

  
  setFormChangeFlags(){
    this.noCommentsAdded = this.comments == '' ? true : false;
    const isFormDataChanged = (this.comments != '' || this.attachments.length > 0) ? true : false;
    if(isFormDataChanged){
      this.isFormChanged = true;
      window.dispatchEvent(new Event('formDirty'));
    }else{
      this.isFormChanged = false;
      window.dispatchEvent(new Event('formClean'));
    }
  }

  

}
